import { useNavigate, useParams } from 'react-router-dom';
import { Button, Grid, MenuItem } from '@mui/material';
import { Form } from './styled';
import { BasePage } from '../../../../../components/BaseMain';
import { goToAdmin, goToBack, goToCoinList } from '../../../../../router/coordinator';
import {
  AlertMessage,
  Loading,
  Modal,
  TextFieldKeyPress,
  TextFieldNextFocus,
  TitlePages
} from '../../../../../components';
import { clearAlertMessage, isDesktop } from '../../../../../utils';
import { useForm } from '../../../../../hooks/useForm';
import { useCallback, useEffect, useState } from 'react';
import { editCoin, findCoin } from '../../../../../services';
import { GENERIC_ERROR } from '../../../../../utils/constantes';
import { Coin } from '../../../../../utils/enums';
import { InformativeText } from '../../../../../components/InformativeText';
import { validateMandatoryInput } from '../../../../../utils/validates';

export function EditCoinPage() {
  const navigate = useNavigate();
  const breadcrumbs = [
    { title: 'Administrativo', functionCoordinator: () => goToAdmin(navigate) },
    { title: 'Moedas', functionCoordinator: () => goToCoinList(navigate) },
    { title: 'Editar' }
  ];

  const { uuid } = useParams();
  if (!uuid) {
    goToBack(navigate);
  }

  const [form, onChange, _, setForm] = useForm({
    title: '',
    acronym: ''
  });
  const [errorsValidation, setErrorsValidation] = useState({});
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const init = useCallback(async () => {
    try {
      setLoading(true);

      const coin = await findCoin(uuid);

      setForm({
        title: coin?.title,
        acronym: coin?.acronym
      });
    } catch (error) {
      setAlertMessage({
        title: error?.message || GENERIC_ERROR,
        message: error?.trace && `Código: ${error?.trace}`
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  const onConfirmModal = async () => {
    try {
      setLoading(true);
      handleClose();

      const { title, acronym } = form;

      await editCoin(uuid, title, acronym);

      setAlertMessage((prev) => ({
        ...prev,
        title: 'Moeda editada com sucesso.',
        message: '',
        severity: 'success'
      }));
    } catch (error) {
      setAlertMessage({
        title: error?.message || GENERIC_ERROR,
        message: error?.trace && `Código: ${error?.trace}`
      });
    } finally {
      setLoading(false);
    }
  };

  const disabledButton = () => {
    const fields = Object.keys(form);
    return !fields?.every((field) => form[field]);
  };

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages title="Editar Moeda" />

      <Loading loading={loading} />
      <Grid container>
        <AlertMessage
          message={alertMessage?.message}
          severity={alertMessage?.severity}
          title={alertMessage?.title}
          onClose={() => clearAlertMessage(setAlertMessage)}
        />
      </Grid>

      <Form container xs={12} rowGap={2} columnSpacing={isDesktop() && 2}>
        <Grid item xs={12} lg={4}>
          <TextFieldNextFocus
            label="Nome"
            name="title"
            required
            value={form.title?.toUpperCase?.()}
            onChange={onChange}
            onBlur={() => validateMandatoryInput('title', form, setErrorsValidation)}
            error={errorsValidation.title}
            helperText={errorsValidation.title}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextFieldKeyPress
            label="Sigla da moeda"
            placeholder="BRL"
            name="acronym"
            required
            select
            value={form?.acronym?.toUpperCase?.()}
            onChange={onChange}
            onBlur={() => validateMandatoryInput('acronym', form, setErrorsValidation)}
            error={errorsValidation.acronym}
            helperText={errorsValidation.acronym}
          >
            {Object.values(Coin)?.map((coinISO) => (
              <MenuItem key={coinISO} value={coinISO}>
                {coinISO}
              </MenuItem>
            ))}
          </TextFieldKeyPress>
        </Grid>

        <InformativeText />

        <Grid item xs={12} className="buttonGroup twoButtons nearTheTop">
          <Button variant="outlined" onClick={() => goToCoinList(navigate)}>
            Voltar
          </Button>
          <Button onClick={handleOpen} disabled={disabledButton()}>
            Editar
          </Button>
        </Grid>
      </Form>

      <Modal
        open={openModal}
        onClose={handleClose}
        onConfirm={onConfirmModal}
        title="Editar moeda"
        text="Tem certeza que deseja editar as informações desta moeda? "
      />
    </BasePage>
  );
}
