import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import LockIcon from '@mui/icons-material/Lock';
import { Grid, Icon } from '@mui/material';
import { BasePage } from '../../../components/BaseCredentials';
import { Button, Content, Form, Subtitle, Title } from './styled';
import { ReactComponent as LoginImage } from '../../../assets/loginImage.svg';
import { metrics } from '../../../theme/styles';
import { useForm } from '../../../hooks/useForm';
import { validatePassword } from '../../../utils/validates';
import { TextFieldPassword, RulesPassword, Loading, AlertMessage } from '../../../components';
import { useQuery } from '../../../hooks/useQuery';
import { recoverPassword, setItemLocalStorage } from '../../../services';
import { goToLogin } from '../../../router/coordinator';
import { GENERIC_ERROR } from '../../../utils/constantes';

export function RedefinePassword() {
  const navigate = useNavigate();
  const auth = useQuery().get('auth');
  if (!auth) {
    goToLogin(navigate);
  }

  const [form, onChange] = useForm({ password: '', confirmPassword: '' });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    title: '',
    message: '',
    severity: '',
    progressDuration: undefined,
    onCompleteProgress: undefined
  });
  const [errorsValidation, setErrorsValidation] = useState({
    confirmPassword: false,
    password: false
  });

  useEffect(() => {
    setItemLocalStorage('token', auth);
  }, []);

  // Valida campo de senha
  const validateInputPasword = () => {
    const { password } = form;
    const isValid = validatePassword(password);
    setErrorsValidation((prevError) => ({ ...prevError, password: !isValid }));
  };

  const validateInputConfirmPasword = () => {
    const { confirmPassword, password } = form;
    if (confirmPassword) {
      const isValid = confirmPassword === password;
      setErrorsValidation((prevError) => ({ ...prevError, confirmPassword: !isValid }));
    }
  };

  const disabledButton = () => {
    if (!form.password) {
      return true;
    }
    if (!form.confirmPassword) {
      return true;
    }
    if (errorsValidation.password) {
      return true;
    }
    if (errorsValidation.confirmPassword) {
      return true;
    }

    return false;
  };

  const onConfirmButton = async () => {
    try {
      setLoading(true);
      await recoverPassword(form.password, form.confirmPassword);

      setMessage((prev) => ({
        ...prev,
        title: `Sua senha foi redefinida com sucesso. Aguarde que você será redirecionado para a tela de login.`,
        severity: 'success'
      }));
      setLoading(false);

      setTimeout(() => goToLogin(navigate), 4000);
    } catch (err) {
      setMessage({
        title: err.message || GENERIC_ERROR,
        message: err.trace && `Código: ${err.trace}`
      });
      setLoading(false);
    }
  };

  return (
    <BasePage ImageReceived={LoginImage}>
      <Loading loading={loading} />
      <Content className="center">
        <Icon component={LockIcon} sx={{ fontSize: metrics.sizeIconCredential }} />
        <Title>Redefinição de senha</Title>
        <Subtitle>Defina sua nova senha</Subtitle>
        <Form>
          <AlertMessage
            title={message?.title}
            message={message?.message}
            severity={message?.severity}
            progressDuration={message?.progressDuration}
            onCompleteProgress={message?.onCompleteProgress}
          />
          <RulesPassword value={form?.password} />
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
              <TextFieldPassword
                value={form?.password}
                onChange={onChange}
                label="Senha"
                name="password"
                onBlur={validateInputPasword}
                error={errorsValidation.password}
                helperText={errorsValidation.password && 'Senha inválida'}
                required
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <TextFieldPassword
                label="Confirmar a senha"
                value={form?.confirmPassword}
                onChange={onChange}
                name="confirmPassword"
                onBlur={validateInputConfirmPasword}
                error={errorsValidation.confirmPassword}
                required
                helperText={
                  errorsValidation.confirmPassword &&
                  'A confirmação de senha não coincide com a senha '
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" disabled={disabledButton()} onClick={onConfirmButton}>
                Cadastrar
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Content>
    </BasePage>
  );
}
