import styled from 'styled-components';
import { colors } from '../../theme/styles';
import { breakpoints } from '../../theme/breakpoints';

export const Container = styled.div`
  display: flex;

  #content {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const Content = styled.section`
  background-color: ${colors.backgroundWhite};
  padding: 1.5rem;
  min-height: 100vh;
  position: relative;

  @media (min-width: ${breakpoints.md}) {
    padding: 2rem;
    margin: 0 1.5rem 1.5rem 0;
    min-height: 0;
    border-radius: 10px;
  }
`;
