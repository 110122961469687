import { TablePagination } from '@mui/material';

export function PaginationMobile({ handlePage, handleChangeRowsPerPage, ...props }) {
  return (
    <TablePagination
      {...props}
      component="div"
      onPageChange={handlePage}
      labelDisplayedRows={({ from, to, count }) => `${from}–${to} de ${count}`}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelRowsPerPage=""
      rowsPerPageOptions={[]}
    />
  );
}
