import styled from 'styled-components';

export const Item = styled.div`
  .MuiMenuItem-root {
    color: ${({ color }) => color};
  }
`;

export const IconContainer = styled.span`
  margin-top: 0.2rem;
  margin-right: 0.3rem;
`;
