import { useState } from 'react';

// Hook para lidar com formulários
export function useForm(initialValueForm) {
  const [form, setForm] = useState(initialValueForm);

  const handle = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  const resetForm = () => {
    setForm(initialValueForm);
  };

  return [form, handle, resetForm, setForm];
}
