import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { PaginationDesktop, SkeletonDesktop, UserInformations } from '../../../../../../components';
import { convertCentsToWhole } from '../../../../../../utils/convert';
import dayjs from 'dayjs';

export function DesktopTable({
  historics,
  count,
  loading,
  page,
  offset,
  handlePage,
  handleChangeRowsPerPage
}) {
  const historicList = historics?.map((historic) => (
    <TableRow key={historic.uuid} className="not-effect">
      <TableCell>
        <UserInformations user={historic.user} />
      </TableCell>
      <TableCell>
        {(historic.date && dayjs(historic.date, 'YYYY-MM-DD').format('DD/MM/YYYY')) || '-'}
      </TableCell>
      <TableCell>
        {historic.exchangeInReal && convertCentsToWhole(historic.exchangeInReal, 'REAL')}
      </TableCell>
      <TableCell>{historic.origin?.toTitleCase()}</TableCell>
      <TableCell>{historic.type?.toTitleCase()}</TableCell>
      <TableCell>{historic.timestamp?.createdAt?.toMask('date')}</TableCell>
    </TableRow>
  ));

  function RenderTable() {
    if (loading) {
      return <SkeletonDesktop numbersOfColumns={6} />;
    }

    return historicList;
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Usuário que adicionou</TableCell>
              <TableCell>Data da cotação</TableCell>
              <TableCell>Câmbio em real</TableCell>
              <TableCell>Origem</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Data que foi adicionado</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <RenderTable />
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationDesktop
        count={count}
        page={page}
        rowsPerPage={offset}
        handlePage={handlePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}
