import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material';
import { PaginationDesktop, SkeletonDesktop, TargetStatus } from '../../../../../../components';
import { goToCompanyInformation } from '../../../../../../router/coordinator';
import { Order, Status } from '../../../../../../utils/enums';
import { hasPermission } from '../../../../../../utils';

export function DesktopTable({
  companies,
  navigate,
  count,
  loading,
  page,
  offset,
  handlePage,
  handleChangeRowsPerPage,
  order,
  handleOrder
}) {
  const goTo = (uuid) => {
    if (hasPermission('FIND_COMPANY')) {
      goToCompanyInformation(navigate, uuid);
    }
  };

  const companiesList = companies?.map((company) => {
    return (
      <TableRow
        key={company?.uuid}
        onClick={() => goTo(company?.uuid)}
        className={!hasPermission('FIND_COMPANY') && 'not-effect'}
      >
        <TableCell>{company?.name?.toTitleCase()}</TableCell>
        <TableCell>{company?.document?.toMask('document')}</TableCell>
        <TableCell>{company?.acronym?.toUpperCase()}</TableCell>
        <TableCell>
          <TargetStatus status={company?.active ? Status.ACTIVE : Status.INACTIVE} />
        </TableCell>
        <TableCell>{company?.industry?.title?.toTitleCase() || '-'}</TableCell>
      </TableRow>
    );
  });

  function RenderTable() {
    if (loading) {
      return <SkeletonDesktop numbersOfColumns={5} />;
    }
    return companiesList;
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  direction={order}
                  active={order === Order.ASC}
                  onClick={handleOrder}
                >
                  Nome
                </TableSortLabel>
              </TableCell>
              <TableCell>Documento</TableCell>
              <TableCell>Sigla</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Setor de atuação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <RenderTable />
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationDesktop
        count={count}
        page={page}
        rowsPerPage={offset}
        handlePage={handlePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}
