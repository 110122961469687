import { MenuItem, Menu as MenuMUI } from '@mui/material';
import {
  goToChooseCompany,
  goToEditAccount,
  goToLogin,
  goToRedefinePassword,
  goToTermsAndGuidelines
} from '../../router/coordinator';
import { clearLocalStorage, getItemLocalStorage } from '../../services/localStorageService';
import { useEffect, useState } from 'react';

export function Menu(props) {
  const navigate = props.navigate;
  const [showChangeCompany, setShowChangeCompany] = useState(true);

  useEffect(() => {
    const user = getItemLocalStorage('user');
    setShowChangeCompany(user?.show_change_company);
  }, []);

  const goTo = (path) => {
    const routes = {
      editAccount: goToEditAccount,
      redefinePassword: goToRedefinePassword,
      termsAndGuidelines: goToTermsAndGuidelines,
      chooseCompany: goToChooseCompany,
      exit: goToLogin
    };

    if (routes[path]) {
      routes[path](navigate);
    }
  };

  const exit = () => {
    clearLocalStorage();
    goTo('exit');
  };

  return (
    <MenuMUI {...props}>
      {showChangeCompany && (
        <MenuItem onClick={() => goTo('chooseCompany')}>Mudar empresa escolhida</MenuItem>
      )}
      <MenuItem onClick={() => goTo('editAccount')}>Editar conta</MenuItem>
      <MenuItem onClick={() => goTo('redefinePassword')}>Redefinir senha</MenuItem>
      <MenuItem onClick={() => goTo('termsAndGuidelines')}>Termos e diretrizes</MenuItem>
      <MenuItem sx={{ color: 'red' }} onClick={exit}>
        Sair
      </MenuItem>
    </MenuMUI>
  );
}
