import { TableRow } from '@mui/material';
import styled from 'styled-components';

export const Row = styled(TableRow)`
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
