import styled from 'styled-components';
import { colors } from '../../../../../../../theme/styles';
import { breakpoints } from '../../../../../../../theme/breakpoints';

export const Content = styled.div`
  margin: 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    height: 10rem;
    width: 10rem;
    color: ${colors.theme.primary};
  }

  h1 {
    font-size: 3rem;
    color: ${colors.theme.primary};
  }

  p {
    font-size: 2rem;
    text-align: center;

    @media (min-width: ${breakpoints.md}) {
      font-size: 1.5rem;
      width: 60%;
    }
  }

  button {
    margin-top: 2rem;
    width: 100%;

    @media (min-width: ${breakpoints.md}) {
      width: 30%;
    }
  }
`;
