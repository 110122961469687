import React from 'react';
import { FilterTitle, Filters } from './styled';
import { isDesktop } from '../../utils';

export function FilterContainer({ children }) {
  return (
    <React.Fragment>
      <FilterTitle>Filtros</FilterTitle>
      <Filters container xs={12} rowGap={2} columnSpacing={isDesktop() && 2}>
        {children}
      </Filters>
    </React.Fragment>
  );
}
