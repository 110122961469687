import styled from 'styled-components';
import { colors, metrics } from '../../../theme/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Box, Grid, Button as ButtonMUI } from '@mui/material';

export const Icon = styled(AccountCircleIcon)`
  height: ${metrics.sizeIconCredential};
  width: ${metrics.sizeIconCredential};
`;

export const Content = styled(Box)`
  text-align: center;
  width: 80%;
`;

export const Title = styled.h1`
  font-size: ${metrics.titleCredential};
  margin: 0;
`;

export const Subtitle = styled.h3`
  margin: 0;
  font-weight: ${metrics.weight.regular};
  color: ${colors.mediumBlack};
  font-size: ${metrics.size.small};
`;

export const Form = styled(Grid)`
  width: 100%;
  padding-top: 2rem;
`;

export const ContainerButtonUpload = styled(Grid)`
  text-align: left;

  .MuiFormLabel-root {
    margin-top: 0.8rem;
    font-weight: 500;
  }
`;

export const ButtonUpload = styled(ButtonMUI)`
  && {
    border: 0.13rem dashed ${colors.theme.primary};
    padding: 0.5rem;
    margin-top: 0.3rem;
    min-width: ${metrics.buttonUpload.width};
  }
`;

export const FileUploadDescription = styled.p`
  && {
    align-self: center;
    color: ${colors.gray};
    text-overflow: ellipsis;
    width: 100%;
  }
`;

export const Button = styled(ButtonMUI)`
  && {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 3rem;
  }
`;

export const Links = styled.div`
  width: 100%;
  text-align: right;
  margin-top: 1rem;

  p {
    margin: 0;
    margin-top: 0.5rem;
  }
`;
