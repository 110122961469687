import { colors as colorsMUI } from '@mui/material';

// Variáveis para padronização das cores
export const colors = {
  backgroundWhite: '#F8F4F9',
  mediumBlack: '#575557',
  gray: '#989494',
  black: '#000000',
  lightBlack: '#0a0a0a',
  status: {
    text: {
      green: colorsMUI.green[800],
      red: colorsMUI.red[800],
      yellow: colorsMUI.amber[800],
      grey: colorsMUI.grey[500]
    },
    backgroundColor: {
      red: colorsMUI.red[100],
      yellow: colorsMUI.amber[100],
      green: colorsMUI.green[100],
      grey: colorsMUI.grey[100]
    }
  },
  theme: {
    primary: '#058C42',
    secundary: '#EA9010',
    background: '#262730',
    darkGray: '#00241B'
  }
};
