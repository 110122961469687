import { useEffect, useState } from 'react';
import { Menu } from '../Menu';
import { Content, Image } from './styled';
import { getItemLocalStorage } from '../../services/localStorageService';

export function User({ navigate }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [user, setUser] = useState({});
  const open = Boolean(anchorEl);

  useEffect(() => {
    const userStorage = getItemLocalStorage('user');
    setUser(userStorage);
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const user = getItemLocalStorage('user');
    setUser(user);
  }, []);

  return (
    <>
      <Content
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Image alt={`foto de perfil do ${user?.name}`} src={user?.profile_image} />
        <div>
          <h3>{user?.fullname?.toCapitalizeCase()}</h3>
          <h4>{user?.company?.toCapitalizeCase()}</h4>
        </div>
      </Content>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        navigate={navigate}
      />
    </>
  );
}
