import {
  HiddenForPermission,
  MobileTableConteiner,
  PaginationMobile,
  SkeletonMobile,
  TableMobileButton,
  TableMobileCell,
  TableMobileContent,
  TableMobileItem,
  TableMobileTitle,
  TargetStatus,
  UserInformations
} from '../../../../../../components';
import { ActiveAndInactiveButton } from '../../../../../../components/ActiveAndInactiveButton';
import { goToDatailsUser, goToEditUserAdmin } from '../../../../../../router/coordinator';
import { Status } from '../../../../../../utils/enums';

export function MobileTable({
  users,
  navigate,
  openModal,
  loading,
  count,
  page,
  offset,
  handlePage
}) {
  const usersList = users?.map((user) => {
    const status = user.status?.toLowerCase();
    const notShowButton = status === Status.PENDING || status === Status.REPROVED || false;
    const isActiveButton = status === Status.ACTIVE && status !== Status.PENDING;

    return (
      <TableMobileCell key={user.uuid}>
        <TableMobileItem>
          <TableMobileContent>
            <UserInformations user={user} src={user.profileImage?.imageBase64} />
          </TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Status </TableMobileTitle>
          <TableMobileContent>
            <TargetStatus status={user.status} />
          </TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Celular </TableMobileTitle>
          <TableMobileContent>
            {(user.cellphone && user.cellphone.toMask('cellphone')) || '-'}
          </TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Telefone </TableMobileTitle>
          <TableMobileContent>
            {(user.tellphone && user.tellphone.toMask('tellphone')) || '-'}
          </TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>E-mail </TableMobileTitle>
          <TableMobileContent>{user.email && user.email}</TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Cargo </TableMobileTitle>
          <TableMobileContent>
            {user.office && user.office.title && user.office.title.toTitleCase()}
          </TableMobileContent>
        </TableMobileItem>

        <HiddenForPermission keys={'ACTIVE_INACTIVE_USER_ADMIN'}>
          <TableMobileItem>
            <TableMobileTitle>Mudar status</TableMobileTitle>
            <TableMobileContent>
              {!notShowButton ? (
                <ActiveAndInactiveButton
                  active={isActiveButton}
                  onClick={() => openModal(user.uuid)}
                />
              ) : (
                '-'
              )}
            </TableMobileContent>
          </TableMobileItem>
        </HiddenForPermission>

        <HiddenForPermission keys={['FIND_USER_ADMIN', 'EDIT_ADMIN_USER']}>
          <HiddenForPermission keys="FIND_USER_ADMIN">
            <TableMobileButton onClick={() => goToDatailsUser(navigate, user.uuid)}>
              Ver detalhes
            </TableMobileButton>
          </HiddenForPermission>
          <HiddenForPermission keys="EDIT_ADMIN_USER">
            <TableMobileButton onClick={() => goToEditUserAdmin(navigate, user.uuid)}>
              Editar
            </TableMobileButton>
          </HiddenForPermission>
        </HiddenForPermission>
      </TableMobileCell>
    );
  });

  return (
    <MobileTableConteiner>
      {!loading ? usersList : <SkeletonMobile numbersOfColumns={10} />}
      <PaginationMobile count={count} page={page} rowsPerPage={offset} handlePage={handlePage} />
    </MobileTableConteiner>
  );
}
