import styled from 'styled-components';
import { IconButton as IconButtonMUI } from '@mui/material';
import { colors } from '../../../theme/styles';

export const IconButton = styled(IconButtonMUI)`
  svg {
    color: white;
  }

  && {
    background-color: ${colors?.theme?.primary};
    border-radius: 5px;

    &:hover {
      background-color: ${colors?.theme?.primary}d2;
    }
  }
`;
