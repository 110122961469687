import { useNavigate } from 'react-router-dom';
import { goToBack } from '../../../router/coordinator';
import { BasePage } from '../../../components/BaseMain';
import { AlertMessage, Loading, PDFViewer, TopBackArea } from '../../../components';
import { useCallback, useEffect } from 'react';
import { clearAlertMessage } from '../../../utils';
import { GENERIC_ERROR } from '../../../utils/constantes';
import { listAllHelpFiles } from '../../../services/helpFileService';
import { useUpdateState } from '../../../hooks/useUpdateState';

export function HelpFilePage() {
  const navigate = useNavigate();
  const breadcrumbs = [{ title: 'Ajuda' }];

  const [{ helpFile, loading }, setState] = useUpdateState({
    helpFile: true,
    loading: true
  });
  const [alertMessage, setAlertMessage] = useUpdateState({
    title: '',
    message: '',
    severity: '',
    onClose: () => {}
  });

  const init = useCallback(async () => {
    try {
      setState({ loading: true });

      const result = await listAllHelpFiles();

      setState({ helpFile: result.base64 });
    } catch (err) {
      setAlertMessage({
        title: err?.message || GENERIC_ERROR,
        message: err?.trace && `Código: ${err.trace}`
      });
    } finally {
      setState({ loading: false });
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TopBackArea onBack={() => goToBack(navigate)} />
      <Loading loading={loading} />
      <AlertMessage
        message={alertMessage?.message}
        severity={alertMessage?.severity}
        title={alertMessage?.title}
        onClose={() => clearAlertMessage(setAlertMessage)}
      />

      <PDFViewer base64={helpFile} />
    </BasePage>
  );
}
