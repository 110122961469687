import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material';
import { Row } from './styled';
import { convertCentsToWhole } from '../../../../../../utils/convert';
import { goToVisualizeAdditionalContract } from '../../../../../../router/coordinator';
import { PaginationDesktop, SkeletonDesktop, TargetStatus } from '../../../../../../components';
import { Order } from '../../../../../../utils/enums';
import { hasPermission } from '../../../../../../utils';

export function DesktopTable({
  contracts,
  navigate,
  count,
  loading,
  page,
  offset,
  order,
  orderBy,
  handlePage,
  handleChangeRowsPerPage,
  handleOrder
}) {
  const goTo = (uuid) => {
    if (hasPermission('FIND_FILE_ADDITIONAL_CONTRACT')) {
      goToVisualizeAdditionalContract(navigate, uuid);
    }
  };

  const contractsList = contracts?.map((contract) => {
    return (
      <Row
        key={contract.id}
        onClick={() => goTo(contract.uuid)}
        className={!hasPermission('FIND_FILE_ADDITIONAL_CONTRACT') && 'not-effect'}
      >
        <TableCell>{contract.order && `${contract.order}°`}</TableCell>
        <TableCell>
          <TargetStatus status={contract.contractType?.title} noCircle variant="solid" />
        </TableCell>
        <TableCell>{contract.index?.name?.toUpperCase?.() || '-'}</TableCell>
        <TableCell>{contract.coinHistory?.coin?.title?.toTitleCase?.() || '-'}</TableCell>
        <TableCell>{convertCentsToWhole(contract.coinHistory?.exchangeInReal) || '-'}</TableCell>
        <TableCell>{convertCentsToWhole(contract.newAmount) || '-'}</TableCell>
        <TableCell>{contract.initDate?.toMask('date') || '-'}</TableCell>
        <TableCell>{contract.expirationDate?.toMask('date') || '-'}</TableCell>
        <TableCell>{contract.observation || '-'}</TableCell>
      </Row>
    );
  });

  function RenderTable() {
    if (loading) {
      return <SkeletonDesktop numbersOfColumns={9} />;
    }

    return contractsList;
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="15px">
                <TableSortLabel
                  direction={order}
                  active={order === Order.ASC && orderBy === 'order'}
                  onClick={() => handleOrder('order')}
                >
                  Ordem
                </TableSortLabel>
              </TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Índice</TableCell>
              <TableCell>Moeda</TableCell>
              <TableCell>Cotação em real</TableCell>
              <TableCell>Valor</TableCell>
              <TableCell>
                <TableSortLabel
                  direction={order}
                  active={order === Order.ASC && orderBy === 'initDate'}
                  onClick={() => handleOrder('initDate')}
                >
                  Data inicial
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  direction={order}
                  active={order === Order.ASC && orderBy === 'expirationDate'}
                  onClick={() => handleOrder('expirationDate')}
                >
                  Data de expiração
                </TableSortLabel>
              </TableCell>
              <TableCell>Observação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <RenderTable />
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationDesktop
        count={count}
        page={page}
        rowsPerPage={offset}
        handlePage={handlePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}
