import { useNavigate } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import { Form } from './styled';
import { BasePage } from '../../../../../components/BaseMain';
import { goToAdmin, goToCreateIndex, goToIndexList } from '../../../../../router/coordinator';
import {
  AlertMessage,
  Loading,
  Modal,
  TextFieldNextFocus,
  TitlePages
} from '../../../../../components';
import { clearAlertMessage, clearPercent, isDesktop } from '../../../../../utils';
import { useForm } from '../../../../../hooks/useForm';
import { useState } from 'react';
import { GENERIC_ERROR } from '../../../../../utils/constantes';
import { createIndex } from '../../../../../services';
import { InformativeText } from '../../../../../components/InformativeText';

export function CreateIndexPage() {
  const navigate = useNavigate();
  const breadcrumbs = [
    { title: 'Administrativo', functionCoordinator: () => goToAdmin(navigate) },
    { title: 'Índices', functionCoordinator: () => goToCreateIndex(navigate) },
    { title: 'Cadastrar' }
  ];

  const [form, onChange, clearForm] = useForm({
    name: '',
    description: ''
  });
  const [errorsValidation, setErrorsValidation] = useState({});
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  // --- Lógica do modal
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const onConfirmModal = async () => {
    try {
      setLoading(true);
      handleClose();

      const { name, description, percent } = form;
      const percentClear = percent && clearPercent(percent);

      await createIndex(name, percentClear, description);

      setAlertMessage((prev) => ({
        ...prev,
        title: 'Índice criado com sucesso.',
        severity: 'success'
      }));

      clearForm();
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
    } finally {
      setLoading(false);
    }
  };
  // ---

  const validateMandatoryInput = (field) => {
    if (!form[field]) {
      setErrorsValidation((prevError) => ({ ...prevError, [field]: true }));
      return;
    }
    setErrorsValidation((prevError) => ({ ...prevError, [field]: false }));
  };

  const disabledButton = () => {
    if (!form.name) {
      return true;
    }

    return false;
  };

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages
        title="Cadastrar novo índice"
        description="Aqui será criado um novo índice, mas qualquer valor deve ser adicionado em sua tela de histórico."
      />

      <Loading loading={loading} />
      <Grid container>
        <AlertMessage
          message={alertMessage.message}
          severity={alertMessage.severity}
          title={alertMessage.title}
          onClose={() => clearAlertMessage(setAlertMessage)}
        />
      </Grid>

      <Form container xs={12} rowGap={2} columnSpacing={isDesktop() && 2}>
        <Grid item xs={12} lg={3}>
          <TextFieldNextFocus
            label="Nome"
            name="name"
            required
            value={form.name.toUpperCase()}
            onChange={onChange}
            onBlur={() => validateMandatoryInput('name')}
            error={errorsValidation.name}
            helperText={errorsValidation.name && 'O nome é obrigatório'}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextFieldNextFocus
            label="Descrição"
            name="description"
            value={form.description.toUpperCase()}
            onChange={onChange}
          />
        </Grid>

        <InformativeText />

        <Grid item xs={12} className="buttonGroup twoButtons nearTheTop">
          <Button variant="outlined" onClick={() => goToIndexList(navigate)}>
            Voltar
          </Button>
          <Button onClick={handleOpen} disabled={disabledButton()}>
            Criar
          </Button>
        </Grid>
      </Form>

      <Modal
        open={openModal}
        onClose={handleClose}
        onConfirm={onConfirmModal}
        title="Criar novo índice"
        text={`Tem certeza que deseja criar o índice ${form.name && form.name.toUpperCase()}?`}
        warning="Após ser criado, o índice não poderá ser apagado ou ter suas informações alteradas."
      />
    </BasePage>
  );
}
