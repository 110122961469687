import { Link } from '@mui/material';
import { Container } from './styles';

export function Card({ title, quantity, goTo, navigate }) {
  return (
    <Container>
      <h4>{title}</h4>
      <div>
        <span>{quantity}</span>
        <Link onClick={() => goTo(navigate)}>Ver lista</Link>
      </div>
    </Container>
  );
}
