import {
  HiddenForPermission,
  MobileTableConteiner,
  PaginationMobile,
  SkeletonMobile,
  TableMobileButton,
  TableMobileCell,
  TableMobileContent,
  TableMobileItem,
  TableMobileTitle,
  UserInformations
} from '../../../../../../components';
import { goToEditHistoryIndex } from '../../../../../../router/coordinator';

export function MobileTable({ historics, loading, count, page, offset, handlePage, navigate }) {
  const indexesList = historics?.map((historic) => (
    <TableMobileCell key={historic.uuid}>
      <TableMobileItem direction="column">
        <TableMobileTitle direction="column">Usuário que adicionou</TableMobileTitle>
        <TableMobileContent>
          <UserInformations src={historic?.user?.profileImage?.imageBase64} user={historic.user} />
        </TableMobileContent>
      </TableMobileItem>

      <TableMobileItem>
        <TableMobileTitle>Data</TableMobileTitle>
        <TableMobileContent>{historic.date?.toMask('date', 'MM/YYYY')}</TableMobileContent>
      </TableMobileItem>

      <TableMobileItem>
        <TableMobileTitle>Porcentagem</TableMobileTitle>
        <TableMobileContent>
          {historic.percent?.toLocaleString('pt-br', {
            minimumFractionDigits: 4
          })}
          %
        </TableMobileContent>
      </TableMobileItem>

      <HiddenForPermission keys="EDIT_HISTORY_INDEX">
        <TableMobileButton onClick={() => goToEditHistoryIndex(navigate, historic.uuid)}>
          Editar
        </TableMobileButton>
      </HiddenForPermission>
    </TableMobileCell>
  ));

  return (
    <MobileTableConteiner>
      {!loading ? indexesList : <SkeletonMobile numbersOfColumns={3} />}
      <PaginationMobile count={count} page={page} rowsPerPage={offset} handlePage={handlePage} />
    </MobileTableConteiner>
  );
}
