import { useNavigate, useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { Button, Grid, InputAdornment, MenuItem, TextField } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { DatePicker } from '@mui/x-date-pickers';
import { BasePage } from '../../../../../components/BaseMain';
import {
  goToAdmin,
  goToBack,
  goToCoinList,
  goToCreateHistoryCoin
} from '../../../../../router/coordinator';
import {
  AlertMessage,
  EmptyTable,
  FilterContainer,
  HiddenForPermission,
  TitlePages
} from '../../../../../components';
import { useForm } from '../../../../../hooks/useForm';
import { clearAlertMessage, hasPermission, isDesktop, onChangeDate } from '../../../../../utils';
import { TableContainer } from '../../../../../components/TableContainer';
import { DesktopTable } from './TablesComponents/DesktopTable';
import { MobileTable } from './TablesComponents/MobileTable';
import { useEffect, useState } from 'react';
import { Order } from '../../../../../utils/enums';
import { findCoin, listAllHistoryCoins } from '../../../../../services/coinService';
import { GENERIC_ERROR } from '../../../../../utils/constantes';

export function CoinHistoryPage() {
  const navigate = useNavigate();

  const { uuid } = useParams();
  if (!uuid) {
    goToBack(navigate);
  }

  const [coin, setCoin] = useState({});
  const [coinHistory, setCoinHistory] = useState([]);
  const breadcrumbs = [
    { title: 'Administrativo', functionCoordinator: () => goToAdmin(navigate) },
    { title: 'Moeda', functionCoordinator: () => goToCoinList(navigate) },
    { title: 'Histórico' }
  ];

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(5);
  const [order, setOrder] = useState(Order.DESC);

  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  const [filters, onChange] = useForm({
    userName: '',
    userDocument: '',
    date: null,
    percent: '',
    isActual: null
  });
  const [debouncedFilter] = useDebounce(filters, 500);

  useEffect(async () => await init(), []);
  useEffect(async () => await list(), [page, offset, order, debouncedFilter]);

  const init = async () => {
    try {
      setLoading(true);

      const result = await Promise.all([
        listAllHistoryCoins(uuid, page, offset, order, filters),
        findCoin(uuid)
      ]).then((res) => ({ historicCoin: res[0], coin: res[1] }));

      setCoinHistory(result.historicCoin.data);
      setCount(result.historicCoin.count);
      setCoin(result.coin);

      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  };

  const list = async () => {
    try {
      setLoading(true);

      const result = await listAllHistoryCoins(uuid, page, offset, order, filters);

      setCoinHistory(result.data);
      setCount(result.count);

      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  };

  const handlePage = (_event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setOffset(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleOrder = () => {
    if (order === Order.ASC) {
      setOrder(Order.DESC);
      return;
    }

    setOrder(Order.ASC);
  };

  function TableResponsive() {
    if (!coinHistory?.length && !loading) {
      return <EmptyTable />;
    }

    if (isDesktop()) {
      return (
        <DesktopTable
          historics={coinHistory}
          loading={loading}
          count={count}
          page={page}
          offset={offset}
          order={order}
          handlePage={handlePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleOrder={handleOrder}
        />
      );
    }

    return (
      <MobileTable
        historics={coinHistory}
        loading={loading}
        count={count}
        page={page}
        offset={offset}
        setPage={setPage}
        setOffset={setOffset}
        handlePage={handlePage}
      />
    );
  }

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages
        title={`Histórico do índice ${coin.title}`}
        textButton={'Cadastrar novo valor'}
        Icon={AddCircleOutlineIcon}
        onClickButton={() => goToCreateHistoryCoin(navigate, uuid)}
        toHideDivider={!hasPermission('LIST_HISTORY_COINS')}
        keyPermissionButton={'REGISTER_HISTORY_COIN'}
      />

      <HiddenForPermission keys={'LIST_HISTORY_COINS'}>
        <Grid container>
          <AlertMessage
            message={alertMessage.message}
            severity={alertMessage.severity}
            title={alertMessage.title}
            onClose={() => clearAlertMessage(setAlertMessage)}
          />
        </Grid>

        <FilterContainer>
          <Grid item xs={12} lg={3}>
            <TextField
              label={'Nome do usuário'}
              name={'userName'}
              value={filters.userName}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <TextField
              label={'Documento do usuário'}
              name={'userDocument'}
              value={filters.userDocument}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <DatePicker
              label="Data"
              name="date"
              value={filters.date}
              onChange={(e) => onChangeDate(e, 'date')}
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <TextField
              label={'Porcentagem'}
              name={'percent'}
              value={filters.percent}
              type="number"
              onChange={onChange}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <TextField
              label={'É atual'}
              select
              name={'isActual'}
              value={filters.isActual}
              onChange={onChange}
            >
              <MenuItem value={true}>Sim</MenuItem>
              <MenuItem value={false}>Não</MenuItem>
            </TextField>
          </Grid>
        </FilterContainer>

        <TableContainer>
          <TableResponsive />
        </TableContainer>

        <div className={'buttonGroup twoButtons'}>
          <Button variant="outlined" onClick={() => goToCoinList(navigate)}>
            Voltar
          </Button>
          <span></span>
        </div>
      </HiddenForPermission>
    </BasePage>
  );
}
