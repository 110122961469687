import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Avatar, Button, Grid } from '@mui/material';
import GppGoodIcon from '@mui/icons-material/GppGood';
import DescriptionIcon from '@mui/icons-material/Description';
import BusinessIcon from '@mui/icons-material/Business';
import { BasePage } from '../../../../../components/BaseMain';
import {
  goToAdmin,
  goToBack,
  goToUserCompanyPermission,
  goToUserContractsAdded,
  goToUserPermission,
  goToUsersList
} from '../../../../../router/coordinator';
import {
  AlertMessage,
  ButtonPage,
  HiddenForPermission,
  Loading,
  Modal,
  TargetStatus,
  TopBackArea
} from '../../../../../components';
import { AproveAndReproveButtons, ButtonGroup, ContainerAvatar, Content, LineGrid } from './styled';
import { ActiveAndInactiveButton } from '../../../../../components/ActiveAndInactiveButton';
import { Status } from '../../../../../utils/enums';
import {
  activeOrInactiveUser,
  aproveOrReproveUser,
  findUser,
  resendEmailPassword
} from '../../../../../services';
import { GENERIC_ERROR } from '../../../../../utils/constantes';

export function DetailsUserPage() {
  const navigate = useNavigate();

  const { uuid } = useParams();
  if (!uuid) {
    goToBack(navigate);
  }

  const [user, setUser] = useState({});
  const breadcrumbs = [
    { title: 'Administrativo', functionCoordinator: () => goToAdmin(navigate) },
    { title: 'Usuários' }
  ];

  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  const clearAlertMessage = () =>
    setAlertMessage({
      title: '',
      message: '',
      severity: 'error'
    });

  // --- Lógica do modal
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const onConfirmModal = () => {
    handleCloseModal();
    changeStatusUser();
  };

  const [isApproved, setIsApproved] = useState(false);
  const [openModalAproveOrReprove, setOpenModalAproveOrReprove] = useState(false);
  const handleOpenModalAproveOrReprove = (approved) => {
    setOpenModalAproveOrReprove(true);
    setIsApproved(approved);
  };
  const handleCloseModalAproveOrReprove = () => setOpenModalAproveOrReprove(false);
  const onConfirmModalAproveOrReprove = () => {
    handleCloseModal();
    aproveOrReprove(isApproved);
  };

  const changeStatusUser = async () => {
    try {
      setLoading(true);
      handleCloseModal();
      clearAlertMessage();

      await activeOrInactiveUser(user.uuid);
      await init();

      setAlertMessage((prev) => ({
        ...prev,
        title: 'Status do usuário alterado com sucesso.',
        severity: 'success'
      }));

      setLoading(false);
    } catch (err) {
      setAlertMessage({
        title: err.message || GENERIC_ERROR,
        message: err.trace && `Código: ${err.trace}`
      });
      setLoading(false);
    }
  };
  // ---

  useEffect(async () => await init(), []);

  const init = async () => {
    try {
      setLoading(true);

      const result = await findUser(uuid);
      setUser(result);

      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  };

  const aproveOrReprove = async (isApproved) => {
    try {
      setLoading(true);
      handleCloseModalAproveOrReprove();

      const result = await aproveOrReproveUser(uuid, isApproved);
      setUser(result);

      const message = isApproved
        ? 'O usuário foi APROVADO com sucesso'
        : 'O usuário foi REPROVADO com sucesso';
      setAlertMessage((prev) => ({
        ...prev,
        title: `${message}. Um e-mail será enviado com a sua decisão.`,
        severity: 'success'
      }));

      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  };

  const handleButton = () => {
    if ([Status.PENDING, Status.REPROVED].includes(user.status?.toLowerCase())) {
      return (
        <HiddenForPermission keys={'APROVE_REPROVE_USER_ADMIN'}>
          <AproveAndReproveButtons>
            {user.status?.toLowerCase() === Status.PENDING && (
              <Button color="error" onClick={() => handleOpenModalAproveOrReprove(false)}>
                Reprovar
              </Button>
            )}

            <Button onClick={() => handleOpenModalAproveOrReprove(true)}>Aprovar</Button>
          </AproveAndReproveButtons>
        </HiddenForPermission>
      );
    }

    return (
      <ButtonGroup>
        <HiddenForPermission keys={'LIST_CONTRACTS_USER'}>
          <ButtonPage Icon={DescriptionIcon} onClick={() => goToUserContractsAdded(navigate, uuid)}>
            Contratos adicionados
          </ButtonPage>
        </HiddenForPermission>
        {user?.office?.title !== 'USUÁRIO COMUM' && (
          <HiddenForPermission keys={'ACCESS_COMPANY'}>
            <ButtonPage
              Icon={BusinessIcon}
              onClick={() => goToUserCompanyPermission(navigate, uuid)}
            >
              Modificar acesso as empresas
            </ButtonPage>
          </HiddenForPermission>
        )}

        <HiddenForPermission keys={'ALTER_PERMISSIONS'}>
          <ButtonPage Icon={GppGoodIcon} onClick={() => goToUserPermission(navigate, uuid)}>
            Modificar permissões
          </ButtonPage>
        </HiddenForPermission>
      </ButtonGroup>
    );
  };

  const handleChangeStatusButton = () => {
    if (user.status && user.status !== Status.PENDING) {
      const { status } = user;
      const isActiveButton = status?.toLowerCase() === Status.ACTIVE;

      return <ActiveAndInactiveButton active={isActiveButton} onClick={handleOpenModal} />;
    }

    return null;
  };

  const resendCreatePasswordUser = useCallback(async () => {
    try {
      setLoading(true);
      await resendEmailPassword(uuid);
      setAlertMessage({
        title: 'E-mail reenviado para este usuário com sucesso.',
        message: '',
        severity: 'success'
      });
    } catch (error) {
      setAlertMessage({
        title: error.message || GENERIC_ERROR,
        message: error?.stack && `Código: ${error?.stack}`
      });
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TopBackArea onBack={() => goToUsersList(navigate)} rightArea={handleChangeStatusButton()} />

      <Loading loading={loading} />
      <Grid container>
        <AlertMessage
          message={alertMessage.message}
          severity={alertMessage.severity}
          title={alertMessage.title}
        />
      </Grid>

      <Content>
        <div id="top-content">
          <ContainerAvatar>
            <Avatar id="avatar" src={user.profileImage?.imageBase64} />
            <div>
              <strong>
                {user.name?.toTitleCase()} {user.lastName?.toTitleCase()}
              </strong>
              <p>{user.document?.toMask('document')}</p>
            </div>
          </ContainerAvatar>
          <TargetStatus variant={'solid'} status={user.status} />
        </div>

        <Grid container>
          <LineGrid container>
            <Grid item xs={12} lg={4}>
              <p>Empresa</p>
              <p>{user.company?.name?.toTitleCase()}</p>
            </Grid>
            <Grid item xs={12} lg={4}>
              <p>Cargo</p>
              <p>{user.office?.title?.toTitleCase()}</p>
            </Grid>
            <Grid item xs={12} lg={4}>
              <p>E-mail</p>
              <p>{user.email}</p>
            </Grid>
          </LineGrid>

          <LineGrid container>
            {user.cellphone && (
              <Grid item xs={12} lg={4}>
                <p>Celular</p>
                <p>{user.cellphone?.toMask('cellphone')}</p>
              </Grid>
            )}
            {user.tellphone && (
              <Grid item xs={12} lg={4}>
                <p>Telefone</p>
                <p>{user.tellphone?.toMask('tellphone')}</p>
              </Grid>
            )}
            {!user?.havePassword && (
              <Grid item xs={12} lg={4}>
                <p>Senha</p>
                <p>
                  Esse usuário ainda não cadastrou uma senha.
                  <Button id="resend-email-button" onClick={resendCreatePasswordUser}>
                    Reenviar e-mail
                  </Button>
                </p>
              </Grid>
            )}
          </LineGrid>
        </Grid>

        {handleButton()}
      </Content>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        onConfirm={onConfirmModal}
        title={'Mudar status so usuário'}
        text={`Tem certeza que deseja alterar o status do usuário? `}
        buttonConfirm={'Sim'}
        buttonCancel={'Não'}
      />

      <Modal
        open={openModalAproveOrReprove}
        onClose={handleCloseModalAproveOrReprove}
        onConfirm={onConfirmModalAproveOrReprove}
        title={'Mudar status so usuário'}
        text={`Tem certeza que deseja alterar o ${isApproved ? 'APROVAR' : 'REPROVAR'} usuário? `}
        buttonConfirm={'Sim'}
        buttonCancel={'Não'}
      />
    </BasePage>
  );
}
