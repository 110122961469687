import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { BasePage } from '../../../../../components/BaseMain';
import { goToAdmin, goToRegisterNewUser } from '../../../../../router/coordinator';
import {
  AlertMessage,
  EmptyTable,
  FilterContainer,
  HiddenForPermission,
  Modal,
  TitlePages
} from '../../../../../components';
import { useForm } from '../../../../../hooks/useForm';
import { TableContainer } from '../../../../../components/TableContainer';
import { clearAlertMessage, hasPermission, isDesktop } from '../../../../../utils';
import { DesktopTable } from './TablesComponents/DesktopTable';
import { MobileTable } from './TablesComponents/MobileTable';
import { Order, Status } from '../../../../../utils/enums';
import { GENERIC_ERROR } from '../../../../../utils/constantes';
import { activeOrInactiveUser, listOffices, listUsers } from '../../../../../services';
import { FilterButton } from '../../../../../components/FilterButton';
import { translateStatus } from '../../../../../utils/translate';

export function UsersListPage() {
  const navigate = useNavigate();
  const breadcrumbs = [
    { title: 'Administrativo', functionCoordinator: () => goToAdmin(navigate) },
    { title: 'Usuários' }
  ];

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(5);
  const [order, setOrder] = useState(Order.DESC);
  const [orderBy, setOrderBy] = useState('');

  const [uuidUser, setUuidUser] = useState('');

  const [filters, onChange, clearForm] = useForm({
    name: '',
    document: '',
    status: '',
    cellphone: '',
    tellphone: '',
    email: '',
    uuid_office: ''
  });
  const [debouncedFilter] = useDebounce(filters, 500);

  const [users, setUsers] = useState([]);
  const [offices, setOffices] = useState([]);

  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  useEffect(async () => await init(), []);
  useEffect(async () => await list(), [page, offset, order, debouncedFilter]);

  const init = async () => {
    try {
      setLoading(true);

      const result = await Promise.all([
        listUsers(page, offset, order, filters),
        listOffices()
      ]).then((res) => ({
        users: res[0],
        offices: res[1]
      }));

      setUsers(result.users.data);
      setCount(result.users.count);
      setOffices(result.offices);

      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  };

  const list = async () => {
    try {
      setLoading(true);

      const result = await listUsers(page, offset, order, filters);

      setUsers(result.data);
      setCount(result.count);

      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  };

  // --- Lógica do modal
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = (uuid) => {
    setOpenModal(true);
    setUuidUser(uuid);
  };
  const handleClose = () => setOpenModal(false);

  const onConfirmModal = () => {
    changeStatusUser();
  };

  const changeStatusUser = async () => {
    try {
      setLoading(true);
      handleClose();

      await activeOrInactiveUser(uuidUser);
      await list();

      setAlertMessage((prev) => ({
        ...prev,
        title: 'Status do usuário alterado com sucesso.',
        severity: 'success'
      }));

      setLoading(false);
    } catch (err) {
      setAlertMessage({
        title: err.message || GENERIC_ERROR,
        message: err.trace && `Código: ${err.trace}`
      });
      setLoading(false);
    }
  };
  // ---

  const handlePage = (_event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setOffset(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleOrder = (field) => {
    setOrderBy(field);

    if (order === Order.ASC) {
      setOrder(Order.DESC);
      return;
    }

    setOrder(Order.ASC);
  };

  function TableResponsive() {
    if (!users?.length && !loading) {
      return <EmptyTable />;
    }

    if (isDesktop()) {
      return (
        <DesktopTable
          users={users}
          loading={loading}
          count={count}
          page={page}
          offset={offset}
          order={order}
          orderBy={orderBy}
          navigate={navigate}
          handlePage={handlePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleOrder={handleOrder}
          openModal={handleOpen}
        />
      );
    }

    return (
      <MobileTable
        users={users}
        loading={loading}
        count={count}
        page={page}
        offset={offset}
        navigate={navigate}
        setPage={setPage}
        setOffset={setOffset}
        handlePage={handlePage}
        openModal={handleOpen}
      />
    );
  }

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages
        title={'Lista de usuários'}
        description={'Lista de todos os usuários da empresa.'}
        textButton={'Cadastrar usuário'}
        Icon={AddCircleOutlineIcon}
        onClickButton={() => goToRegisterNewUser(navigate)}
        keyPermissionButton={'REGISTER_USER_ADMIN'}
        toHideDivider={!hasPermission('LIST_USER_ADMIN')}
      />

      <HiddenForPermission keys={'LIST_USER_ADMIN'}>
        <Grid container>
          <AlertMessage
            message={alertMessage.message}
            severity={alertMessage.severity}
            title={alertMessage.title}
            onClose={() => clearAlertMessage(setAlertMessage)}
          />
        </Grid>

        <FilterContainer>
          <Grid item xs={12} lg={3}>
            <TextField label={'Nome'} name={'name'} value={filters.name} onChange={onChange} />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextField
              label={'Documento'}
              name={'document'}
              value={filters.document}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextField
              select
              label="Status"
              name="status"
              value={filters.status}
              onChange={onChange}
            >
              <MenuItem value={Status.ACTIVE}>{translateStatus(Status.ACTIVE)}</MenuItem>
              <MenuItem value={Status.INACTIVE}>{translateStatus(Status.INACTIVE)}</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextField
              label={'Celular'}
              name={'cellphone'}
              value={filters.cellphone}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextField
              label={'Telefone'}
              name={'cellphone'}
              value={filters.cellphone}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextField label={'E-mail'} name={'email'} value={filters.email} onChange={onChange} />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextField
              select
              label="Cargo"
              name="uuid_office"
              value={filters.uuid_office}
              onChange={onChange}
            >
              {offices?.map((o) => (
                <MenuItem key={o.uuid} value={o.uuid}>
                  {o.title?.toTitleCase()}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} lg={1}>
            <FilterButton onClick={clearForm} />
          </Grid>
        </FilterContainer>

        <TableContainer>
          <TableResponsive />
        </TableContainer>

        <div className={'buttonGroup twoButtons'}>
          <Button variant="outlined" onClick={() => goToAdmin(navigate)}>
            Voltar
          </Button>
          <span> </span>
        </div>

        <Modal
          open={openModal}
          onClose={handleClose}
          onConfirm={onConfirmModal}
          title={'Mudar status so usuário'}
          text={`Tem certeza que deseja alterar o status do usuário? `}
          buttonConfirm={'Sim'}
          buttonCancel={'Não'}
        />
      </HiddenForPermission>
    </BasePage>
  );
}
