import styled from 'styled-components';
import { colors } from '../../../theme/styles';

export const Container = styled.div`
  padding-top: 1rem;

  .MuiButtonBase-root {
    padding: 0;
  }

  .MuiDivider-root {
    background-color: ${colors.gray};
    opacity: 0.1;
  }

  .colapse {
    margin-left: 20px;
  }
`;
