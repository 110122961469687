import { createTheme } from '@mui/material/styles';
import { colors } from './styles';

export const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        fullWidth: true,
        margin: 'dense'
      },
      styleOverrides: {
        root: {
          textAlign: 'left'
        }
      }
    },
    MuiFormControl: {
      defaultProps: {
        fullWidth: true
      }
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained'
      },
      styleOverrides: {
        root: {
          padding: '0.5rem',
          minWidth: '10%'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer'
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: colors.theme.darkGray
        }
      }
    }
  },
  palette: {
    primary: {
      main: colors.theme.primary
    },
    secondary: {
      main: colors.theme.secundary
    }
  }
});
