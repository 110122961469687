import { Skeleton } from '@mui/material';
import { TableMobileCell, TableMobileItem } from '../../MobileTable';

export function SkeletonMobile({ numbersOfColumns }) {
  const arrayWithTheNumberOfColumns = [];
  for (let i = 0; i < numbersOfColumns; i++) {
    arrayWithTheNumberOfColumns.push(i);
  }

  const handleCellSkeleton = () => {
    const skeletons = arrayWithTheNumberOfColumns.map((i) => (
      <TableMobileItem key={i}>
        <Skeleton animation="wave" variant="rectangular" height={40} width={'100%'} />
      </TableMobileItem>
    ));

    return skeletons;
  };

  const handleSkeleton = () => {
    const rowsWithSkeletons = [1, 2].map((i) => (
      <TableMobileCell key={i}>{handleCellSkeleton()}</TableMobileCell>
    ));

    return rowsWithSkeletons;
  };

  return handleSkeleton();
}
