import { useNavigate } from 'react-router-dom';
import { BasePage } from '../../../../../components/BaseMain';
import {
  goToAdmin,
  goToChoiceCompanyPermission,
  goToUsersList
} from '../../../../../router/coordinator';
import {
  AlertMessage,
  Loading,
  TextFieldKeyPress,
  TextFieldNextFocus,
  TitlePages
} from '../../../../../components';
import { Button, Grid, MenuItem } from '@mui/material';
import { useForm } from '../../../../../hooks/useForm';
import { isDesktop, onlyNumbers } from '../../../../../utils';
import ReactInputMask from 'react-input-mask';
import {
  validateCellphone,
  validateDocument,
  validateTellphone
} from '../../../../../utils/validates';
import { useEffect, useState } from 'react';
import { Form } from './styled';
import { createUser, listAllSectors, listOffices } from '../../../../../services';
import { GENERIC_ERROR } from '../../../../../utils/constantes';

export function RegisterNewUser() {
  const [errorsValidation, setErrorsValidation] = useState({});

  const navigate = useNavigate();
  const breadcrumbs = [
    { title: 'Administrativo', functionCoordinator: () => goToAdmin(navigate) },
    { title: 'Usuários', functionCoordinator: () => goToUsersList(navigate) },
    { title: 'Criar' }
  ];

  const initialValueForm = {
    name: '',
    lastName: '',
    person_type: '',
    document: '',
    email: '',
    office_uuid: '',
    sector_uuid: '',
    cellphone: '',
    tellphone: ''
  };
  const [form, onChange] = useForm(initialValueForm);

  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });
  const [offices, setOffices] = useState([]);
  const [sectors, setSectors] = useState([]);

  const clearAlertMessage = () =>
    setAlertMessage({
      title: '',
      message: '',
      severity: 'error'
    });

  useEffect(async () => await init(), []);

  const init = async () => {
    try {
      setLoading(true);

      const result = await Promise.all([listOffices(), listAllSectors()]).then((res) => ({
        offices: res[0],
        sectors: res[1].data
      }));

      setOffices(result.offices);
      setSectors(result.sectors);

      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  };

  const validateInputDocument = () => {
    const { document } = form;

    let isValid = true;

    if (onlyNumbers(document)) {
      isValid = validateDocument(document);
    }
    setErrorsValidation((prevError) => ({ ...prevError, document: !isValid }));
  };

  const isPJ = () => {
    return form.person_type === 'pj';
  };

  /**
   * Gera erro se um input obrigatório não está preenchido
   * @param {*} field - campo a ser verificado
   * @returns
   */
  const validateMandatoryInput = (field) => {
    if (!form[field]) {
      setErrorsValidation((prevError) => ({ ...prevError, [field]: true }));
      return;
    }
    setErrorsValidation((prevError) => ({ ...prevError, [field]: false }));
  };

  const validateInputCellphone = () => {
    const { cellphone } = form;
    let isValid = true;

    if (onlyNumbers(cellphone)) {
      isValid = validateCellphone(cellphone);
    }

    setErrorsValidation((prevError) => ({ ...prevError, cellphone: !isValid }));
  };

  const validateInputTellphone = () => {
    const { tellphone } = form;
    let isValid = true;

    if (onlyNumbers(tellphone)) {
      isValid = validateTellphone(tellphone);
    }

    setErrorsValidation((prevError) => ({ ...prevError, tellphone: !isValid }));
  };

  const disabledButton = () => {
    if (!form.name) {
      return true;
    }
    if (!form.lastName) {
      return true;
    }
    if (!form.person_type) {
      return true;
    }
    if (!form.document) {
      return true;
    }
    if (!form.email) {
      return true;
    }
    if (!form.office_uuid) {
      return true;
    }
    if (!form.sector_uuid) {
      return true;
    }

    return false;
  };

  const create = async () => {
    try {
      setLoading(true);
      clearAlertMessage();

      const {
        name,
        lastName,
        document,
        email,
        cellphone,
        tellphone,
        office_uuid: uuidOffice,
        sector_uuid: uuidSector
      } = form;

      const result = await createUser(
        name,
        lastName,
        document,
        email,
        cellphone,
        tellphone,
        uuidOffice,
        uuidSector
      );

      setLoading(false);
      goToChoiceCompanyPermission(navigate, result.uuid);
    } catch (err) {
      setAlertMessage((...prev) => ({ ...prev, title: err.message || GENERIC_ERROR }));
      setLoading(false);
    }
  };

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages
        title={'Crie uma conta'}
        description={
          'Após a criação da conta, será enviado um e-mail para o usuário cadastrar sua senha.'
        }
      />
      <Loading loading={loading} />

      <Grid container>
        <AlertMessage
          title={alertMessage.title}
          message={alertMessage.message}
          severity={alertMessage.severity}
        />
      </Grid>

      <Form container xs={12} rowGap={2} columnSpacing={isDesktop() && 2}>
        <Grid item xs={12} lg={4}>
          <TextFieldNextFocus
            required
            label="Nome"
            name={'name'}
            value={form.name}
            onChange={onChange}
            onBlur={() => validateMandatoryInput('name')}
            error={errorsValidation.name}
            helperText={errorsValidation.name && 'O nome é obrigatório'}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextFieldNextFocus
            required
            label="Sobrenome"
            name={'lastName'}
            value={form.lastName}
            onChange={onChange}
            onBlur={() => validateMandatoryInput('lastName')}
            error={errorsValidation.lastName}
            helperText={errorsValidation.lastName && 'O sobrenome é obrigatório'}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextFieldNextFocus
            select
            required
            label="PF ou PJ"
            name={'person_type'}
            onChange={onChange}
            value={form.person_type}
            onBlur={() => validateMandatoryInput('person_type')}
            error={errorsValidation.person_type}
            helperText={errorsValidation.person_type && 'O campo é obrigatório'}
          >
            <MenuItem key={1} value="pf">
              Pessoa física (PF)
            </MenuItem>
            <MenuItem key={2} value="pj">
              Pessoa jurídica (PJ)
            </MenuItem>
          </TextFieldNextFocus>
        </Grid>
        <Grid item xs={12} lg={4}>
          <ReactInputMask
            mask={isPJ() ? '99.999.999/9999-99' : '999.999.999-99'}
            value={form.document}
            onChange={onChange}
            maskChar="_"
            disabled={!form.person_type}
            onBlur={validateInputDocument}
          >
            {() => (
              <TextFieldNextFocus
                label="Documento"
                required
                name={'document'}
                value={form.document}
                disabled={!form.person_type}
                error={errorsValidation.document}
                helperText={errorsValidation.document ? 'Documento inválido' : 'CPF ou CNPJ'}
              />
            )}
          </ReactInputMask>
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextFieldNextFocus
            label="E-mail corporativo"
            required
            placeholder="joao_silva@pamafa.com.br"
            type={'email'}
            name={'email'}
            value={form.email}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextFieldNextFocus
            select
            label={'Cargo no sistema'}
            required
            name="office_uuid"
            value={form.office_uuid}
            onChange={onChange}
            onBlur={() => validateMandatoryInput('office_uuid')}
            error={errorsValidation.office_uuid}
            helperText={
              errorsValidation.office_uuid
                ? 'A empresa é obrigatória'
                : 'Define suas funções no sistema'
            }
          >
            {offices?.map((o) => (
              <MenuItem key={o.uuid} value={o.uuid}>
                {o.title?.toTitleCase()}
              </MenuItem>
            ))}
          </TextFieldNextFocus>
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextFieldNextFocus
            select
            label={'Setor de atuação'}
            required
            name="sector_uuid"
            value={form.sector_uuid}
            onChange={onChange}
            onBlur={() => validateMandatoryInput('sector_uuid')}
            error={errorsValidation.sector_uuid}
            helperText={errorsValidation.sector_uuid && 'O setor é obrigatório'}
          >
            {sectors?.map((s) => (
              <MenuItem key={s.uuid} value={s.uuid}>
                {s.title?.toTitleCase()}
              </MenuItem>
            ))}
          </TextFieldNextFocus>
        </Grid>
        <Grid item xs={12} lg={4}>
          <ReactInputMask
            mask={'(99) 99999-9999'}
            maskChar="_"
            value={form.cellphone}
            onChange={onChange}
            onBlur={validateInputCellphone}
          >
            {() => (
              <TextFieldNextFocus
                label="Celular"
                name={'cellphone'}
                value={form.cellphone}
                onChange={onChange}
                error={errorsValidation.cellphone}
                helperText={errorsValidation.cellphone && 'Celular está inválido'}
              />
            )}
          </ReactInputMask>
        </Grid>
        <Grid item xs={12} lg={4}>
          <ReactInputMask
            mask={'(99) 9999-9999'}
            maskChar="_"
            value={form.tellphone}
            onChange={onChange}
            onBlur={validateInputTellphone}
          >
            {() => (
              <TextFieldKeyPress
                label="Telefone"
                name={'tellphone'}
                value={form.tellphone}
                onChange={onChange}
                error={errorsValidation.tellphone}
                helperText={errorsValidation.tellphone && 'Telefone está inválido'}
              />
            )}
          </ReactInputMask>
        </Grid>

        <Grid item xs={12} className={'buttonGroup twoButtons'}>
          <Button variant="outlined" onClick={() => goToUsersList(navigate)}>
            Voltar
          </Button>
          <Button onClick={create} disabled={disabledButton()}>
            Criar e avançar
          </Button>
        </Grid>
      </Form>
    </BasePage>
  );
}
