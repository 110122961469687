import styled from 'styled-components';
import { Avatar } from '@mui/material';

export const Content = styled.div`
  display: flex;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 5px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    opacity: 0.9;
  }

  h3,
  h4 {
    margin: 0;
  }

  h3 {
    font-size: 1rem;
  }

  h4 {
    font-size: 1rem;
    font-weight: 400;
    margin-top: -2px;
  }
`;

export const Image = styled(Avatar)`
  margin-right: 0.5rem;
`;
