import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Grid, InputAdornment } from '@mui/material';
import { Form } from './styled';
import { goToAdmin, goToBack, goToCoinList } from '../../../../../router/coordinator';
import { useForm } from '../../../../../hooks/useForm';
import { BasePage } from '../../../../../components/BaseMain';
import {
  AlertMessage,
  Loading,
  Modal,
  TextFieldKeyPress,
  TitlePages
} from '../../../../../components';
import { clearAlertMessage, clearPercent, isDesktop } from '../../../../../utils';
import { editIndexHistory, findIndexHistory } from '../../../../../services/indexService';
import { GENERIC_ERROR } from '../../../../../utils/constantes';
import { InformativeText } from '../../../../../components/InformativeText';
import dayjs from 'dayjs';
import { validateMandatoryInput } from '../../../../../utils/validates';

export function EditIndexHistoryPage() {
  const navigate = useNavigate();

  const { uuid } = useParams();
  if (!uuid) {
    goToBack(navigate);
  }

  const [errorsValidation, setErrorsValidation] = useState({});

  const [indexHistory, onChange, _, setIndexHistory] = useForm({ percent: '' });
  const breadcrumbs = [
    { title: 'Administrativo', functionCoordinator: () => goToAdmin(navigate) },
    { title: 'Índice', functionCoordinator: () => goToCoinList(navigate) },
    { title: 'Histórico', functionCoordinator: () => goToBack(navigate) },
    { title: 'Editar' }
  ];

  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  const init = useCallback(async () => {
    try {
      setLoading(true);

      const result = await findIndexHistory(uuid);

      setIndexHistory({
        ...result,
        percent: result?.percent?.toLocaleString('pt-br', { minimumFractionDigits: 4 })
      });
    } catch (error) {
      setAlertMessage({
        title: error.message || GENERIC_ERROR,
        message: error.trace && `Código: ${error.trace}`
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const onConfirmModal = async () => {
    try {
      setLoading(true);
      handleClose();

      await editIndexHistory(uuid, clearPercent(indexHistory?.percent));

      setAlertMessage((prev) => ({
        ...prev,
        title: 'Valor alterado com sucesso.',
        message: '',
        severity: 'success'
      }));
    } catch (error) {
      setAlertMessage({
        title: error.message || GENERIC_ERROR,
        message: error.trace && `Código: ${error.trace}`
      });
    } finally {
      setLoading(false);
    }
  };

  const disabledButton = () => {
    if (!indexHistory.percent) {
      return true;
    }

    return false;
  };

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages
        title={`Alterar valor do índice para a data ${dayjs(indexHistory?.date).format('MM/YYYY')}`}
      />

      <Loading loading={loading} />

      <Grid container>
        <AlertMessage
          message={alertMessage.message}
          severity={alertMessage.severity}
          title={alertMessage.title}
          onClose={() => clearAlertMessage(setAlertMessage)}
        />
      </Grid>

      <Form container xs={12} rowGap={2} columnSpacing={isDesktop() && 2}>
        <Grid item xs={12} lg={3}>
          <TextFieldKeyPress
            label="Valor em Porcentagem"
            name="percent"
            required
            value={indexHistory.percent}
            onChange={onChange}
            onBlur={() => validateMandatoryInput('percent', indexHistory, setErrorsValidation)}
            error={errorsValidation.percent}
            helperText={
              errorsValidation.percent ||
              'O valor pode conter até 4 casas decimais. Exemplo: 1,0450'
            }
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
          />
        </Grid>

        <InformativeText />

        <Grid item xs={12} className="buttonGroup twoButtons nearTheTop">
          <Button variant="outlined" onClick={() => goToBack(navigate)}>
            Voltar
          </Button>
          <Button onClick={handleOpen} disabled={disabledButton()}>
            Editar
          </Button>
        </Grid>
      </Form>

      <Modal
        open={openModal}
        onClose={handleClose}
        onConfirm={onConfirmModal}
        title="Criar novo valor ao índice"
        text={`Tem certeza que deseja alterar o valor do índice para ${indexHistory.percent}%?`}
        warning="Após ser adicionado, o valor não pode ser excluido ou alterado."
      />
    </BasePage>
  );
}
