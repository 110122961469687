import { useEffect, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { TextFieldNextFocus } from '../TextFieldNextFocus';
import { onlyNumbers } from '../../utils';
import { PHONE_8_NUMBERS_MASK, PHONE_9_NUMBERS_MASK } from '../../utils/constantes';

export function ReactInputMaskPhoneNumber({ changeMask, initialMask, value, ...props }) {
  const [mask, setMask] = useState(initialMask || PHONE_9_NUMBERS_MASK);
  const [updateMask, setUpdateMask] = useState();

  useEffect(() => {
    if (value && changeMask) {
      setMask(
        updateMask || onlyNumbers(value).length <= 10 ? PHONE_8_NUMBERS_MASK : PHONE_9_NUMBERS_MASK
      );
      setUpdateMask();
    }
  }, [value]);

  const onKeyDown = (data) => {
    if (value && changeMask) {
      if (data.code === 'Backspace') {
        if (onlyNumbers(value).length === 11) {
          setUpdateMask(PHONE_8_NUMBERS_MASK);
        } else {
          setMask(onlyNumbers(value).length < 11 ? PHONE_8_NUMBERS_MASK : PHONE_9_NUMBERS_MASK);
        }
      } else {
        setMask(onlyNumbers(value).length >= 10 ? PHONE_9_NUMBERS_MASK : PHONE_8_NUMBERS_MASK);
      }
    }
  };

  return (
    <ReactInputMask {...props} mask={mask} value={value} onKeyDown={onKeyDown}>
      {(inputProps) => <TextFieldNextFocus {...inputProps} />}
    </ReactInputMask>
  );
}
