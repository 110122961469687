import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CPF } from 'cpf_cnpj';
import { Button, Grid, MenuItem } from '@mui/material';
import ReactInputMask from 'react-input-mask';
import { BasePage } from '../../../../../components/BaseMain';
import { goToAdmin, goToBack, goToUsersList } from '../../../../../router/coordinator';
import {
  AlertMessage,
  Loading,
  Modal,
  TextFieldNextFocus,
  TitlePages
} from '../../../../../components';
import { useForm } from '../../../../../hooks/useForm';
import { isDesktop } from '../../../../../utils';
import { validateInputDocument, validateMandatoryInput } from '../../../../../utils/validates';
import { Form } from './styled';
import { editUserAdmin, findUser, listOffices, listSectors } from '../../../../../services';
import { GENERIC_ERROR } from '../../../../../utils/constantes';
import { InformativeText } from '../../../../../components/InformativeText';

export function EditUserPage() {
  const navigate = useNavigate();
  const breadcrumbs = [
    { title: 'Administrativo', functionCoordinator: () => goToAdmin(navigate) },
    { title: 'Usuários', functionCoordinator: () => goToUsersList(navigate) },
    { title: 'Editar' }
  ];

  const { uuid: uuidUser } = useParams();
  if (!uuidUser) {
    goToBack(navigate);
  }

  const [form, onChange, _, setForm] = useForm({
    name: '',
    lastName: '',
    personType: '',
    document: '',
    email: '',
    uuidOffice: '',
    uuidSector: ''
  });
  const [errorsValidation, setErrorsValidation] = useState({});
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });
  const [offices, setOffices] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const clearAlertMessage = () =>
    setAlertMessage({
      title: '',
      message: '',
      severity: 'error'
    });

  const init = useCallback(async () => {
    try {
      setLoading(true);

      const [offices, sectors, user] = await Promise.all([
        listOffices(),
        listSectors(),
        findUser(uuidUser)
      ]);

      setOffices(offices);
      setSectors(sectors);

      setForm({
        name: user?.name?.toTitleCase?.(),
        lastName: user?.lastName?.toTitleCase?.(),
        personType: CPF.isValid(user?.document) ? 'pf' : 'pj',
        document: user?.document,
        email: user?.email,
        uuidOffice: user?.office?.uuid,
        uuidSector: user?.sector?.uuid
      });
    } catch (error) {
      setAlertMessage({
        title: error?.message || GENERIC_ERROR,
        message: error?.trace && `Código: ${error.trace}`
      });
    } finally {
      handleClose();
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    init();
  }, []);

  const isPJ = () => form.personType === 'pj';

  const handleDocumentMask = () => (isPJ() ? '99.999.999/9999-99' : '999.999.999-99');

  const disabledButton = () => {
    const fields = Object.keys(form);
    return !fields?.every((field) => form[field]);
  };

  const updateUser = async () => {
    try {
      setLoading(true);
      clearAlertMessage();

      const { name, lastName, document, email, uuidOffice, uuidSector } = form;
      await editUserAdmin(uuidUser, name, lastName, document, email, uuidSector, uuidOffice);

      setAlertMessage((...prev) => ({
        ...prev,
        title: 'Usuário editado com sucesso.',
        severity: 'success'
      }));
    } catch (error) {
      setAlertMessage({
        title: error?.message || GENERIC_ERROR,
        message: error?.trace && `Código: ${error.trace}`
      });
    } finally {
      handleClose();
      setLoading(false);
    }
  };

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages title="Editar usuário" />
      <Loading loading={loading} />

      <Grid container>
        <AlertMessage
          title={alertMessage.title}
          message={alertMessage.message}
          severity={alertMessage.severity}
        />
      </Grid>

      <Form container xs={12} rowGap={2} columnSpacing={isDesktop() && 2}>
        <Grid item xs={12} lg={4}>
          <TextFieldNextFocus
            required
            label="Nome"
            name="name"
            value={form.name}
            onChange={onChange}
            onBlur={() => validateMandatoryInput('name', form, setErrorsValidation)}
            error={errorsValidation.name}
            helperText={errorsValidation.name}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextFieldNextFocus
            required
            label="Sobrenome"
            name="lastName"
            value={form.lastName}
            onChange={onChange}
            onBlur={() => validateMandatoryInput('lastName', form, setErrorsValidation)}
            error={errorsValidation.lastName}
            helperText={errorsValidation.lastName}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextFieldNextFocus
            select
            required
            label="PF ou PJ"
            name="personType"
            onChange={onChange}
            value={form.personType}
            onBlur={() => validateMandatoryInput('personType', form, setErrorsValidation)}
            error={errorsValidation.personType}
            helperText={errorsValidation.personType}
          >
            <MenuItem key={1} value="pf">
              Pessoa física (PF)
            </MenuItem>
            <MenuItem key={2} value="pj">
              Pessoa jurídica (PJ)
            </MenuItem>
          </TextFieldNextFocus>
        </Grid>
        <Grid item xs={12} lg={4}>
          <ReactInputMask
            mask={handleDocumentMask()}
            value={form.document}
            onChange={onChange}
            maskChar="_"
            disabled={!form.personType}
            onBlur={() => validateInputDocument(form.document, setErrorsValidation)}
          >
            {() => (
              <TextFieldNextFocus
                label="Documento"
                required
                name="document"
                value={form.document}
                disabled={!form.personType}
                error={errorsValidation.document}
                helperText={errorsValidation.document || 'CPF ou CNPJ'}
              />
            )}
          </ReactInputMask>
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextFieldNextFocus
            label="E-mail corporativo"
            required
            placeholder="joao_silva@pamafa.com.br"
            type="email"
            name="email"
            value={form.email}
            onChange={onChange}
            onBlur={() => validateMandatoryInput('email', form, setErrorsValidation)}
            error={errorsValidation.email}
            helperText={errorsValidation.email}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextFieldNextFocus
            select
            label="Cargo no sistema"
            required
            name="uuidOffice"
            value={form.uuidOffice}
            onChange={onChange}
            onBlur={() => validateMandatoryInput('uuidOffice', form, setErrorsValidation)}
            error={errorsValidation.uuidOffice}
            helperText={errorsValidation.uuidOffice || 'Define suas funções no sistema'}
          >
            {offices?.map((o) => (
              <MenuItem key={o.uuid} value={o.uuid}>
                {o.title?.toTitleCase()}
              </MenuItem>
            ))}
          </TextFieldNextFocus>
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextFieldNextFocus
            select
            label="Setor de atuação"
            required
            name="uuidSector"
            value={form.uuidSector}
            onChange={onChange}
            onBlur={() => validateMandatoryInput('uuidSector', form, setErrorsValidation)}
            error={errorsValidation.uuidSector}
            helperText={errorsValidation.uuidSector}
          >
            {sectors?.map((s) => (
              <MenuItem key={s.uuid} value={s.uuid}>
                {s.title?.toTitleCase()}
              </MenuItem>
            ))}
          </TextFieldNextFocus>
        </Grid>
        <InformativeText />

        <Grid item xs={12} className="buttonGroup twoButtons nearTheTop">
          <Button variant="outlined" onClick={() => goToUsersList(navigate)}>
            Voltar
          </Button>
          <Button onClick={handleOpen} disabled={disabledButton()}>
            Editar usuário
          </Button>
        </Grid>
      </Form>

      <Modal
        open={openModal}
        onClose={handleClose}
        onConfirm={updateUser}
        title="Editar usuário"
        text="Tem certeza que deseja editar as informações do usuário?"
      />
    </BasePage>
  );
}
