import { Button as ButtonMUI } from '@mui/material';
import styled from 'styled-components';
import { breakpoints } from '../../theme/breakpoints';

export const Button = styled(ButtonMUI)`
  && {
    width: 100%;
    min-height: 9.5rem;
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem;

    @media (min-width: ${breakpoints.md}) {
      width: 15rem;
    }

    div {
      width: 100%;
      text-align: left;

      svg {
        width: 3.5rem;
        height: 3.5rem;
      }
    }

    p {
      width: 100%;
      height: 100%;
      text-align: left;
      margin-left: 0.8rem;
      display: flex;
      align-items: flex-end;
      font-size: 1rem;
    }
  }
`;
