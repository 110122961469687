import { Divider, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { ListItemButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import DescriptionIcon from '@mui/icons-material/Description';
import EventIcon from '@mui/icons-material/Event';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { Container } from './styled';
import {
  goToAddedContracts,
  goToAdmin,
  goToContractsFines,
  goToExpiringContracts,
  goToFinancial,
  goToHome,
  goToListAllContracts
} from '../../../router/coordinator';
import { HiddenForPermission } from '../../HiddenForPermission';
import { HiddenForOffice } from '../../HiddenForOffice';

export function ListItemsOfMenu({ navigate }) {
  return (
    <Container>
      <ListItem>
        <ListItemButton onClick={() => goToHome(navigate)}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary={'Início'} />
        </ListItemButton>
      </ListItem>
      <Divider />
      <HiddenForPermission keys={['LIST_CONTRACT', 'CREATE_CONTRACT']}>
        <ListItem>
          <ListItemButton onClick={() => goToListAllContracts(navigate)}>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary={'Contratos'} />
          </ListItemButton>
        </ListItem>
        <Divider />
      </HiddenForPermission>
      <List>
        <HiddenForPermission keys={'LIST_30_DAYS_CONTRACT_EXPIRING'}>
          <ListItem>
            <ListItemButton className="colapse" onClick={() => goToExpiringContracts(navigate)}>
              <ListItemIcon>
                <EventIcon />
              </ListItemIcon>
              <ListItemText primary="Expirando" />
            </ListItemButton>
          </ListItem>
          <Divider />
        </HiddenForPermission>
        <HiddenForPermission keys={'LIST_TERMINATION_FINE_CONTRACT'}>
          <ListItem>
            <ListItemButton className="colapse" onClick={() => goToContractsFines(navigate)}>
              <ListItemIcon>
                <RequestQuoteIcon />
              </ListItemIcon>
              <ListItemText primary={'Multa rescisória'} />
            </ListItemButton>
          </ListItem>
          <Divider />
        </HiddenForPermission>
        <HiddenForPermission keys={'LIST_30_DAYS_CONTRACT_ADDED'}>
          <ListItem>
            <ListItemButton className="colapse" onClick={() => goToAddedContracts(navigate)}>
              <ListItemIcon>
                <AddCircleIcon />
              </ListItemIcon>
              <ListItemText primary={'Adicionados'} />
            </ListItemButton>
          </ListItem>
          <Divider />
        </HiddenForPermission>
      </List>
      <ListItem>
        <ListItemButton onClick={() => goToFinancial(navigate)}>
          <ListItemIcon>
            <AttachMoneyIcon />
          </ListItemIcon>
          <ListItemText primary={'Financeiro'} />
        </ListItemButton>
      </ListItem>
      <Divider />
      <HiddenForOffice office={'ADMINISTRADOR'}>
        <ListItem>
          <ListItemButton onClick={() => goToAdmin(navigate)}>
            <ListItemIcon>
              <AdminPanelSettingsIcon />
            </ListItemIcon>
            <ListItemText primary={'Administrativo'} />
          </ListItemButton>
        </ListItem>
        <Divider />
      </HiddenForOffice>
    </Container>
  );
}
