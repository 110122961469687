import { Avatar, Button as ButtonMUI } from '@mui/material';
import styled from 'styled-components';
import { breakpoints } from '../../../../theme/breakpoints';
import { colors, metrics } from '../../../../theme/styles';

export const Content = styled.div``;

export const Title = styled.h2`
  color: ${colors.theme.primary};
  font-size: ${metrics.size.mediumLarge};
  margin-bottom: 1rem;
`;

export const Image = styled(Avatar)`
  && {
    width: 11rem;
    height: 11rem;
  }
  margin: 2rem 1rem 1rem 0;
`;

export const ContainerImage = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1rem;

  @media (min-width: ${breakpoints.md}) {
    flex-direction: row;
  }
`;

export const Button = styled(ButtonMUI)`
  width: 100%;

  @media (min-width: ${breakpoints.md}) {
    width: 20%;
  }
`;
