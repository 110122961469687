import { Button, Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { Container } from './styled';
import {
  AlertMessage,
  Loading,
  Modal,
  TextFieldKeyPress,
  UploadButton
} from '../../../../../../../components';
import { useForm } from '../../../../../../../hooks/useForm';
import { GENERIC_ERROR } from '../../../../../../../utils/constantes';
import {
  createContractStep1,
  createContractStep2,
  createContractStep3
} from '../../../../../../../services/contractService';
import { clearAlertMessage, clearPercent, toCent } from '../../../../../../../utils';
import { createCoinQuotation } from '../../../../../../../services/coinService';
import { LocalStorageKeys, Values } from '../../../../../../../utils/enums';
import { InformativeText } from '../../../../../../../components/InformativeText';
import { getItemLocalStorage, setItemLocalStorage } from '../../../../../../../services';
import { useUpdateState } from '../../../../../../../hooks/useUpdateState';

export function Final({ onBackStep, lastStep, setLastStep, onInitStep }) {
  const [form, onChange, _, setForm] = useForm({
    is_confidential: false,
    is_signed: false,
    file: '',
    observation: ''
  });
  const [{ document, documentName, loading }, setStates] = useUpdateState({
    document: null,
    documentName: '',
    loading: false
  });

  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => setOpenModal(false);
  const handleOpen = () => {
    onCloseAlertMessage();
    setForm((prevForm) => ({
      ...prevForm,
      file: document
    }));
    setOpenModal(true);
  };

  const onCloseAlertMessage = () => {
    setAlertMessage({
      severity: '',
      title: '',
      message: ''
    });
  };
  const [alertMessage, setAlertMessage] = useState({
    severity: '',
    title: '',
    message: ''
  });

  useEffect(() => {
    getExistForm();
  }, []);

  const onConfirmModal = async () => {
    handleClose();
    await createContract();
  };

  const createContract = async () => {
    try {
      setStates({ loading: true });
      let actualStep = lastStep;

      // Etapa 1 - Criar as informações básicas do contrato
      if (actualStep === 1) {
        await saveBasicInformations();

        actualStep = 2;
        setLastStep(2);
      }

      // Etapa 2 - Criar as informações financeiras do contrato
      if (actualStep === 2) {
        await saveFinanialInformations();

        actualStep = 3;
        setLastStep(3);
      }

      // Etapa 3 - Salvar documento em PDF
      if (actualStep === 3) {
        if (document) {
          await saveDocument();
        }

        actualStep = 1;
        setLastStep(1);
      }

      setItemLocalStorage(LocalStorageKeys.CREATE_CONTRACT, {
        company_uuid: form.company_uuid,
        isSuccess: true
      });
      onInitStep();
    } catch (err) {
      setAlertMessage({
        title: err?.message || GENERIC_ERROR,
        message: err?.trace ? `Código: ${err.trace}` : ''
      });
    } finally {
      setStates({ loading: false });
    }
  };

  const saveBasicInformations = async () => {
    const {
      title,
      service,
      uuid_sector: uuidSector,
      uuid_segment: uuidSegment,
      uuid_index: uuidIndex,
      init_date: initDate,
      expiration_date: expirationDate,
      uuid_provider: uuidProvider,
      is_confidential: isConfidential,
      uuid_city: cityServiceProvision,
      observation
    } = form;

    const result = await createContractStep1(
      title,
      service,
      uuidSector,
      uuidSegment,
      uuidIndex !== Values.NO_INDEX ? uuidIndex : null,
      initDate,
      expirationDate,
      uuidProvider,
      isConfidential,
      cityServiceProvision,
      observation
    );

    form['uuid_contract'] = result.uuid;
  };

  const saveFinanialInformations = async () => {
    const { uuid_coin: uuidCoin, is_custom_coin: isCustomCoin, exchang_type: exchangType } = form;

    const exchangeValue = form.exchang_value && toCent(form.exchang_value);
    if (isCustomCoin) {
      const result = await createCoinQuotation(uuidCoin, exchangeValue, exchangType);
      form['uuid_coin_history'] = result.uuid;
    }

    const {
      debit,
      credit,
      uuid_contract: uuidContract,
      readjustment_month: readjustmentMonth,
      total_installments: totalInstallments,
      installments_paid: installmentsPaid,
      uuid_coin_history: uuidCoinHistory,
      payment_periodicity: paymentPeriodicity,
      readjustment_periodicity: readjustmentPeriodicity,
      days_rescission_fine: daysRescissionFine
    } = form;

    const initialValue = form.initial_value && toCent(form.initial_value);
    const lateFee = form.late_fee && clearPercent(form.late_fee);
    const fineForSalary = form.fine_for_salary && clearPercent(form.fine_for_salary);

    await createContractStep2(
      uuidContract,
      debit,
      credit,
      readjustmentMonth,
      totalInstallments,
      installmentsPaid,
      uuidCoin,
      uuidCoinHistory,
      paymentPeriodicity,
      readjustmentPeriodicity,
      initialValue,
      lateFee,
      daysRescissionFine,
      fineForSalary
    );
  };

  const saveDocument = async () => {
    const { uuid_contract: uuidContract } = form;

    await createContractStep3(uuidContract, document);
  };

  const getExistForm = () => {
    const saveForm = getItemLocalStorage(LocalStorageKeys.CREATE_CONTRACT);
    if (saveForm) {
      setForm(saveForm);
    }
  };

  const handleChange = (event) => {
    const { name, checked } = event.target;

    const target = {
      name,
      value: checked
    };
    onChange({ target });
  };

  const saveInLocalStorage = () => {
    setItemLocalStorage(LocalStorageKeys.CREATE_CONTRACT, form);
  };

  const backStep = () => {
    saveInLocalStorage();
    onBackStep();
  };

  return (
    <Container container>
      <Loading loading={loading} />
      <Grid item xs={12}>
        <AlertMessage
          title={alertMessage?.title}
          severity={alertMessage?.severity}
          message={alertMessage?.message}
          onClose={() => clearAlertMessage(setAlertMessage)}
        />
      </Grid>
      <UploadButton
        text="Contrato em PDF *"
        noImageMassege="Nunhum documento selecionado."
        outImageName={documentName}
        setImage={(document) => setStates({ document })}
        setOutImageName={(documentName) => setStates({ documentName })}
        noConvertToBase64
      />
      <FormGroup id="checkbox">
        <FormControlLabel
          control={
            <Checkbox
              name="is_confidential"
              checked={form.is_confidential}
              onChange={handleChange}
            />
          }
          label="Ao marcar essa opção, você diz que o contrato é confidencial."
        />
        <FormControlLabel
          control={<Checkbox name="is_signed" checked={form.is_signed} onChange={handleChange} />}
          required
          label="Confirmo que o contrato foi assinado pelo jurídico e pelas partes. *"
        />
      </FormGroup>
      <TextFieldKeyPress
        multiline
        rows={4}
        label="Observação"
        name="observation"
        onChange={onChange}
        value={form.observation}
      />

      <InformativeText />

      <Grid item className="buttonGroup twoButtons">
        <Button onClick={backStep} variant="outlined">
          Voltar
        </Button>
        <Button onClick={handleOpen} disabled={!form.is_signed}>
          Cadastrar
        </Button>
      </Grid>

      <Modal
        open={openModal}
        onClose={handleClose}
        onConfirm={onConfirmModal}
        title="Criar contrato"
        text="Tem certeza que deseja criar um novo contrato?"
        warning="As informações não poderão ser editadas ou excluidas após sua criação."
      />
    </Container>
  );
}
