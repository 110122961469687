import { useCallback, useEffect } from 'react';
import { useDebounce } from 'use-debounce';
import { useNavigate } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Grid, MenuItem, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Table } from './styled';
import { DesktopTable } from './TablesComponents/DesktopTable';
import { MobileTable } from './TablesComponents/MobileTable';
import { BasePage } from '../../../../components/BaseMain';
import {
  TitlePages,
  FilterContainer,
  EmptyTable,
  AlertMessage,
  HiddenForPermission,
  TargetStatus
} from '../../../../components';
import { useForm } from '../../../../hooks/useForm';
import { hasPermission, isDesktop, onChangeDate } from '../../../../utils';
import { goToAddContract, goToDatailsContract } from '../../../../router/coordinator';
import { Order, Status } from '../../../../utils/enums';
import { listAllContracts } from '../../../../services/contractService';
import { GENERIC_ERROR, INVALID_DATE } from '../../../../utils/constantes';
import { FilterButton } from '../../../../components/FilterButton';
import { listCompanies, listIndexes, listSectors, listSegments } from '../../../../services';
import AutocompleteSearch from '../../../../components/AutocompleteSearch';
import { useUpdateState } from '../../../../hooks/useUpdateState';

export function ListAllContracts() {
  const navigate = useNavigate();
  const breadcrumbs = [{ title: 'Contratos' }, { title: 'Todos' }];
  const [{ count, page, offset, order, orderBy }, setPaginateStates] = useUpdateState({
    count: 0,
    page: 0,
    offset: 5,
    order: Order.DESC,
    orderBy: ''
  });

  const [filters, onChange, clearForm] = useForm({
    id: '',
    status: '',
    uuid_segment: '',
    uuid_provider: '',
    init_date: null,
    expiring_date: null,
    uuid_index: '',
    uuid_sector: '',
    provider_is_intern: null
  });
  const [debouncedFilter] = useDebounce(filters, 500);
  const [{ contracts, loading }, setStates] = useUpdateState({
    contracts: [],
    loading: true,
    loadingfields: true
  });
  const [alertMessage, setAlertMessage] = useUpdateState({
    title: '',
    message: '',
    severity: 'error'
  });

  const getProviders = useCallback(async () => {
    try {
      const data = await listCompanies();
      return data;
    } catch (error) {
      setAlertMessage({
        title: error?.message || GENERIC_ERROR,
        message: error?.trace && `Código: ${error?.trace}`
      });
      return [];
    }
  }, []);

  const getSectors = useCallback(async () => {
    try {
      const data = await listSectors();
      return data;
    } catch (error) {
      setAlertMessage({
        title: error?.message || GENERIC_ERROR,
        message: error?.trace && `Código: ${error?.trace}`
      });
      return [];
    }
  }, []);

  const getIndexes = useCallback(async () => {
    try {
      const data = await listIndexes();
      return data;
    } catch (error) {
      setAlertMessage({
        title: error?.message || GENERIC_ERROR,
        message: error?.trace && `Código: ${error?.trace}`
      });
      return [];
    }
  }, []);

  const getSegments = useCallback(async () => {
    try {
      const data = await listSegments();
      return data;
    } catch (error) {
      setAlertMessage({
        title: error?.message || GENERIC_ERROR,
        message: error?.trace && `Código: ${error?.trace}`
      });
      return [];
    }
  }, []);

  const handleFilter = () => {
    const initDateFormat = filters.init_date?.format('YYYY-MM-DD');
    const expiringDateFormat = filters.expiring_date?.format('YYYY-MM-DD');

    const handleFitler = {
      ...filters,
      order_by: orderBy,
      init_date: initDateFormat === INVALID_DATE ? undefined : initDateFormat,
      expiring_date: expiringDateFormat === INVALID_DATE ? undefined : expiringDateFormat,
      status: filters?.status === Status.ALL ? undefined : filters?.status
    };

    return handleFitler;
  };

  const listContracts = useCallback(async () => {
    try {
      setStates({ loading: true });

      const filter = handleFilter();
      const result = await listAllContracts(page, offset, order, filter);

      setPaginateStates({ count: result.count });
      setStates({ contracts: result.data });
    } catch (error) {
      setAlertMessage({ title: error?.message || GENERIC_ERROR });
    } finally {
      setStates({ loading: false });
    }
  }, [page, offset, order, debouncedFilter]);

  useEffect(() => {
    listContracts();
  }, [listContracts]);

  const goToDetails = (uuid) => {
    goToDatailsContract(navigate, uuid);
  };

  const handlePage = (_event, newPage) => setPaginateStates({ page: newPage });
  const handleChangeRowsPerPage = (event) => {
    setPaginateStates({ offset: parseInt(event.target.value, 10), page: 0 });
  };
  const handleOrder = (field) => {
    setPaginateStates({ orderBy: field, order: order === Order.ASC ? Order.DESC : Order.ASC });
  };

  function TableResponsive() {
    if (!contracts?.length && !loading) {
      return <EmptyTable />;
    }

    if (isDesktop()) {
      return (
        <DesktopTable
          contracts={contracts}
          loading={loading}
          count={count}
          page={page}
          offset={offset}
          order={order}
          orderBy={orderBy}
          handlePage={handlePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleOrder={handleOrder}
          goToDetails={goToDetails}
        />
      );
    }

    return (
      <MobileTable
        contracts={contracts}
        loading={loading}
        count={count}
        page={page}
        offset={offset}
        handlePage={handlePage}
        goToDetails={goToDetails}
      />
    );
  }

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages
        title={'Lista de contratos'}
        description={'Lista de todos os contratos da empresa disponíveis a você.'}
        textButton={'Cadastrar contrato'}
        Icon={AddCircleOutlineIcon}
        onClickButton={() => goToAddContract(navigate)}
        keyPermissionButton={'CREATE_CONTRACT'}
        toHideDivider={!hasPermission('LIST_CONTRACT')}
      />

      <HiddenForPermission keys={'LIST_CONTRACT'}>
        <Grid container>
          <AlertMessage
            title={alertMessage?.title}
            message={alertMessage?.message}
            severity={alertMessage?.severity}
          />
        </Grid>

        <FilterContainer>
          <Grid item xs={12} lg={2}>
            <TextField label={'Id'} name={'id'} value={filters.id} onChange={onChange} />
          </Grid>
          <Grid item xs={12} lg={2}>
            <AutocompleteSearch
              label="Segmento"
              name="uuid_segment"
              value={filters.uuid_segment}
              onChange={onChange}
              optionLabel={['title']}
              getListDataCallback={getSegments}
              formatLabel={(label) => label?.toTitleCase?.()}
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <AutocompleteSearch
              label="Índice"
              name="uuid_index"
              value={filters.uuid_index}
              onChange={onChange}
              optionLabel={['name']}
              getListDataCallback={getIndexes}
              formatLabel={(label) => label?.toUpperCase?.()}
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <TextField
              select
              label="Status"
              name="status"
              value={filters.status}
              onChange={onChange}
            >
              <MenuItem value={Status.ALL}>
                <TargetStatus status={Status.ALL} padding="0" />
              </MenuItem>
              <MenuItem value={Status.ACTIVE}>
                <TargetStatus status={Status.ACTIVE} padding="0" />
              </MenuItem>
              <MenuItem value={Status.INACTIVE}>
                <TargetStatus status={Status.INACTIVE} padding="0" />
              </MenuItem>
              <MenuItem value={Status.PENDING}>
                <TargetStatus status={Status.PENDING} padding="0" />
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} lg={4}>
            <AutocompleteSearch
              label="Fornecedor"
              name="uuid_provider"
              value={filters.uuid_provider}
              onChange={onChange}
              optionLabel={['name']}
              getListDataCallback={getProviders}
              formatLabel={(label) => label?.toCapitalizeCase?.()}
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <AutocompleteSearch
              label="Setor"
              name="uuid_sector"
              value={filters.uuid_sector}
              onChange={onChange}
              optionLabel={['title']}
              getListDataCallback={getSectors}
              formatLabel={(label) => label?.toCapitalizeCase?.()}
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <DatePicker
              label="Data de início"
              name="init_date"
              value={filters.init_date}
              onChange={(e) => onChangeDate(e, 'init_date', onChange)}
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <DatePicker
              label="Data de expiração"
              value={filters.expiring_date}
              name="expiring_date"
              onChange={(e) => onChangeDate(e, 'expiring_date', onChange)}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              select
              label="O fornecedor pertence ao grupo graça?"
              name="provider_is_intern"
              value={filters.provider_is_intern}
              onChange={onChange}
            >
              <MenuItem value={true}>Sim</MenuItem>
              <MenuItem value={false}>Não</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} lg={1}>
            <FilterButton onClick={clearForm} />
          </Grid>
        </FilterContainer>

        <Table>
          <TableResponsive />
        </Table>
      </HiddenForPermission>
    </BasePage>
  );
}
