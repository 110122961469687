import { TextField } from '@mui/material';

/**
 * Input que faz a ação de clicar em um botão
 * submit ao ter 'enter' precionado
 *
 * [ Atenção: se o botão de envio não for tipo 'Submit' a lógica não funciona ]
 *
 * @param {*} props
 * @returns
 */
export function TextFieldKeyPress(props) {
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      const button = document.querySelector('button[type="submit"]');
      if (button) {
        event.preventDefault();
        button.click();
      }
    }
  };

  return <TextField {...props} onKeyPress={handleKeyPress} />;
}
