import { Grid } from '@mui/material';
import styled from 'styled-components';
import { breakpoints } from '../../../../../../theme/breakpoints';

export const Content = styled(Grid)`
  #button-group {
    margin-bottom: 1rem;
    width: 100%;

    button {
      width: 100%;
      margin-top: 1rem;

      @media (min-width: ${breakpoints.md}) {
        margin-right: 1rem;
        width: 25%;
      }
    }
  }

  #allSwitches {
    margin-bottom: 1rem;
  }

  .column {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    text-transform: capitalize;
  }

  .twoButtons {
    margin-top: 1rem;
  }
`;
