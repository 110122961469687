import {
  HiddenForPermission,
  MobileTableConteiner,
  SkeletonMobile,
  TableMobileButton,
  TableMobileCell,
  TableMobileContent,
  TableMobileItem,
  TableMobileTitle
} from '../../../../../../components';
import { goToEditCoin } from '../../../../../../router/coordinator';

export function MobileTable({ coins, loading, navigate }) {
  const coinsList = coins.map((coin) => (
    <TableMobileCell key={coin.uuid}>
      <TableMobileItem>
        <TableMobileTitle>Moeda</TableMobileTitle>
        <TableMobileContent>{coin.title}</TableMobileContent>
      </TableMobileItem>

      <HiddenForPermission keys="EDIT_COIN">
        <TableMobileButton onClick={() => goToEditCoin(navigate, coin.uuid)}>
          Editar
        </TableMobileButton>
      </HiddenForPermission>
    </TableMobileCell>
  ));

  return (
    <MobileTableConteiner>
      {!loading ? coinsList : <SkeletonMobile numbersOfColumns={1} />}
    </MobileTableConteiner>
  );
}
