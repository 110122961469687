import { Button, Divider } from '@mui/material';
import { Content } from './styled';
import { HiddenForPermission } from '../HiddenForPermission';

export function TitlePages({
  title,
  description,
  textButton,
  Icon,
  onClickButton,
  toHideDivider,
  keyPermissionButton,
  ButtonRight
}) {
  return (
    <>
      <Content>
        <div>
          <h2 id="title">{title}</h2>

          <p id="description">
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </p>
        </div>
        <div id="button-content">
          {textButton && (
            <HiddenForPermission keys={keyPermissionButton}>
              <Button onClick={onClickButton}>
                {Icon && <Icon />}
                <span>{textButton}</span>
              </Button>
            </HiddenForPermission>
          )}

          {ButtonRight && (
            <HiddenForPermission keys={keyPermissionButton}>
              <ButtonRight />
            </HiddenForPermission>
          )}
        </div>
      </Content>
      {toHideDivider ? null : <Divider />}
    </>
  );
}
