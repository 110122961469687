import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import { Form } from './styled';
import { BasePage } from '../../../../../components/BaseMain';
import { goToAdmin, goToBack, goToIndustryList } from '../../../../../router/coordinator';
import {
  AlertMessage,
  Loading,
  Modal,
  TextFieldNextFocus,
  TitlePages
} from '../../../../../components';
import { clearAlertMessage, isDesktop } from '../../../../../utils';
import { useForm } from '../../../../../hooks/useForm';
import { editSegment, findSegment } from '../../../../../services';
import { GENERIC_ERROR } from '../../../../../utils/constantes';
import { InformativeText } from '../../../../../components/InformativeText';
import { validateMandatoryInput } from '../../../../../utils/validates';

export function EditSegmentPage() {
  const navigate = useNavigate();
  const breadcrumbs = [
    { title: 'Administrativo', functionCoordinator: () => goToAdmin(navigate) },
    { title: 'Setores das empresas', functionCoordinator: () => goToIndustryList(navigate) },
    { title: 'Editar' }
  ];

  const { uuid } = useParams();
  if (!uuid) {
    goToBack(navigate);
  }

  const [form, onChange, _, setForm] = useForm({
    title: ''
  });
  const [errorsValidation, setErrorsValidation] = useState({});
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const init = useCallback(async () => {
    try {
      const segment = await findSegment(uuid);
      setForm({ title: segment?.title?.toTitleCase?.() });
    } catch (error) {
      setAlertMessage({
        title: error.message || GENERIC_ERROR,
        message: error.trace && `Código: ${error.trace}`
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    init();
  }, []);

  const onConfirmModal = async () => {
    try {
      setLoading(true);
      handleClose();

      await editSegment(uuid, form.title);

      setAlertMessage({
        title: 'Segmento editado com sucesso.',
        message: '',
        severity: 'success'
      });
    } catch (error) {
      setAlertMessage({
        title: error.message || GENERIC_ERROR,
        message: error.trace && `Código: ${error.trace}`
      });
    } finally {
      setLoading(false);
    }
  };

  const disabledButton = () => {
    if (!form.title) {
      return true;
    }

    return false;
  };

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages title="Editar um segmento de contrato" />

      <Loading loading={loading} />

      <Grid container>
        <AlertMessage
          message={alertMessage.message}
          severity={alertMessage.severity}
          title={alertMessage.title}
          onClose={() => clearAlertMessage(setAlertMessage)}
        />
      </Grid>

      <Form container xs={12} rowGap={2} columnSpacing={isDesktop() && 2}>
        <Grid item xs={12} lg={5}>
          <TextFieldNextFocus
            label="Nome"
            name="title"
            required
            value={form.title}
            onChange={onChange}
            onBlur={() => validateMandatoryInput('title', form, setErrorsValidation)}
            error={errorsValidation.title}
            helperText={errorsValidation.title}
          />
        </Grid>

        <InformativeText />

        <Grid item xs={12} className="buttonGroup twoButtons nearTheTop">
          <Button variant="outlined" onClick={() => goToBack(navigate)}>
            Voltar
          </Button>
          <Button onClick={handleOpen} disabled={disabledButton()}>
            Editar
          </Button>
        </Grid>
      </Form>

      <Modal
        open={openModal}
        onClose={handleClose}
        onConfirm={onConfirmModal}
        title="Editar segmento do contrato"
        text="Tem certeza que deseja editar o segmento?"
      />
    </BasePage>
  );
}
