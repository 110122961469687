import {
  HiddenForPermission,
  MobileTableConteiner,
  PaginationMobile,
  SkeletonMobile,
  TableMobileButton,
  TableMobileCell,
  TableMobileContent,
  TableMobileItem,
  TableMobileTitle
} from '../../../../../../components';
import { goToEditIndustry } from '../../../../../../router/coordinator';

export function MobileTable({ sectors, loading, count, page, offset, handlePage, navigate }) {
  const sectorsList = sectors?.map((sector) => {
    return (
      <TableMobileCell key={sector.uuid}>
        <TableMobileItem>
          <TableMobileTitle>Nome </TableMobileTitle>
          <TableMobileContent>{sector.title?.toTitleCase()}</TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Descrição </TableMobileTitle>
          <TableMobileContent>{sector.description?.toTitleCase() || '-'}</TableMobileContent>
        </TableMobileItem>

        <HiddenForPermission keys="EDIT_INDUSTRY">
          <TableMobileButton onClick={() => goToEditIndustry(navigate, sector.uuid)}>
            Editar
          </TableMobileButton>
        </HiddenForPermission>
      </TableMobileCell>
    );
  });

  return (
    <MobileTableConteiner>
      {!loading ? sectorsList : <SkeletonMobile numbersOfColumns={3} />}
      <PaginationMobile count={count} page={page} rowsPerPage={offset} handlePage={handlePage} />
    </MobileTableConteiner>
  );
}
