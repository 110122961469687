import { api } from '../utils/axiosConfig';

/**
 * Dá acesso de uma empresa a um usuário
 * @param {*} userUUID - identificador do usuários
 * @param {*} payload - chave: uuid da empresa | valor: true ou false
 * @returns
 */
export const permissionAccessToCompany = async (userUUID, payload) => {
  try {
    const { data } = await api.post(`/permission/access-company/user/${userUUID}`, payload);
    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Lista todas as permissões
 * @param {*} isAdmin - diz se o usuário é administrador ou n
 * @returns
 */
export const listAllPermissions = async (isAdmin) => {
  try {
    const { data } = await api.get(`/permission/all`, {
      params: {
        is_admin: isAdmin
      }
    });
    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Muda as permissões do usuário em uma empresa
 * @param {*} userUUID - identificador do usuários
 * @param {*} companyUUID - identificador da empresa
 * @param {*} payload - chave: chave da permissão | valor: true ou false
 * @returns
 */
export const changeUserPermissions = async (userUUID, companyUUID, payload) => {
  try {
    const { data } = await api.post(`/permission/user/${userUUID}/company/${companyUUID}`, payload);
    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Busca as permissões do usuário em uma empresa
 * @param {*} userUUID - identificador do usuários
 * @param {*} companyUUID - identificador da empresa
 * @returns
 */
export const listUserPermissionsForCompany = async (userUUID, companyUUID) => {
  try {
    const { data } = await api.get(`/permission/all/user/${userUUID}/company/${companyUUID}`);
    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};
