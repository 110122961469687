import { Alert, Box } from '@mui/material';
import styled from 'styled-components';

export const Content = styled(Alert)`
  margin: 1.5rem 0;
  text-align: left;
`;

export const LinearProgressWrapper = styled(Box)`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

export const LinearProgressContainer = styled(Box)`
  width: 100%;
`;
