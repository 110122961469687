import { api } from '../utils/axiosConfig';

/**
 * Busca todas as empresas do grupo graça
 * @param isIntern - Diz se busca só as empresas internas
 */
export const listAllCompaniesPublic = async (isIntern) => {
  try {
    const { data } = await api.get('/public/company/list-all', {
      params: {
        is_intern: isIntern
      }
    });
    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Lista os cargos
 */
export const listAllOffices = async () => {
  try {
    const { data } = await api.get('/public/office/list-all');
    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Lista os setores
 */
export const listAllSectorsPublic = async () => {
  try {
    const { data } = await api.get('/public/sector/list-all');
    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};
