import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { BasePage } from '../../../../../components/BaseMain';
import { goToAdmin, goToCreateCoin } from '../../../../../router/coordinator';
import {
  AlertMessage,
  EmptyTable,
  FilterContainer,
  HiddenForPermission,
  TitlePages
} from '../../../../../components';
import { useForm } from '../../../../../hooks/useForm';
import { Button, Grid, TextField } from '@mui/material';
import { TableContainer } from '../../../../../components/TableContainer';
import { clearAlertMessage, hasPermission, isDesktop } from '../../../../../utils';
import { DesktopTable } from './TablesComponents/DesktopTable';
import { MobileTable } from './TablesComponents/MobileTable';
import { GENERIC_ERROR } from '../../../../../utils/constantes';
import { listAllCoins } from '../../../../../services';

export function ListCoinPage() {
  const navigate = useNavigate();
  const breadcrumbs = [
    { title: 'Administrativo', functionCoordinator: () => goToAdmin(navigate) },
    { title: 'Moedas' }
  ];

  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  const [filters, onChange] = useForm({
    title: ''
  });
  const [debouncedFilter] = useDebounce(filters, 500);

  const [coins, setCoins] = useState([]);

  useEffect(async () => await list(), [debouncedFilter]);

  const list = async () => {
    try {
      setLoading(true);

      const result = await listAllCoins(filters);

      setCoins(result);
      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  };

  function TableResponsive() {
    if (!coins?.length && !loading) {
      return <EmptyTable />;
    }

    if (isDesktop()) {
      return <DesktopTable coins={coins} loading={loading} navigate={navigate} />;
    }

    return <MobileTable coins={coins} loading={loading} navigate={navigate} />;
  }

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages
        title={'Lista de moedas aceitas'}
        textButton={'Cadastrar nova moeda'}
        Icon={AddCircleOutlineIcon}
        onClickButton={() => goToCreateCoin(navigate)}
        toHideDivider={!hasPermission('LIST_COINS')}
        keyPermissionButton={'CREATE_COIN'}
      />

      <HiddenForPermission keys={'LIST_COINS'}>
        <Grid container>
          <AlertMessage
            message={alertMessage.message}
            severity={alertMessage.severity}
            title={alertMessage.title}
            onClose={() => clearAlertMessage(setAlertMessage)}
          />
        </Grid>

        <FilterContainer>
          <Grid item xs={12} lg={3}>
            <TextField label={'Moeda'} name={'title'} value={filters.title} onChange={onChange} />
          </Grid>
        </FilterContainer>

        <TableContainer>
          <TableResponsive />
        </TableContainer>

        <div className={'buttonGroup twoButtons'}>
          <Button variant="outlined" onClick={() => goToAdmin(navigate)}>
            Voltar
          </Button>
          <span></span>
        </div>
      </HiddenForPermission>
    </BasePage>
  );
}
