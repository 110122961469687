import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import { colors } from '../../../../../../theme/styles';
import { goToIndexHistory } from '../../../../../../router/coordinator';
import { HiddenForPermission, SkeletonDesktop } from '../../../../../../components';

export function DesktopTable({ indexes, navigate, loading }) {
  const indexesList = indexes?.map((index) => (
    <TableRow key={index.uuid} className="not-effect">
      <TableCell>{index.name?.toUpperCase()}</TableCell>
      <TableCell>{index.description?.toTitleCase() || '-'}</TableCell>
      <TableCell>
        {index.accumulatedValue?.toLocaleString('pt-br', {
          minimumFractionDigits: 4
        }) || '-'}
        %
      </TableCell>
      <HiddenForPermission keys="LIST_INDEX_HISTORY">
        <TableCell style={{ textAlign: 'right' }}>
          <IconButton
            style={{ color: colors.black }}
            onClick={() => goToIndexHistory(navigate, index.uuid)}
          >
            <HistoryIcon />
          </IconButton>
        </TableCell>
      </HiddenForPermission>
    </TableRow>
  ));

  function RenderTable() {
    if (loading) {
      return <SkeletonDesktop numbersOfColumns={3} />;
    }

    return indexesList;
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Índice</TableCell>
              <TableCell>Descrição</TableCell>
              <TableCell>Acumulado dos últimos 12 meses</TableCell>
              <HiddenForPermission keys="LIST_INDEX_HISTORY">
                <TableCell style={{ textAlign: 'right' }}>Histórico do indíce</TableCell>
              </HiddenForPermission>
            </TableRow>
          </TableHead>
          <TableBody>
            <RenderTable />
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
