import { useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import LockIcon from '@mui/icons-material/Lock';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import CalculateIcon from '@mui/icons-material/Calculate';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { BasePage } from '../../../../components/BaseMain';
import {
  AlertMessage,
  ButtonPage,
  HiddenForPermission,
  Loading,
  TargetStatus,
  TopBackArea
} from '../../../../components';
import {
  goToBack,
  goToCompanyInformation,
  goToDatailsFinancialContract,
  goToEditContract,
  goToListAdicionalsContract,
  goToListAllContracts,
  goToVisualizeContract
} from '../../../../router/coordinator';
import { ButtonGroup, Content, LineGrid, Link, OptionButton, Title } from './styled';
import { useCallback, useEffect, useState } from 'react';
import { Status } from '../../../../utils/enums';
import {
  activeOrInactiveContract,
  deleteContract,
  findContract
} from '../../../../services/contractService';
import { GENERIC_ERROR } from '../../../../utils/constantes';
import { ModalInput } from '../../../../components/ModalInput';
import { Edit } from '@mui/icons-material';
import MenuOptions from '../../../../components/MenuOptions';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';

export function DetailsContractPage() {
  const navigate = useNavigate();

  const { uuid } = useParams();
  if (!uuid) {
    goToBack(navigate);
  }

  const [openModal, setOpenModal] = useState(false);
  const [contract, setContract] = useState({});
  const [loading, setLoading] = useState(true);
  const [contractActive, setContractActive] = useState();
  const [message, setMessage] = useState({
    title: '',
    message: '',
    severity: 'error',
    progressDuration: undefined,
    onCompleteProgress: undefined
  });

  const breadcrumbs = [
    { title: 'Contratos', functionCoordinator: () => goToListAllContracts(navigate) },
    { title: 'Detalhes' }
  ];

  const handleModal = () => setOpenModal(!openModal);
  const onConfirmModal = async (exclusionReason) => {
    try {
      setLoading(true);
      handleModal();

      await deleteContract(uuid, exclusionReason);
      setLoading(false);
      setMessage((prev) => ({
        ...prev,
        title: 'Contrato deletado com sucesso. Você será redecionado a tela anterior.',
        severity: 'success'
      }));

      setTimeout(() => goToListAllContracts(), 2000);
    } catch (err) {
      setMessage({
        title: err.message || GENERIC_ERROR,
        message: err.trace && `Código: ${err.trace}`
      });
      setLoading(false);
      handleModal();
    }
  };

  const getContract = useCallback(async () => {
    try {
      const result = await findContract(uuid);
      setContract(result);
      setContractActive(contract.active);
    } catch (error) {
      setMessage({
        title: error?.message || GENERIC_ERROR,
        message: error?.trace && `Código: ${error?.trace}`
      });
    } finally {
      setLoading(false);
    }
  }, [contractActive]);

  useEffect(() => {
    getContract();
  }, [getContract]);

  const activeOrInactive = async () => {
    try {
      setLoading(true);

      await activeOrInactiveContract(uuid);

      setContractActive(!contractActive);
      setMessage({
        title: `Contrato ${contract.active ? 'inativado' : 'ativado'} com sucesso.`,
        severity: 'success'
      });
    } catch (error) {
      setMessage({
        title: error?.message || GENERIC_ERROR,
        message: error?.trace && `Código: ${error?.trace}`
      });
    } finally {
      setLoading(false);
    }
  };

  function RightButton() {
    return (
      <MenuOptions.Container>
        <HiddenForPermission keys="ACTIVE_INACTIVE_CONTRACT">
          <MenuOptions.Item onClick={activeOrInactive}>
            <OptionButton variant="text" color={contractActive ? 'error' : 'success'}>
              {contractActive ? (
                <>
                  <DoNotDisturbAltIcon />
                  <span>Inativar contrato</span>
                </>
              ) : (
                <>
                  <CheckCircleIcon />
                  <span>Ativar contrato</span>
                </>
              )}
            </OptionButton>
          </MenuOptions.Item>
        </HiddenForPermission>
        <HiddenForPermission keys="DELETE_CONTRACT">
          <MenuOptions.Item onClick={handleModal}>
            {contract.status && contract.status !== Status.deleted && (
              <OptionButton variant="text" color="error">
                <DeleteIcon />
                <span>Deletar contrato</span>
              </OptionButton>
            )}
          </MenuOptions.Item>
        </HiddenForPermission>
      </MenuOptions.Container>
    );
  }

  const handleCityServiceProvisionCity = (city) => {
    if (!city) {
      return '-';
    }

    return `${city?.name?.toCapitalizeCase?.()}, ${city?.state?.name?.toCapitalizeCase?.()}/${city?.state?.uf?.toUpperCase?.()}`;
  };

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TopBackArea onBack={() => goToBack(navigate)} rightArea={<RightButton />} />
      <Loading loading={loading} />
      <Grid container>
        <AlertMessage
          title={message.title}
          message={message.message}
          severity={message.severity}
          progressDuration={message.progressDuration}
          onCompleteProgress={message.onCompleteProgress}
        />
      </Grid>

      <Content>
        <Title>
          <h2>
            {contract.title?.toTitleCase()}
            <span>
              <TargetStatus status={contract.status} variant={'solid'} />
            </span>
          </h2>
          {contract.isConfidential && (
            <p id="confidential">
              <LockIcon /> <span>Confidencial</span>
            </p>
          )}
        </Title>

        <Grid container>
          <LineGrid container>
            <Grid item xs={12} lg={4}>
              <p>Identificador</p>
              <p>{contract.id || '-'}</p>
            </Grid>
            <Grid item xs={12} lg={4}>
              <p>Setor</p>
              <p>{contract?.sector?.title?.toTitleCase() || '-'}</p>
            </Grid>
            <Grid item xs={12} lg={4}>
              <p>Fornecedor</p>
              <Link onClick={() => goToCompanyInformation(navigate, contract?.provider?.uuid)}>
                {contract?.provider?.name?.toTitleCase() || '-'}
              </Link>
            </Grid>
          </LineGrid>

          <LineGrid container>
            <Grid item xs={12} lg={4}>
              <p>Índice inicial</p>
              <p>{contract?.indexInitial?.name?.toUpperCase() || '-'}</p>
            </Grid>
            <Grid item xs={12} lg={4}>
              <p>Índice atual</p>
              <p>{contract?.indexCurrent?.name?.toUpperCase() || '-'}</p>
            </Grid>
          </LineGrid>

          <LineGrid container>
            <Grid item xs={12} lg={4}>
              <p>Segmento</p>
              <p>{contract?.segments?.title?.toTitleCase() || '-'}</p>
            </Grid>
            <Grid item xs={12} lg={4}>
              <p>Número de contratos adicionais</p>
              <p>{contract?.additionalContracts?.length || 0}</p>
            </Grid>
            <Grid item xs={12} lg={4}>
              <p>Local da prestação de serviço</p>
              <p>{handleCityServiceProvisionCity(contract?.cityServiceProvisionCity)}</p>
            </Grid>
          </LineGrid>

          <LineGrid container>
            <Grid item xs={12} lg={4}>
              <p>Data de início</p>
              <p>{contract.initDate?.toMask('date') || '-'}</p>
            </Grid>
            <Grid item xs={12} lg={4}>
              <p>Data de expiração</p>
              <p>
                {!contract.expirationDate
                  ? 'Indeterminado'
                  : contract.expirationDate?.toMask('date') || '-'}
              </p>
            </Grid>

            {!!contract?.additionalContracts?.length && (
              <Grid item xs={12} lg={4}>
                <p>Data do aditivo</p>
                <p>{contract.additionalDate?.toMask('date') || '-'}</p>
              </Grid>
            )}
          </LineGrid>

          {contract.observation && (
            <LineGrid container>
              <Grid item xs={12}>
                <p>Observação</p>
                <p>{contract.observation}</p>
              </Grid>
            </LineGrid>
          )}

          {contract.timestamp?.deletedAt && (
            <LineGrid container>
              <Grid item xs={12}>
                <p>Motivo da exclusão</p>
                <p>{contract.exclusionReason}</p>
              </Grid>
            </LineGrid>
          )}
        </Grid>

        <ButtonGroup>
          <HiddenForPermission keys={'FIND_FINANCIAL_CONTRACT'}>
            <ButtonPage
              Icon={CalculateIcon}
              onClick={() => goToDatailsFinancialContract(navigate, uuid)}
            >
              Detalhes financeiros
            </ButtonPage>
          </HiddenForPermission>
          <HiddenForPermission keys={['LIST_ADDITIONALS_CONTRACT', 'CREATE_ADDITIONAL_CONTRACT']}>
            <ButtonPage
              Icon={AddToPhotosIcon}
              onClick={() => goToListAdicionalsContract(navigate, uuid)}
            >
              Contratos adicionais
            </ButtonPage>
          </HiddenForPermission>
          <HiddenForPermission keys={'FIND_FILE_CONTRACT'}>
            <ButtonPage
              Icon={InsertDriveFileIcon}
              onClick={() => goToVisualizeContract(navigate, uuid)}
            >
              Visualizar contrato
            </ButtonPage>
          </HiddenForPermission>
          <HiddenForPermission keys={'EDIT_CONTRACT'}>
            <ButtonPage Icon={Edit} onClick={() => goToEditContract(navigate, uuid)}>
              Editar contrato
            </ButtonPage>
          </HiddenForPermission>
        </ButtonGroup>
      </Content>

      <ModalInput
        open={openModal}
        onClose={handleModal}
        onConfirm={onConfirmModal}
        title={'Deletar contrato'}
        inputLabel={`Digite o motivo da exclusão do contrato: ${contract.id}`}
        buttonConfirm={'Deletar'}
        buttonCancel={'Cancelar'}
        warning={'Esta ação é irreversível.'}
      />
    </BasePage>
  );
}
