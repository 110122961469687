import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { BasePage } from '../../../../../components/BaseMain';
import {
  goToBack,
  goToDatailsContract,
  goToListAllContracts
} from '../../../../../router/coordinator';
import { TopBackArea, PDFViewer, Loading, AlertMessage } from '../../../../../components';
import { GENERIC_ERROR } from '../../../../../utils/constantes';
import { findAdditionalContractFile } from '../../../../../services';
import { clearAlertMessage } from '../../../../../utils';

export function VisualizeAdditionalContractPage() {
  const navigate = useNavigate();

  const { uuid } = useParams();
  if (!uuid) {
    goToBack(navigate);
  }

  const breadcrumbs = [
    {
      title: 'Contratos',
      functionCoordinator: () => goToListAllContracts(navigate)
    },
    { title: 'Detalhes', functionCoordinator: () => goToDatailsContract(navigate, uuid) },
    { title: 'Visualizar' }
  ];

  const [contractFile, setContractFile] = useState(true);
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  useEffect(async () => await init(), []);

  const init = async () => {
    try {
      const result = await findAdditionalContractFile(uuid);
      setContractFile(result.base64);

      setLoading(false);
    } catch (err) {
      setAlertMessage({
        title: err.message || GENERIC_ERROR,
        message: err.trace && `Código: ${err.trace}`
      });
      setLoading(false);
    }
  };

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TopBackArea onBack={() => goToBack(navigate)} />
      <Loading loading={loading} />
      <AlertMessage
        message={alertMessage.message}
        severity={alertMessage.severity}
        title={alertMessage.title}
        onClose={() => clearAlertMessage(setAlertMessage)}
      />

      <PDFViewer base64={contractFile} />
    </BasePage>
  );
}
