import { useNavigate } from 'react-router-dom';
import { Button, Grid, InputAdornment, MenuItem } from '@mui/material';
import { Form } from './styled';
import { BasePage } from '../../../../../components/BaseMain';
import { goToAdmin, goToCoinList, goToCreateCoin } from '../../../../../router/coordinator';
import {
  AlertMessage,
  Loading,
  Modal,
  TextFieldKeyPress,
  TextFieldNextFocus,
  TitlePages
} from '../../../../../components';
import { clearAlertMessage, isDesktop, onChangeMoney, toCent } from '../../../../../utils';
import { useForm } from '../../../../../hooks/useForm';
import { useState } from 'react';
import { createCoin } from '../../../../../services';
import { GENERIC_ERROR } from '../../../../../utils/constantes';
import { Coin } from '../../../../../utils/enums';

export function CreateCoinPage() {
  const navigate = useNavigate();
  const breadcrumbs = [
    { title: 'Administrativo', functionCoordinator: () => goToAdmin(navigate) },
    { title: 'Moedas', functionCoordinator: () => goToCreateCoin(navigate) },
    { title: 'Cadastrar' }
  ];

  const [form, onChange, clearForm] = useForm({
    name: '',
    acronym: '',
    exchange: ''
  });
  const [errorsValidation, setErrorsValidation] = useState({});
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  // --- Lógica do modal
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const onConfirmModal = async () => {
    try {
      setLoading(true);
      handleClose();

      const { name, acronym, exchange } = form;
      const exchangeToCent = exchange && toCent(exchange);

      await createCoin(name, acronym, exchangeToCent);

      setAlertMessage((prev) => ({
        ...prev,
        title: 'Moeda criada com sucesso.',
        severity: 'success'
      }));

      clearForm();
      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  };
  // ---

  const validateMandatoryInput = (field) => {
    if (!form[field]) {
      setErrorsValidation((prevError) => ({ ...prevError, [field]: true }));
      return;
    }
    setErrorsValidation((prevError) => ({ ...prevError, [field]: false }));
  };

  const disabledButton = () => {
    if (!form.name) {
      return true;
    }
    if (!form.acronym) {
      return true;
    }

    return false;
  };

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages title={'Cadastrar nova Moeda'} />

      <Loading loading={loading} />
      <Grid container>
        <AlertMessage
          message={alertMessage.message}
          severity={alertMessage.severity}
          title={alertMessage.title}
          onClose={() => clearAlertMessage(setAlertMessage)}
        />
      </Grid>

      <Form container xs={12} rowGap={2} columnSpacing={isDesktop() && 2}>
        <Grid item xs={12} lg={4}>
          <TextFieldNextFocus
            label="Nome"
            name={'name'}
            required
            value={form.name.toUpperCase()}
            onChange={onChange}
            onBlur={() => validateMandatoryInput('name')}
            error={errorsValidation.name}
            helperText={errorsValidation.name && 'O nome é obrigatório'}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextFieldKeyPress
            label="Sigla da moeda"
            placeholder="BRL"
            name={'acronym'}
            required
            select
            value={form.acronym.toUpperCase()}
            onChange={onChange}
            onBlur={() => validateMandatoryInput('acronym')}
            error={errorsValidation.acronym}
            helperText={errorsValidation.acronym && 'A sigla é obrigatória'}
          >
            {Object.values(Coin)?.map((coinISO) => (
              <MenuItem key={coinISO} value={coinISO}>
                {coinISO}
              </MenuItem>
            ))}
          </TextFieldKeyPress>
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextFieldKeyPress
            label="Valor do câmbio atual em real"
            name={'exchange'}
            value={form.exchange}
            onChange={(e) => onChangeMoney(e, onChange)}
            InputProps={{
              startAdornment: <InputAdornment position="start">R$</InputAdornment>
            }}
          />
        </Grid>

        <Grid item xs={12} className={'buttonGroup twoButtons'}>
          <Button variant="outlined" onClick={() => goToCoinList(navigate)}>
            Voltar
          </Button>
          <Button onClick={handleOpen} disabled={disabledButton()}>
            Cadastrar
          </Button>
        </Grid>
      </Form>

      <Modal
        open={openModal}
        onClose={handleClose}
        onConfirm={onConfirmModal}
        title={'Criar nova moeda'}
        text={`Tem certeza que deseja criar a moeda ${form.name && form.name.toUpperCase()}? `}
        warning={'Após ser criado, a moeda não poderá ser apagada.'}
        buttonConfirm={'Sim'}
        buttonCancel={'Não'}
      />
    </BasePage>
  );
}
