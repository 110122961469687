import { Skeleton, TableCell, TableRow } from '@mui/material';

export function SkeletonDesktop({ numbersOfColumns }) {
  const arrayWithTheNumberOfColumns = [];
  for (let i = 0; i < numbersOfColumns; i++) {
    arrayWithTheNumberOfColumns.push(i);
  }

  const handleCellSkeleton = () => {
    const skeletons = arrayWithTheNumberOfColumns.map((i) => (
      <TableCell key={i}>
        <Skeleton animation="wave" variant="rectangular" height={30} />
      </TableCell>
    ));

    return skeletons;
  };

  const handleSkeleton = () => {
    const rowsWithSkeletons = [1, 2, 3, 4, 5].map((i) => (
      <TableRow key={i}>{handleCellSkeleton()}</TableRow>
    ));

    return rowsWithSkeletons;
  };

  return handleSkeleton();
}
