import styled from 'styled-components';
import { metrics, colors } from '../../theme/styles';

export const Main = styled.main`
  padding: ${metrics.padding.terms};

  #content {
    background-color: ${colors.backgroundWhite};
    padding: 1.5rem;
    border-radius: 5px;
    min-height: 85vh;
    position: relative;
  }

  #term {
    margin-top: 1rem;
    padding: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    min-height: 65vh;
    overflow-y: auto;
  }

  #checkbox {
    margin-top: 1rem;
  }
`;

export const Title = styled.h1`
  font-size: ${metrics.size.medium};
  margin: 0;
  color: ${colors.theme.primary};
`;

export const ButtonContent = styled.div`
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;

  button {
    width: 8%;
  }
`;
