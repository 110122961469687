import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Informations, Table } from './styled';
import { DesktopTable } from './TablesComponents/DesktopTable';
import { MobileTable } from './TablesComponents/MobileTable';
import { useForm } from '../../../../hooks/useForm';
import { BasePage } from '../../../../components/BaseMain';
import {
  AlertMessage,
  HiddenForPermission,
  EmptyTable,
  FilterContainer,
  TitlePages
} from '../../../../components';
import { clearAlertMessage, hasPermission, isDesktop, onChangeDate } from '../../../../utils';
import { GENERIC_ERROR, INVALID_DATE } from '../../../../utils/constantes';
import { Order, Status } from '../../../../utils/enums';
import {
  findAddedContractInformations,
  listAddedContracts,
  listSectors
} from '../../../../services';
import { useDebounce } from 'use-debounce';
import { FilterButton } from '../../../../components/FilterButton';
import AutocompleteSearch from '../../../../components/AutocompleteSearch';
import { useUpdateState } from '../../../../hooks/useUpdateState';

export function ListOfAddedContracts() {
  const navigate = useNavigate();
  const breadcrumbs = [{ title: 'Contratos' }, { title: 'Adicionados' }];

  const [{ count, page, offset, order }, setPaginate] = useUpdateState({
    count: 0,
    page: 0,
    offset: 5,
    order: Order.DESC,
    orderBy: ''
  });
  const [{ contracts, loading, informations }, setStates] = useUpdateState({
    contracts: [],
    loading: true,
    informations: []
  });
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  const [filters, onChange, clearForm] = useForm({
    id: '',
    uuid_sector: '',
    name: '',
    tellphone: '',
    cellphone: '',
    email: '',
    created_at: null
  });
  const [debouncedFilter] = useDebounce(filters, 500);

  const init = useCallback(async () => {
    try {
      setStates({ loading: true });

      const result = await listAddedContracts(page, offset, order, filters);

      setStates({ contracts: result?.data });
      setPaginate({ count: result?.count });

      if (hasPermission('ADDED_CONTRACT_INFORMATION_CONTRACT')) {
        const informations = await findAddedContractInformations();
        setStates({ informations });
      }
    } catch (error) {
      setAlertMessage({
        title: error?.message || GENERIC_ERROR,
        message: error?.trace && `Código: ${error?.trace}`
      });
    } finally {
      setStates({ loading: false });
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  const listContracts = useCallback(async () => {
    try {
      setStates({ loading: true });

      const filter = handleFilter();
      const result = await listAddedContracts(page, offset, order, filter);

      setStates({ contracts: result?.data });
      setPaginate({ count: result.count });
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
    } finally {
      setStates({ loading: false });
    }
  }, [page, offset, order, debouncedFilter]);

  useEffect(() => {
    listContracts();
  }, [listContracts]);

  const handleFilter = () => {
    const createdAtFormat = filters.created_at?.format('YYYY-MM-DD');

    const handlerFilter = {
      ...filters,
      status: filters?.status === Status.ALL ? undefined : filters?.status,
      init_date: createdAtFormat === INVALID_DATE ? undefined : createdAtFormat
    };

    return handlerFilter;
  };

  const handlePage = (_event, newPage) => setPaginate({ page: newPage });
  const handleChangeRowsPerPage = (event) => {
    setPaginate({ offset: parseInt(event.target.value, 10), page: 0 });
  };
  const handleOrder = (field) => {
    setPaginate({ orderBy: field, order: order === Order.ASC ? Order.DESC : Order.ASC });
  };

  const getSectors = useCallback(async () => {
    try {
      const data = await listSectors();
      return data;
    } catch (error) {
      setAlertMessage({
        title: error?.message || GENERIC_ERROR,
        message: error?.trace && `Código: ${error?.trace}`
      });
      return [];
    }
  }, []);

  function TableResponsive() {
    if (!contracts?.length && !loading) {
      return <EmptyTable />;
    }

    if (isDesktop()) {
      return (
        <DesktopTable
          contracts={contracts}
          loading={loading}
          count={count}
          page={page}
          offset={offset}
          order={order}
          navigate={navigate}
          handlePage={handlePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleOrder={handleOrder}
        />
      );
    }

    return (
      <MobileTable
        contracts={contracts}
        loading={loading}
        count={count}
        page={page}
        offset={offset}
        navigate={navigate}
        handlePage={handlePage}
      />
    );
  }

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages
        title="Lista de contratos adicionados"
        description="Contratos que foram adicionados na empresa nos últimos 30 dias."
      />

      <Grid container>
        <AlertMessage
          title={alertMessage.title}
          message={alertMessage.message}
          severity={alertMessage.severity}
          onClose={() => clearAlertMessage(setAlertMessage)}
        />
      </Grid>

      <HiddenForPermission keys="ADDED_CONTRACT_INFORMATION_CONTRACT">
        <Informations container>
          <Grid item xs={12} lg={4}>
            <p>Total de contratos disponíveis</p>
            <p>{informations?.total || 0}</p>
          </Grid>
          <Grid item xs={12} lg={5}>
            <p>Quantidade de contratos antes dos adicionados</p>
            <p>{informations?.count_contract_before || 0}</p>
          </Grid>
          <Grid item xs={12} lg={3}>
            <p>Quantidade de contratos adicionados</p>
            <p>{informations?.added_contracts || 0}</p>
          </Grid>
        </Informations>
      </HiddenForPermission>

      <FilterContainer>
        <Grid item xs={12} lg={3}>
          <TextField label="Id" name="id" value={filters?.id} onChange={onChange} />
        </Grid>
        <Grid item xs={12} lg={3}>
          <AutocompleteSearch
            label="Setor"
            name="uuid_sector"
            value={filters?.uuid_sector}
            onChange={onChange}
            optionLabel={['title']}
            getListDataCallback={getSectors}
            formatLabel={(label) => label?.toCapitalizeCase?.()}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <TextField label="Contato" name="name" value={filters.name} onChange={onChange} />
        </Grid>
        <Grid item xs={12} lg={3}>
          <TextField
            label="Telefone"
            name="tellphone"
            value={filters.tellphone}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <TextField
            label="Celular"
            name="cellphone"
            value={filters.cellphone}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <TextField label="E-mail" name="email" value={filters.email} onChange={onChange} />
        </Grid>
        <Grid item xs={12} lg={3}>
          <DatePicker
            label="Adicionado em"
            name="created_at"
            value={filters.created_at}
            onChange={(e) => onChangeDate(e, 'created_at', onChange)}
          />
        </Grid>
        <Grid item xs={12} lg={1}>
          <FilterButton onClick={clearForm} />
        </Grid>
      </FilterContainer>

      <Table>
        <TableResponsive />
      </Table>
    </BasePage>
  );
}
