import styled from 'styled-components';
import { colors } from '../../theme/styles';
import { breakpoints } from '../../theme/breakpoints';

export const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  abbr {
    width: 100%;
  }

  p {
    margin: 0 0.5rem;
  }

  button {
    margin-bottom: 0.2rem;
    color: ${colors.gray};
    border-color: ${colors.gray};
    width: 100%;

    && {
      width: 5rem;
    }

    :hover {
      border-color: ${colors.gray};
    }

    @media (min-width: ${breakpoints.md}) {
      width: auto;
    }
  }
`;
