import { Button } from './styled';

/**
 * Botão quadrado e grande, usado para diferenciar do botões de ação
 * esse manda pra outra página
 * @param {*} props
 * @returns
 */
export function ButtonPage(props) {
  const { Icon, children } = props;

  return (
    <Button {...props}>
      {Icon && (
        <div>
          <Icon />
        </div>
      )}
      <p>{children}</p>
    </Button>
  );
}
