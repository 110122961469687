import { ReactComponent as NotFound } from '../../../assets/notFound.svg';
import { Container } from './styled';

export function NotFoundPage() {
  return (
    <Container>
      <NotFound />
      <p>Página não encontrada</p>
    </Container>
  );
}
