export const monthsOfTheYear = [
  { value: 1, text: 'Janeiro' },
  { value: 2, text: 'Fevereiro' },
  { value: 3, text: 'Março' },
  { value: 4, text: 'Abril' },
  { value: 5, text: 'Maio' },
  { value: 6, text: 'Junho' },
  { value: 7, text: 'Julho' },
  { value: 8, text: 'Agosto' },
  { value: 9, text: 'Setembro' },
  { value: 10, text: 'Outubro' },
  { value: 11, text: 'Novembro' },
  { value: 12, text: 'Dezembro' }
];
