import { useNavigate } from 'react-router-dom';
import { BasePage } from '../../../../../../../components/BaseMain';
import { goToAdmin, goToUsersList } from '../../../../../../../router/coordinator';
import { Content } from './styled';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button } from '@mui/material';

export function ConcludedPage() {
  const navigate = useNavigate();
  const breadcrumbs = [
    { title: 'Administrativo', functionCoordinator: () => goToAdmin(navigate) },
    { title: 'Usuários', functionCoordinator: () => goToUsersList(navigate) },
    { title: 'Criar' },
    { title: 'Concluído' }
  ];

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <Content>
        <CheckCircleIcon />
        <h1>Concluído</h1>
        <p>
          O usuário foi criado e já está ativo no sistema,
          <br />
          ele receberá em seu e-mail um link para criar a senha.
        </p>
        <Button onClick={() => goToUsersList(navigate)}>Voltar para a lista de usuários</Button>
      </Content>
    </BasePage>
  );
}
