import {
  MobileTableConteiner,
  PaginationMobile,
  SkeletonMobile,
  TableMobileButton,
  TableMobileCell,
  TableMobileContent,
  TableMobileItem,
  TableMobileTitle,
  TargetStatus
} from '../../../../../../../components';
import { goToDatailsContract } from '../../../../../../../router/coordinator';

export function MobileTable({
  contracts,
  navigate,
  loading,
  count,
  page,
  offset,
  handlePage,
  handleChangeRowsPerPage
}) {
  const contractsList = contracts?.map((contract) => (
    <TableMobileCell key={contract.uuid}>
      <TableMobileItem>
        <TableMobileTitle>Id</TableMobileTitle>
        <TableMobileContent>{contract.id}</TableMobileContent>
      </TableMobileItem>

      <TableMobileItem>
        <TableMobileTitle>Status</TableMobileTitle>
        <TableMobileContent>
          <TargetStatus status={contract.status} />
        </TableMobileContent>
      </TableMobileItem>

      <TableMobileItem>
        <TableMobileTitle>Segmento</TableMobileTitle>
        <TableMobileContent>{contract.segments?.title?.toTitleCase()}</TableMobileContent>
      </TableMobileItem>

      <TableMobileItem>
        <TableMobileTitle>Fornecedor</TableMobileTitle>
        <TableMobileContent>{contract.provider?.name?.toTitleCase()}</TableMobileContent>
      </TableMobileItem>

      <TableMobileItem>
        <TableMobileTitle>Data inicial</TableMobileTitle>
        <TableMobileContent>{contract.initDate?.toMask('date')}</TableMobileContent>
      </TableMobileItem>

      <TableMobileItem>
        <TableMobileTitle>Data de expiração</TableMobileTitle>
        <TableMobileContent>{contract.expirationDate?.toMask('date')}</TableMobileContent>
      </TableMobileItem>

      <TableMobileButton onClick={() => goToDatailsContract(navigate, contract.uuid)}>
        Ver detalhes
      </TableMobileButton>
    </TableMobileCell>
  ));

  return (
    <MobileTableConteiner>
      {!loading ? contractsList : <SkeletonMobile numbersOfColumns={10} />}
      <PaginationMobile
        count={count}
        page={page}
        rowsPerPage={offset}
        handlePage={handlePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </MobileTableConteiner>
  );
}
