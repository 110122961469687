import { useEffect, useState } from 'react';
import { hasPermission } from '../../utils';

export function HiddenForPermission({ keys, children }) {
  const [showComponent, setShowComponent] = useState(false);

  useEffect(() => {
    if (!keys || !keys?.length) {
      setShowComponent(true);
      return;
    }

    const havePermission = hasPermission(keys);
    setShowComponent(havePermission);
  }, []);

  return showComponent ? children : null;
}
