import { goToAdmin } from '../../../../../router/coordinator';
import { GENERIC_ERROR } from '../../../../../utils/constantes';
import { BasePage } from '../../../../../components/BaseMain';
import {
  AlertMessage,
  HiddenForPermission,
  PDFViewer,
  TitlePages,
  UploadButton
} from '../../../../../components';
import { clearAlertMessage, hasPermission } from '../../../../../utils';
import { Button, Grid } from '@mui/material';
import { StyledGrid } from './styled';
import { useNavigate } from 'react-router-dom';
import { createHelpFile } from '../../../../../services/helpFileService';
import { useUpdateState } from '../../../../../hooks/useUpdateState';

export function CreateHelpFilePage() {
  const navigate = useNavigate();
  const breadcrumbs = [
    { title: 'Administrativo', functionCoordinator: () => goToAdmin(navigate) },
    { title: 'Arquivo de ajuda' }
  ];
  const [{ document, documentName, documentBase64 }, setStates] = useUpdateState({
    document: null,
    documentName: '',
    documentBase64: ''
  });

  const [alertMessage, setAlertMessage] = useUpdateState({
    severity: '',
    title: '',
    message: '',
    onClose: () => {}
  });

  const handleDocumentUpload = (file) => {
    setStates({ document: file });
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setStates({ documentBase64: reader.result.split(',')[1] });
    };
  };

  const handleSubmit = async () => {
    try {
      await createHelpFile(document);

      setAlertMessage({
        severity: 'success',
        title: 'Sucesso',
        message: 'Arquivo de ajuda enviado com sucesso.'
      });
    } catch (err) {
      setAlertMessage({
        severity: 'error',
        title: 'Erro ao enviar arquivo',
        message: err?.message || GENERIC_ERROR
      });
    }
  };

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages
        title="Tela para enviar arquivo de ajuda"
        description="Nesta tela você enviará o arquivo que aparecerá na tela de ajuda, ele sobrescreverá o arquivo anterior."
        toHideDivider={!hasPermission('CREATE_HELP_FILE')}
      />
      <HiddenForPermission keys={'CREATE_HELP_FILE'}>
        <Grid container>
          <AlertMessage
            title={alertMessage?.title}
            severity={alertMessage?.severity}
            message={alertMessage?.message}
            onClose={() => clearAlertMessage(setAlertMessage)}
          />
        </Grid>

        <UploadButton
          text="Arquivo de ajuda *"
          noImageMassege="Nenhum documento selecionado."
          setImage={handleDocumentUpload}
          outImageName={documentName}
          setOutImageName={(documentName) => setStates({ documentName })}
          noConvertToBase64
        />

        {documentBase64 && (
          <StyledGrid>
            <PDFViewer base64={documentBase64} />
          </StyledGrid>
        )}

        <div className={'buttonGroup twoButtons'}>
          <Button variant="outlined" onClick={() => goToAdmin(navigate)}>
            Voltar
          </Button>

          <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!document}>
            Enviar arquivo
          </Button>
        </div>
      </HiddenForPermission>
    </BasePage>
  );
}
