import { Button, colors } from '@mui/material';
import styled from 'styled-components';

const ButtonBase = styled(Button)`
  && {
    font-size: 0.7rem;
    padding: 0.2rem 0.8rem;
  }
`;

export const InactiveButton = styled(ButtonBase)`
  && {
    background-color: ${colors.red[700]};

    &:hover {
      background-color: ${colors.red[800]};
    }
  }
`;

export const ActiveButton = styled(ButtonBase)`
  && {
    background-color: ${colors.green[700]};

    &:hover {
      background-color: ${colors.green[800]};
    }
  }
`;
