import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BasePage } from '../../../../../components/BaseMain';
import { goToAdmin, goToBack, goToTemplatesList } from '../../../../../router/coordinator';
import { AlertMessage, Loading, Modal, TitlePages } from '../../../../../components';
import { Button, Grid, Switch } from '@mui/material';
import { GENERIC_ERROR } from '../../../../../utils/constantes';
import { listAllPermissions } from '../../../../../services';
import {
  editTemplate,
  listAllPermissionsOfTemplate
} from '../../../../../services/templateService';
import { clearAlertMessage, noRepeatArray } from '../../../../../utils';
import { translateCategoryPermission } from '../../../../../utils/translate';
import { CustomFormControlLabel } from '../../../../../components/CustomFormControlLabel';
import { Container, Content } from './styled';
import { CategoryContainer } from '../../user/RegisterNewUserPage/permissions/ChoicePermissionsPage/styled';
import { findOffice } from '../../../../../services/officeService';
import { Offices } from '../../../../../utils/enums';

export function EditTemplatePage() {
  const navigate = useNavigate();
  const breadcrumbs = [
    { title: 'Administrativo', functionCoordinator: () => goToAdmin(navigate) },
    { title: 'Permissões padrões', functionCoordinator: () => goToTemplatesList(navigate) },
    { title: 'Editar' }
  ];

  const { uuid } = useParams();
  if (!uuid) {
    goToBack(navigate);
  }

  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });
  const [permissions, setPermissions] = useState([]);
  const [permissionsChoice, setPermissionsChoice] = useState({});
  const [categories, setCategories] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const initListOfPermissions = useCallback(
    (permissionsUser) => {
      let permissionsList = {};
      permissions?.forEach((permission) => {
        const haveAccess = permissionsUser?.some(
          (permissionKey) => permissionKey === permission.key
        );
        permissionsList = { ...permissionsList, [permission.key]: haveAccess };
      });

      setPermissionsChoice(permissionsList);
    },
    [permissions]
  );

  const init = useCallback(async () => {
    try {
      const office = await findOffice(uuid);
      const isAdmin = office?.title === Offices.ADMIN;

      const [permissionsResult, permissionsOfTemplate] = await Promise.all([
        listAllPermissions(isAdmin),
        listAllPermissionsOfTemplate(uuid)
      ]);

      setPermissions(permissionsResult);
      setCategories(noRepeatArray(permissionsResult?.map((permission) => permission?.category)));

      initListOfPermissions(permissionsOfTemplate);
    } catch (err) {
      setAlertMessage((prev) => ({
        ...prev,
        title: err?.trace,
        message: err?.message || GENERIC_ERROR,
        severity: 'error'
      }));
    }
  }, []);

  const listChoicePermissions = useCallback(async () => {
    try {
      setLoading(true);

      const [permissionsOfTemplate] = await Promise.all([listAllPermissionsOfTemplate(uuid)]);

      initListOfPermissions(permissionsOfTemplate);
    } catch (err) {
      setAlertMessage((prev) => ({
        ...prev,
        title: err?.trace,
        message: err?.message || GENERIC_ERROR,
        severity: 'error'
      }));
    } finally {
      setLoading(false);
    }
  }, [permissions]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    listChoicePermissions();
  }, [listChoicePermissions]);

  const handleToggle = (e) => {
    const { name, checked } = e?.target || {};
    setPermissionsChoice({ ...permissionsChoice, [name]: checked });
  };

  const ListPermissions = ({ columnType }) => {
    const halfListLength = Math.round(categories.length / 2);
    const categoriesList =
      columnType === 'first'
        ? categories.slice(0, halfListLength)
        : categories.slice(halfListLength);

    return categoriesList?.map((category, index) => {
      const permissionsByCategory = permissions?.filter(
        (permission) => permission?.category === category
      );

      return (
        permissionsByCategory?.length && (
          <CategoryContainer key={index}>
            <p>{translateCategoryPermission(category)}</p>
            <div>
              {permissionsByCategory?.map((permission) => (
                <CustomFormControlLabel
                  key={permission?.uuid}
                  control={
                    <Switch
                      checked={permissionsChoice[permission.key]}
                      name={permission?.key}
                      onChange={handleToggle}
                    />
                  }
                  label={permission?.description?.toTitleCase()}
                />
              ))}
            </div>
          </CategoryContainer>
        )
      );
    });
  };

  const onConfirmModal = async () => {
    try {
      setLoading(true);
      handleClose();

      await editTemplate(uuid, permissionsChoice);

      setAlertMessage((prev) => ({
        ...prev,
        title: 'Permissões alteradas com sucesso.',
        severity: 'success'
      }));
    } catch (error) {
      setAlertMessage((prev) => ({
        ...prev,
        title: error?.trace,
        message: error?.message || GENERIC_ERROR,
        severity: 'error'
      }));
    } finally {
      setLoading(false);
    }
  };

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <Loading loading={loading} />

      <Container>
        <TitlePages
          title="Escolha as permissões que o cargo escolhido terá como padrão inicial"
          description='As alterações só serão salvas após apertar o botão "Salvar", qualquer alteração feita sem essa confirmação será ignorada pelo sistema.'
        />

        <Grid container>
          <AlertMessage
            title={alertMessage.title}
            message={alertMessage.message}
            severity={alertMessage.severity}
            onClose={() => clearAlertMessage(setAlertMessage)}
          />
        </Grid>

        <Content container>
          <Grid item className="column" xs={10} lg={6}>
            <ListPermissions columnType="first" />
          </Grid>
          <Grid item className="column" xs={10} lg={6}>
            <ListPermissions columnType="second" />
          </Grid>

          <Grid container className="buttonGroup twoButtons">
            <Button variant="outlined" onClick={() => goToBack(navigate)}>
              Voltar
            </Button>
            <Button onClick={handleOpen}>Salvar</Button>
          </Grid>
        </Content>
      </Container>

      <Modal
        open={openModal}
        onClose={handleClose}
        onConfirm={onConfirmModal}
        title="Mudar permissões padrões do cargo"
        text="Tem certeza que deseja fazer a mudança?"
        warning="Essa mudança não irá afetar os usuários já criados, para isso, vá no usuário que deseja alterar a permissão e execute a ação desejada."
      />
    </BasePage>
  );
}
