import { api } from '../utils/axiosConfig';

/**
 * Busca as permissões do usuário logado
 * @returns
 */
export const getPermissions = async () => {
  try {
    const { data } = await api.get('/user/permissions');
    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Busca as informações do usuário logado
 * @returns
 */
export const getUserInformations = async () => {
  try {
    const { data } = await api.get('/user/details');
    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Redefine a senha
 * @param {*} password - senha
 * @param {*} confirmPassword - recuperação de senha
 */
export const recoverPassword = async (password, confirmPassword) => {
  try {
    const payload = {
      password,
      confirm_password: confirmPassword
    };
    await api.put('/user/forgot-password', payload);
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Redefine a senha
 * @param {*} password - senha
 * @param {*} confirmPassword - recuperação de senha
 */
export const changeChooseCompany = async (companyUUID) => {
  try {
    const result = await api.put(`/user/alter-chosen-company/${companyUUID}`);
    return result.data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Redefine a senha
 * @param {*} lastPassword - senha antiga
 * @param {*} newPassword - nova senha
 * @param {*} confirmPassword - confirmação da nova senha
 * @returns
 */
export const redefinePassword = async (lastPassword, newPassword, confirmPassword) => {
  try {
    const payload = {
      last_password: lastPassword,
      password: newPassword,
      confirm_password: confirmPassword
    };

    const result = await api.put(`/user/redefine-password`, payload);
    return result.data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Busca as informações do usuário logado
 * @returns
 */
export const getUserLoggedDetails = async () => {
  try {
    const { data } = await api.get(`/user/details`);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Edita o usuário
 * @param {*} uuidUser - identificador
 * @param {*} name
 * @param {*} lastName
 * @param {*} email
 * @param {*} cellphone
 * @param {*} tellphone
 * @param {*} profileImage
 * @returns
 */
export const editUser = async (
  uuidUser,
  name,
  lastName,
  email,
  cellphone,
  tellphone,
  profileImage
) => {
  try {
    const payload = {
      name,
      last_name: lastName,
      email,
      cellphone,
      tellphone,
      profile_image: profileImage
    };
    await api.put(`/user/${uuidUser}`, payload);
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Define uma senha para o usuário cadastrado pelo administrador do sistema
 * @param {*} password
 * @param {*} confirmPassword
 */
export const definePassword = async (password, confirmPassword) => {
  try {
    const payload = {
      password,
      confirm_password: confirmPassword
    };
    await api.post('/user/define-password', payload);
  } catch (error) {
    throw error?.response?.data;
  }
};
