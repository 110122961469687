import { useEffect, useState } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { Loading } from '../Loading';
import { ContainerLoading, ContainerPDF, ContainerViewer, ToolbarContent } from './styled';
import packageJson from '../../../package.json';
import { base64toBlob, isDesktop } from '../../utils';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const pdfjsVersion = packageJson.dependencies['pdfjs-dist'];

export function PDFViewer({ base64 }) {
  const [url, setURL] = useState('');

  useEffect(() => {
    document.querySelector('#pdf').addEventListener('contextmenu', (e) => e.preventDefault());

    if (base64) {
      const blob = base64toBlob(base64);
      const result = URL.createObjectURL(blob);

      setURL(result);
    }
  }, [base64]);

  const loading = () => {
    return (
      <ContainerLoading>
        <Loading loading={true} />
      </ContainerLoading>
    );
  };

  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  function ToolbarComponent() {
    return (
      <Toolbar>
        {(props) => {
          const {
            CurrentPageInput,
            // Download,
            Print,
            EnterFullScreen,
            GoToNextPage,
            GoToPreviousPage,
            NumberOfPages,
            ZoomIn,
            ZoomOut,
            Zoom
          } = props;
          return (
            <>
              <div id="zoom" className="item-toolbar">
                <ZoomOut enableShortcuts={false} />
                <Zoom />
                <ZoomIn />
              </div>
              {isDesktop() && (
                <div className="item-toolbar">
                  <GoToPreviousPage />
                  <CurrentPageInput />
                  / <NumberOfPages />
                  <GoToNextPage />
                </div>
              )}
              {isDesktop() && (
                <div id="others" className="item-toolbar">
                  <Print />
                  <div id="item-empty"></div>
                  <EnterFullScreen />
                </div>
              )}
            </>
          );
        }}
      </Toolbar>
    );
  }

  return (
    <ContainerPDF>
      <ToolbarContent>
        <ToolbarComponent />
      </ToolbarContent>
      <ContainerViewer id="pdf">
        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}>
          <Viewer id="pdf" fileUrl={url} renderLoader={loading} plugins={[toolbarPluginInstance]} />
        </Worker>
      </ContainerViewer>
    </ContainerPDF>
  );
}
