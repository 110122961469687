import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { PaginationDesktop } from '../../../../components/pagintations/PaginationDesktop';
import { goToDatailsFinancialContract } from '../../../../router/coordinator';
import { SkeletonDesktop, TargetStatus } from '../../../../components';
import { convertReadjustmentMonth } from '../../../../utils/convert';
import { hasPermission } from '../../../../utils';

export function DesktopTable({
  contracts,
  navigate,
  count,
  loading,
  page,
  offset,
  handlePage,
  handleChangeRowsPerPage
}) {
  const goTo = (uuid) => {
    if (hasPermission('FIND_FINANCIAL_CONTRACT')) {
      goToDatailsFinancialContract(navigate, uuid);
    }
  };

  const contractsList = contracts?.map((c) => {
    return (
      <TableRow
        key={c.contract?.id}
        onClick={() => goTo(c.contract?.uuid)}
        className={!hasPermission('FIND_FINANCIAL_CONTRACT') && 'not-effect'}
      >
        <TableCell>{c.contract?.id || '-'}</TableCell>
        <TableCell>
          <TargetStatus status={c.contract?.status} />
        </TableCell>
        <TableCell>{c.debit?.toMask('debit-credit')}</TableCell>
        <TableCell>{c.credit?.toMask('debit-credit')}</TableCell>
        <TableCell>{c.coin?.title?.toTitleCase() || '-'} </TableCell>
        <TableCell>{c.totalInstallments}</TableCell>
        <TableCell>{c.installmentsPaid}</TableCell>
        <TableCell>{convertReadjustmentMonth(c.readjustmentMonth)}</TableCell>
      </TableRow>
    );
  });

  function RenderTable() {
    if (loading) {
      return <SkeletonDesktop numbersOfColumns={8} />;
    }

    return contractsList;
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Id contrato</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Débito</TableCell>
              <TableCell>Crédito</TableCell>
              <TableCell>Moeda</TableCell>
              <TableCell>Parcelas totais</TableCell>
              <TableCell>Parcelas pagas</TableCell>
              <TableCell>Mês reajuste</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <RenderTable />
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationDesktop
        count={count}
        page={page}
        rowsPerPage={offset}
        handlePage={handlePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}
