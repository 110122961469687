import { MenuItem as MenuItemMUI } from '@mui/material';

import { IconContainer, Item } from './styled';

export default function MenuItem({ icon: Icon, children, onClick, color }) {
  return (
    <Item color={color}>
      <MenuItemMUI onClick={() => onClick?.()}>
        {Icon && (
          <IconContainer>
            <Icon />
          </IconContainer>
        )}
        {children}
      </MenuItemMUI>
    </Item>
  );
}
