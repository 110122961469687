import { Grid } from '@mui/material';
import { Text } from './styles';

export function InformativeText({ children }) {
  return (
    <Grid item xs={12}>
      <Text>{children || '* Apenas os campos com asterístico são obrigatórios'}</Text>
    </Grid>
  );
}
