import { Button, Divider, Grid } from '@mui/material';
import { BottomArea, ButtonContainer, Container, Content, Text, Title, Warning } from './styled';
import WarningIcon from '@mui/icons-material/Warning';

/**
 * Props esperadas:
 *
 * [open]: booleano - Diz se o modal abre
 * [onClose]: function - função que fechará o modal
 * [onConfirm]: function - função acionada após o usuário confirmar o modal
 * [title]: string - Texto de título
 * [text]: string - Texto do corpo do modal
 * [buttonConfirm]: string - texto no botão de confirmar
 * [buttonCancel]: string - texto no botão de cancelar
 *
 * @param {*} props
 * @returns
 */
export function Modal(props) {
  const { title, text, onClose, onConfirm, buttonCancel, buttonConfirm, warning } = props;

  return (
    <Container {...props}>
      <Content>
        <Title>{title}</Title>
        <Divider />
        <Text>{text}</Text>

        <BottomArea>
          {warning && (
            <Warning color="error">
              <WarningIcon />
              <span>{warning}</span>
            </Warning>
          )}

          <ButtonContainer container>
            <Grid item xs={12} lg={3}>
              <Button variant="outlined" onClick={onClose}>
                {buttonCancel ? buttonCancel : 'Não'}
              </Button>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Button type="submit" onClick={onConfirm}>
                {buttonConfirm ? buttonConfirm : 'Sim'}
              </Button>
            </Grid>
          </ButtonContainer>
        </BottomArea>
      </Content>
    </Container>
  );
}
