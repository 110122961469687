import { Button as ButtonMUI } from '@mui/material';
import styled from 'styled-components';

export const Button = styled(ButtonMUI)`
  display: flex;
  align-items: center;

  span {
    margin-left: 0.5rem;
  }
`;
