import { Grid, Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
import styled from 'styled-components';
import { breakpoints } from '../../theme/breakpoints';
import { colors } from '../../theme/styles';

export const Container = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled(Box)`
  background-color: ${colors.backgroundWhite};
  border-radius: 10px;
  width: 80%;
  padding: 1.5rem;

  @media (min-width: ${breakpoints.md}) {
    width: 40%;
  }

  .input {
    margin-top: 1.5rem;
  }
`;

export const Title = styled.h2`
  color: ${colors.theme.primary};
  padding-bottom: 1rem;
`;

export const Text = styled.p`
  margin-top: 1rem;
  font-size: 15pt;
`;

export const Warning = styled(Typography)`
  display: flex;
  align-items: center;

  span {
    margin-left: 0.5rem;
  }
`;

export const BottomArea = styled.div`
  padding-top: 2.5rem;
`;

export const ButtonContainer = styled(Grid)`
  padding-top: 0.5rem;

  @media (min-width: ${breakpoints.md}) {
    display: flex;
    justify-content: space-between;
  }

  button {
    width: 100%;
    margin-top: 0.8rem;
  }
`;
