import { api } from '../utils/axiosConfig';

/**
 * Lista os usuários
 * @param {*} initialPage - Página de inicio
 * @param {*} offset - Itens por página
 * @param {*} order - ordem [asc, desc]
 * @param {*} filter - filtros
 * @returns
 */
export const listUsers = async (initialPage, offset, order = 'ASC', filter = {}) => {
  try {
    const page = initialPage + 1;
    const { data } = await api.get(`/admin/users/all`, {
      params: {
        initial_page: page,
        offset,
        order,
        ...filter
      }
    });

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Muda o status do usuário (ativo -> inativa | inativo -> ativa)
 * @param {*} uuidUser - identificador do usuário
 * @returns
 */
export const activeOrInactiveUser = async (uuidUser) => {
  try {
    const { data } = await api.patch(`/admin/active-inactive/user/${uuidUser}`);
    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Busca as informações do usuário
 * @param {*} uuidUser - identificador do usuário
 * @returns
 */
export const findUser = async (uuidUser) => {
  try {
    const { data } = await api.get(`/admin/user/${uuidUser}`);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Cria um usuário
 * @param {*} name - nome
 * @param {*} lastName - sobrenome
 * @param {*} document - cpf / cnpj
 * @param {*} email
 * @param {*} cellphone
 * @param {*} tellphone
 * @param {*} uuidOffice - indentificador do cargo
 * @param {*} uuidSector - identificador do setor
 * @returns
 */
export const createUser = async (
  name,
  lastName,
  document,
  email,
  cellphone,
  tellphone,
  uuidOffice,
  uuidSector
) => {
  try {
    const payload = {
      name,
      last_name: lastName,
      document,
      email,
      cellphone,
      tellphone,
      office_uuid: uuidOffice,
      sector_uuid: uuidSector
    };
    const { data } = await api.post(`/admin/user/register`, payload);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Lista os contratos adicionados pelo usuário
 * @param {*} uuidUser - Identificador do usuário
 * @param {*} initialPage - Página de inicio
 * @param {*} offset - Itens por página
 * @param {*} order - ordem [asc, desc]
 * @returns
 */
export const listContractsAddedByUser = async (
  uuidUser,
  initialPage,
  offset,
  order = 'ASC',
  orderBy
) => {
  try {
    const page = initialPage + 1;
    const { data } = await api.get(`/admin/${uuidUser}/list-contracts`, {
      params: {
        initial_page: page,
        offset,
        order,
        order_by: orderBy
      }
    });

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Aprova ou reprova usuário
 * @param {*} uuidUser - identificador do usuário
 * @param {*} isApproved - se o usuário foi aprovado ou não
 * @returns
 */
export const aproveOrReproveUser = async (uuidUser, isApproved) => {
  try {
    const { data } = await api.patch(`/admin/aprove-reprove/user/${uuidUser}/${isApproved}`);
    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Cria um usuário
 * @param {*} uuidUser - identificador do usuário
 * @param {*} name - nome
 * @param {*} lastName - sobrenome
 * @param {*} document - cpf / cnpj
 * @param {*} email
 * @param {*} uuidOffice - indentificador do cargo
 * @param {*} uuidSector - identificador do setor
 * @returns
 */
export const editUserAdmin = async (
  uuidUser,
  name,
  lastName,
  document,
  email,
  uuidSector,
  uuidOffice
) => {
  try {
    const payload = {
      name,
      last_name: lastName,
      document,
      email,
      uuid_office: uuidOffice,
      uuid_sector: uuidSector
    };

    const { data } = await api.put(`/admin/user/${uuidUser}/edit`, payload);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Reenvia o e-mail que levo o usuário para a tela de DEFINIR senha
 * @param {*} uuidUser - identificador do usuário
 * @returns
 */
export const resendEmailPassword = async (uuidUser) => {
  try {
    await api.post(`/admin/${uuidUser}/resend-email`);
  } catch (error) {
    throw error?.response?.data;
  }
};
