import { Container } from './styled';
import { ReactComponent as EmptyTableSVG } from '../../assets/emptyTable.svg';

export function EmptyTable() {
  return (
    <Container>
      <EmptyTableSVG />
      <p>Tabela vazia</p>
    </Container>
  );
}
