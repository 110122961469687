import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BasePage } from '../../../../../../../components/BaseMain';
import {
  goToAdmin,
  goToBack,
  goToConcludedRegisterUser,
  goToUsersList
} from '../../../../../../../router/coordinator';
import { AlertMessage, Loading, Modal, TitlePages } from '../../../../../../../components';
import { CategoryContainer, Content } from './styled';
import { Button, Grid, Switch } from '@mui/material';
import { clearAlertMessage, noRepeatArray } from '../../../../../../../utils';
import { translateCategoryPermission } from '../../../../../../../utils/translate';
import { GENERIC_ERROR } from '../../../../../../../utils/constantes';
import {
  changeUserPermissions,
  listAllPermissions
} from '../../../../../../../services/permissionsService';
import { getCompanyByUUID } from '../../../../../../../services/companyService';
import { findUser, getItemLocalStorage, setItemLocalStorage } from '../../../../../../../services';
import { Offices } from '../../../../../../../utils/enums';
import { CustomFormControlLabel } from '../../../../../../../components/CustomFormControlLabel';
import { listAllPermissionsOfTemplate } from '../../../../../../../services/templateService';

export function ChoicePermissions() {
  const [permissionsChoice, setPermissionsChoice] = useState({});
  const [allPermissionsSelected, setAllPermissionsSelected] = useState(false);
  const [categories, setCategories] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [user, setUser] = useState(null);
  const [company, setCompany] = useState({});
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  const navigate = useNavigate();
  const { uuid } = useParams();
  if (!uuid) {
    goToBack(navigate);
  }

  const breadcrumbs = [
    { title: 'Administrativo', functionCoordinator: () => goToAdmin(navigate) },
    { title: 'Usuários', functionCoordinator: () => goToUsersList(navigate) },
    { title: 'Criar' },
    { title: 'Permissões' }
  ];

  // --- Lógica do modal
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const initListOfPermissions = useCallback(
    (permissionsUser) => {
      let permissionsList = {};
      permissions?.forEach((permission) => {
        const haveAccess = permissionsUser?.some(
          (permissionKey) => permissionKey === permission.key
        );
        permissionsList = { ...permissionsList, [permission.key]: haveAccess };
      });

      setPermissionsChoice(permissionsList);
    },
    [permissions]
  );

  const init = useCallback(async () => {
    try {
      setLoading(true);
      setPermissionsChoice({});
      setAllPermissionsSelected(false);

      const uuidThisCompany = getItemLocalStorage('companies_access')[0];

      const [userResult, companyResult] = await Promise.all([
        findUser(uuid),
        getCompanyByUUID(uuidThisCompany)
      ]);
      setCompany(companyResult);
      setUser(userResult);

      const isAdmin = userResult?.office?.title === Offices.ADMIN;
      const permissions = await listAllPermissions(isAdmin);

      setPermissions(permissions);
      setCategories(noRepeatArray(permissions?.map((permission) => permission?.category)));
    } catch (error) {
      setAlertMessage((prev) => ({
        ...prev,
        title: error?.trace,
        message: error?.message || GENERIC_ERROR,
        severity: 'error'
      }));
    }
  }, []);

  const listChoicePermissions = useCallback(async () => {
    try {
      setLoading(true);
      setAllPermissionsSelected(false);

      if (user) {
        const permissions = await listAllPermissionsOfTemplate(user?.office?.uuid);
        initListOfPermissions(permissions);
      }
    } catch (error) {
      setAlertMessage((prev) => ({
        ...prev,
        title: error?.trace,
        message: error?.message || GENERIC_ERROR,
        severity: 'error'
      }));
    } finally {
      setLoading(false);
    }
  }, [permissions]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    listChoicePermissions();
  }, [listChoicePermissions]);

  /**
   * Marcar ou desmarca todos o toggles
   * @param {*} value - verdadeiro ou falso
   */
  const changeAllToggles = (value) => {
    let newValues = {};
    for (const key in permissionsChoice) {
      newValues = { ...newValues, [key]: value };
    }

    setPermissionsChoice(newValues);
    setAllPermissionsSelected(value);
  };

  const handleToggle = (e) => {
    const { name, checked } = e.target;
    setPermissionsChoice({ ...permissionsChoice, [name]: checked });
    setAllPermissionsSelected(null);
  };

  const ListPermissions = ({ columnType }) => {
    const halfListLength = Math.round(categories.length / 2);
    const categoriesList =
      columnType === 'first'
        ? categories.slice(0, halfListLength)
        : categories.slice(halfListLength);

    return categoriesList?.map((category, index) => {
      const permissionsByCategory = permissions?.filter(
        (permission) => permission?.category === category
      );

      return (
        permissionsByCategory?.length && (
          <CategoryContainer key={index}>
            <p>{translateCategoryPermission(category)}</p>
            <div>
              {permissionsByCategory?.map((permission) => (
                <CustomFormControlLabel
                  key={permission?.uuid}
                  control={
                    <Switch
                      checked={permissionsChoice[permission.key]}
                      name={permission?.key}
                      onChange={handleToggle}
                    />
                  }
                  label={permission?.description?.toTitleCase()}
                />
              ))}
            </div>
          </CategoryContainer>
        )
      );
    });
  };

  const nextStep = async () => {
    try {
      setLoading(true);
      handleClose();

      await changeUserPermissions(uuid, company.uuid, permissionsChoice);

      const companiesAccess = getItemLocalStorage('companies_access');
      companiesAccess.shift();
      await setItemLocalStorage('companies_access', companiesAccess);

      if (!companiesAccess || !companiesAccess?.length) {
        goToConcludedRegisterUser(navigate);
        return;
      }

      await init();
      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  };

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages
        title={`Escolha as permissões que o usuário terá na ${company.name}`}
        toHideDivider={true}
      />

      <Loading loading={loading} />

      <Grid container>
        <AlertMessage
          title={alertMessage.title}
          message={alertMessage.message}
          severity={alertMessage.severity}
          onClose={() => clearAlertMessage(setAlertMessage)}
        />
      </Grid>

      <Content container>
        <div id="button-group">
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => changeAllToggles(true)}
            disabled={allPermissionsSelected}
          >
            Marcar todas as permissões
          </Button>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => changeAllToggles(false)}
            disabled={allPermissionsSelected === false}
          >
            Desmarcar todas as permissões
          </Button>
        </div>

        <Grid item className="column" xs={10} lg={6}>
          <ListPermissions columnType="first" />
        </Grid>
        <Grid item className="column" xs={10} lg={6}>
          <ListPermissions columnType="second" />
        </Grid>

        <Grid container className={'buttonGroup oneButton'}>
          <Button onClick={handleOpen}>Salvar e avançar</Button>
        </Grid>
      </Content>

      <Modal
        open={openModal}
        onClose={handleClose}
        onConfirm={nextStep}
        title={'Adicionar permissões de acesso ao usuário'}
        text={`Tem certeza que deseja dar permissão de acesso dessas empresas ao usuário? `}
        warning={'Ao confirmar, as permissões de acesso já serão atribuidas  ao usuário'}
        buttonConfirm={'Sim'}
        buttonCancel={'Não'}
      />
    </BasePage>
  );
}
