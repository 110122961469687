import { Box, Button } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(Box)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;

export const StyledButton = styled(Button)`
  display: flex;
  gap: 0.2rem;
  align-items: center;

  && {
    display: flex;
    align-items: center;
  }
`;
