import { Grid, InputAdornment } from '@mui/material';
import { isDesktop, onChangePercent } from '../../../../../../../../../utils';
import { TextFieldNextFocus, BorderWithText } from '../../../../../../../../../components';

export function TrafficTicketArea({ form, onChange }) {
  return (
    <BorderWithText title={'Multa'}>
      <Grid container spacing={isDesktop() && 2}>
        <Grid item xs={12} lg={4}>
          <TextFieldNextFocus
            label={'Porcentagem da multa por atraso'}
            placeholder={'0,01%'}
            name={'late_fee'}
            value={form.late_fee}
            onChange={(e) => onChangePercent(e, onChange)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <b>%</b>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextFieldNextFocus
            label={'Porcentagem da multa por vencimento'}
            placeholder={'0,11%'}
            name={'fine_for_salary'}
            value={form.fine_for_salary}
            onChange={(e) => onChangePercent(e, onChange)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <b>%</b>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextFieldNextFocus
            type="number"
            label={'Número de dias de multa rescisória'}
            name={'days_rescission_fine'}
            value={form.days_rescission_fine}
            onChange={onChange}
          />
        </Grid>
      </Grid>
    </BorderWithText>
  );
}
