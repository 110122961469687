import styled from 'styled-components';
import { colors } from '../../theme/styles';

export const Container = styled.div`
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;

  a {
    color: ${colors.black};
    display: flex;
    align-items: center;
    text-decoration: none;
    justify-self: left;

    svg {
      width: 1.2rem;
      height: 1.2rem;
    }

    span {
      padding-left: 0.2rem;
      font-weight: 600;
    }
  }
`;
