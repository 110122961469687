import {
  HiddenForPermission,
  MobileTableConteiner,
  PaginationMobile,
  SkeletonMobile,
  TableMobileButton,
  TableMobileCell,
  TableMobileContent,
  TableMobileItem,
  TableMobileTitle,
  TargetStatus
} from '../../../../../../components';
import { goToProviderDetails } from '../../../../../../router/coordinator';
import { Status } from '../../../../../../utils/enums';

export function MobileTable({ providers, navigate, loading, count, page, offset, handlePage }) {
  const contractsList = providers?.map((company) => {
    return (
      <TableMobileCell key={company.uuid}>
        <TableMobileItem>
          <TableMobileTitle>Nome </TableMobileTitle>
          <TableMobileContent>{company.name?.toTitleCase()}</TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Documento </TableMobileTitle>
          <TableMobileContent>{company.document?.toMask('document')}</TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Status </TableMobileTitle>
          <TableMobileContent>
            <TargetStatus status={company.active ? Status.ACTIVE : Status.INACTIVE} />
          </TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Setor de atuação </TableMobileTitle>
          <TableMobileContent>{company.industry?.title?.toTitleCase()}</TableMobileContent>
        </TableMobileItem>

        <HiddenForPermission keys="LIST_PROVIDERS">
          <TableMobileButton onClick={() => goToProviderDetails(navigate, company.uuid)}>
            Ver detalhes
          </TableMobileButton>
        </HiddenForPermission>
      </TableMobileCell>
    );
  });

  return (
    <MobileTableConteiner>
      {!loading ? contractsList : <SkeletonMobile numbersOfColumns={4} />}
      <PaginationMobile count={count} page={page} rowsPerPage={offset} handlePage={handlePage} />
    </MobileTableConteiner>
  );
}
