import { FormControlLabel, Switch } from '@mui/material';

/**
 * Remover a transformação em Capitalize que ele fazer automaticamente
 * @param {*} props
 * @returns
 */
export function CustomFormControlLabel(props) {
  return (
    <FormControlLabel
      control={<Switch {...props} />}
      {...props}
      label={<span style={{ textTransform: 'none' }}>{props.label}</span>}
    />
  );
}
