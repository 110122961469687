import { Box } from '@mui/material';
import { User } from '../../User';
import { ListItemsOfMenu } from '../ListItemsOfMenu';
import { ContainerUser, List } from './styled';

export function FixedMenu({ navigate }) {
  return (
    <List>
      <ContainerUser>
        <User navigate={navigate} />
      </ContainerUser>
      <Box sx={{ width: 250 }} role="presentation">
        <ListItemsOfMenu navigate={navigate} />
      </Box>
    </List>
  );
}
