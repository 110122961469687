import { Grid, Icon } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { useNavigate } from 'react-router-dom';
import { BasePage } from '../../../components/BaseCredentials';
import { Button, ButtonGroup, Content, Title } from './styled';
import { metrics } from '../../../theme/styles';
import { ReactComponent as SignUpImage } from '../../../assets/createPasswordImage.svg';
import { goToAccountCreated, goToSignup } from '../../../router/coordinator';
import { AlertMessage, Loading, RulesPassword, TextFieldPassword } from '../../../components';
import { useForm } from '../../../hooks/useForm';
import { useEffect, useState } from 'react';
import { validatePassword } from '../../../utils/validates';
import { getItemLocalStorage, signup } from '../../../services';
import { GENERIC_ERROR } from '../../../utils/constantes';
import { onlyNumbers } from '../../../utils';

export function CreatePassword() {
  const navigate = useNavigate();
  const [form, onChange, _, setForm] = useForm({ password: '', confirmPassword: '' });
  const [loading, setLoading] = useState(false);
  const [errorsValidation, setErrorsValidation] = useState({
    confirmPassword: false,
    password: false
  });
  const [errors, setErrors] = useState({
    title: '',
    message: '',
    severity: '',
    onClose: () => {}
  });

  const clearError = () => {
    setErrors((prev) => ({
      ...prev,
      title: '',
      severity: '',
      message: ''
    }));
  };

  useEffect(async () => {
    await getSignupInformation();
  }, []);

  // Busca as informações da primeira etapa do cadastro
  const getSignupInformation = async () => {
    const signupInformation = getItemLocalStorage('signup');
    if (signupInformation) {
      setForm((previewForm) => ({ ...previewForm, ...signupInformation }));
    }
  };

  const onConfirmButton = async () => {
    setLoading(true);
    clearError();

    await registerUser();
  };

  // Valida campo de senha
  const validateInputPasword = () => {
    const { password } = form;
    const isValid = validatePassword(password);
    setErrorsValidation((prevError) => ({ ...prevError, password: !isValid }));
  };

  const validateInputConfirmPasword = () => {
    const { confirmPassword, password } = form;
    if (confirmPassword) {
      const isValid = confirmPassword === password;
      setErrorsValidation((prevError) => ({ ...prevError, confirmPassword: !isValid }));
    }
  };

  const disabledButton = () => {
    if (!form.password) {
      return true;
    }
    if (!form.confirmPassword) {
      return true;
    }
    if (errorsValidation.password) {
      return true;
    }
    if (errorsValidation.confirmPassword) {
      return true;
    }

    return false;
  };

  const registerUser = async () => {
    try {
      const name = form.name.toUpperCase().trim();
      const lastName = form.lastName.toUpperCase().trim();
      const document = form.document && onlyNumbers(form.document);
      const email = form.email.trim();
      const password = form.password.trim();
      const confirmPassword = form.confirmPassword.trim();
      const cellphone = form.cellphone && onlyNumbers(form.cellphone);
      const tellphone = form.tellphone && onlyNumbers(form.tellphone);

      const { office, company, sector, profileImage } = form;

      await signup(
        name,
        lastName,
        document,
        email,
        office,
        sector,
        company,
        password,
        confirmPassword,
        cellphone,
        tellphone,
        profileImage
      );

      setLoading(false);
      goToAccountCreated(navigate);
    } catch (err) {
      setErrors({ title: err?.message || GENERIC_ERROR });
      setLoading(false);
    }
  };

  return (
    <BasePage ImageReceived={SignUpImage}>
      <Loading loading={loading} />
      <Content className="center">
        <Icon component={LockIcon} sx={{ fontSize: metrics.sizeIconCredential }} />
        <Title>Crie uma senha</Title>
        <AlertMessage
          title={errors.title}
          message={errors.message}
          severity={errors.severity}
          onClose={errors.onClose}
        />
        <RulesPassword value={form.password} />
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <TextFieldPassword
              value={form.password}
              onChange={onChange}
              name={'password'}
              label={'Senha'}
              onBlur={() => validateInputPasword()}
              error={errorsValidation.password}
              helperText={errorsValidation.password && 'Senha inválida'}
              required={true}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <TextFieldPassword
              label={'Confirmar a senha'}
              value={form.confirmPassword}
              onChange={onChange}
              name={'confirmPassword'}
              onBlur={() => validateInputConfirmPasword()}
              error={errorsValidation.confirmPassword}
              required={true}
              helperText={
                errorsValidation.confirmPassword &&
                'A confirmação de senha não coincide com a senha '
              }
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <ButtonGroup>
              <Button type="submit" disabled={disabledButton()} onClick={onConfirmButton}>
                Cadastrar
              </Button>
              <Button variant="outlined" type="submit" onClick={() => goToSignup(navigate)}>
                Voltar
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Content>
    </BasePage>
  );
}
