import styled from 'styled-components';
import { colors, metrics } from '../../../../../theme/styles';

export const Content = styled.div`
  background-color: ${colors.backgroundWhite};
  padding: 1.5rem;
  border-radius: 5px;

  #term {
    margin-top: 1rem;
    padding: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    min-height: 60vh;
    overflow-y: auto;
  }
`;

export const Title = styled.h1`
  font-size: ${metrics.size.medium};
  margin: 0;
  color: ${colors.theme.primary};
`;
