import styled from 'styled-components';
import { colors } from '../../../../../theme/styles';
import { Grid } from '@mui/material';
import { breakpoints } from '../../../../../theme/breakpoints';

export const Content = styled.div`
  margin: 2rem 0;

  @media (min-width: ${breakpoints.md}) {
    margin: 2rem 2rem;
  }

  #top-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: ${breakpoints.md}) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  #resend-email-button {
    margin-top: 0.6rem;
    display: block;
  }
`;

export const ContainerAvatar = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 0.5rem;

  @media (min-width: ${breakpoints.md}) {
    flex-direction: row;
  }

  #avatar {
    width: 10rem;
    height: 10rem;

    svg {
      width: 9rem;
      height: 9rem;
    }
  }

  div:last-child {
    text-align: center;

    @media (min-width: ${breakpoints.md}) {
      margin-left: 1rem;
      text-align: left;
    }

    strong {
      font-size: 3rem;
      color: ${colors.theme.primary};
    }

    p {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }
`;

export const LineGrid = styled(Grid)`
  margin-top: 2rem;

  div {
    margin-top: 0.8rem;

    p:first-child {
      font-weight: 600;
      color: ${colors.theme.primary};
      font-size: 1.2rem;
    }

    p:last-child {
      font-weight: 400;
      font-size: 1.5rem;
    }
  }
`;

export const ButtonGroup = styled.div`
  margin-top: 5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
`;

export const AproveAndReproveButtons = styled.div`
  margin-top: 5rem;
  display: flex;
  gap: 1rem;
  justify-content: end;
  flex-direction: column;

  @media (min-width: ${breakpoints.md}) {
    flex-direction: row;
  }

  button {
    width: 100%;

    @media (min-width: ${breakpoints.md}) {
      width: 20%;
    }
  }
`;
