import { useEffect, useState } from 'react';
import { Button, Checkbox, FormControlLabel, Grid, MenuItem, TextField } from '@mui/material';
import ReactInputMask from 'react-input-mask';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { CoinArea, ValueArea, TrafficTicketArea } from './areas';
import { isDesktop, onChangeDate, onlyNumbers } from '../../../../../../../utils';
import { AlertMessage, Loading, TextFieldNextFocus } from '../../../../../../../components';
import { monthsOfTheYear } from '../../../../../../../utils/monthsOfTheYear';
import { useForm } from '../../../../../../../hooks/useForm';
import { InformativeText } from '../../../../../../../components/InformativeText';
import { getItemLocalStorage, setItemLocalStorage } from '../../../../../../../services';
import { LocalStorageKeys } from '../../../../../../../utils/enums';
import { validateMandatoryInput } from '../../../../../../../utils/validates';
import { useUpdateState } from '../../../../../../../hooks/useUpdateState';

export function FinancialInformations({ onNextStep, onBackStep }) {
  const [form, onChange, _, setForm] = useForm({
    credit: '',
    debit: '',
    readjustment_month: '',
    init_date: null,
    expiration_date: null,
    uuid_coin: '',
    uuid_coin_history: '',
    initial_value: '',
    total_installments: '',
    installments_paid: '',
    payment_periodicity: '',
    readjustment_periodicity: '',

    late_fee: '',
    fine_for_salary: '',
    days_rescission_fine: '',

    exchange_date: null,
    exchang_type: '',
    exchang_value: ''
  });
  const [
    { indeterminatedExpiration, currencySymbol, loading, localValues, isCustomCoin },
    setStates
  ] = useUpdateState({
    indeterminatedExpiration: false,
    currencySymbol: null,
    loading: false,
    isCustomCoin: false,
    localValues: { initialDate: '', expirationDate: '', paymentPeriodicity: '' }
  });
  const [errorsValidation, setErrorsValidation] = useState({});
  const [errors, setErrors] = useUpdateState({
    title: '',
    message: '',
    severity: ''
  });

  useEffect(() => {
    getExistForm();
  }, []);

  useEffect(() => {
    if (indeterminatedExpiration) {
      handleIndeterminatedDateChange();
      return;
    }

    handleExpiryDateChange();
  }, [form, indeterminatedExpiration]);

  const calculateInstallments = (months, periodicity) => {
    if (periodicity <= 0) {
      return 0;
    }
    return parseInt(months / periodicity);
  };

  const handleIndeterminatedDateChange = () => {
    const {
      init_date: initDate,
      expiration_date: expirationDate,
      payment_periodicity: paymentPeriodicity
    } = form;
    const {
      initialDate: initialDateLocal,
      expirationDate: expirationDateLocal,
      paymentPeriodicity: paymentPeriodicityLocal
    } = localValues;

    const haveValue = initDate && paymentPeriodicity;
    const isDateChanged =
      haveValue &&
      (initDate !== initialDateLocal ||
        expirationDate !== expirationDateLocal ||
        paymentPeriodicity !== paymentPeriodicityLocal);

    if (expirationDate) {
      setForm((prev) => ({
        ...prev,
        expiration_date: null
      }));
    }

    if (isDateChanged) {
      handleIndeterminatedInstallments();
    }
  };

  const handleIndeterminatedInstallments = () => {
    setStates({
      localValues: {
        initialDate: form.init_date,
        expirationDate: null,
        paymentPeriodicity: form.payment_periodicity
      }
    });

    const initDate = dayjs(form.init_date);

    const isValidDate = initDate.isValid();
    if (!isValidDate) {
      return;
    }

    // Calcula a diferença em meses entre as datas
    const paidMonths = dayjs().diff(initDate, 'month');

    if (isNaN(paidMonths)) {
      return;
    }

    const { payment_periodicity: periodicity } = form;

    // Calcula o número de parcelas totais e pagas com base na periodicidade
    let paidInstallments = calculateInstallments(paidMonths, periodicity);

    if (paidInstallments < 0) {
      paidInstallments = 0;
    }

    setForm((prevForm) => ({
      ...prevForm,
      installments_paid: paidInstallments,
      total_installments: 0,
      expiration_date: null
    }));
  };

  const handleExpiryDateChange = () => {
    const {
      init_date: initDate,
      expiration_date: expirationDate,
      payment_periodicity: paymentPeriodicity
    } = form;
    const {
      initialDate: initialDateLocal,
      expirationDate: expirationDateLocal,
      paymentPeriodicity: paymentPeriodicityLocal
    } = localValues;

    const haveValue = initDate && expirationDate && paymentPeriodicity;

    const isDateChanged =
      haveValue &&
      (initDate !== initialDateLocal ||
        expirationDate !== expirationDateLocal ||
        paymentPeriodicity !== paymentPeriodicityLocal);

    if (isDateChanged) {
      handleDeterminatedInstallments();
    }
  };

  const handleDeterminatedInstallments = () => {
    setStates({
      localValues: {
        initialDate: form.init_date,
        expirationDate: form.expiration_date,
        paymentPeriodicity: form.payment_periodicity
      }
    });

    const initDate = dayjs(form.init_date);
    const expirationDate = dayjs(form.expiration_date);

    const isValidDates = initDate.isValid() && expirationDate.isValid();
    if (!isValidDates) {
      return;
    }

    // Calcula a diferença em meses entre as datas
    const totalMonths = expirationDate.diff(initDate, 'month');
    const paidMonths = dayjs().diff(initDate, 'month');

    if (isNaN(totalMonths) || isNaN(paidMonths)) {
      return;
    }

    const { payment_periodicity: periodicity } = form;

    // Calcula o número de parcelas totais e pagas com base na periodicidade
    const totalInstallments = calculateInstallments(totalMonths, periodicity);
    let paidInstallments = calculateInstallments(paidMonths, periodicity);

    if (paidInstallments > totalInstallments) {
      paidInstallments = totalInstallments;
    }

    setForm((prevForm) => ({
      ...prevForm,
      total_installments: totalInstallments,
      installments_paid: paidInstallments
    }));
  };

  const clearError = () =>
    setErrors({
      title: '',
      severity: '',
      message: ''
    });

  const getExistForm = () => {
    const saveForm = getItemLocalStorage(LocalStorageKeys.CREATE_CONTRACT);
    if (saveForm) {
      setForm({
        ...form,
        ...saveForm,
        init_date: saveForm.init_date ? dayjs(saveForm.init_date, 'YYYY-MM-DDTHH:mm') : null,
        expiration_date: saveForm.expiration_date
          ? dayjs(saveForm.expiration_date, 'YYYY-MM-DDTHH:mm')
          : null,
        exchange_date: saveForm.exchange_date
          ? dayjs(saveForm.exchange_date, 'YYYY-MM-DDTHH:mm')
          : null
      });
      setStates({
        indeterminatedExpiration: saveForm.indeterminatedExpiration,
        currencySymbol: saveForm.currencySymbol,
        isCustomCoin: saveForm.isCustomCoin
      });
    }
  };

  const saveInLocalStorage = () => {
    setItemLocalStorage(LocalStorageKeys.CREATE_CONTRACT, {
      ...form,
      currencySymbol,
      indeterminatedExpiration,
      isCustomCoin
    });
  };

  /**
   * Gera erro se o valor da conta de débito ou crédito
   * estiver vazio ou for menos que 9 caracteres
   * @param {*} field - campo a ser verificado [credit, debit]
   * @returns
   */
  const validateDebitCreditInput = (field) => {
    const value = onlyNumbers(form[field]);

    if (!value || value.length !== 6) {
      setErrorsValidation((prevError) => ({ ...prevError, [field]: true }));
      return;
    }

    setErrorsValidation((prevError) => ({ ...prevError, [field]: false }));
  };

  const validForm = () => {
    const { init_date: initialDate, expiration_date: expirationDate } = form;
    if (dayjs(initialDate).isAfter(expirationDate)) {
      setErrors({
        title: 'Datas do contrato',
        message: 'A data de expiração é anterior a data de inicio.'
      });
      return;
    }

    const { total_installments: totalInstallments, installments_paid: paidInstallments } = form;
    if (paidInstallments > totalInstallments) {
      setErrors({
        title: 'Parcelas do contrato',
        message: 'O número de parcelas pagas é maior que o número de parcelas totais.'
      });
    }
  };

  const nextStep = () => {
    validForm();
    clearError();

    saveInLocalStorage();
    onNextStep();
  };

  const backStep = () => {
    saveInLocalStorage();
    onBackStep();
  };

  const disabledButton = () => {
    if (!form.credit || errorsValidation.credit) {
      return true;
    }
    if (!form.debit || errorsValidation.debit) {
      return true;
    }
    if (!form.readjustment_month) {
      return true;
    }
    if (!form.init_date) {
      return true;
    }
    if (!indeterminatedExpiration && !form.expiration_date) {
      return true;
    }
    if (!indeterminatedExpiration && !form.total_installments) {
      return true;
    }
    if (!form.installments_paid && form.installments_paid !== 0) {
      return true;
    }
    if (!form.uuid_coin) {
      return true;
    }
    if (!form.initial_value) {
      return true;
    }
    if (!form.payment_periodicity) {
      return true;
    }
    if (!form.readjustment_periodicity) {
      return true;
    }

    return false;
  };

  return (
    <Grid container xs={12} rowGap={2} columnSpacing={isDesktop() && 2}>
      <Loading loading={loading} />
      <Grid item xs={12}>
        <AlertMessage
          title={errors.title}
          message={errors.message}
          severity={errors.severity}
          onClose={errors.onClose}
        />
      </Grid>

      <Grid item xs={12} lg={3}>
        <ReactInputMask
          mask="999999"
          maskChar="_"
          value={form.credit}
          onChange={onChange}
          onBlur={() => validateDebitCreditInput('credit')}
        >
          {() => (
            <TextFieldNextFocus
              label="Conta reduzida de débito"
              required
              name="credit"
              value={form.credit}
              error={errorsValidation.credit}
              helperText={errorsValidation.credit && 'O número da conta de crédito está inválido.'}
            />
          )}
        </ReactInputMask>
      </Grid>
      <Grid item xs={12} lg={3}>
        <ReactInputMask
          mask="999999"
          maskChar="_"
          value={form.debit}
          onChange={onChange}
          onBlur={() => validateDebitCreditInput('debit')}
        >
          {() => (
            <TextFieldNextFocus
              label="Conta reduzida de crédito"
              required
              name="debit"
              value={form.debit}
              error={errorsValidation.debit}
              helperText={errorsValidation.debit && 'O número da conta de débito está inválido.'}
            />
          )}
        </ReactInputMask>
      </Grid>
      <Grid item xs={12} lg={3}>
        <TextField
          select
          required
          label="Periodicidade de reajuste"
          name="readjustment_periodicity"
          value={form.readjustment_periodicity}
          onChange={onChange}
          onBlur={() =>
            validateMandatoryInput('readjustment_periodicity', form, setErrorsValidation)
          }
          error={errorsValidation.readjustment_periodicity}
          helperText={errorsValidation.readjustment_periodicity}
        >
          <MenuItem value={1}>Mensal</MenuItem>
          <MenuItem value={3}>Trimestral</MenuItem>
          <MenuItem value={6}>Semestral</MenuItem>
          <MenuItem value={12}>Anual</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12} lg={3}>
        <TextField
          select
          required
          label="Mês de reajuste"
          name="readjustment_month"
          value={form.readjustment_month}
          onChange={onChange}
          onBlur={() => validateMandatoryInput('readjustment_month', form, setErrorsValidation)}
          error={errorsValidation.readjustment_month}
          helperText={errorsValidation.readjustment_month}
        >
          {monthsOfTheYear.map((v) => (
            <MenuItem key={v.value} value={v.value}>
              {v.text}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} lg={4}>
        <TextField
          select
          required
          label="Periodicidade de pagamento"
          name="payment_periodicity"
          value={form.payment_periodicity}
          onChange={onChange}
          onBlur={() => validateMandatoryInput('payment_periodicity', form, setErrorsValidation)}
          error={errorsValidation.payment_periodicity}
          helperText={
            errorsValidation.payment_periodicity ||
            'Este campo é usado pra fazer o cálculo das parcelas'
          }
        >
          <MenuItem value={1}>Mensal</MenuItem>
          <MenuItem value={3}>Trimestral</MenuItem>
          <MenuItem value={6}>Semestral</MenuItem>
          <MenuItem value={12}>Anual</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12} lg={4}>
        <DatePicker
          label="Data de início *"
          required
          name="init_date"
          value={form.init_date}
          onChange={(e) => onChangeDate(e, 'init_date', onChange)}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <DatePicker
          label="Data de expiração *"
          name="expiration_date"
          required
          value={form.expiration_date}
          onChange={(e) => onChangeDate(e, 'expiration_date', onChange)}
          disabled={indeterminatedExpiration}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              name="indeterminated_expiration"
              checked={indeterminatedExpiration}
              onChange={({ target }) => setStates({ indeterminatedExpiration: target?.checked })}
            />
          }
          label="Este contrato tem data de expiração indeterminada."
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <TextFieldNextFocus
          required={!indeterminatedExpiration}
          type="number"
          label="Quantidade de parcelas totais"
          name="total_installments"
          value={form.total_installments}
          onChange={onChange}
          onBlur={() => validateMandatoryInput('total_installments', form, setErrorsValidation)}
          error={errorsValidation.total_installments && !indeterminatedExpiration}
          helperText={
            (errorsValidation.total_installments && !indeterminatedExpiration) ||
            'Valide o valor gerado nesse campo antes de confirmar.'
          }
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextFieldNextFocus
          required
          type="number"
          label="Quantidade de parcelas pagas"
          name="installments_paid"
          value={form.installments_paid}
          onChange={onChange}
          onBlur={() => validateMandatoryInput('installments_paid', form, setErrorsValidation)}
          error={errorsValidation.installments_paid}
          helperText={
            errorsValidation.installments_paid ||
            'Valide o valor gerado nesse campo antes de confirmar.'
          }
        />
      </Grid>

      <Grid item xs={12}>
        <CoinArea
          form={form}
          setForm={setForm}
          onChange={onChange}
          coin={currencySymbol}
          updateStateGlobal={setStates}
          setError={setErrors}
          isCoinCustom={isCustomCoin}
          isRequired={true}
        />
      </Grid>

      <Grid item xs={12}>
        <ValueArea form={form} onChange={onChange} coin={currencySymbol} />
      </Grid>

      <Grid item xs={12}>
        <TrafficTicketArea form={form} onChange={onChange} />
      </Grid>

      <InformativeText />

      <Grid item className="buttonGroup twoButtons nearTheTop">
        <Button onClick={backStep} variant="outlined">
          Voltar
        </Button>
        <Button onClick={nextStep} disabled={disabledButton()}>
          Próximo
        </Button>
      </Grid>
    </Grid>
  );
}
