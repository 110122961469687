import { Breadcrumbs } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../theme/styles';

export const Container = styled(Breadcrumbs)`
  && {
    margin: 0 0 0.5rem 1rem;
    color: ${colors.backgroundWhite};
  }
`;
