import { Container, Content, Image } from './styled';
import { isDesktop } from '../../utils';

export function BasePage({ children, ImageReceived }) {
  // Imagem só aparece para desktops
  const showImage = () => {
    return (
      isDesktop() && (
        <Image item xs={7} md={0}>
          <ImageReceived />
        </Image>
      )
    );
  };

  return (
    <Container container>
      {showImage()}
      <Content item xs={12} lg={5}>
        {children}
      </Content>
    </Container>
  );
}
