import { useState } from 'react';
import { Grid, InputAdornment } from '@mui/material';
import { BorderWithText, TextFieldNextFocus } from '../../../../../../../../../components';
import { handleCoin, isDesktop, onChangeMoney } from '../../../../../../../../../utils';
import { validateMandatoryInput } from '../../../../../../../../../utils/validates';

export function ValueArea({ form, onChange, coin }) {
  const [errorsValidation, setErrorsValidation] = useState({});

  return (
    <BorderWithText title={'Valores'}>
      <Grid container spacing={isDesktop() && 2}>
        <Grid item xs={12}>
          <TextFieldNextFocus
            label={'Valor do contrato'}
            disabled={!form.uuid_coin}
            value={form.initial_value}
            name="initial_value"
            onChange={(e) => onChangeMoney(e, onChange)}
            required
            onBlur={() => validateMandatoryInput('initial_value', form, setErrorsValidation)}
            error={errorsValidation.initial_value}
            helperText={errorsValidation.initial_value}
            InputProps={{
              startAdornment: <InputAdornment position="start">{handleCoin(coin)}</InputAdornment>
            }}
          />
        </Grid>
      </Grid>
    </BorderWithText>
  );
}
