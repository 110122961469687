import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactInputMask from 'react-input-mask';
import { BasePage } from '../../../../../components/BaseMain';
import { goToAdmin, goToBack, goToListCompanies } from '../../../../../router/coordinator';
import {
  AlertMessage,
  HiddenForPermission,
  Loading,
  Modal,
  TextFieldNextFocus,
  TitlePages
} from '../../../../../components';
import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { clearAlertMessage, isDesktop } from '../../../../../utils';
import { editCompany, getCompanyByUUID, listIndustries } from '../../../../../services';
import { GENERIC_ERROR } from '../../../../../utils/constantes';
import { useForm } from '../../../../../hooks/useForm';
import { validateInputDocument, validateMandatoryInput } from '../../../../../utils/validates';
import { InformativeText } from '../../../../../components/InformativeText';
import { Form } from './styled';

export function EditCompanyInformationsPage() {
  const navigate = useNavigate();

  const { uuid } = useParams();
  if (!uuid) {
    goToBack(navigate);
  }

  const breadcrumbs = [
    { title: 'Administrativo', functionCoordinator: () => goToAdmin(navigate) },
    { title: 'Empresas', functionCoordinator: () => goToListCompanies(navigate) },
    { title: 'Editar' }
  ];

  const initialValueForm = {
    name: '',
    acronym: '',
    document: '',
    domain: '',
    uuidIndustry: ''
  };
  const [company, onChange, _, setCompany] = useForm(initialValueForm);
  const [industries, setIndustries] = useState([]);
  const [errorsValidation, setErrorsValidation] = useState({});
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  const init = useCallback(async () => {
    try {
      setLoading(true);

      const [company, industries] = await Promise.all([getCompanyByUUID(uuid), listIndustries()]);

      setCompany({
        ...company,
        uuidIndustry: company?.industry?.uuid
      });

      setIndustries(industries);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  // --- Lógica do modal
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const onConfirmModal = async () => {
    try {
      setLoading(true);
      handleClose();

      const { name, uuidIndustry, domain, isIntern } = company;
      await editCompany(uuid, name, uuidIndustry, domain, isIntern);

      setAlertMessage((prev) => ({
        ...prev,
        title: 'Empresa editada com sucesso.',
        severity: 'success'
      }));
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
    } finally {
      setLoading(false);
    }
  };
  // ---

  const disabledButton = () => {
    if (!company.name) {
      return true;
    }
    if (!company.domain) {
      return true;
    }
    if (!company.uuidIndustry) {
      return true;
    }

    return false;
  };

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages title="Editar informações da empresa" />

      <Loading loading={loading} />

      <Grid container>
        <AlertMessage
          message={alertMessage.message}
          severity={alertMessage.severity}
          title={alertMessage.title}
          onClose={() => clearAlertMessage(setAlertMessage)}
        />
      </Grid>

      <Form container xs={12} rowGap={2} columnSpacing={isDesktop() && 2}>
        <Grid item xs={12} lg={4}>
          <TextFieldNextFocus
            label="Nome"
            name="name"
            required
            value={company.name?.toTitleCase()}
            onChange={onChange}
            onBlur={() => validateMandatoryInput('name', company, setErrorsValidation)}
            error={errorsValidation.name}
            helperText={errorsValidation.name}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <ReactInputMask
            mask="99.999.999/9999-99"
            value={company.document}
            onChange={onChange}
            required
            maskChar="_"
            onBlur={() => validateInputDocument(company.document, setErrorsValidation)}
          >
            {() => (
              <TextFieldNextFocus
                label="Documento"
                required
                name={'document'}
                value={company.document}
                error={errorsValidation.document}
                helperText={errorsValidation.document}
              />
            )}
          </ReactInputMask>
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            select
            required
            label="Setor de atuação"
            name="uuidIndustry"
            value={company.uuidIndustry}
            onChange={onChange}
            onBlur={() => validateMandatoryInput('uuidIndustry', company, setErrorsValidation)}
            error={errorsValidation.uuidIndustry}
            helperText={errorsValidation.uuidIndustry}
          >
            {industries.map((indutry) => (
              <MenuItem value={indutry.uuid} key={indutry.uuid}>
                {indutry.title?.toTitleCase?.()}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextFieldNextFocus
            label="Domínio"
            name="domain"
            required
            value={company.domain?.toLowerCase()}
            onChange={onChange}
            onBlur={() => validateMandatoryInput('domain', company, setErrorsValidation)}
            error={errorsValidation.domain}
            helperText={
              errorsValidation.domain || 'Colocar domínio completo, exemplo: @dominio.com.br'
            }
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextFieldNextFocus
            label="Sigla"
            name="acronym"
            disabled
            value={company.acronym}
            onChange={onChange}
          />
        </Grid>
        <InformativeText />
        <Grid item className="buttonGroup twoButtons nearTheTop">
          <Button variant="outlined" onClick={() => goToBack(navigate)}>
            Voltar
          </Button>
          <HiddenForPermission keys={'EDIT_COMPANY'}>
            <Button onClick={handleOpen} disabled={disabledButton()}>
              Editar
            </Button>
          </HiddenForPermission>
        </Grid>
      </Form>

      <Modal
        open={openModal}
        onClose={handleClose}
        onConfirm={onConfirmModal}
        title={'Editar informações da empresa'}
        text={`Tem certeza que deseja editar as informações da empresa? `}
        buttonConfirm={'Sim'}
        buttonCancel={'Não'}
      />
    </BasePage>
  );
}
