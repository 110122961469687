import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { BasePage } from '../../../components/BaseMain';
import { TitlePages } from '../../../components/TitlePages';
import {
  goToAddedContracts,
  goToContractsFines,
  goToExpiringContracts,
  goToListAllContracts
} from '../../../router/coordinator';
import { Content } from './styled';
import { getDashboardInformations } from '../../../services/dashboardService';
import { GENERIC_ERROR } from '../../../utils/constantes';
import { AlertMessage, Card, Loading } from '../../../components';
import { HiddenForPermission } from '../../../components/HiddenForPermission';
import { hasPermission } from '../../../utils';

export function Home() {
  const breadcrumbs = [{ title: 'Início' }];
  const navigate = useNavigate();
  const [dashboard, setDashboard] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  useEffect(async () => {
    await getDashboard();
  }, []);

  const getDashboard = async () => {
    try {
      const result = await getDashboardInformations();
      setDashboard(result);
      setLoading(false);
    } catch (error) {
      setError({ title: error?.message || GENERIC_ERROR });
      setLoading(false);
    }
  };

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages
        title={'Bem-vindo(a)'}
        description={
          hasPermission('DASHBOARD_INFORMATIONS') &&
          'Aqui estão algumas informações importantes e que merecem atenção.'
        }
        toHideDivider={!hasPermission('DASHBOARD_INFORMATIONS')}
      />

      <HiddenForPermission keys={'DASHBOARD_INFORMATIONS'}>
        <Grid container lg={12}>
          <AlertMessage title={error.title} message={error.message} severity={error.severity} />
        </Grid>

        <Content container spacing={4}>
          <Loading loading={loading} />

          <Grid item lg={6}>
            <Card
              title="Quantidade de contratos a expirar"
              quantity={dashboard.expiring_contracts}
              goTo={goToExpiringContracts}
              navigate={navigate}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card
              title="Quantidade de contratos a entrar na multa rescisória"
              quantity={dashboard.termination_fine}
              goTo={goToContractsFines}
              navigate={navigate}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card
              title="Quantidade de contratos adicionados"
              quantity={dashboard.added_contracts}
              goTo={goToAddedContracts}
              navigate={navigate}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card
              title="Quantidade total de contratos"
              quantity={dashboard.total}
              goTo={goToListAllContracts}
              navigate={navigate}
            />
          </Grid>
        </Content>
      </HiddenForPermission>
    </BasePage>
  );
}
