import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import {
  HiddenForPermission,
  PaginationDesktop,
  SkeletonDesktop,
  UserInformations
} from '../../../../../../components';
import { goToEditHistoryIndex } from '../../../../../../router/coordinator';

export function DesktopTable({
  historics,
  count,
  loading,
  page,
  offset,
  handlePage,
  handleChangeRowsPerPage,
  navigate
}) {
  const historicList = historics?.map((historic) => (
    <TableRow key={historic.uuid} className="not-effect">
      <TableCell>
        <UserInformations src={historic?.user?.profileImage?.imageBase64} user={historic.user} />
      </TableCell>
      <TableCell>{historic?.date?.toMask('date', 'MM/YYYY')}</TableCell>
      <TableCell>
        {historic?.percent?.toLocaleString('pt-br', { minimumFractionDigits: 4 })}%
      </TableCell>
      <HiddenForPermission keys="EDIT_HISTORY_INDEX">
        <TableCell style={{ textAlign: 'right' }}>
          <IconButton onClick={() => goToEditHistoryIndex(navigate, historic.uuid)}>
            <CreateIcon />
          </IconButton>
        </TableCell>
      </HiddenForPermission>
    </TableRow>
  ));

  function RenderTable() {
    if (loading) {
      return <SkeletonDesktop numbersOfColumns={3} />;
    }

    return historicList;
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Usuário que adicionou</TableCell>
              <TableCell>Data</TableCell>
              <TableCell>Porcentagem</TableCell>
              <TableCell style={{ textAlign: 'right' }}>Editar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <RenderTable />
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationDesktop
        count={count}
        page={page}
        rowsPerPage={offset}
        handlePage={handlePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}
