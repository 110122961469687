import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material';
import { goToDatailsContract } from '../../../../../router/coordinator';
import { PaginationDesktop, SkeletonDesktop } from '../../../../../components';
import { Order } from '../../../../../utils/enums';
import { hasPermission } from '../../../../../utils';

export function DesktopTable({
  contracts,
  navigate,
  count,
  loading,
  page,
  offset,
  order,
  handlePage,
  handleChangeRowsPerPage,
  handleOrder
}) {
  const goTo = (uuid) => {
    if (hasPermission('FIND_CONTRACT')) {
      goToDatailsContract(navigate, uuid);
    }
  };

  const contractsList = contracts?.map((c) => {
    const fullName = `${c.user?.name?.toTitleCase()} ${c.user?.lastName?.toTitleCase()}`;

    return (
      <TableRow
        key={c.id}
        onClick={() => goTo(c.uuid)}
        className={!hasPermission('FIND_CONTRACT') && 'not-effect'}
      >
        <TableCell
          style={{
            whiteSpace: 'nowrap'
          }}
        >
          {c.id || '-'}
        </TableCell>
        <TableCell>{c.sector?.title?.toTitleCase() || '-'}</TableCell>
        <TableCell>{fullName}</TableCell>
        <TableCell>{c?.user?.cellphone?.toMask?.('cellphone') || '-'}</TableCell>
        <TableCell>{c.user?.tellphone?.toMask?.('tellphone') || '-'}</TableCell>
        <TableCell> {c.user?.email}</TableCell>
        <TableCell>{c.timestamp?.createdAt?.toMask('date')}</TableCell>
      </TableRow>
    );
  });

  function RenderTable() {
    if (loading) {
      return <SkeletonDesktop numbersOfColumns={7} />;
    }

    return contractsList;
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Setor</TableCell>
              <TableCell>Contato</TableCell>
              <TableCell>Celular</TableCell>
              <TableCell>Telefone</TableCell>
              <TableCell>E-mail</TableCell>
              <TableCell>
                <TableSortLabel
                  direction={order}
                  active={order === Order.ASC}
                  onClick={handleOrder}
                >
                  Adicionado em
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <RenderTable />
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationDesktop
        count={count}
        page={page}
        rowsPerPage={offset}
        handlePage={handlePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}
