import { Grid } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../../../../theme/styles';
import { breakpoints } from '../../../../../theme/breakpoints';

export const Container = styled.div`
  #label-company {
    color: ${colors.theme.primary};
    font-weight: 600;
  }

  #button-content {
    display: none;
  }
`;

export const SelectCompany = styled(Grid)`
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

export const Content = styled(Grid)`
  #button-group {
    margin-bottom: 1rem;
    width: 100%;

    button {
      width: 100%;
      margin-top: 1rem;

      @media (min-width: ${breakpoints.md}) {
        margin-right: 1rem;
        width: 25%;
      }
    }
  }

  #allSwitches {
    margin-bottom: 1rem;
  }

  .column {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    text-transform: capitalize;
  }

  .twoButtons {
    margin-top: 1rem;
  }
`;
