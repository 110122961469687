import axios from 'axios';
import {
  clearLocalStorage,
  getItemLocalStorage,
  setItemLocalStorage
} from '../services/localStorageService';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

const expiredSession = async () => {
  window.location.href = '/credenciais/entrar';
  clearLocalStorage();
  await setItemLocalStorage('session_expired', true);
};

api.interceptors.request.use(async (request) => {
  const headers = request.headers ?? {};
  const token = getItemLocalStorage('token');

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  request.headers = headers;
  return request;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const statusError = error.response?.status;
    const trace = error.response?.data?.trace;

    // Códigos de token expirado, se o usuário não tiver permissão pra algo
    // O erro é 401, mas nesse caso o usuário precisa de um feedback
    const tracesExpiredToken = ['ASC0062', 'ASC0063', 'ASC0074'];

    if (statusError === 401 && tracesExpiredToken.includes(trace)) {
      await expiredSession();
    }

    return Promise.reject(error);
  }
);
