import { HiddenForPermission } from '../../../../../components';
import {
  MobileTableConteiner,
  TableMobileButton,
  TableMobileCell,
  TableMobileContent,
  TableMobileItem,
  TableMobileTitle
} from '../../../../../components/MobileTable';
import { goToDatailsContract } from '../../../../../router/coordinator';

export function MobileTable({ contracts, navigate }) {
  const contractsList = contracts?.map((c) => {
    const fullName = `${c.user?.name?.toTitleCase()} ${c.user?.lastName?.toTitleCase()}`;

    return (
      <TableMobileCell key={c.id}>
        <TableMobileItem>
          <TableMobileTitle>Id</TableMobileTitle>
          <TableMobileContent>{c.id || '-'}</TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Setor</TableMobileTitle>
          <TableMobileContent>{c.sector?.title?.toTitleCase() || '-'} </TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Contato</TableMobileTitle>
          <TableMobileContent>{fullName}</TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Celular</TableMobileTitle>
          <TableMobileContent>{c.user?.cellphone?.toMask?.('cellphone') || '-'}</TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Telefone</TableMobileTitle>
          <TableMobileContent>{c.user?.tellphone?.toMask?.('tellphone') || '-'}</TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>E-mail</TableMobileTitle>
          <TableMobileContent>{c.user?.email}</TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Adicionado em</TableMobileTitle>
          <TableMobileContent>{c.timestamp?.createdAt?.toMask('date')}</TableMobileContent>
        </TableMobileItem>

        <HiddenForPermission keys={'FIND_CONTRACT'}>
          <TableMobileButton onClick={() => goToDatailsContract(navigate, c.uuid)}>
            Ver detalhes
          </TableMobileButton>
        </HiddenForPermission>
      </TableMobileCell>
    );
  });

  return <MobileTableConteiner>{contractsList}</MobileTableConteiner>;
}
