import { CircularProgress } from '@mui/material';
import { Container } from './styled';

export function Loading({ loading, light, text }) {
  return (
    loading && (
      <Container light={light}>
        <div>
          <CircularProgress />
          <p>{text || 'Carregando...'}</p>
        </div>
      </Container>
    )
  );
}
