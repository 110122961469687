import {
  MobileTableConteiner,
  PaginationMobile,
  SkeletonMobile,
  TableMobileCell,
  TableMobileContent,
  TableMobileItem,
  TableMobileTitle
} from '../../../../../../components';

export function MobileTable({ segments, loading, count, page, offset, handlePage }) {
  const sectorsList = segments?.map((sector) => {
    return (
      <TableMobileCell key={sector.uuid}>
        <TableMobileItem>
          <TableMobileTitle>Nome </TableMobileTitle>
          <TableMobileContent>{sector.title?.toTitleCase()}</TableMobileContent>
        </TableMobileItem>
      </TableMobileCell>
    );
  });

  return (
    <MobileTableConteiner>
      {!loading ? sectorsList : <SkeletonMobile numbersOfColumns={10} />}
      <PaginationMobile count={count} page={page} rowsPerPage={offset} handlePage={handlePage} />
    </MobileTableConteiner>
  );
}
