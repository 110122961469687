import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material';
import { TargetStatus, SkeletonDesktop, PaginationDesktop } from '../../../../../components';
import { convertCentsToWhole, convertReadjustmentMonth } from '../../../../../utils/convert';
import { Order } from '../../../../../utils/enums';
import { hasPermission } from '../../../../../utils';

export function DesktopTable({
  contracts,
  count,
  loading,
  page,
  offset,
  order,
  orderBy,
  handlePage,
  handleChangeRowsPerPage,
  handleOrder,
  goToDetails
}) {
  const goTo = (uuid) => {
    if (hasPermission('FIND_CONTRACT')) {
      goToDetails(uuid);
    }
  };

  const contractsList =
    contracts?.length &&
    contracts.map((c) => {
      return (
        <TableRow
          key={c.uuid}
          onClick={() => goTo(c.uuid)}
          className={!hasPermission('FIND_CONTRACT') && 'not-effect'}
        >
          <TableCell
            style={{
              whiteSpace: 'nowrap'
            }}
          >
            {c.id || '-'}
          </TableCell>
          <TableCell>
            <TargetStatus status={c.status} />
          </TableCell>
          <TableCell>{c.segments?.title?.toTitleCase() || '-'}</TableCell>
          <TableCell>{c.provider?.name?.toTitleCase()}</TableCell>
          <TableCell>{c.initDate.toMask('date')}</TableCell>
          <TableCell>{c.expirationDate?.toMask('date') || '-'}</TableCell>
          <TableCell
            style={{
              whiteSpace: 'nowrap'
            }}
          >
            {c.indexCurrent?.name?.toUpperCase() || c.indexInitial?.name?.toUpperCase() || '-'}
          </TableCell>
          <TableCell>
            {convertCentsToWhole(
              c.financialContract?.initialValue,
              c.financialContract?.coin?.title
            )}
          </TableCell>
          <TableCell>
            {convertCentsToWhole(
              c.financialContract?.currentValue,
              c.financialContract?.coin?.title
            ) || '-'}
          </TableCell>
          <TableCell>{convertReadjustmentMonth(c.financialContract?.readjustmentMonth)}</TableCell>
        </TableRow>
      );
    });

  function RenderTable() {
    if (loading) {
      return <SkeletonDesktop numbersOfColumns={10} />;
    }

    return contractsList;
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Segmento</TableCell>
              <TableCell>Fornecedor</TableCell>
              <TableCell>
                <TableSortLabel
                  direction={order}
                  active={order === Order.ASC && orderBy === 'initDate'}
                  onClick={() => handleOrder('initDate')}
                >
                  Data de início
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  direction={order}
                  active={order === Order.ASC && orderBy === 'expirationDate'}
                  onClick={() => handleOrder('expirationDate')}
                >
                  Data de expiração
                </TableSortLabel>
              </TableCell>
              <TableCell>Índice</TableCell>
              <TableCell>Valor inicial</TableCell>
              <TableCell>Valor atual</TableCell>
              <TableCell>Mês de reajuste</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <RenderTable />
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationDesktop
        count={count}
        page={page}
        rowsPerPage={offset}
        handlePage={handlePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}
