import styled from 'styled-components';
import { breakpoints } from '../../theme/breakpoints';
import { colors, metrics } from '../../theme/styles';

export const Container = styled.div`
  min-height: 85%;
  margin: auto;
  padding: 1rem 1.5rem;
  box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${breakpoints.md}) {
    width: 80%;
    padding: 1.5rem 2.5rem;
  }

  h4 {
    font-size: ${metrics.size.medium};
    font-weight: 500;
    width: 100%;
  }

  div {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    span {
      font-size: 4.5rem;
      font-weight: bold;
      color: ${colors.theme.primary};
    }

    a {
      font-size: 1.3rem;
      padding-bottom: 10px;

      @media (min-width: ${breakpoints.md}) {
        font-size: 1.5rem;
      }
    }
  }
`;
