import {
  HiddenForPermission,
  PaginationMobile,
  SkeletonMobile,
  TargetStatus
} from '../../../../../components';
import {
  MobileTableConteiner,
  TableMobileButton,
  TableMobileCell,
  TableMobileContent,
  TableMobileItem,
  TableMobileTitle
} from '../../../../../components/MobileTable';
import { goToDatailsContract } from '../../../../../router/coordinator';

export function MobileTable({ contracts, navigate, loading, count, page, offset, handlePage }) {
  const contractsList =
    contracts &&
    contracts.map((c) => {
      return (
        <TableMobileCell key={c.id}>
          <TableMobileItem>
            <TableMobileTitle>Id </TableMobileTitle>
            <TableMobileContent>{c.id}</TableMobileContent>
          </TableMobileItem>

          <TableMobileItem>
            <TableMobileTitle>Status </TableMobileTitle>
            <TableMobileContent>
              <TargetStatus status={c.status} />
            </TableMobileContent>
          </TableMobileItem>

          <TableMobileItem>
            <TableMobileTitle>Segmento</TableMobileTitle>
            <TableMobileContent>{c.segments?.title?.toTitleCase()}</TableMobileContent>
          </TableMobileItem>

          <TableMobileItem>
            <TableMobileTitle>Fornecedor </TableMobileTitle>
            <TableMobileContent>{c.provider?.name?.toTitleCase()}</TableMobileContent>
          </TableMobileItem>

          <TableMobileItem>
            <TableMobileTitle>Data de início </TableMobileTitle>
            <TableMobileContent>{c.initDate?.toMask('date')}</TableMobileContent>
          </TableMobileItem>

          <TableMobileItem>
            <TableMobileTitle>Data de expiração vigente</TableMobileTitle>
            <TableMobileContent>
              {c.additionalDate?.toMask('date') || c.expirationDate?.toMask('date')}
            </TableMobileContent>
          </TableMobileItem>

          <HiddenForPermission keys={'FIND_CONTRACT'}>
            <TableMobileButton onClick={() => goToDatailsContract(navigate, c.uuid)}>
              Ver detalhes
            </TableMobileButton>
          </HiddenForPermission>
        </TableMobileCell>
      );
    });

  return (
    <MobileTableConteiner>
      {!loading ? contractsList : <SkeletonMobile numbersOfColumns={6} />}
      <PaginationMobile count={count} page={page} rowsPerPage={offset} handlePage={handlePage} />
    </MobileTableConteiner>
  );
}
