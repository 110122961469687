import { api } from '../utils/axiosConfig';

/**
 * Lista todas as moedas
 */
export const listAllCoins = async (filter = {}) => {
  try {
    const { data } = await api.get('/coin/list-all', { params: { ...filter } });

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Busca a cotação de uma moeda
 * @param {*} uuidCoin - identificador da moeda
 * @param {*} date - data da cotação
 * @param {*} type - tipo de cotação [comercial]
 * @returns
 */
export const searchCoinQuotation = async (uuidCoin, date, type) => {
  try {
    const { data } = await api.get(`/coin/${uuidCoin}/search/history`, {
      params: { date, type }
    });

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Cria uma cotação pra moeda ( um valor pro histórico )
 * @param {*} uuidCoin - identificador da moeda
 * @param {*} excharge - cotação
 * @param {*} type - tipo [comercial]
 * @param  origin - origem da cotação [BANCO CENTRAL, PERSONALIZADO]
 * @param  date - data da cotação
 * @returns
 */
export const createCoinQuotation = async (
  uuidCoin,
  excharge,
  type,
  origin = 'personalizado',
  date = ''
) => {
  try {
    const payload = { date, excharge, type, origin };
    const { data } = await api.post(`/coin/create-history/${uuidCoin}`, payload);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Cria uma moeda
 * @param {*} title - nome da moeda
 * @param {*} acronym - Sigla [ex: EUA, EUR, BRL]
 * @param {*} excherge - cota atual
 * @returns
 */
export const createCoin = async (title, acronym, excharge) => {
  try {
    const payload = { title, acronym, excharge };
    const { data } = await api.post(`/coin/create`, payload);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Lista o histórico de uma moeda
 * @param {*} uuidCoin - identificador da moeda
 * @param {*} initialPage - Página de inicio
 * @param {*} offset - Itens por página
 * @param {*} order - ordem [asc, desc]
 * @param {*} filter - filtros
 * @returns
 */
export const listAllHistoryCoins = async (
  uuidCoin,
  initialPage,
  offset,
  order = 'ASC',
  filter = {}
) => {
  try {
    const page = initialPage + 1;
    const { data } = await api.get(`/coin/${uuidCoin}/history`, {
      params: {
        initial_page: page,
        offset,
        order,
        ...filter
      }
    });

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Busca uma moeda pelo uuid
 * @param {*} uuidCoin - identificador da moeda
 * @returns
 */
export const findCoin = async (uuidCoin) => {
  try {
    const { data } = await api.get(`/coin/find/${uuidCoin}`);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Cadastra um valor pra moeda
 * @param {*} uuidCoin - identificador da moeda
 * @param {*} excharge - valor dacoração em real
 * @param {*} type - tipo [comercial]
 * @param {*} date - data da cotação
 * @param {*} origin - origem desse valor ['BANCO CENTRAL' | 'Personalizado']
 * @returns
 */
export const createCoinHistory = async (
  uuidCoin,
  excharge,
  type,
  date = null,
  origin = 'personalizado'
) => {
  try {
    const payload = { excharge, type, date, origin };
    const { data } = await api.post(`/coin/create-history/${uuidCoin}`, payload);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Editar uma moeda
 * @param {*} title - nome da moeda
 * @param {*} acronym - Sigla [ex: EUA, EUR, BRL]
 * @returns
 */
export const editCoin = async (uuidCoin, title, acronym) => {
  try {
    const payload = { title, acronym };
    const { data } = await api.put(`/coin/${uuidCoin}/edit`, payload);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};
