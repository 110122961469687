import styled from 'styled-components';
import { colors } from '../../theme/styles';
import { Button } from '@mui/material';

export const Container = styled.div``;

export const Card = styled.div`
  margin: 1rem 0;
  padding: 1rem;
  box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

export const Cell = styled.p`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  width: 100%;
  flex-direction: ${({ direction }) => direction || 'row'};
  align-items: ${({ direction }) => (direction === 'column' ? 'left' : 'center')};
`;

export const Title = styled.span`
  color: ${colors.theme.primary};
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: ${({ direction }) => direction === 'column' && '0.5rem'};
`;

export const Content = styled.span`
  font-weight: 600;
  text-align: right;
`;

export const Btn = styled(Button)`
  && {
    margin-top: 1.5rem;
  }
  width: 100%;
`;
