import { Grid } from '@mui/material';
import styled from 'styled-components';
import { breakpoints } from '../../../../../../../../../theme/breakpoints';

export const Area = styled(Grid)`
  && {
    margin-left: 0;
    width: 100%;
  }

  .MuiGrid-item:first-child {
    padding-left: 0;
  }
`;

export const BottomArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 100%;

  @media (min-width: ${breakpoints.md}) {
    justify-content: space-between;
    flex-direction: row;
  }

  .price {
    padding-top: 1rem;
  }

  p {
    font-weight: 600;
  }

  button {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    justify-self: right;

    @media (min-width: ${breakpoints.md}) {
      margin-top: 0;
    }

    svg {
      margin-right: 10px;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    button.defined {
      width: 14rem;
    }

    button.custom {
      width: 25rem;
    }
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
