import { Button, Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material';
import { useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { goToChooseCompany } from '../../router/coordinator';
import { ButtonContent, Main, Title } from './styled';
import { getTerm, listNotAcceptTerms, optin } from '../../services/termsService';
import { GENERIC_ERROR } from '../../utils/constantes';
import { getItemLocalStorage, setItemLocalStorage } from '../../services/localStorageService';
import { AlertMessage, Loading, PDFViewer } from '../../components';
import { scrollToTopPage } from '../../utils';

export function Terms() {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [term, setTerm] = useState({});
  const [termsForLocalStorage, setTermsForLocalStorage] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  const clearAlertMessage = () =>
    setAlertMessage((prev) => ({ ...prev, title: '', message: '', severity: 'error' }));

  useLayoutEffect(() => {
    (async () => {
      await listTerms();
    })();
  }, []);

  const listTerms = async () => {
    try {
      scrollToTopPage();
      setLoading(true);
      clearAlertMessage();
      setChecked(false);

      const termsLS = getItemLocalStorage('list_terms');

      let termsForLocalStorage = [];
      if (termsLS?.length) {
        const firstTermUUID = termsLS.shift();
        const term = await getTerm(firstTermUUID);
        setTerm(term);

        termsForLocalStorage = termsLS;
      } else {
        const listTerms = await listNotAcceptTerms();
        if (!listTerms.length) {
          goToChooseCompany(navigate);
          return;
        }

        const firstTerm = listTerms.shift();

        setTerm(firstTerm);

        termsForLocalStorage = listTerms.map((t) => t.uuid);
      }

      setTermsForLocalStorage(termsForLocalStorage);

      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  };

  function RenderVizualizer() {
    if (term.type === 'html') {
      return (
        <div id="term">
          <div dangerouslySetInnerHTML={{ __html: term.document }} />
        </div>
      );
    }

    if (term.type === 'pdf') {
      return <PDFViewer base64={term.document} />;
    }

    return null;
  }

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const next = async () => {
    try {
      setLoading(true);
      clearAlertMessage();

      await optin(term.uuid);

      await setItemLocalStorage('list_terms', termsForLocalStorage);

      if (!termsForLocalStorage.length) {
        goToChooseCompany(navigate);
        return;
      }

      await listTerms();
      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  };

  return (
    <Main>
      <div id="content">
        <Title>{term.name?.toTitleCase()}</Title>

        <Loading loading={loading} />
        <Grid container>
          <AlertMessage
            title={alertMessage.title}
            message={alertMessage.message}
            severity={alertMessage.severity}
            onClose={() => clearAlertMessage(setAlertMessage)}
          />
        </Grid>

        <RenderVizualizer />

        <FormGroup id="checkbox">
          <FormControlLabel
            name="accept"
            checked={checked}
            onChange={handleChange}
            control={<Checkbox />}
            label="Ao marcar essa opção, você diz que concorda com as diretrizes informada acima."
          />
        </FormGroup>
        <ButtonContent>
          <Button onClick={next} disabled={!checked}>
            Próximo
          </Button>
        </ButtonContent>
      </div>
    </Main>
  );
}
