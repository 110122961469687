import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { HiddenForPermission, SkeletonDesktop } from '../../../../../../components';
import { goToEditCoin } from '../../../../../../router/coordinator';

export function DesktopTable({ coins, loading, navigate }) {
  const coinsList = coins?.map((coin) => (
    <TableRow key={coin.uuid} className="not-effect">
      <TableCell>{coin.title}</TableCell>
      <HiddenForPermission keys="EDIT_COIN">
        <TableCell onClick={() => goToEditCoin(navigate, coin.uuid)} style={{ textAlign: 'right' }}>
          <IconButton>
            <EditIcon />
          </IconButton>
        </TableCell>
      </HiddenForPermission>
    </TableRow>
  ));

  function RenderTable() {
    if (loading) {
      return <SkeletonDesktop numbersOfColumns={1} />;
    }

    return coinsList;
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Moeda</TableCell>
              <HiddenForPermission keys="EDIT_COIN">
                <TableCell style={{ textAlign: 'right' }}>Editar</TableCell>
              </HiddenForPermission>
            </TableRow>
          </TableHead>
          <TableBody>
            <RenderTable />
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
