import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import { Form } from './styled';
import { BasePage } from '../../../../../components/BaseMain';
import { goToAdmin, goToBack, goToIndustryList } from '../../../../../router/coordinator';
import {
  AlertMessage,
  Loading,
  Modal,
  TextFieldKeyPress,
  TextFieldNextFocus,
  TitlePages
} from '../../../../../components';
import { clearAlertMessage, isDesktop } from '../../../../../utils';
import { useForm } from '../../../../../hooks/useForm';
import { editIndustry, findIndustry } from '../../../../../services';
import { GENERIC_ERROR } from '../../../../../utils/constantes';
import { validateMandatoryInput } from '../../../../../utils/validates';
import { InformativeText } from '../../../../../components/InformativeText';

export function EditIndustryPage() {
  const navigate = useNavigate();
  const breadcrumbs = [
    { title: 'Administrativo', functionCoordinator: () => goToAdmin(navigate) },
    { title: 'Setores das empresas', functionCoordinator: () => goToIndustryList(navigate) },
    { title: 'Editar' }
  ];

  const initialValueForm = {
    title: '',
    description: ''
  };
  const [form, onChange, _, setForm] = useForm(initialValueForm);
  const [errorsValidation, setErrorsValidation] = useState({});
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  // --- Lógica do modal
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const { uuid } = useParams();
  if (!uuid) {
    goToBack(navigate);
  }

  const init = useCallback(async () => {
    try {
      setLoading(true);

      const result = await findIndustry(uuid);

      setForm({
        title: result?.title?.toTitleCase?.(),
        description: result?.description?.toTitleCase?.()
      });
    } catch (error) {
      setAlertMessage({
        title: error?.message || GENERIC_ERROR,
        message: error?.trace && `Código: ${error.trace}`
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  const onConfirmModal = async () => {
    try {
      setLoading(true);
      handleClose();

      await editIndustry(uuid, form.title, form.description);

      setAlertMessage((prev) => ({
        ...prev,
        title: 'Setor de atuação editado com sucesso.',
        message: '',
        severity: 'success'
      }));
    } catch (error) {
      setAlertMessage({
        title: error?.message || GENERIC_ERROR,
        message: error?.trace && `Código: ${error.trace}`
      });
    } finally {
      setLoading(false);
    }
  };

  const disabledButton = () => {
    if (!form.title) {
      return true;
    }

    return false;
  };

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages title="Editar setor de atuação de empresa" />

      <Loading loading={loading} />

      <Grid container>
        <AlertMessage
          message={alertMessage.message}
          severity={alertMessage.severity}
          title={alertMessage.title}
          onClose={() => clearAlertMessage(setAlertMessage)}
        />
      </Grid>

      <Form container xs={12} rowGap={2} columnSpacing={isDesktop() && 2}>
        <Grid item xs={12} lg={3}>
          <TextFieldNextFocus
            label="Nome"
            name="title"
            required
            value={form.title}
            onChange={onChange}
            onBlur={() => validateMandatoryInput('title', form, setErrorsValidation)}
            error={errorsValidation.title}
            helperText={errorsValidation.title && 'O nome é obrigatório'}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <TextFieldKeyPress
            label="Descrição"
            name="description"
            value={form.description}
            onChange={onChange}
          />
        </Grid>

        <InformativeText />

        <Grid item xs={12} className="buttonGroup twoButtons nearTheTop">
          <Button variant="outlined" onClick={() => goToBack(navigate)}>
            Voltar
          </Button>
          <Button onClick={handleOpen} disabled={disabledButton()}>
            Editar
          </Button>
        </Grid>
      </Form>

      <Modal
        open={openModal}
        onClose={handleClose}
        onConfirm={onConfirmModal}
        title="Editar setor de atuação de empresa"
        text="Tem certeza que deseja editar o setor de atuação"
      />
    </BasePage>
  );
}
