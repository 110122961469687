import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactInputMask from 'react-input-mask';
import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { BasePage } from '../../../../../components/BaseMain';
import { goToAdmin, goToBack, goToProvidersList } from '../../../../../router/coordinator';
import {
  AlertMessage,
  HiddenForPermission,
  Loading,
  Modal,
  TextFieldNextFocus,
  TitlePages
} from '../../../../../components';
import { clearAlertMessage, isDesktop } from '../../../../../utils';
import { editCompany, getCompanyByUUID, listIndustries } from '../../../../../services';
import { GENERIC_ERROR } from '../../../../../utils/constantes';
import { useForm } from '../../../../../hooks/useForm';
import { validateInputDocument, validateMandatoryInput } from '../../../../../utils/validates';
import { PersonType } from '../../../../../utils/enums';
import { InformativeText } from '../../../../../components/InformativeText';
import { Form } from './styled';

export function EditProviderInformationsPage() {
  const navigate = useNavigate();

  const { uuid } = useParams();
  if (!uuid) {
    goToBack(navigate);
  }

  const breadcrumbs = [
    { title: 'Administrativo', functionCoordinator: () => goToAdmin(navigate) },
    {
      title: 'Fornecedores e clientes',
      functionCoordinator: () => goToProvidersList(navigate, uuid)
    },
    { title: 'Editar' }
  ];

  const initialValueForm = {
    name: '',
    document: '',
    uuidIndustry: '',
    personType: ''
  };
  const [company, onChange, _, setCompany] = useForm(initialValueForm);
  const [industries, setIndustries] = useState([]);
  const [errorsValidation, setErrorsValidation] = useState({});
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  const init = useCallback(async () => {
    try {
      setLoading(true);

      const [company, industries] = await Promise.all([getCompanyByUUID(uuid), listIndustries()]);

      setCompany({
        ...company,
        uuidIndustry: company?.industry?.uuid,
        personType: company?.document?.length === 14 ? PersonType.PJ : PersonType.PF
      });

      setIndustries(industries);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  // --- Lógica do modal
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const onConfirmModal = async () => {
    try {
      setLoading(true);
      handleClose();

      const { name, uuidIndustry, domain, document } = company;
      await editCompany(uuid, name, uuidIndustry, domain, false, document);

      setAlertMessage((prev) => ({
        ...prev,
        title: 'Fornecedor editado com sucesso.',
        severity: 'success'
      }));
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
    } finally {
      setLoading(false);
    }
  };
  // ---

  const disabledButton = () => {
    if (!company.name) {
      return true;
    }
    if (!company.document) {
      return true;
    }
    if (!company.uuidIndustry) {
      return true;
    }

    return false;
  };

  const clearCompany = () => {
    setCompany((prev) => ({
      ...prev,
      name: '',
      document: '',
      uuidIndustry: ''
    }));
  };

  const onChangePersonType = (e) => {
    onChange(e);
    clearCompany();
  };

  const handleDocumentMask = () =>
    company.personType === PersonType.PF ? '999.999.999-99' : '99.999.999/9999-99';

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages title="Editar informações do fornecedor ou cliente" />

      <Loading loading={loading} />

      <Grid container>
        <AlertMessage
          message={alertMessage.message}
          severity={alertMessage.severity}
          title={alertMessage.title}
          onClose={() => clearAlertMessage(setAlertMessage)}
        />
      </Grid>

      <Form container xs={12} rowGap={2} columnSpacing={isDesktop() && 2}>
        <Grid item xs={12} lg={4}>
          <TextField
            label="PF ou PJ"
            name="personType"
            onChange={onChangePersonType}
            value={company.personType}
            select
            required
          >
            <MenuItem value="pf">Pessoa física (PF)</MenuItem>
            <MenuItem value="pj">Pessoa jurídica (PJ)</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} lg={4}>
          <ReactInputMask
            mask={handleDocumentMask()}
            maskChar="_"
            value={company.document}
            onChange={onChange}
            onBlur={() => validateInputDocument(company.document, setErrorsValidation)}
            disabled={loading || !company.personType}
          >
            {() => (
              <TextField
                required
                label="Documento"
                name="document"
                value={company.document}
                error={errorsValidation.document}
                helperText={errorsValidation.document && 'O documento está inválido'}
                disabled={loading || !company.personType}
              />
            )}
          </ReactInputMask>
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextFieldNextFocus
            label="Nome"
            name="name"
            required
            value={company.name?.toTitleCase()}
            onChange={onChange}
            onBlur={() => validateMandatoryInput('name', company, setErrorsValidation)}
            error={errorsValidation.name}
            helperText={errorsValidation.name}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            select
            required
            label="Setor de atuação"
            name="uuidIndustry"
            value={company.uuidIndustry}
            onChange={onChange}
            onBlur={() => validateMandatoryInput('uuidIndustry', company, setErrorsValidation)}
            error={errorsValidation.uuidIndustry}
            helperText={errorsValidation.uuidIndustry}
          >
            {industries.map((indutry) => (
              <MenuItem value={indutry.uuid} key={indutry.uuid}>
                {indutry.title?.toTitleCase?.()}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <InformativeText />
        <Grid item className="buttonGroup twoButtons nearTheTop">
          <Button variant="outlined" onClick={() => goToBack(navigate)}>
            Voltar
          </Button>
          <HiddenForPermission keys="EDIT_PROVIDER">
            <Button onClick={handleOpen} disabled={disabledButton()}>
              Editar
            </Button>
          </HiddenForPermission>
        </Grid>
      </Form>

      <Modal
        open={openModal}
        onClose={handleClose}
        onConfirm={onConfirmModal}
        title="Editar informações da empresa"
        text="Tem certeza que deseja editar as informações da empresa?"
      />
    </BasePage>
  );
}
