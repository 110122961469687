import { useCallback, useEffect, useState } from 'react';
import { Button, Grid, MenuItem, TextField } from '@mui/material';
import ReactInputMask from 'react-input-mask';
import { isDesktop, onlyNumbers } from '../../../../../../../utils';
import {
  AlertMessage,
  BorderWithText,
  Loading,
  TextFieldNextFocus
} from '../../../../../../../components';
import {
  validateInputDocument,
  validateMandatoryInput
} from '../../../../../../../utils/validates';
import { searchCompany } from '../../../../../../../services/companyService';
import { COMPANY_ACRONYM_LENGTH, GENERIC_ERROR } from '../../../../../../../utils/constantes';
import { useForm } from '../../../../../../../hooks/useForm';
import { listIndustries } from '../../../../../../../services/utilsService';
import { LocalStorageKeys, PersonType } from '../../../../../../../utils/enums';
import { getItemLocalStorage, setItemLocalStorage } from '../../../../../../../services';
import { useUpdateState } from '../../../../../../../hooks/useUpdateState';
import { Area } from '../../../../EditContractPage/steppers/step2/areas/coinArea/styled';
import { Text } from '../../../../../../../components/InformativeText/styles';
import AutocompleteSearch from '../../../../../../../components/AutocompleteSearch';

export function SupplierInformation({ onNextStep, onBackStep }) {
  const [form, onChange, _, setForm] = useForm({
    uuid_provider: '',
    document: '',
    name: '',
    uuid_industry: '',
    acronym: '',
    is_intern: false,
    domain: '',
    personType: ''
  });
  const [{ loading }, setStates] = useUpdateState({
    loading: false
  });
  const [errorsValidation, setErrorsValidation] = useState({});
  const [error, setError] = useUpdateState({
    title: '',
    message: '',
    severity: 'error'
  });

  const getExistForm = () => {
    const saveForm = getItemLocalStorage(LocalStorageKeys.CREATE_CONTRACT);
    if (saveForm) {
      setForm(saveForm);
    }
  };

  useEffect(() => {
    getExistForm();
  }, []);

  const saveInLocalStorage = () => {
    setItemLocalStorage(LocalStorageKeys.CREATE_CONTRACT, form);
  };

  const onClearError = () => {
    setError({
      title: '',
      message: ''
    });
  };

  const onClearForm = (document) => {
    setForm({
      ...form,
      uuid_provider: '',
      name: '',
      uuid_industry: '',
      acronym: '',
      domain: '',
      is_intern: '',
      document
    });
  };

  /**
   * Ao colocar o documento  eu busco no Banco se ele axistir, caso sim eu coloco nos campos.
   * Se não ele será cadastrado futuramente
   */
  const onChangeSearchSupplier = async (event) => {
    onChange(event);
    const document = onlyNumbers(event?.target?.value);
    if (!document) {
      onClearError();
      onClearForm();
      return;
    }

    try {
      onClearError();
      const isSearchSuplier =
        (form?.personType === PersonType.PF && document.length === 11) ||
        (form?.personType === PersonType.PJ && document.length === 14);

      if (isSearchSuplier) {
        setStates({ loading: true });

        const result = await searchCompany(document);

        setForm({
          ...form,
          uuid_provider: result.uuid,
          name: result.name?.toTitleCase(),
          uuid_industry: result.industry.uuid,
          acronym: result.acronym?.toUpperCase(),
          domain: result.domain,
          is_intern: result.isIntern,
          document
        });

        setTimeout(() => setStates({ loading: false }), 1000);
      }
    } catch (err) {
      onClearForm(document);
      setError({
        title: err?.message || GENERIC_ERROR,
        message: err?.trace && `Código: ${err?.trace}`
      });
    } finally {
      setStates({ loading: false });
    }
  };

  const clearSupplierInformations = () => {
    setForm((prev) => ({
      ...prev,
      uuid_provider: '',
      name: '',
      uuid_industry: '',
      acronym: '',
      domain: '',
      is_intern: '',
      document: ''
    }));
  };

  const onChangePersonType = (e) => {
    onChange(e);
    clearSupplierInformations();
  };

  const nextStep = () => {
    saveInLocalStorage();
    onNextStep();
  };

  const backStep = () => {
    saveInLocalStorage();
    onBackStep();
  };

  const getIndustries = useCallback(async () => {
    try {
      const data = await listIndustries();
      return data;
    } catch (error) {
      setError({
        title: error?.message || GENERIC_ERROR,
        message: error?.trace && `Código: ${error?.trace}`
      });
      return [];
    }
  }, []);

  const disabledButton = () => {
    if (!form.personType) {
      return true;
    }
    if (!form.document) {
      return true;
    }
    if (!form.uuid_provider) {
      return true;
    }

    return false;
  };

  const handleDocumentMask = () =>
    form.personType === PersonType.PF ? '999.999.999-99' : '99.999.999/9999-99';

  return (
    <Grid container spacing={2}>
      <Loading loading={loading} />

      <Grid item xs={12}>
        <AlertMessage title={error.title} message={error.message} severity={error.severity} />
      </Grid>
      <Grid item xs={12}>
        <Text style={{ padding: 0 }}>
          {`Nesta tela você fará a pesquisa do fornecedor pertencente a esse contrato, qualquer edição
          deve ser feita na tela de "Editar empresa de acesso" ou "Editar fornecedor".`}
        </Text>
      </Grid>
      <Grid item xs={12}>
        <BorderWithText title="Campos para busca de fornecedor" xs={12}>
          <Area container columnSpacing={isDesktop() ? 2 : 0}>
            <Grid item xs={12} lg={6}>
              <TextField
                label="PF ou PJ"
                name="personType"
                onChange={onChangePersonType}
                value={form.personType}
                select
                required
              >
                <MenuItem value="pf">Pessoa física (PF)</MenuItem>
                <MenuItem value="pj">Pessoa jurídica (PJ)</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} lg={6}>
              <ReactInputMask
                mask={handleDocumentMask()}
                maskChar="_"
                value={form.document}
                onChange={onChangeSearchSupplier}
                onBlur={() => validateInputDocument(form.document, setErrorsValidation)}
                disabled={loading || !form.personType}
              >
                {() => (
                  <TextField
                    required
                    label="Documento"
                    name="document"
                    value={form.document}
                    error={errorsValidation.document}
                    helperText={errorsValidation.document && 'O documento está inválido'}
                    disabled={loading || !form.personType}
                  />
                )}
              </ReactInputMask>
            </Grid>
          </Area>
        </BorderWithText>
      </Grid>
      <Grid item xs={12}>
        <BorderWithText title="Informações do fornecedor" xs={12}>
          <Area container columnSpacing={isDesktop() && 2}>
            <Grid item xs={12} lg={4}>
              <TextFieldNextFocus
                label="Nome"
                name="name"
                value={form.name}
                disabled
                onChange={onChange}
                onBlur={() => validateMandatoryInput('name', form, setErrorsValidation)}
                error={errorsValidation.name}
                helperText={errorsValidation.name && 'O nome é obrigatório'}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <AutocompleteSearch
                disabled
                label="Setor de atuação"
                name="uuid_industry"
                value={form.uuid_industry}
                onChange={onChange}
                optionLabel={['title']}
                getListDataCallback={getIndustries}
                formatLabel={(label) => label?.toTitleCase?.()}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                select
                disabled
                label="Faz parte das Empresas e Instituições da Graça?"
                name="is_intern"
                value={form.is_intern}
                onChange={onChange}
              >
                <MenuItem value={true}>Sim</MenuItem>
                <MenuItem value={false}>Não</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} lg={6} style={{ padding: 0 }}>
              <TextFieldNextFocus
                label="Sigla"
                name="acronym"
                disabled
                value={form.acronym}
                onChange={onChange}
                onBlur={() => validateMandatoryInput('acronym', form, setErrorsValidation)}
                inputProps={{ maxLength: COMPANY_ACRONYM_LENGTH }}
                error={errorsValidation.acronym}
                helperText={
                  errorsValidation.acronym || `Máximo de ${COMPANY_ACRONYM_LENGTH} caracteres`
                }
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextFieldNextFocus
                disabled
                label="Domínio"
                name="domain"
                value={form.domain}
                onChange={onChange}
              />
            </Grid>
          </Area>
        </BorderWithText>
      </Grid>

      <Grid item className="buttonGroup twoButtons nearTheTop">
        <Button onClick={backStep} variant="outlined">
          Voltar
        </Button>
        <Button onClick={nextStep} disabled={disabledButton()}>
          Próximo
        </Button>
      </Grid>
    </Grid>
  );
}
