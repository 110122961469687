import dayjs from 'dayjs';
import {
  MobileTableConteiner,
  PaginationMobile,
  SkeletonMobile,
  TableMobileCell,
  TableMobileContent,
  TableMobileItem,
  TableMobileTitle,
  UserInformations
} from '../../../../../../components';
import { convertCentsToWhole } from '../../../../../../utils/convert';

export function MobileTable({ historics, count, loading, page, offset, handlePage }) {
  const coinsList = historics?.map((historic) => (
    <TableMobileCell key={historic.uuid}>
      <TableMobileItem direction="column">
        <TableMobileTitle direction="column">Usuário que adicionou</TableMobileTitle>
        <TableMobileContent>
          <UserInformations user={historic.user} />
        </TableMobileContent>
      </TableMobileItem>

      <TableMobileItem>
        <TableMobileTitle>Data</TableMobileTitle>
        <TableMobileContent>
          {(historic.date && dayjs(historic.date, 'YYYY-MM-DD').format('DD/MM/YYYY')) || '-'}
        </TableMobileContent>
      </TableMobileItem>

      <TableMobileItem>
        <TableMobileTitle>Câmbio em real</TableMobileTitle>
        <TableMobileContent>
          {historic.exchangeInReal && convertCentsToWhole(historic.exchangeInReal, 'REAL')}
        </TableMobileContent>
      </TableMobileItem>

      <TableMobileItem>
        <TableMobileTitle>Origem</TableMobileTitle>
        <TableMobileContent>{historic.origin?.toTitleCase()}</TableMobileContent>
      </TableMobileItem>

      <TableMobileItem>
        <TableMobileTitle>Tipo</TableMobileTitle>
        <TableMobileContent>{historic.type?.toTitleCase()}</TableMobileContent>
      </TableMobileItem>
    </TableMobileCell>
  ));

  return (
    <MobileTableConteiner>
      {!loading ? coinsList : <SkeletonMobile numbersOfColumns={10} />}
      <PaginationMobile count={count} page={page} rowsPerPage={offset} handlePage={handlePage} />
    </MobileTableConteiner>
  );
}
