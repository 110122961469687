import { Divider, Link } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Container } from './styled';

export function TopBackArea({ onBack, rightArea }) {
  return (
    <>
      <Container>
        <Link variant="text" onClick={onBack}>
          <ArrowBackIosIcon />
          <span> Voltar</span>
        </Link>
        {rightArea}
      </Container>
      <Divider />
    </>
  );
}
