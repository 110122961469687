import { getMimeTypeFromBase64 } from '.';
import { monthsOfTheYear } from '../utils/monthsOfTheYear';
import { CoinName } from './enums';

/**
 * Os valores de dinheiro do banco vem em moeda
 * A função converte e retorna formatada conforme sua moeda
 * @param {*} value
 * @param {*} coin
 * @returns
 */
export const convertCentsToWhole = (value, coin = 'real') => {
  const money = parseInt(value);
  if (!money || isNaN(value)) {
    return '';
  }

  if (!coin) {
    coin = CoinName.REAL;
  }

  const currency = {
    ['DÓLAR']: 'USD',
    ['REAL']: 'BRL',
    ['EURO']: 'EUR'
  };

  const valueConerting = money / 100;
  return valueConerting.toLocaleString('pt-BR', {
    style: 'currency',
    currency: currency[coin.toUpperCase().toUpperCase()] || 'BRL'
  });
};

/**
 * Número do mês no seu texto
 * @param {*} month - número do mês
 * @returns
 */
export const convertReadjustmentMonth = (month) => {
  if (!month) {
    return null;
  }
  return monthsOfTheYear[month - 1].text;
};

/**
 * Converte a periodicidade de vencimento do contrato em mensal, trimestral, semestral ou anual
 * @param {*} frequency - numero de dias
 * @returns
 */
export const convertReadjustmentPeriodicity = (frequency) => {
  if (!frequency) {
    return 0;
  }

  if (frequency === 1) {
    return 'Mensal';
  }
  if (frequency === 3) {
    return 'Trimestral';
  }
  if (frequency === 6) {
    return 'Semestral';
  }
  if (frequency === 12) {
    return 'Anual';
  }

  return `A cada ${frequency} meses`;
};

/**
 * converte o valor de uma moeda para outro
 * @param {*} value
 * @param {*} valueNewCoin
 * @returns
 */
export const convertNewValueCoin = (value, valueNewCoin) => {
  if (!value || !valueNewCoin) {
    return null;
  }

  const result = (parseFloat(value) * parseFloat(valueNewCoin)) / 100;
  if (!result || isNaN(result)) {
    return null;
  }

  return result;
};

/**
 * Converte uma variável blob para base64
 * @param {*} blob
 */
export const convertBlobToBase64 = (blob) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

// Função para converter Base64 para um arquivo binário
export const convertBase64ToBlob = (base64String) => {
  const mimeType = getMimeTypeFromBase64(base64String);

  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeType });
};

// Função para converter Base64 para um arquivo binário
export const base64ToBlob = (base64String) => {
  const mimeType = getMimeTypeFromBase64(base64String);

  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeType });
};
