import styled from 'styled-components';
import { breakpoints } from '../../theme/breakpoints';
import { colors, metrics } from '../../theme/styles';

export const Container = styled.div``;

export const Content = styled.div`
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.md}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  #title {
    color: ${colors.theme.primary};
    font-size: ${metrics.size.medium};
  }

  #description {
    font-size: 15pt;
  }

  #button-content {
    width: 100%;
    margin-top: 1.5rem;

    @media (min-width: ${breakpoints.md}) {
      width: 16rem;
      margin-top: 0;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    span {
      padding-left: 0.5rem;
      padding-top: 4px;
    }
  }
`;
