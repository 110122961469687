import { api } from '../utils/axiosConfig';

/**
 * Lista os termos e diretrizes não aceitos pelo usuário
 * @returns
 */
export const listNotAcceptTerms = async () => {
  try {
    const { data } = await api.get('/terms/list-not-accept-terms');
    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Lista os termos e diretrizes aceitos pelo usuário
 * @returns
 */
export const listAcceptTerms = async () => {
  try {
    const { data } = await api.get('/terms/list-accept-terms');
    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Busca um termo pelo uuid
 * @param {*} uuidTerm
 * @returns
 */
export const getTerm = async (uuidTerm) => {
  try {
    const { data } = await api.get(`/terms/${uuidTerm}`);
    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Aceita um termo ou diretriz
 * @param {*} uuidTerm
 * @returns
 */
export const optin = async (uuidTerm) => {
  try {
    const { data } = await api.patch(`/terms/opt-in/${uuidTerm}`);
    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};
