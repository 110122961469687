import { ContainerArea } from './styled';

export function BorderWithText({ children, title, ...props }) {
  return (
    <ContainerArea {...props}>
      <p className="title">{title}</p>
      {children}
    </ContainerArea>
  );
}
