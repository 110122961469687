import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import { Form } from './styled';
import { BasePage } from '../../../../../components/BaseMain';
import { goToAdmin, goToBack, goToIndustryList } from '../../../../../router/coordinator';
import {
  AlertMessage,
  Loading,
  Modal,
  TextFieldNextFocus,
  TitlePages
} from '../../../../../components';
import { clearAlertMessage, isDesktop } from '../../../../../utils';
import { useForm } from '../../../../../hooks/useForm';
import { createSegment } from '../../../../../services';
import { GENERIC_ERROR } from '../../../../../utils/constantes';

export function CreateSegmentPage() {
  const navigate = useNavigate();
  const breadcrumbs = [
    { title: 'Administrativo', functionCoordinator: () => goToAdmin(navigate) },
    { title: 'Setores das empresas', functionCoordinator: () => goToIndustryList(navigate) },
    { title: 'Cadastrar' }
  ];

  const initialValueForm = {
    title: ''
  };
  const [form, onChange, clearForm] = useForm(initialValueForm);
  const [errorsValidation, setErrorsValidation] = useState({});
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  // --- Lógica do modal
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const onConfirmModal = async () => {
    await create();
  };
  // ---

  const create = async () => {
    try {
      setLoading(true);
      handleClose();
      clearForm();

      await createSegment(form.title);

      setAlertMessage((prev) => ({
        ...prev,
        title: 'Segmento criado com sucesso.',
        severity: 'success'
      }));

      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  };

  const disabledButton = () => {
    if (!form.title) {
      return true;
    }

    return false;
  };

  const validateMandatoryInput = (field) => {
    if (!form[field]) {
      setErrorsValidation((prevError) => ({ ...prevError, [field]: true }));
      return;
    }
    setErrorsValidation((prevError) => ({ ...prevError, [field]: false }));
  };

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages title={'Cadastrar novo segmento de de contrato'} />

      <Loading loading={loading} />

      <Grid container>
        <AlertMessage
          message={alertMessage.message}
          severity={alertMessage.severity}
          title={alertMessage.title}
          onClose={() => clearAlertMessage(setAlertMessage)}
        />
      </Grid>

      <Form container xs={12} rowGap={2} columnSpacing={isDesktop() && 2}>
        <Grid item xs={12} lg={3}>
          <TextFieldNextFocus
            label="Nome"
            name={'title'}
            required
            value={form.title}
            onChange={onChange}
            onBlur={() => validateMandatoryInput('title')}
            error={errorsValidation.title}
            helperText={errorsValidation.title && 'O nome é obrigatório'}
          />
        </Grid>
        <Grid item xs={12} className={'buttonGroup twoButtons'}>
          <Button variant="outlined" onClick={() => goToBack(navigate)}>
            Voltar
          </Button>
          <Button onClick={handleOpen} disabled={disabledButton()}>
            Cadastrar
          </Button>
        </Grid>
      </Form>

      <Modal
        open={openModal}
        onClose={handleClose}
        onConfirm={onConfirmModal}
        title={'Cadastrar segmento do contrato'}
        text={`Tem certeza que deseja criar o segmento ${form.title}? `}
        buttonConfirm={'Sim'}
        buttonCancel={'Não'}
      />
    </BasePage>
  );
}
