import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, MenuItem, TextField } from '@mui/material';
import { useDebounce } from 'use-debounce';
import { DesktopTable } from './TablesComponents/DesktopTable';
import { MobileTable } from './TablesComponents/MobileTable';
import { Table } from './styled';
import { BasePage } from '../../../components/BaseMain';
import { TitlePages } from '../../../components/TitlePages';
import { useForm } from '../../../hooks/useForm';
import { FilterContainer } from '../../../components/FilterContainer';
import { clearAlertMessage, isDesktop } from '../../../utils';
import { Order, Status } from '../../../utils/enums';
import { AlertMessage, EmptyTable, TargetStatus } from '../../../components';
import { listCoins, listFinancialContracts } from '../../../services';
import { GENERIC_ERROR } from '../../../utils/constantes';
import { monthsOfTheYear } from '../../../utils/monthsOfTheYear';
import { FilterButton } from '../../../components/FilterButton';
import { useUpdateState } from '../../../hooks/useUpdateState';
import AutocompleteSearch from '../../../components/AutocompleteSearch';

export function Financial() {
  const navigate = useNavigate();
  const breadcrumbs = [{ title: 'Contratos' }, { title: 'Financeiro' }];
  const [{ contracts, loading }, setStates] = useUpdateState({
    contracts: [],
    loading: true
  });
  const [{ count, page, offset, order, orderBy }, setPaginate] = useUpdateState({
    count: 0,
    page: 0,
    offset: 5,
    order: Order.DESC,
    orderBy: ''
  });
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });
  const [filters, onChange, clearForm] = useForm({
    id_contract: '',
    debit: '',
    credit: '',
    status: '',
    uuid_coin: '',
    total_installments: '',
    paid_installments: '',
    readjustment_month: ''
  });
  const [debouncedFilter] = useDebounce(filters, 500);

  const listContracts = useCallback(async () => {
    try {
      setStates({ loading: true });

      const filter = {
        ...debouncedFilter,
        status: filters?.status === Status.ALL ? undefined : filters?.status
      };
      const result = await listFinancialContracts(page, offset, order, filter);

      setStates({ contracts: result?.data });
      setPaginate({ count: result?.count });
    } catch (error) {
      setAlertMessage({
        title: error?.message || GENERIC_ERROR,
        message: error?.trace && `Código: ${error?.trace}`
      });
    } finally {
      setStates({ loading: false });
    }
  }, [page, offset, order, debouncedFilter]);

  useEffect(() => {
    listContracts();
  }, [listContracts]);

  const handlePage = (_event, newPage) => setPaginate({ page: newPage });
  const handleChangeRowsPerPage = (event) => {
    setPaginate({ offset: parseInt(event.target.value, 10), page: 0 });
  };
  const handleOrder = (field) => {
    setPaginate({ orderBy: field, order: order === Order.ASC ? Order.DESC : Order.ASC });
  };

  const getCoins = useCallback(async () => {
    try {
      const data = await listCoins();
      return data;
    } catch (error) {
      setAlertMessage({
        title: error?.message || GENERIC_ERROR,
        message: error?.trace && `Código: ${error?.trace}`
      });
      return [];
    }
  }, []);

  function TableResponsive() {
    if (!contracts?.length && !loading) {
      return <EmptyTable />;
    }

    if (isDesktop()) {
      return (
        <DesktopTable
          contracts={contracts}
          loading={loading}
          count={count}
          page={page}
          offset={offset}
          order={order}
          orderBy={orderBy}
          navigate={navigate}
          handlePage={handlePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleOrder={handleOrder}
        />
      );
    }

    return (
      <MobileTable
        contracts={contracts}
        loading={loading}
        count={count}
        page={page}
        offset={offset}
        navigate={navigate}
        handlePage={handlePage}
      />
    );
  }

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages
        title="Informações financeiras do contrato"
        description="Lista de todos os contratos da empresa disponíveis a você com as informações financeiras."
      />

      <Grid container>
        <AlertMessage
          message={alertMessage.message}
          severity={alertMessage.severity}
          title={alertMessage.title}
          onClose={() => clearAlertMessage(setAlertMessage)}
        />
      </Grid>

      <FilterContainer>
        <Grid item xs={12} lg={2}>
          <TextField
            label="Id contrato"
            name="id_contract"
            value={filters.id_contract}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <TextField label="Débito" name="debit" value={filters.debit} onChange={onChange} />
        </Grid>
        <Grid item xs={12} lg={2}>
          <TextField label="Crédito" name="credit" value={filters.credit} onChange={onChange} />
        </Grid>
        <Grid item xs={12} lg={2}>
          <TextField select label="Status" name="status" value={filters.status} onChange={onChange}>
            <MenuItem value={Status.ALL}>
              <TargetStatus status={Status.ALL} padding="0" />
            </MenuItem>
            <MenuItem value={Status.ACTIVE}>
              <TargetStatus status={Status.ACTIVE} padding="0" />
            </MenuItem>
            <MenuItem value={Status.INACTIVE}>
              <TargetStatus status={Status.INACTIVE} padding="0" />
            </MenuItem>
            <MenuItem value={Status.PENDING}>
              <TargetStatus status={Status.PENDING} padding="0" />
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} lg={2}>
          <AutocompleteSearch
            label="Moeda"
            name="uuid_coin"
            value={filters?.uuid_coin}
            onChange={onChange}
            optionLabel={['title']}
            getListDataCallback={getCoins}
            formatLabel={(label) => label?.toTitleCase?.()}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <TextField
            label="Parcelas totais"
            name="totalInstallments"
            value={filters.totalInstallments}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <TextField
            label="Parcelas pagas"
            name="paidInstallments"
            value={filters.paidInstallments}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <TextField
            select
            label="Mês de reajuste"
            name="readjustmentMonth"
            value={filters.readjustmentMonth}
            onChange={onChange}
          >
            {monthsOfTheYear.map((m) => (
              <MenuItem key={m.value} value={m.value}>
                {m.text}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} lg={1}>
          <FilterButton onClick={clearForm} />
        </Grid>
      </FilterContainer>

      <Table>
        <TableResponsive />
      </Table>
    </BasePage>
  );
}
