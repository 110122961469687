import { api } from '../utils/axiosConfig';

/**
 * Busca as informações do dashboard
 */
export const getDashboardInformations = async () => {
  try {
    const result = await api.get('/dashboard/informations');
    return result.data;
  } catch (error) {
    throw error?.response?.data;
  }
};
