import { Box, TextField, Button as ButtonMUI } from '@mui/material';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { metrics, colors } from '../../theme/styles';
import { breakpoints } from '../../theme/breakpoints';

export const Content = styled.main`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled(Box)`
  width: ${metrics.widthContentChooseCompany};
  text-align: center;
  padding: 1rem;
`;

export const Title = styled.h1`
  font-size: ${metrics.titleCredential};
  width: 100%;
  margin: 0;
  margin-bottom: 1rem;
  color: #fff;

  span {
    color: ${colors.theme.primary};
  }
`;

const StyledTextField = withStyles({
  root: {
    '&.MuiFormLabel-root': {
      color: 'white'
    },
    '& label': {
      color: 'white'
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'white'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: 'white'
    },
    '& .MuiSvgIcon-root': {
      color: 'white'
    },
    '& .MuiSelect-iconOpen': {
      color: colors.theme.primary
    },
    '& .MuiAutocomplete-input': {
      color: 'white'
    }
  }
})(TextField);

export const Input = styled(StyledTextField)`
  && {
    width: 95%;
    margin-top: 2rem;
  }
`;

export const Button = styled(ButtonMUI)`
  && {
    width: 95%;
    margin-top: 4rem;
  }
`;

export const Warning = styled.div`
  background-color: ${colors.theme.background};
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${colors.backgroundWhite};

  svg {
    width: 15rem;
    height: 15rem;
  }

  p {
    margin-top: 1rem;
    font-size: 2.5rem;
    padding: 0.5rem;

    @media (min-width: ${breakpoints.md}) {
      width: 40%;
    }
  }

  div {
    width: 90%;

    @media (min-width: ${breakpoints.md}) {
      width: 30%;
    }
  }
`;
