import { Grid } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../../../theme/styles';

export const Informations = styled(Grid)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;

  div {
    margin-top: 1.5rem;

    p:first-child {
      color: ${colors.theme.primary};
      font-weight: 600;
    }
  }
`;

export const Table = styled.div`
  margin-top: 3rem;
`;
