import styled from 'styled-components';
import { CustomFormControlLabel } from '../../../../components/CustomFormControlLabel';
import { breakpoints } from '../../../../theme/breakpoints';

export const Table = styled.div`
  margin-top: 3rem;
`;

export const ContainerCheckbox = styled(CustomFormControlLabel)`
  height: 100%;
  padding-left: 0.2rem;

  @media (min-width: ${breakpoints.md}) {
    padding-top: 0.5rem;
  }
`;
