import styled from 'styled-components';
import { colors } from '../../../../theme/styles';

export const ButtonGroup = styled.div`
  margin: 2rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
`;

export const Subtitle = styled.h2`
  color: ${colors?.theme?.primary};
  margin-top: 1rem;
`;
