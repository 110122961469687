import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { SkeletonDesktop, PaginationDesktop } from '../../../../../../components';
import { monthsOfTheYear } from '../../../../../../utils/monthsOfTheYear';
import { convertCentsToWhole } from '../../../../../../utils/convert';

export function DesktopTable({
  contracts,
  count,
  loading,
  page,
  offset,
  coin,
  handlePage,
  handleChangeRowsPerPage
}) {
  function ContractsList() {
    return contracts?.map((contract) => (
      <TableRow key={contract?.uuid} className="not-effect">
        <TableCell>
          {monthsOfTheYear?.find((month) => month.value === contract?.month)?.text || '-'}
        </TableCell>
        <TableCell>{contract?.year || '-'}</TableCell>
        <TableCell>{convertCentsToWhole(contract?.amount, coin) || '-'}</TableCell>
      </TableRow>
    ));
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Mês</TableCell>
              <TableCell>Ano</TableCell>
              <TableCell>Valor</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? <SkeletonDesktop numbersOfColumns={3} /> : <ContractsList />}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationDesktop
        count={count}
        page={page}
        rowsPerPage={offset}
        handlePage={handlePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}
