import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import { BasePage } from '../../../../components/BaseMain';
import { Modal } from '../../../../components/Modal';
import { TextFieldNextFocus } from '../../../../components/TextFieldNextFocus';
import { TitlePages } from '../../../../components/TitlePages';
import { UploadButton } from '../../../../components/UploadButton';
import { isDesktop } from '../../../../utils';
import { Content, ContainerImage, Image, Button } from './styled';
import { validateEmail } from '../../../../utils/validates';
import { editUser, getUserLoggedDetails } from '../../../../services';
import { GENERIC_ERROR, PHONE_8_NUMBERS_MASK } from '../../../../utils/constantes';
import { AlertMessage, Loading } from '../../../../components';
import { convertBlobToBase64 } from '../../../../utils/convert';
import { useForm } from '../../../../hooks/useForm';
import { ReactInputMaskPhoneNumber } from '../../../../components/ReactInputMaskPhoneNumber';

export function EditAccount() {
  const navigate = useNavigate();
  const breadcrumbs = [{ title: 'Menu' }, { title: 'Editar conta' }];

  const initialValue = {
    name: '',
    lastName: '',
    email: '',
    cellphone: '',
    tellphone: ''
  };
  const [user, onChange, _, setUser] = useForm(initialValue);
  const [errorsValidation, setErrorsValidation] = useState({ email: false });
  const [image, setImage] = useState(null);
  const [outImageName, setOutImageName] = useState(null);
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  useEffect(async () => await init(), []);

  const init = async () => {
    try {
      setLoading(true);

      const result = await getUserLoggedDetails();
      setUser(result);

      if (result.profileImage) {
        setImage(result.profileImage.imageBase64);
      }

      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error?.message || GENERIC_ERROR });
      setLoading(false);
    }
  };

  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const clearAlertMessage = () =>
    setAlertMessage({
      title: '',
      message: '',
      severity: 'error'
    });

  const onConfirmModal = async () => {
    try {
      setLoading(true);
      handleClose();
      clearAlertMessage();

      const compressedImage = await compressImage(image);

      const { name, lastName, email, cellphone, tellphone } = user;
      await editUser(user.uuid, name, lastName, email, cellphone, tellphone, compressedImage);

      setAlertMessage((prev) => ({
        ...prev,
        title: 'Usuário alterado com sucesso.',
        severity: 'success'
      }));

      setLoading(false);
    } catch (err) {
      setAlertMessage((...prev) => ({ ...prev, title: err?.message || GENERIC_ERROR }));
      setLoading(false);
    }
  };

  const compressImage = async () => {
    if (!image) {
      return image;
    }

    const imageBlob = await fetch(image).then((res) => res.blob());

    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 600,
      useWebWorker: true
    };
    const compressedFile = await imageCompression(imageBlob, options);
    const imageBase64 = await convertBlobToBase64(compressedFile);

    return imageBase64;
  };

  const validateInputEmail = () => {
    const { email } = user;
    const isValid = validateEmail(email);
    setErrorsValidation((prevError) => ({ ...prevError, email: !isValid }));
  };

  const disabledButton = () => {
    if (!user.name) {
      return true;
    }
    if (!user.lastName) {
      return true;
    }
    if (!user.email) {
      return true;
    }

    return false;
  };

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages title={'Editar conta'} />

      <Loading loading={loading} />
      <Grid container>
        <AlertMessage
          title={alertMessage?.title}
          message={alertMessage?.message}
          severity={alertMessage?.severity}
          onClose={() => clearAlertMessage(setAlertMessage)}
        />
      </Grid>

      <Content>
        <ContainerImage>
          <Image alt={`foto de perfil do ${user.name}`} src={image} />
          <UploadButton
            setImage={setImage}
            outImageName={outImageName}
            setOutImageName={setOutImageName}
          />
        </ContainerImage>
        <Grid container rowGap={2} columnSpacing={isDesktop() && 2}>
          <Grid item xs={12} lg={6}>
            <TextFieldNextFocus
              label="Nome"
              name={'name'}
              value={user.name?.toTitleCase()}
              onChange={onChange}
              required
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextFieldNextFocus
              label="Sobrenome"
              name={'lastName'}
              value={user.lastName?.toTitleCase()}
              onChange={onChange}
              required
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextFieldNextFocus
              label="Email corporativo"
              name={'email'}
              value={user.email}
              onChange={onChange}
              onBlur={() => validateInputEmail()}
              error={errorsValidation.email}
              helperText={errorsValidation.email && 'E-mail inválido'}
              required
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <ReactInputMaskPhoneNumber
              label="Celular"
              name="cellphone"
              value={user?.cellphone}
              maskChar="_"
              onChange={onChange}
              changeMask
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <ReactInputMaskPhoneNumber
              label="Telefone"
              name="tellphone"
              value={user.tellphone}
              maskChar="_"
              onChange={onChange}
              initialMask={PHONE_8_NUMBERS_MASK}
            />
          </Grid>
        </Grid>
        <div className="buttonGroup oneButton">
          <Button type="submit" disabled={disabledButton()} onClick={handleOpen}>
            Editar
          </Button>
        </div>
      </Content>
      <Modal
        open={openModal}
        onClose={handleClose}
        onConfirm={onConfirmModal}
        title={'Editar conta'}
        text={'Tem certeza que deseja editar sua conta?'}
        buttonConfirm={'Sim'}
        buttonCancel={'Não'}
      />
    </BasePage>
  );
}
