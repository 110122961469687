import styled from 'styled-components';
import { colors } from '../../theme/styles';
import { breakpoints } from '../../theme/breakpoints';

export const Container = styled.div`
  background-color: ${colors.gray}24;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 3rem;

  svg {
    width: 80%;
    height: 80%;

    @media (min-width: ${breakpoints.md}) {
      width: 20%;
      height: 20%;
    }
  }

  p {
    padding-top: 1rem;
    font-size: 30pt;
    font-weight: 300;
    color: ${colors.gray};

    @media (min-width: ${breakpoints.md}) {
      font-size: 40pt;
    }
  }
`;
