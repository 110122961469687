import { Typography, colors as colorsMui } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../theme/styles';

export const Text = styled(Typography).attrs({
  variant: 'subtitle2'
})`
  display: block;
  width: 100%;
  padding-top: 2rem;
  color: ${colors?.theme?.primary || colorsMui?.grey[500]};
  font-weight: normal;
`;
