import { onlyNumbers } from '.';
import { CPF, CNPJ } from 'cpf_cnpj';

export const validateEmail = (email) => {
  const regex =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi;
  return regex.test(email);
};

/**
 * A senha deve conter:
 * - Ao menos uma letra minúscula.
 * - Ao menos uma letra maiúscula
 * - Ao menos um caractere especial
 * - Ao menos um número
 */
export const validatePassword = (password) => {
  const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).{8,}$/;

  return regex.test(password);
};

export const validateDocument = (document) => {
  document = onlyNumbers(document);

  if (document.length === 11) {
    return CPF.isValid(document);
  }
  return CNPJ.isValid(document);
};

export const validateCellphone = (cellphone) => {
  cellphone = onlyNumbers(cellphone);
  if (!cellphone) {
    return false;
  }

  return cellphone.length === 11;
};

export const validateTellphone = (tellphone) => {
  tellphone = onlyNumbers(tellphone);
  if (!tellphone) {
    return false;
  }

  return tellphone.length === 10;
};

export const validateMandatoryInput = (field, form, setErrorsValidation) => {
  if (!form[field]) {
    setErrorsValidation((prevError) => ({ ...prevError, [field]: 'Este campo é obrigatório' }));
    return;
  }

  setErrorsValidation((prevError) => ({ ...prevError, [field]: '' }));
};

export const validateInputTellphone = (tellphone, setErrorsValidation) => {
  let isValid = true;

  if (onlyNumbers(tellphone)) {
    isValid = validateTellphone(tellphone);
  }

  setErrorsValidation((prevError) => ({ ...prevError, tellphone: !isValid }));
};

export const validateInputDocument = (document, setErrorsValidation) => {
  const clearDocument = onlyNumbers(document);

  if (clearDocument && !validateDocument(clearDocument)) {
    setErrorsValidation((prevError) => ({ ...prevError, document: 'Documento inválido' }));
    return;
  }

  setErrorsValidation((prevError) => ({ ...prevError, document: '' }));
};
