import { api } from '../utils/axiosConfig';

/**
 * Lista as empresas que o usuário logado tem acesso
 */
export const listAccessCompanies = async (initialPage, offset, order = 'ASC', filter = {}) => {
  try {
    const page = initialPage + 1;
    const queryParams = {
      initial_page: page,
      offset,
      order
    };

    if (filter.industry !== null) {
      queryParams.uuid_industry = filter.industry;
    }

    if (filter.document !== null) {
      queryParams.document = filter.document;
    }

    if (filter.name !== null) {
      queryParams.name = filter.name;
    }

    const { data } = await api.get('/company/list', {
      params: queryParams
    });

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Lista as empresas que um usuário tem acesso sem paginação
 * @param uuidUser - identificador único do usuário
 */
export const listAccessUserCompanies = async (uuidUser) => {
  try {
    const { data } = await api.get(`/company/list-access/${uuidUser}`);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Lista todas as empresas
 */
export const listAllCompanies = async () => {
  try {
    const { data } = await api.get('/company/list-all');

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Lista todos os fornecedores
 * @param {*} initialPage - Página de inicio
 * @param {*} offset - Itens por página
 * @param {*} order - ordem [asc, desc]
 * @param {*} filter - filtros
 * @returns
 */
export const listAllProviders = async (initialPage, offset, order = 'ASC', filter = {}) => {
  try {
    const page = initialPage + 1;
    const { data } = await api.get(`/company/list-providers`, {
      params: {
        initial_page: page,
        offset,
        order,
        ...filter
      }
    });

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Busca uma empresa pelo documento
 * @returns
 */
export const searchCompany = async (document) => {
  try {
    const { data } = await api.get(`/company/search/${document}`);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Busca uma empresa pelo UUID
 * @param {*} companyUUID - indentificar único da empresa
 * @returns
 */
export const getCompanyByUUID = async (companyUUID) => {
  try {
    const { data } = await api.get(`/company/${companyUUID}`);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Cria uma empresa
 * @param {*} document - cnpj da empresa
 * @param {*} name - nome da empresa
 * @param {*} acronym - sigla de 4 caracteres
 * @param {*} uuidIndustry - setor de atuação
 * @param {*} isIntern - se pertence ao grupo graça
 * @param {*} domain - domínio da empresa para validação de email
 * @returns
 */
export const createCompany = async (document, name, acronym, uuidIndustry, isIntern, domain) => {
  try {
    const payload = {
      document,
      name,
      acronym,
      uuid_industry: uuidIndustry,
      is_intern: isIntern,
      domain
    };
    const { data } = await api.post(`/company/create`, payload);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Edita uma empresa
 * @param {*} uuidCompany
 * @param {*} name
 * @param {*} uuidIndustry
 * @param {*} domain
 * @param {*} isIntern
 * @param {*} document
 * @returns
 */
export const editCompany = async (uuidCompany, name, uuidIndustry, domain, isIntern, document) => {
  try {
    const payload = {
      name,
      uuid_industry: uuidIndustry,
      is_intern: isIntern,
      domain,
      document
    };

    const { data } = await api.put(`/company/${uuidCompany}`, payload);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Ativa ou inativa a empresa dependendo do seu estado atual
 * @param {*} uuidCompany
 * @returns
 */
export const activeOrInactiveCompany = async (uuidCompany) => {
  try {
    const { data } = await api.patch(`/company/active-inactive/${uuidCompany}`);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};
