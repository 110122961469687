import { api } from '../utils/axiosConfig';

/**
 * Lista todos os cargos
 */
export const listOffices = async () => {
  try {
    const { data } = await api.get('/utils/office/list-all');

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Lista todos os setores
 */
export const listSectors = async () => {
  try {
    const { data } = await api.get('/utils/sector/list-all');

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Lista todas as empresas
 */
export const listCompanies = async (isIntern) => {
  try {
    const { data } = await api.get('/utils/company/list-all', { params: { is_intern: isIntern } });

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Lista todas as empresas que o usuário tem acesso
 */
export const listAccessCompaniesNoPaginate = async () => {
  try {
    const { data } = await api.get('/utils/company-access/list-all');

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Lista todas os segmentos
 */
export const listSegments = async () => {
  try {
    const { data } = await api.get('/utils/segments/list-all');

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Lista todas os indices
 */
export const listIndexes = async () => {
  try {
    const { data } = await api.get('/utils/index/list-all');

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Lista todas as moedas
 */
export const listCoins = async () => {
  try {
    const { data } = await api.get('/utils/coins/list-all');

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Lista todas os setores de atuação da empresa
 */
export const listIndustries = async () => {
  try {
    const { data } = await api.get('/utils/industries/list-all');

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

export const listStates = async () => {
  try {
    const { data } = await api.get('/utils/states/list-all');

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

export const listCities = async (uuidState) => {
  try {
    const { data } = await api.get(`/utils/cities/list-by-state/${uuidState}`);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};
