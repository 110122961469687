import { useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Grid, InputAdornment, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useForm } from '../../../../../hooks/useForm';
import {
  clearAlertMessage,
  isDesktop,
  onChangeDate,
  onChangeMoney,
  toCent
} from '../../../../../utils';
import { AlertMessage, Loading, Modal, TitlePages } from '../../../../../components';
import { BasePage } from '../../../../../components/BaseMain';
import {
  goToBack,
  goToContractAllValues,
  goToContractValuesVariables
} from '../../../../../router/coordinator';
import { addValueContract, findContract } from '../../../../../services/contractService';
import { GENERIC_ERROR } from '../../../../../utils/constantes';
import { Form } from './styled';

export function AddValueContract() {
  const navigate = useNavigate();

  const { uuid } = useParams();
  if (!uuid) {
    goToBack(navigate);
  }

  const breadcrumbs = [
    {
      title: 'Contratos com valores variáveis',
      functionCoordinator: () => goToContractValuesVariables(navigate)
    },
    {
      title: 'Todos os valores registrados',
      functionCoordinator: () => goToContractAllValues(navigate, uuid)
    },
    { title: 'Adicionar valor' }
  ];

  const [contract, setContract] = useState({});
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  const [form, onChange, clearForm] = useForm({
    month: null,
    year: null,
    amount: ''
  });
  const [dateFormat, setDateFormat] = useState('');

  const handleOpen = () => {
    const month = dayjs(form?.month).get('month');
    const year = dayjs(form?.year).get('year');

    setDateFormat(`${String(month)?.padStart(2, '0')}/${year}`);
    setOpenModal(true);
  };
  const handleClose = () => setOpenModal(false);
  const onConfirmModal = async () => {
    try {
      setLoading(true);

      const month = dayjs(form?.month).get('month') + 1;
      const year = dayjs(form?.year).get('year');
      const amount = toCent(form?.amount);

      await addValueContract(uuid, month, year, amount);

      handleClose();
      clearForm();
      setAlertMessage({
        title: 'Valor adicionado com sucesso ao contrato!',
        severity: 'success'
      });
      setLoading(false);
    } catch (error) {
      handleClose();
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  };

  const getContract = useCallback(async () => {
    try {
      setLoading(true);

      const result = await findContract(uuid);

      setContract(result);
      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error?.message || GENERIC_ERROR });
      setLoading(false);
    }
  }, []);

  useEffect(() => getContract(), []);

  const disabledButton = () => {
    const fields = Object.keys(form);
    return !fields?.every((field) => form[field] || form[field] === 0);
  };

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages title={`Adicionar valor ao contrato ${contract?.id}`} />
      <Loading loading={loading} />

      <Grid container>
        <AlertMessage
          message={alertMessage.message}
          severity={alertMessage.severity}
          title={alertMessage.title}
          onClose={() => clearAlertMessage(setAlertMessage)}
        />
      </Grid>

      <Form container xs={12} rowGap={2} columnSpacing={isDesktop() && 2}>
        <Grid item xs={12} lg={3}>
          <DatePicker
            disableFuture
            label="Mês"
            name="month"
            format="MM"
            views={['month']}
            value={form?.month}
            onChange={(e) => onChangeDate(e, 'month', onChange)}
            renderInput={(params) => <TextField {...params} helperText={null} />}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <DatePicker
            disableFuture
            label="Ano"
            name="year"
            format="YYYY"
            views={['year']}
            value={form?.year}
            onChange={(e) => onChangeDate(e, 'year', onChange)}
            renderInput={(params) => <TextField {...params} helperText={null} />}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <TextField
            label="Valor do contrato"
            value={form?.amount}
            name="amount"
            onChange={(e) => onChangeMoney(e, onChange)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {contract?.financialContract?.coin?.acronym}
                </InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid item xs={12} className={'buttonGroup twoButtons'}>
          <Button variant="outlined" onClick={() => goToBack(navigate)}>
            Voltar
          </Button>
          <Button onClick={handleOpen} disabled={disabledButton()}>
            Cadastrar
          </Button>
        </Grid>
      </Form>

      <Modal
        open={openModal}
        onClose={handleClose}
        onConfirm={onConfirmModal}
        title="Cadastrar Equipamento"
        text={`Tem certeza que deseja adicionar o valor de ${contract?.financialContract?.coin?.acronym}${form?.amount} para a data ${dateFormat} no contrato ${contract?.id}? `}
      />
    </BasePage>
  );
}
