import styled, { keyframes } from 'styled-components';

const wave = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
`;

export const Content = styled.div`
  text-align: left;
  width: 100%;
  margin: 1.5rem 0;
`;

export const Title = styled.p`
  font-weight: 600;
  margin: 0;
  margin-bottom: 0.5rem;
`;

export const Item = styled.p`
  margin: 0;
  display: flex;
  align-items: flex-start;

  svg {
    width: 1rem;
    animation: ${wave} 1s;
  }

  span {
    margin-left: 0.5rem;
  }
`;
