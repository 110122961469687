import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  text-align: left;

  div:last-child {
    margin-left: 0.5rem;

    strong {
      font-size: 1.1rem;
    }
  }
`;
