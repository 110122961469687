import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material';
import { PaginationDesktop, SkeletonDesktop, TargetStatus } from '../../../../../../../components';
import { goToDatailsContract } from '../../../../../../../router/coordinator';
import { Order } from '../../../../../../../utils/enums';

export function DesktopTable({
  contracts,
  navigate,
  count,
  loading,
  page,
  offset,
  order,
  orderBy,
  handlePage,
  handleChangeRowsPerPage,
  handleOrder
}) {
  const contractsList = contracts?.map((contract) => (
    <TableRow key={contract.uuid} onClick={() => goToDatailsContract(navigate, contract.uuid)}>
      <TableCell>{contract.id}</TableCell>
      <TableCell>
        <TargetStatus status={contract.status} />
      </TableCell>
      <TableCell>{contract.segments?.title?.toTitleCase()}</TableCell>
      <TableCell>{contract.provider?.name?.toTitleCase()}</TableCell>
      <TableCell>{contract.initDate?.toMask('date')}</TableCell>
      <TableCell>{contract.expirationDate && contract.expirationDate.toMask('date')}</TableCell>
    </TableRow>
  ));

  function RenderTable() {
    if (loading) {
      return <SkeletonDesktop numbersOfColumns={6} />;
    }

    return contractsList;
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Segmento</TableCell>
              <TableCell>Fornecedor</TableCell>
              <TableCell>
                <TableSortLabel
                  direction={order}
                  active={order === Order.ASC && orderBy === 'initDate'}
                  onClick={() => handleOrder('initDate')}
                >
                  Data de início
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  direction={order}
                  active={order === Order.ASC && orderBy === 'expirationDate'}
                  onClick={() => handleOrder('expirationDate')}
                >
                  Data de expiração
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <RenderTable />
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationDesktop
        count={count}
        page={page}
        rowsPerPage={offset}
        handlePage={handlePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}
