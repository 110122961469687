import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import {
  HiddenForPermission,
  PaginationDesktop,
  SkeletonDesktop
} from '../../../../../../components';
import { goToEditSegment } from '../../../../../../router/coordinator';

export function DesktopTable({
  segments,
  count,
  loading,
  page,
  offset,
  handlePage,
  handleChangeRowsPerPage,
  navigate
}) {
  const segmentsList = segments.map((segment) => {
    return (
      <TableRow key={segment.uuid} className="not-effect">
        <TableCell>{segment.title?.toTitleCase()}</TableCell>
        <HiddenForPermission keys="EDIT_SEGMENT">
          <TableCell style={{ textAlign: 'right' }}>
            <IconButton onClick={() => goToEditSegment(navigate, segment.uuid)}>
              <CreateIcon />
            </IconButton>
          </TableCell>
        </HiddenForPermission>
      </TableRow>
    );
  });

  function RenderTable() {
    if (loading) {
      return <SkeletonDesktop numbersOfColumns={2} />;
    }

    return segmentsList;
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell style={{ textAlign: 'right' }}>Editar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <RenderTable />
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationDesktop
        count={count}
        page={page}
        rowsPerPage={offset}
        handlePage={handlePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}
