import dayjs from 'dayjs';

// Cria métodos nativos nos tipos primitivos

// Máscara para visualização
String.prototype.toMask = function (type, formatDate = 'DD/MM/YYYY') {
  if (!this) {
    return this;
  }

  switch (type) {
    case 'cellphone':
      return this.replace(/\D/g, '').replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');

    case 'tellphone':
      return this.replace(/\D/g, '').replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');

    case 'date':
      return dayjs(this).format(formatDate);

    case 'debit-credit':
      return this.replace(/\D/g, '').replace(
        /(\d{1})(\d{1})(\d{1})(\d{2})(\d{4})/,
        '$1.$2.$3.$4.$5'
      );

    case 'document':
      if (this.length === 11) {
        return this.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      }

      return this.replace(/\D/g, '').replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5'
      );

    default:
      return this;
  }
};

// Deixa a primeira letra maiuscula e as outras minúsculas
String.prototype.toTitleCase = function () {
  if (!this) {
    return this;
  }

  return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
};

// Inverte uma string
String.prototype.reverse = function () {
  return this.split('').reverse().join('');
};

// Deixa a primeira letra de todas as palavras de uma frase
String.prototype.toCapitalizeCase = function () {
  const text = this.toLowerCase().split(' ');

  for (let i = 0; i < text.length; i++) {
    text[i] =
      (text[i][0]?.toUpperCase() ? text[i][0]?.toUpperCase() : '') +
      (text[i].substring(1) ? text[i].substring(1) : '');
  }

  return text.join(' ');
};
