import { isDesktop } from '../../utils';
import { Breadcrumbs } from '../Breadcrumbs';
import { Header } from '../Header';
import { FixedMenu } from '../lateralMenu/FixedMenu';
import { Container, Content } from './styled';

export function BasePage({ children, navigate, breadcrumbs }) {
  return (
    <Container>
      {isDesktop() && <FixedMenu navigate={navigate} />}
      <div id="content">
        <Header navigate={navigate} />
        {isDesktop() && <Breadcrumbs breadcrumbs={breadcrumbs} />}
        <Content>{children}</Content>
      </div>
    </Container>
  );
}
