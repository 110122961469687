import styled from 'styled-components';
import { colors, metrics } from '../../../theme/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Grid, Button as ButtonMUI } from '@mui/material';

export const Content = styled.div`
  text-align: center;
  width: 80%;
`;

export const Icon = styled(AccountCircleIcon)`
  height: ${metrics.sizeIconCredential};
  width: ${metrics.sizeIconCredential};
`;

export const Title = styled.h1`
  font-size: ${metrics.titleCredential};
  margin: 0;
`;

export const Subtitle = styled.h3`
  margin: 0;
  font-weight: ${metrics.weight.regular};
  color: ${colors.mediumBlack};
  font-size: ${metrics.size.small};
  align-items: center;
  justify-content: center;
  display: flex;

  p {
    margin: 0;
    width: 90%;
  }
`;

export const Form = styled(Grid)`
  width: 100%;
  padding-top: 2rem;
`;

export const ButtonGroup = styled.div`
  margin-top: 3rem;
  width: 100%;
`;

export const Button = styled(ButtonMUI)`
  && {
    width: 100%;
    margin-top: 0.5rem;
  }
`;
