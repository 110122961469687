import { isDesktop } from '../../utils';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Container, StyledButton } from './styled';

export function CopyText({ children, onCopy }) {
  const handleCopy = (text) => {
    if (text) {
      navigator?.clipboard?.writeText?.(text);
      onCopy?.();
    }
  };

  return (
    <Container onClick={() => handleCopy(children)}>
      <p>{children}</p>
      <abbr title="Copiar">
        <StyledButton variant="text">
          <ContentCopyIcon fontSize="small" />
          {!isDesktop() && <span>Copiar</span>}
        </StyledButton>
      </abbr>
    </Container>
  );
}
