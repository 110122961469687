import { useCallback, useEffect, useState } from 'react';
import { Button, Grid, MenuItem, TextField } from '@mui/material';
import ReactInputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';
import {
  AlertMessage,
  Loading,
  Modal,
  TextFieldNextFocus,
  TitlePages
} from '../../../../../components';
import { BasePage } from '../../../../../components/BaseMain';
import { Form } from './styled';
import { clearAlertMessage, isDesktop } from '../../../../../utils';
import { goToAdmin, goToBack } from '../../../../../router/coordinator';
import { useForm } from '../../../../../hooks/useForm';
import { validateInputDocument, validateMandatoryInput } from '../../../../../utils/validates';
import { createCompany, listIndustries } from '../../../../../services';
import { COMPANY_ACRONYM_LENGTH, GENERIC_ERROR } from '../../../../../utils/constantes';
import { InformativeText } from '../../../../../components/InformativeText';

export function CreateNewCompanyPage() {
  const navigate = useNavigate();

  const breadcrumbs = [
    { title: 'Administrativo', functionCoordinator: () => goToAdmin(navigate) },
    { title: 'Empresa', functionCoordinator: () => goToBack(navigate) },
    { title: 'Criar' }
  ];

  const initialValueForm = {
    document: '',
    name: '',
    acronym: '',
    uuid_industry: '',
    domain: '',
    is_intern: true,
    personType: ''
  };
  const [form, onChange, clearForm] = useForm(initialValueForm);
  const [errorsValidation, setErrorsValidation] = useState({});
  const [industries, setIndustries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  const init = useCallback(async () => {
    try {
      const industries = await listIndustries();
      setIndustries(industries);
    } catch (err) {
      setAlertMessage({
        title: err.message || GENERIC_ERROR,
        message: err.trace && `Código: ${err.trace}`
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  // --- Lógica do modal
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const onConfirmModal = async () => {
    try {
      setLoading(true);
      handleClose();

      const { document, name, acronym, uuid_industry: uuidIndustry, domain } = form;

      await createCompany(document, name, acronym, uuidIndustry, true, domain);

      clearForm();

      setAlertMessage((prev) => ({
        ...prev,
        title: 'Empresa criada com sucesso.',
        severity: 'success'
      }));
    } catch (err) {
      setAlertMessage({
        title: err.message || GENERIC_ERROR,
        message: err.trace && `Código: ${err.trace}`
      });
    } finally {
      setLoading(false);
    }
  };
  // ---

  const disabledButton = () => {
    if (!form.name) {
      return true;
    }
    if (!form.document || errorsValidation.document) {
      return true;
    }
    if (!form.domain) {
      return true;
    }
    if (!form.uuid_industry) {
      return true;
    }
    if (!form.acronym) {
      return true;
    }

    return false;
  };

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages
        title="Cadastrar empresa"
        description="Aqui você pode cadastrar empresas de acesso, ou seja, que pertençam ao grupo graça."
      />

      <Grid container>
        <AlertMessage
          title={alertMessage.title}
          message={alertMessage.message}
          severity={alertMessage.severity}
          onClose={() => clearAlertMessage(setAlertMessage)}
        />
      </Grid>

      <Loading loading={loading} />

      <Form container xs={12} rowGap={2} columnSpacing={isDesktop() && 2}>
        <Grid item xs={12} lg={4}>
          <TextFieldNextFocus
            label="Nome"
            name="name"
            required
            value={form.name}
            onChange={onChange}
            onBlur={() => validateMandatoryInput('name', form, setErrorsValidation)}
            error={errorsValidation.name}
            helperText={errorsValidation.name}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <ReactInputMask
            mask="99.999.999/9999-99"
            value={form.document}
            onChange={onChange}
            required
            maskChar="_"
            onBlur={() => validateInputDocument(form.document, setErrorsValidation)}
          >
            {() => (
              <TextFieldNextFocus
                label="Documento"
                required
                name="document"
                value={form.document}
                error={errorsValidation.document}
                helperText={errorsValidation.document}
              />
            )}
          </ReactInputMask>
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            label="Setor de atuação"
            name="uuid_industry"
            select
            required
            value={form.uuid_industry}
            onChange={onChange}
            onBlur={() => validateMandatoryInput('uuid_industry', form, setErrorsValidation)}
            error={errorsValidation.uuid_industry}
            helperText={errorsValidation.uuid_industry}
          >
            {industries.map((industry) => (
              <MenuItem key={industry.uuid} value={industry.uuid}>
                {industry.title?.toTitleCase()}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextFieldNextFocus
            label="Sigla"
            name="acronym"
            required={form.is_intern}
            disabled={!form.is_intern}
            value={form.acronym}
            onChange={onChange}
            inputProps={{ maxLength: COMPANY_ACRONYM_LENGTH }}
            onBlur={() => validateMandatoryInput('acronym', form, setErrorsValidation)}
            error={errorsValidation.acronym}
            helperText={
              errorsValidation.acronym || `Máximo de ${COMPANY_ACRONYM_LENGTH} caracteres`
            }
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextFieldNextFocus
            label="Domínio"
            name="domain"
            required={form.is_intern}
            disabled={!form.is_intern}
            value={form.domain}
            onChange={onChange}
            onBlur={() => validateMandatoryInput('domain', form, setErrorsValidation)}
            error={errorsValidation.domain}
            helperText={
              errorsValidation.domain || 'Colocar domínio completo, exemplo: @dominio.com.br'
            }
          />
        </Grid>

        <InformativeText />
        <Grid item xs={12} className="buttonGroup twoButtons nearTheTop">
          <Button variant="outlined" onClick={() => goToBack(navigate)}>
            Voltar
          </Button>
          <Button onClick={handleOpen} disabled={disabledButton()}>
            Criar
          </Button>
        </Grid>
      </Form>

      <Modal
        open={openModal}
        onClose={handleClose}
        onConfirm={onConfirmModal}
        title={'Criar nova empresa'}
        text={`Tem certeza que deseja criar a empresa ${form.name} de documento ${form.document}? `}
        buttonConfirm={'Sim'}
        buttonCancel={'Não'}
      />
    </BasePage>
  );
}
