import { api } from '../utils/axiosConfig';

/**
 * Busca um cargo por uuid
 */
export const findOffice = async (uuidOffice) => {
  try {
    const { data } = await api.get(`/office/${uuidOffice}/find`);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};
