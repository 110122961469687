import { Grid } from '@mui/material';
import styled from 'styled-components';

export const Content = styled(Grid)`
  margin: 2rem auto;
`;

export const ButtonContainer = styled(Grid)`
  margin-top: 1rem;

  button {
    width: 100%;
    margin-top: 0.8rem;
  }
`;
