import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Step, StepContent, StepLabel, Stepper } from '@mui/material';
import { Container, StepperContent } from './styled';
import { BasicInformations, Final, FinancialInformations, SupplierInformation } from './steppers';
import { goToListAllContracts } from '../../../../../router/coordinator';
import { BasePage } from '../../../../../components/BaseMain';
import { TitlePages, TopBackArea } from '../../../../../components';
import { isDesktop } from '../../../../../utils';

const INITAL_STEP = 0;
const FINAL_STEP = 3;

export function AddContract() {
  const navigate = useNavigate();
  const breadcrumbs = [
    { title: 'Contratos', functionCoordinator: () => goToListAllContracts(navigate) },
    { title: 'Cadastrar' }
  ];

  // -- Lógica do step
  const [activeStep, setActiveStep] = useState(0);
  const [stepRegisterContract, setStepRegisterContract] = useState(1);
  const [isRegisterContractSuccess, setIsRegisterContractSuccess] = useState(false);

  const onNextStep = () => {
    if (isRegisterContractSuccess) {
      setIsRegisterContractSuccess(false);
    }

    if (activeStep !== FINAL_STEP) {
      setActiveStep(activeStep + 1);
    }
  };

  const onBackStep = () => {
    if (activeStep !== INITAL_STEP) {
      setActiveStep(activeStep - 1);
    }
  };

  const onInitStep = () => {
    setIsRegisterContractSuccess(true);
    setActiveStep(INITAL_STEP);
  };

  const handleStep = () => {
    const steps = {
      0: <BasicInformations onNextStep={onNextStep} isSuccess={isRegisterContractSuccess} />,
      1: <FinancialInformations onNextStep={onNextStep} onBackStep={onBackStep} />,
      2: <SupplierInformation onNextStep={onNextStep} onBackStep={onBackStep} />,
      3: (
        <Final
          onBackStep={onBackStep}
          lastStep={stepRegisterContract}
          setLastStep={setStepRegisterContract}
          onInitStep={onInitStep}
        />
      )
    };

    return steps[activeStep];
  };
  // --

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TopBackArea onBack={() => goToListAllContracts(navigate)} />

      <Container>
        <TitlePages toHideDivider={true} title={'Cadastrar novo contrato'} />

        <Stepper
          id="stepper"
          activeStep={activeStep}
          alternativeLabel={isDesktop()}
          orientation={!isDesktop() ? 'vertical' : 'horizontal'}
        >
          <Step>
            <StepLabel>Informações básicas</StepLabel>
            {!isDesktop() && <StepContent>{handleStep()}</StepContent>}
          </Step>
          <Step>
            <StepLabel>Informações financeiras</StepLabel>
            {!isDesktop() && <StepContent>{handleStep()}</StepContent>}
          </Step>
          <Step>
            <StepLabel>Informações sobre o fornecedor</StepLabel>
            {!isDesktop() && <StepContent>{handleStep()}</StepContent>}
          </Step>
          <Step>
            <StepLabel>Envio de documento e validações finais</StepLabel>
            {!isDesktop() && <StepContent>{handleStep()}</StepContent>}
          </Step>
        </Stepper>

        <StepperContent>{isDesktop() && handleStep()}</StepperContent>
      </Container>
    </BasePage>
  );
}
