import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { DesktopTable } from './TablesComponents/DesktopTable';
import { BasePage } from '../../../../../components/BaseMain';
import { goToAdmin, goToCreateProvider } from '../../../../../router/coordinator';
import { useForm } from '../../../../../hooks/useForm';
import {
  AlertMessage,
  EmptyTable,
  FilterContainer,
  HiddenForPermission,
  TitlePages
} from '../../../../../components';
import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { hasPermission, isDesktop } from '../../../../../utils';
import { TableContainer } from '../../../../../components/TableContainer';
import { MobileTable } from './TablesComponents/MobileTable';
import { listAllProviders, listIndustries } from '../../../../../services';
import { GENERIC_ERROR } from '../../../../../utils/constantes';
import { Order } from '../../../../../utils/enums';
import { FilterButton } from '../../../../../components/FilterButton';

export function ProvidersListPage() {
  const navigate = useNavigate();
  const breadcrumbs = [
    { title: 'Administrativo', functionCoordinator: () => goToAdmin(navigate) },
    { title: 'Fornecedores e clientes' }
  ];

  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  const clearAlertMessage = () => {
    setAlertMessage({
      title: '',
      message: '',
      severity: 'error'
    });
  };

  const [filters, onChange, clearForm] = useForm({
    name: '',
    document: '',
    uuid_industry: ''
  });
  const [debouncedFilter] = useDebounce(filters, 500);

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(5);
  const [order, setOrder] = useState(Order.DESC);

  const [providers, setProviders] = useState([]);
  const [industries, setIndustries] = useState([]);

  useEffect(async () => await init(), []);
  useEffect(async () => await list(), [page, offset, order, debouncedFilter]);

  const init = async () => {
    try {
      setLoading(true);
      clearAlertMessage();

      const result = await Promise.all([
        listAllProviders(page, offset, order, filters),
        listIndustries()
      ]).then((res) => ({ providers: res[0], industries: res[1] }));

      setProviders(result.providers.data);
      setCount(result.providers.count);
      setIndustries(result.industries);

      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  };

  const list = async () => {
    try {
      setLoading(true);

      const result = await listAllProviders(page, offset, order, filters);
      setProviders(result.data);

      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  };

  const handlePage = (_event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setOffset(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleOrder = () => {
    if (order === Order.ASC) {
      setOrder(Order.DESC);
      return;
    }

    setOrder(Order.ASC);
  };

  function TableResponsive() {
    if (!providers?.length && !loading) {
      return <EmptyTable />;
    }

    if (isDesktop()) {
      return (
        <DesktopTable
          providers={providers}
          loading={loading}
          count={count}
          page={page}
          offset={offset}
          order={order}
          navigate={navigate}
          handlePage={handlePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleOrder={handleOrder}
        />
      );
    }

    return (
      <MobileTable
        providers={providers}
        loading={loading}
        count={count}
        page={page}
        offset={offset}
        navigate={navigate}
        setPage={setPage}
        setOffset={setOffset}
        handlePage={handlePage}
      />
    );
  }

  return (
    <BasePage breadcrumbs={breadcrumbs} navigate={navigate}>
      <TitlePages
        title="Lista de fornecedores e clientes"
        description="Lista de todos os fornecedores e clientes que já tiveram ou tem contrato."
        textButton="Cadastrar"
        Icon={AddCircleOutlineIcon}
        onClickButton={() => goToCreateProvider(navigate)}
        keyPermissionButton="CREATE_PROVIDER"
        toHideDivider={!hasPermission('LIST_PROVIDERS')}
      />

      <HiddenForPermission keys="LIST_PROVIDERS">
        <Grid container>
          <AlertMessage
            message={alertMessage.message}
            severity={alertMessage.severity}
            title={alertMessage.title}
          />
        </Grid>

        <FilterContainer>
          <Grid item xs={12} lg={3}>
            <TextField label="Nome" name="name" value={filters.name} onChange={onChange} />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextField
              label="Documento"
              name="document"
              value={filters.document}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextField
              label="Setor de atuação"
              name="uuid_industry"
              value={filters.uuid_industry}
              select
              onChange={onChange}
            >
              {industries?.map((i) => (
                <MenuItem value={i.uuid} key={i.uuid}>
                  {i.title?.toTitleCase()}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} lg={1}>
            <FilterButton onClick={clearForm} />
          </Grid>
        </FilterContainer>

        <TableContainer>
          <TableResponsive />
        </TableContainer>

        <div className="buttonGroup twoButtons">
          <Button variant="outlined" onClick={() => goToAdmin(navigate)}>
            Voltar
          </Button>
          <span></span>
        </div>
      </HiddenForPermission>
    </BasePage>
  );
}
