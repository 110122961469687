import { useNavigate, useParams } from 'react-router-dom';
import {
  goToAdmin,
  goToBack,
  goToCoinHistory,
  goToCreateCoin
} from '../../../../../router/coordinator';
import { useForm } from '../../../../../hooks/useForm';
import { useEffect, useState } from 'react';
import { BasePage } from '../../../../../components/BaseMain';
import {
  AlertMessage,
  Loading,
  Modal,
  TextFieldKeyPress,
  TitlePages
} from '../../../../../components';
import { Form } from './styled';
import { clearAlertMessage, isDesktop, onChangeMoney, toCent } from '../../../../../utils';
import { Button, Grid, InputAdornment, MenuItem, TextField } from '@mui/material';
import { createCoinHistory, findCoin } from '../../../../../services/coinService';
import { GENERIC_ERROR } from '../../../../../utils/constantes';

export function CreateCoinHistoryPage() {
  const navigate = useNavigate();

  const { uuid } = useParams();
  if (!uuid) {
    goToBack(navigate);
  }

  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  const [coin, setCoin] = useState({});
  const breadcrumbs = [
    { title: 'Administrativo', functionCoordinator: () => goToAdmin(navigate) },
    { title: 'Moedas', functionCoordinator: () => goToCreateCoin(navigate) },
    { title: 'Histórico', functionCoordinator: () => goToCoinHistory(navigate, uuid) },
    { title: 'Cadastrar' }
  ];

  useEffect(async () => await init(), []);

  const init = async () => {
    try {
      setLoading(true);

      const result = await findCoin(uuid);

      setCoin(result);

      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  };

  const initialValueForm = {
    exchange: '',
    type: ''
  };
  const [form, onChange, clearForm] = useForm(initialValueForm);
  const [errorsValidation, setErrorsValidation] = useState({});

  // --- Lógica do modal
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const onConfirmModal = async () => {
    try {
      setLoading(true);
      handleClose();

      const { exchange, type } = form;
      const exchangeToCent = exchange && toCent(exchange);

      await createCoinHistory(uuid, exchangeToCent, type);

      setAlertMessage((prev) => ({
        ...prev,
        title: 'Cotação criada com sucesso.',
        severity: 'success'
      }));

      clearForm();
      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  };
  // ---

  const validateMandatoryInput = (field) => {
    if (!form[field]) {
      setErrorsValidation((prevError) => ({ ...prevError, [field]: true }));
      return;
    }
    setErrorsValidation((prevError) => ({ ...prevError, [field]: false }));
  };

  const disabledButton = () => {
    if (!form.exchange) {
      return true;
    }
    if (!form.type) {
      return true;
    }

    return false;
  };

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages title={`Cadastrar novo valor para a moeda ${coin.title}`} />

      <Loading loading={loading} />
      <Grid container>
        <AlertMessage
          message={alertMessage.message}
          severity={alertMessage.severity}
          title={alertMessage.title}
          onClose={() => clearAlertMessage(setAlertMessage)}
        />
      </Grid>

      <Form container xs={12} rowGap={2} columnSpacing={isDesktop() && 2}>
        <Grid item xs={12} lg={4}>
          <TextFieldKeyPress
            label="Valor do câmbio atual em real"
            name={'exchange'}
            required
            value={form.exchange}
            onChange={(e) => onChangeMoney(e, onChange)}
            onBlur={() => validateMandatoryInput('exchange')}
            error={errorsValidation.exchange}
            helperText={errorsValidation.exchange && 'O valor do câmbio é obrigatório'}
            InputProps={{
              startAdornment: <InputAdornment position="start">R$</InputAdornment>
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            label="Tipo de câmbio"
            name={'type'}
            required
            value={form.type}
            select
            onChange={onChange}
            onBlur={() => validateMandatoryInput('type')}
            error={errorsValidation.type}
            helperText={errorsValidation.type && 'O tipo do câmbio é obrigatório'}
          >
            <MenuItem value="comercial">Comercial</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12} className={'buttonGroup twoButtons'}>
          <Button variant="outlined" onClick={() => goToCoinHistory(navigate, uuid)}>
            Voltar
          </Button>
          <Button onClick={handleOpen} disabled={disabledButton()}>
            Cadastrar
          </Button>
        </Grid>
      </Form>

      <Modal
        open={openModal}
        onClose={handleClose}
        onConfirm={onConfirmModal}
        title={'Criar nova moeda'}
        text={`Tem certeza que deseja adicionar o valor R$${form.exchange} na moeda '${coin.title}'? `}
        warning={'Após ser adicionado, o valor não pode ser excluido.'}
        buttonConfirm={'Sim'}
        buttonCancel={'Não'}
      />
    </BasePage>
  );
}
