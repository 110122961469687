import {
  HiddenForPermission,
  PaginationMobile,
  SkeletonMobile,
  TargetStatus
} from '../../../../../components';
import {
  MobileTableConteiner,
  TableMobileButton,
  TableMobileCell,
  TableMobileContent,
  TableMobileItem,
  TableMobileTitle
} from '../../../../../components/MobileTable';
import { goToDatailsContract } from '../../../../../router/coordinator';
import { convertCentsToWhole, convertReadjustmentMonth } from '../../../../../utils/convert';

export function MobileTable({ contracts, navigate, loading, count, page, offset, handlePage }) {
  const contractsList = contracts?.map((contract) => {
    return (
      <TableMobileCell key={contract.id}>
        <TableMobileItem>
          <TableMobileTitle>Id </TableMobileTitle>
          <TableMobileContent>{contract.id && contract.id}</TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Status </TableMobileTitle>
          <TableMobileContent>
            <TargetStatus status={contract.status} />
          </TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Segmento </TableMobileTitle>
          <TableMobileContent>{contract.segments?.title?.toTitleCase()}</TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Fornecedor </TableMobileTitle>
          <TableMobileContent>{contract.provider?.name?.toTitleCase()}</TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Data de início multa rescisória </TableMobileTitle>
          <TableMobileContent>
            {contract.financialContract?.startDateFineRescission?.toMask('date') || '-'}
          </TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Dias de multa rescisória </TableMobileTitle>
          <TableMobileContent>{contract.financialContract?.rescissionFine} dias</TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Índice </TableMobileTitle>
          <TableMobileContent>{contract.indexCurrent?.name?.toUpperCase()}</TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Valor inicial </TableMobileTitle>
          <TableMobileContent>
            {convertCentsToWhole(
              contract.financialContract?.initialValue,
              contract.financialContract?.coin?.title
            )}
          </TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Valor atual </TableMobileTitle>
          <TableMobileContent>
            {convertCentsToWhole(
              contract.financialContract?.currentValue,
              contract.financialContract?.coin?.title
            ) || '-'}
          </TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Mês reajuste </TableMobileTitle>
          <TableMobileContent>
            {convertReadjustmentMonth(contract.financialContract?.readjustmentMonth)}
          </TableMobileContent>
        </TableMobileItem>

        <HiddenForPermission keys={'FIND_CONTRACT'}>
          <TableMobileButton onClick={() => goToDatailsContract(navigate, contract.uuid)}>
            Ver detalhes
          </TableMobileButton>
        </HiddenForPermission>
      </TableMobileCell>
    );
  });

  return (
    <MobileTableConteiner>
      {!loading ? contractsList : <SkeletonMobile numbersOfColumns={10} />}
      <PaginationMobile count={count} page={page} rowsPerPage={offset} handlePage={handlePage} />
    </MobileTableConteiner>
  );
}
