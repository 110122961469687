import { useEffect, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Grid, Icon, TextField, FormControl, MenuItem, Link } from '@mui/material';
import { Content, Title, Subtitle, Links, Button, Form } from './styled';
import { metrics } from '../../../theme/styles';
import { BasePage } from '../../../components/BaseCredentials';
import { UploadButton, Loading, AlertMessage } from '../../../components';
import { ReactComponent as SignUpImage } from '../../../assets/signupImage.svg';
import { goToCreatePassWord, goToLogin } from '../../../router/coordinator';
import {
  validateCellphone,
  validateDocument,
  validateEmail,
  validateTellphone
} from '../../../utils/validates';
import { useForm } from '../../../hooks/useForm';
import { onlyNumbers } from '../../../utils';
import { GENERIC_ERROR } from '../../../utils/constantes';
import {
  createPublicToken,
  getItemLocalStorage,
  setItemLocalStorage,
  listAllCompaniesPublic,
  listAllOffices,
  listAllSectorsPublic
} from '../../../services';

export function Signup() {
  const navigate = useNavigate();

  const initialValueForm = {
    name: '',
    lastName: '',
    personType: '',
    document: '',
    email: '',
    company: '',
    office: '',
    sector: '',
    cellphone: '',
    tellphone: ''
  };
  const [form, onChange, _, setForm] = useForm(initialValueForm);
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [errorsValidation, setErrorsValidation] = useState({});
  const [companies, setCompanies] = useState([]);
  const [offices, setOffices] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errors, setError] = useState({
    title: '',
    message: '',
    severity: '',
    onClose: () => {}
  });

  useEffect(async () => {
    await generatePublicToken();

    const initProcesses = [getCompanies(), getOffices(), getSignupInformation(), getSectors()];
    await Promise.all(initProcesses).finally(() => setLoading(false));
  }, []);

  // Gera o token que dá acesso as API's públicas
  const generatePublicToken = async () => {
    try {
      const token = await createPublicToken();
      await setItemLocalStorage('token', token.access_token);
    } catch (err) {
      setError({
        title: err.message || GENERIC_ERROR,
        message: err.trace && `Código: ${err.trace}`
      });
    }
  };

  // Se o usuário já iniciou o cadastro, as informações ficam no storage
  const getSignupInformation = async () => {
    const signupInformation = getItemLocalStorage('signup');
    if (signupInformation) {
      setForm((previewForm) => ({ ...previewForm, ...signupInformation }));
      setImage(signupInformation.profileImage);
      setImageName(signupInformation.imageName);
    }
  };

  // --- Busca das listagens iniciais
  const getCompanies = async () => {
    try {
      const result = await listAllCompaniesPublic(true);
      setCompanies(result);
    } catch (err) {
      setError({
        title: err.message || GENERIC_ERROR,
        message: err.trace && `Código: ${err.trace}`
      });
    }
  };

  const getOffices = async () => {
    try {
      const result = await listAllOffices();
      setOffices(result);
    } catch (err) {
      setError({
        title: err.message || GENERIC_ERROR,
        message: err.trace && `Código: ${err.trace}`
      });
    }
  };

  const getSectors = async () => {
    try {
      const result = await listAllSectorsPublic();
      setSectors(result);
    } catch (err) {
      setError({
        title: err.message || GENERIC_ERROR,
        message: err.trace && `Código: ${err.trace}`
      });
    }
  };

  // --- Validadores de imput
  const validateInputEmail = () => {
    const { email } = form;
    const isValid = validateEmail(email);
    setErrorsValidation((prevError) => ({ ...prevError, email: !isValid }));
  };

  const validateInputDocument = () => {
    const { document } = form;

    let isValid = true;

    if (onlyNumbers(document)) {
      isValid = validateDocument(document);
    }
    setErrorsValidation((prevError) => ({ ...prevError, document: !isValid }));
  };

  const validateInputCellphone = () => {
    const { cellphone } = form;
    let isValid = true;

    if (onlyNumbers(cellphone)) {
      isValid = validateCellphone(cellphone);
    }

    setErrorsValidation((prevError) => ({ ...prevError, cellphone: !isValid }));
  };

  const validateInputTellphone = () => {
    const { tellphone } = form;
    let isValid = true;

    if (onlyNumbers(tellphone)) {
      isValid = validateTellphone(tellphone);
    }

    setErrorsValidation((prevError) => ({ ...prevError, tellphone: !isValid }));
  };

  const validateOthersInputs = (field) => {
    if (!form[field]) {
      setErrorsValidation((prevError) => ({ ...prevError, [field]: true }));
      return;
    }
    setErrorsValidation((prevError) => ({ ...prevError, [field]: false }));
  };

  const isPJ = () => form.personType === 'pj';

  const nextPage = async () => {
    const formForSave = {
      ...form,
      profileImage: image,
      imageName: imageName
    };

    await setItemLocalStorage('signup', formForSave);
    goToCreatePassWord(navigate);
  };

  const disabledButton = () => {
    if (!form.name) {
      return true;
    }
    if (!form.lastName) {
      return true;
    }
    if (!form.personType) {
      return true;
    }
    if (!form.document) {
      return true;
    }
    if (!form.email) {
      return true;
    }
    if (!form.company) {
      return true;
    }
    if (!form.office) {
      return true;
    }

    return false;
  };

  return (
    <BasePage ImageReceived={SignUpImage}>
      <Loading loading={loading} />
      <Content>
        <Icon component={AccountCircleIcon} sx={{ fontSize: metrics.sizeIconCredential }} />
        <Title>Crie uma conta</Title>
        <Subtitle>Após o cadastro sua conta será analisada.</Subtitle>
        <Form container spacing={2}>
          <AlertMessage
            title={errors.title}
            message={errors.message}
            severity={errors.severity}
            onClose={errors.onClose}
          />
          <Grid item xs={12} lg={6}>
            <FormControl>
              <TextField
                label="Nome"
                name={'name'}
                required
                value={form.name}
                onChange={onChange}
                onBlur={() => validateOthersInputs('name')}
                error={errorsValidation.name}
                helperText={errorsValidation.name && 'Nome é obrigatório'}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              label="Sobrenome"
              name="lastName"
              value={form.lastName}
              onChange={onChange}
              required
              onBlur={() => validateOthersInputs('lastName')}
              error={errorsValidation.lastName}
              helperText={errorsValidation.lastName && 'Sobrenome é obrigatório'}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="PF ou PJ"
              onChange={onChange}
              value={form.personType}
              name={'personType'}
              select
              required
              onBlur={() => validateOthersInputs('personType')}
              error={errorsValidation.personType}
              helperText={errorsValidation.personType && 'Este campo é obrigatório'}
            >
              <MenuItem value="pf">Pessoa física (PF)</MenuItem>
              <MenuItem value="pj">Pessoa jurídica (PJ)</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <ReactInputMask
              mask={isPJ() ? '99.999.999/9999-99' : '999.999.999-99'}
              value={form.document}
              onChange={onChange}
              disabled={!form.personType}
              maskChar="_"
              onBlur={() => validateInputDocument()}
            >
              {() => (
                <TextField
                  label="Documento"
                  disabled={!form.personType}
                  required
                  name={'document'}
                  value={form.document}
                  error={errorsValidation.document}
                  helperText={errorsValidation.document ? 'Documento inválido' : 'CPF ou CNPJ'}
                />
              )}
            </ReactInputMask>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="E-mail corporativo"
              required
              placeholder="joao_silva@pamafa.com.br"
              type={'email'}
              name={'email'}
              value={form.email}
              onChange={onChange}
              onBlur={() => validateInputEmail()}
              error={errorsValidation.email}
              helperText={errorsValidation.email && 'E-mail inválido'}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              select
              name="company"
              value={form.company}
              onChange={onChange}
              label="Empresa"
              required
              onBlur={() => validateOthersInputs('company')}
              error={errorsValidation.company}
              helperText={errorsValidation.company && 'A empresa é obrigatória'}
            >
              {companies?.map((c) => (
                <MenuItem key={c.uuid} value={c.uuid}>
                  {c.name?.toTitleCase()}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              select
              label={'Cargo no sistema'}
              required
              name="office"
              value={form.office}
              onChange={onChange}
              onBlur={() => validateOthersInputs('office')}
              error={errorsValidation.office}
              helperText={
                errorsValidation.office
                  ? 'A empresa é obrigatória'
                  : 'Define suas funções no sistema'
              }
            >
              {offices?.map((c) => (
                <MenuItem key={c.uuid} value={c.uuid}>
                  {c.title?.toTitleCase()}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              select
              label={'Setor de atuação'}
              required
              name="sector"
              value={form.sector}
              onChange={onChange}
              onBlur={() => validateOthersInputs('sector')}
              error={errorsValidation.sector}
              helperText={errorsValidation.sector && 'O setor é obrigatório'}
            >
              {sectors?.map((c) => (
                <MenuItem key={c.uuid} value={c.uuid}>
                  {c.title?.toTitleCase()}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} lg={6}>
            <ReactInputMask
              mask={'(99) 99999-9999'}
              name={'cellphone'}
              value={form.cellphone}
              onChange={onChange}
              onBlur={validateInputCellphone}
              maskChar="_"
            >
              {() => (
                <TextField
                  label="Celular"
                  name={'cellphone'}
                  value={form.cellphone}
                  onChange={onChange}
                  error={errorsValidation.cellphone}
                  helperText={errorsValidation.cellphone && 'Celular está inválido'}
                />
              )}
            </ReactInputMask>
          </Grid>
          <Grid item xs={12} lg={6}>
            <ReactInputMask
              mask={'(99) 9999-9999'}
              value={form.tellphone}
              onChange={onChange}
              name={'tellphone'}
              onBlur={validateInputTellphone}
              maskChar="_"
            >
              {() => (
                <TextField
                  label="Telefone"
                  name={'tellphone'}
                  value={form.tellphone}
                  onChange={onChange}
                  error={errorsValidation.tellphone}
                  helperText={errorsValidation.tellphone && 'Telefone está inválido'}
                />
              )}
            </ReactInputMask>
          </Grid>
          <UploadButton
            setImage={setImage}
            outImageName={imageName}
            setOutImageName={setImageName}
          />
          <Grid item xs={12} lg={12}>
            <Links>
              <p>
                Já possui uma conta? <Link onClick={() => goToLogin(navigate)}>Acesse já</Link>
              </p>
            </Links>
            <Button type="submit" disabled={disabledButton()} onClick={nextPage}>
              Próximo
            </Button>
          </Grid>
        </Form>
      </Content>
    </BasePage>
  );
}
