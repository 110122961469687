import { Button } from './styled';

export function ButtonWithIcon(props) {
  const { Icon, children } = props;

  return (
    <Button {...props}>
      <Icon />
      {children && <span>{children}</span>}
    </Button>
  );
}
