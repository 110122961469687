import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { Button, Grid, TextField } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { DatePicker } from '@mui/x-date-pickers';
import { BasePage } from '../../../../../components/BaseMain';
import {
  goToAdmin,
  goToBack,
  goToCreateHistoryIndex,
  goToIndexList
} from '../../../../../router/coordinator';
import {
  AlertMessage,
  EmptyTable,
  FilterContainer,
  HiddenForPermission,
  Loading,
  TitlePages
} from '../../../../../components';
import { useForm } from '../../../../../hooks/useForm';
import { clearAlertMessage, hasPermission, isDesktop, onChangeDate } from '../../../../../utils';
import { TableContainer } from '../../../../../components/TableContainer';
import { DesktopTable } from './TablesComponents/DesktopTable';
import { MobileTable } from './TablesComponents/MobileTable';
import { findIndex, listAllIndexHistoric } from '../../../../../services/indexService';
import { GENERIC_ERROR } from '../../../../../utils/constantes';
import { Order } from '../../../../../utils/enums';
import { FilterButton } from '../../../../../components/FilterButton';

export function IndexHistoryPage() {
  const navigate = useNavigate();

  const { uuid } = useParams();
  if (!uuid) {
    goToBack(navigate);
  }

  const breadcrumbs = [
    { title: 'Administrativo', functionCoordinator: () => goToAdmin(navigate) },
    { title: 'Índices', functionCoordinator: () => goToIndexList(navigate) },
    { title: 'Histórico' }
  ];

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(5);
  const [order, setOrder] = useState(Order.DESC);

  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  const [filters, onChange, clearForm] = useForm({
    username: '',
    document_user: '',
    date: null,
    percent: ''
  });
  const [debouncedFilter] = useDebounce(filters, 500);

  const [index, setIndex] = useState([]);
  const [historicIndex, setHistoricIndex] = useState([]);

  const init = useCallback(async () => {
    try {
      setLoading(true);

      const [historicIndex, index] = await Promise.all([
        listAllIndexHistoric(uuid, page, offset, order, filters),
        findIndex(uuid)
      ]);

      setHistoricIndex(historicIndex.data);
      setCount(historicIndex.count);
      setIndex(index);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
    } finally {
      setLoading(false);
    }
  }, []);

  const list = useCallback(async () => {
    try {
      setLoading(true);

      if (filters?.date && !dayjs(filters.date).isValid()) {
        return;
      }

      const result = await listAllIndexHistoric(uuid, page, offset, order, filters);

      setHistoricIndex(result.data);
      setCount(result.count);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
    } finally {
      setLoading(false);
    }
  }, [page, offset, order, debouncedFilter]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    list();
  }, [list]);

  const handlePage = (_event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setOffset(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleOrder = () => {
    if (order === Order.ASC) {
      setOrder(Order.DESC);
      return;
    }

    setOrder(Order.ASC);
  };

  function TableResponsive() {
    if (!historicIndex?.length && !loading) {
      return <EmptyTable />;
    }

    if (isDesktop()) {
      return (
        <DesktopTable
          historics={historicIndex}
          loading={loading}
          count={count}
          page={page}
          offset={offset}
          order={order}
          handlePage={handlePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleOrder={handleOrder}
          navigate={navigate}
        />
      );
    }

    return (
      <MobileTable
        historics={historicIndex}
        loading={loading}
        count={count}
        page={page}
        offset={offset}
        setPage={setPage}
        setOffset={setOffset}
        handlePage={handlePage}
        navigate={navigate}
      />
    );
  }

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages
        title={`Histórico do índice ${index.name}`}
        description="Valores adicionados ao índice, sendo o primeiro da tabela o mais recente."
        textButton="Cadastrar novo valor"
        Icon={AddCircleOutlineIcon}
        onClickButton={() => goToCreateHistoryIndex(navigate, uuid)}
        keyPermissionButton="CREATE_HISTORY_INDEX"
        toHideDivider={!hasPermission('LIST_INDEX_HISTORY')}
      />

      <Loading loading={loading} />
      <HiddenForPermission keys="LIST_INDEX_HISTORY">
        <Grid container>
          <AlertMessage
            message={alertMessage.message}
            severity={alertMessage.severity}
            title={alertMessage.title}
            onClose={() => clearAlertMessage(setAlertMessage)}
          />
        </Grid>

        <FilterContainer>
          <Grid item xs={12} lg={3}>
            <TextField
              label={'Nome do usuário'}
              name={'username'}
              value={filters.username}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <TextField
              label={'Documento do usuário'}
              name={'document_user'}
              value={filters.document_user}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <DatePicker
              label="Data"
              name="date"
              value={filters.date}
              disableFuture={true}
              required
              format="MM/YYYY"
              views={['year', 'month']}
              onChange={(e) => onChangeDate(e, 'date', onChange)}
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <TextField
              label={'Porcentagem'}
              name={'percent'}
              value={filters.percent}
              type="number"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} lg={1}>
            <FilterButton onClick={clearForm} />
          </Grid>
        </FilterContainer>

        <TableContainer>
          <TableResponsive />
        </TableContainer>

        <div className={'buttonGroup twoButtons'}>
          <Button variant="outlined" onClick={() => goToIndexList(navigate)}>
            Voltar
          </Button>
          <span></span>
        </div>
      </HiddenForPermission>
    </BasePage>
  );
}
