import { api } from '../utils/axiosConfig';

/**
 * Lista todos os segmentos
 * @param {*} initialPage - Página de inicio
 * @param {*} offset - Itens por página
 * @param {*} order - ordem [asc, desc]
 * @param {*} filter - filtros
 * @returns
 */
export const listAllSegments = async (initialPage, offset, order = 'ASC', filter = {}) => {
  try {
    const page = initialPage + 1;
    const { data } = await api.get(`/segment/list-all`, {
      params: {
        initial_page: page,
        offset,
        order,
        ...filter
      }
    });

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Cadastrar um segmento de contrato
 * @param {*} title - nome
 * @returns
 */
export const createSegment = async (title) => {
  try {
    const payload = {
      title
    };
    const { data } = await api.post(`/segment/create`, payload);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Editar um segmento de contrato
 * @param {*} uuidSegment - uuid
 * @param {*} title - nome
 * @returns
 */
export const editSegment = async (uuidSegment, title) => {
  try {
    const { data } = await api.put(`/segment/edit/${uuidSegment}`, {
      title
    });

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * BUsca um segmento de contrato
 * @param {*} uuidSegment - uuid
 * @returns
 */
export const findSegment = async (uuidSegment) => {
  try {
    const { data } = await api.get(`/segment/find/${uuidSegment}`);
    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};
