import { Button, Grid } from '@mui/material';
import styled from 'styled-components';
import { colors, metrics } from '../../../../theme/styles';
import { breakpoints } from '../../../../theme/breakpoints';

export const OptionButton = styled(Button)`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  span {
    margin-left: 0.5rem;
    padding-top: 4px;
  }
`;

export const Content = styled.div`
  margin-top: ${metrics.size.smallMedium};
`;

export const Title = styled.div`
  h2 {
    color: ${colors.theme.primary};
    font-size: ${metrics.size.medium};

    @media (min-width: ${breakpoints.md}) {
      display: flex;
      align-items: center;
    }

    span {
      font-size: 1rem;
      margin-top: 0.5rem;

      @media (min-width: ${breakpoints.md}) {
        margin-left: 0.5rem;
        margin-top: 2px;
        margin-top: 0;
      }
    }
  }

  h3 {
    font-size: ${metrics.size.smallMedium};
    font-weight: 600;
  }

  #confidential {
    color: ${colors.gray};
    display: flex;
    align-items: center;
    margin-top: 0.8rem;

    svg {
      width: 1.2rem;
      height: 1.2rem;
    }

    span {
      padding-left: 0.3rem;
      font-weight: 600;
    }
  }
`;

export const LineGrid = styled(Grid)`
  margin-top: 2rem;

  div {
    margin-top: 0.8rem;

    p:first-child {
      font-weight: 600;
      color: ${colors.theme.primary};
      font-size: 1.2rem;
    }

    p:last-child {
      font-weight: 400;
      font-size: 1.5rem;
    }
  }
`;

export const ButtonGroup = styled.div`
  margin-top: 4rem;
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
`;

export const Link = styled.p`
  &:hover {
    color: ${colors.theme.secundary};
    text-decoration: underline;
    cursor: pointer;
  }
`;
