import { useEffect, useState } from 'react';
import { getItemLocalStorage } from '../../services/localStorageService';

export function HiddenForOffice({ office, children }) {
  const [showComponent, setShowComponent] = useState(false);

  useEffect(() => {
    const user = getItemLocalStorage('user');
    if (!user || !user?.office) {
      setShowComponent(false);
      return;
    }

    if (user.office === 'MASTER' || user.office === 'DEVELOPER' || user.office === office) {
      setShowComponent(true);
    }
  }, []);

  return showComponent ? <div>{children}</div> : null;
}
