import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { BasePage } from '../../../../../components/BaseMain';
import { goToAdmin, goToEditTemplate } from '../../../../../router/coordinator';
import { AlertMessage, SkeletonDesktop, TitlePages } from '../../../../../components';
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { TableContainer } from '../../../../../components/TableContainer';
import { GENERIC_ERROR } from '../../../../../utils/constantes';
import { listOffices } from '../../../../../services';
import { clearAlertMessage } from '../../../../../utils';

export function ListOfficesPage() {
  const navigate = useNavigate();
  const breadcrumbs = [
    { title: 'Administrativo', functionCoordinator: () => goToAdmin(navigate) },
    { title: 'Permissões padrões' }
  ];

  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });
  const [offices, setOffices] = useState([]);

  const list = useCallback(async () => {
    try {
      setLoading(true);

      const result = await listOffices();

      setOffices(result);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    list();
  }, [list]);

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages
        title="Tela para configurar as permissões padrões dos cargos"
        description="Selecione o cargo desejado, em seguida defina as permissões que qualquer usuário no sistema com esse cargo irá começar. (Após o usuário criado qualquer alteração aqui não o afetará, deverá ser feito individualmente)"
      />

      <Grid container>
        <AlertMessage
          message={alertMessage.message}
          severity={alertMessage.severity}
          title={alertMessage.title}
          onClose={() => clearAlertMessage(setAlertMessage)}
        />
      </Grid>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Cargo</TableCell>
              <TableCell style={{ textAlign: 'end' }}>Editar permissões</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <SkeletonDesktop numbersOfColumns={2} />
            ) : (
              offices?.map((office) => (
                <TableRow className="not-effect" key={office?.uuid}>
                  <TableCell>{office?.title?.toTitleCase()}</TableCell>
                  <TableCell style={{ textAlign: 'end' }}>
                    <IconButton onClick={() => goToEditTemplate(navigate, office?.uuid)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <div className="buttonGroup twoButtons">
        <Button variant="outlined" onClick={() => goToAdmin(navigate)}>
          Voltar
        </Button>
        <span></span>
      </div>
    </BasePage>
  );
}
