import { Button, Divider, Grid, TextField } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { BottomArea, ButtonContainer, Container, Content, Title, Warning } from './styled';
import { useForm } from '../../hooks/useForm';

/**
 * Props esperadas:
 *
 * [open]: booleano - Diz se o modal abre
 * [onClose]: function - função que fechará o modal
 * [onConfirm]: function - função acionada após o usuário confirmar o modal
 * [title]: string - Texto de título
 * [text]: string - Texto do corpo do modal
 * [buttonConfirm]: string - texto no botão de confirmar
 * [buttonCancel]: string - texto no botão de cancelar
 *
 * @param {*} props
 * @returns
 */
export function ModalInput(props) {
  const { title, onClose, onConfirm, buttonCancel, buttonConfirm, warning, inputLabel } = props;
  const [form, onChange] = useForm({ input: '' });

  return (
    <Container {...props}>
      <Content>
        <Title>{title}</Title>
        <Divider />
        <TextField
          className="input"
          label={inputLabel}
          name={'input'}
          value={form.input}
          onChange={onChange}
          multiline
          rows={4}
        />

        <BottomArea>
          {warning && (
            <Warning color="error">
              <WarningIcon />
              <span>{warning}</span>
            </Warning>
          )}

          <ButtonContainer container>
            <Grid item xs={12} lg={3}>
              <Button variant="outlined" onClick={onClose}>
                {buttonCancel ? buttonCancel : 'Cancelar'}
              </Button>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Button type="submit" onClick={() => onConfirm(form.input)}>
                {buttonConfirm ? buttonConfirm : 'Confirmar'}
              </Button>
            </Grid>
          </ButtonContainer>
        </BottomArea>
      </Content>
    </Container>
  );
}
