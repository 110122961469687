import styled from 'styled-components';
import { colors } from '../../theme/styles';

export const Container = styled.div`
  background-color: ${({ light }) => (light ? colors.theme.background : colors.backgroundWhite)}e7;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
  border-radius: 5px;

  div {
    color: ${({ light }) => (light ? 'white' : 'black')};
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
      margin-top: 1rem;
      font-style: italic;
    }
  }
`;
