import styled from 'styled-components';
import { colors } from '../../theme/styles';
import { Grid } from '@mui/material';
import { breakpoints } from '../../theme/breakpoints';

export const ContainerArea = styled(Grid)`
  position: relative;
  border: 1px solid ${colors.gray};
  padding: 1rem 1.5rem;
  border-radius: 10px;
  margin-top: 1.5rem;

  .title {
    padding: 0 10px;
    position: absolute;
    top: -15px;
    left: calc(1.5rem - 10px);
    background-color: ${colors.backgroundWhite};
    color: ${colors.gray};
    font-weight: 500;

    @media (max-width: ${breakpoints.md}) {
      font-size: 0.8rem;
      top: -10px;
    }
  }
`;
