import styled from 'styled-components';
import { Grid, IconButton } from '@mui/material';
import { colors, metrics } from '../../../../theme/styles';
import { breakpoints } from '../../../../theme/breakpoints';

export const Title = styled.div`
  margin-top: 1rem;

  h2 {
    color: ${colors.theme.primary};
    font-size: ${metrics.size.medium};

    @media (min-width: ${breakpoints.md}) {
      display: flex;
      align-items: center;
    }

    span {
      font-size: 1rem;
      margin-top: 0.5rem;

      @media (min-width: ${breakpoints.md}) {
        margin-left: 0.5rem;
        margin-top: 2px;
        margin-top: 0;
      }
    }
  }

  #confidential {
    color: ${colors.gray};
    display: flex;
    align-items: center;
    margin-top: 0.8rem;

    svg {
      width: 1.2rem;
      height: 1.2rem;
    }

    span {
      padding-left: 0.3rem;
      font-weight: 600;
    }
  }
`;

export const LineGrid = styled(Grid)`
  margin-top: 2rem;

  div {
    margin-top: 0.8rem;

    p:first-child {
      font-weight: 600;
      color: ${colors.theme.primary};
      font-size: 1.2rem;
    }

    p:last-child {
      font-weight: 400;
      font-size: 1.5rem;
    }
  }
`;

export const ButtonGroup = styled.div`
  margin-top: 4rem;
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
`;

export const ButtonIcon = styled(IconButton)`
  height: 1rem;

  svg {
    width: 1rem;
    height: 1rem;
  }
`;
