/**
 * Armazena um valor no localStorage.
 * @param {string} key - A chave do item a ser armazenado.
 * @param {any} value - O valor a ser armazenado.
 */
export const setItemLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

/**
 * Busca um valor no localStorage.
 * @param {string} key - A chave do item a ser buscado.
 */
export const getItemLocalStorage = (key) => {
  try {
    const value = localStorage.getItem(key);
    return JSON.parse(value);
  } catch (error) {
    return null;
  }
};

/**
 * Limpa o localStorage
 */
export const clearLocalStorage = () => localStorage.clear();

/**
 * Remove valor no localStorage.
 * @param {string} key - A chave do item a ser armazenado.
 */
export const removeItemLocalStorage = (key) => {
  localStorage.removeItem(key);
};
