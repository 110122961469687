import { Autocomplete } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DomainDisabledIcon from '@mui/icons-material/DomainDisabled';
import { useForm } from '../../hooks/useForm';
import { goToHome, goToLogin } from '../../router/coordinator';
import { Content, Container, Input, Title, Button, Warning } from './styled';
import { setItemLocalStorage } from '../../services/localStorageService';
import { useCallback, useLayoutEffect, useState } from 'react';
import { Loading } from '../../components/Loading';
import {
  changeChooseCompany,
  getPermissions,
  getUserInformations
} from '../../services/userService';
import { AlertMessage } from '../../components';
import { GENERIC_ERROR } from '../../utils/constantes';
import { listAccessCompaniesNoPaginate } from '../../services/utilsService';

export function ChooseCompany() {
  const navigate = useNavigate();
  const [form, onChange, _, setForm] = useForm({ company: '' });
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({
    title: '',
    message: '',
    severity: '',
    onClose: () => {}
  });

  const clearError = () => {
    setErrors((prev) => ({
      ...prev,
      title: '',
      severity: '',
      message: ''
    }));
  };

  const enterSystem = async (selectedCompany) => {
    try {
      setLoading(true);
      clearError();

      let company = selectedCompany;
      if (!company) {
        company = companies.find(
          (company) => company.name?.toUpperCase() === form.company?.toUpperCase()
        );
      }

      const token = await changeChooseCompany(company?.uuid);
      setItemLocalStorage('token', token.access_token);

      const userInformations = await getUserInformations();
      const user = {
        company: company.name,
        company_uuid: company.uuid,
        fullname: `${userInformations.name} ${userInformations.lastName}`,
        office: userInformations?.office?.title,
        profile_image: userInformations?.profileImage?.imageBase64,
        show_change_company: !selectedCompany
      };

      setItemLocalStorage('user', user);
      await setPermissions();

      goToHome(navigate);
    } catch (err) {
      setErrors({
        title: err?.message || GENERIC_ERROR,
        message: err?.trace && `Código: ${err.trace}`,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const getCompanies = useCallback(async () => {
    try {
      const companies = await listAccessCompaniesNoPaginate();
      setCompanies(companies);

      if (companies?.length === 1) {
        await enterSystem(companies[0]);
      }
    } catch (err) {
      setErrors({
        title: err?.message || GENERIC_ERROR,
        message: err?.trace && `Código: ${err.trace}`,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useLayoutEffect(() => {
    getCompanies();
  }, [getCompanies]);

  const setPermissions = async () => {
    const permissions = await getPermissions();
    setItemLocalStorage('permissions', permissions);
  };

  function WarningNoCompaniesAccess() {
    return (
      <Warning>
        <DomainDisabledIcon />
        <p>No momento você não tem acesso a nenhuma empresa. </p>

        <div>
          <Button onClick={() => goToLogin(navigate)}>Voltar a tela de login</Button>
        </div>
      </Warning>
    );
  }

  return (
    <Content>
      <Loading loading={loading} light={true} />
      <Container>
        {!loading && (
          <>
            {!companies?.length && <WarningNoCompaniesAccess />}

            <Title>
              De que <span>empresa</span> você quer ver as informações?
            </Title>
            <AlertMessage
              title={errors.title}
              message={errors.message}
              severity={errors.severity}
            />

            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={companies?.map?.((company) => ({
                label: company.name?.toCapitalizeCase?.()
              }))}
              name="company"
              required
              value={form.company}
              onInputChange={(_, newInputValue) => {
                setForm({
                  company: newInputValue
                });
              }}
              renderInput={(params) => <Input {...params} label="Empresa" onChange={onChange} />}
              noOptionsText="Sem opções"
            />

            <Button type="submit" onClick={() => enterSystem()} disabled={!form.company}>
              Entrar
            </Button>
          </>
        )}
      </Container>
    </Content>
  );
}
