import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Icon } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Button, Content, Subtitle, Title } from './styled';
import { colors, metrics } from '../../../theme/styles';
import { BasePage } from '../../../components/BaseCredentials';
import { ReactComponent as SignUpImage } from '../../../assets/accountCreatedImage.svg';
import { goToLogin } from '../../../router/coordinator';

export function AccountCreated() {
  const navigate = useNavigate();
  const onConfirmButton = () => {
    goToLogin(navigate);
  };

  return (
    <BasePage ImageReceived={SignUpImage}>
      <Content className="center">
        <Icon
          component={CheckCircleIcon}
          sx={{ fontSize: metrics.sizeIconCredential, color: colors.theme.primary }}
        />
        <Title>Conta criada!</Title>
        <Subtitle>
          Agora é só aguardar. Sua conta será analisada e quando ativada você receberá um e-mail o
          avisando.
        </Subtitle>
        <Button type="submit" onClick={onConfirmButton}>
          Voltar para a tela de login
        </Button>
      </Content>
    </BasePage>
  );
}
