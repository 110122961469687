import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { hasPermission, isAuth } from '../../utils';
import { getItemLocalStorage } from '../../services';

export function PrivateRoute({ Page, permissionKey, isAdmin }) {
  useEffect(async () => {}, []);

  const isPermission = () => {
    const havePermission = hasPermission(permissionKey);

    const user = getItemLocalStorage('user');
    const isAdministrator = user.office === 'ADMINISTRADOR';

    if (isAdmin && !havePermission && !isAdministrator) {
      return false;
    }

    // Rotas com permissão
    if (!havePermission && permissionKey && !isAuth(permissionKey)) {
      return false;
    }

    return true;
  };

  return isPermission() ? <Page /> : <Navigate to={-1} />;
}
