import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { BasePage } from '../../../../../components/BaseMain';
import { goToAdmin, goToIndustryCreate } from '../../../../../router/coordinator';
import {
  AlertMessage,
  EmptyTable,
  FilterContainer,
  HiddenForPermission,
  TitlePages
} from '../../../../../components';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { clearAlertMessage, hasPermission, isDesktop } from '../../../../../utils';
import { Button, Grid, TextField } from '@mui/material';
import { TableContainer } from '../../../../../components/TableContainer';
import { useForm } from '../../../../../hooks/useForm';
import { FilterButton } from '../../../../../components/FilterButton';
import { Order } from '../../../../../utils/enums';
import { DesktopTable } from './TablesComponents/DesktopTable';
import { listAllIndustries } from '../../../../../services';
import { GENERIC_ERROR } from '../../../../../utils/constantes';
import { MobileTable } from './TablesComponents/MobileTable';

export function ListIndustryPage() {
  const navigate = useNavigate();
  const breadcrumbs = [
    { title: 'Administrativo', functionCoordinator: () => goToAdmin(navigate) },
    { title: 'Setores das empresas' }
  ];

  const [sectors, setSectors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  const [filters, onChange, clearForm] = useForm({
    title: '',
    description: ''
  });
  const [debouncedFilter] = useDebounce(filters, 500);

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(5);
  const [order, setOrder] = useState(Order.DESC);

  useEffect(async () => await list(), [page, offset, order, debouncedFilter]);

  const list = async () => {
    try {
      setLoading(true);

      const result = await listAllIndustries(page, offset, order, filters);

      setSectors(result.data);
      setCount(result.count);

      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  };

  const handlePage = (_event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setOffset(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleOrder = () => {
    if (order === Order.ASC) {
      setOrder(Order.DESC);
      return;
    }

    setOrder(Order.ASC);
  };

  function TableResponsive() {
    if (!sectors?.length && !loading) {
      return <EmptyTable />;
    }

    if (isDesktop()) {
      return (
        <DesktopTable
          sectors={sectors}
          loading={loading}
          count={count}
          page={page}
          offset={offset}
          order={order}
          navigate={navigate}
          handlePage={handlePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleOrder={handleOrder}
        />
      );
    }

    return (
      <MobileTable
        sectors={sectors}
        loading={loading}
        count={count}
        page={page}
        offset={offset}
        navigate={navigate}
        setPage={setPage}
        setOffset={setOffset}
        handlePage={handlePage}
      />
    );
  }

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages
        title={'Lista de setores de atuação das empresas'}
        description={
          'Lista de todos os setores de atuação cadastrados. Esse setor é associado as empresas.'
        }
        textButton={'Cadastrar setor'}
        Icon={AddCircleOutlineIcon}
        onClickButton={() => goToIndustryCreate(navigate)}
        keyPermissionButton={'CREATE_INDUSTRY'}
        toHideDivider={!hasPermission('LIST_INDUSTRY')}
      />

      <HiddenForPermission keys="LIST_INDUSTRY">
        <Grid container>
          <AlertMessage
            message={alertMessage.message}
            severity={alertMessage.severity}
            title={alertMessage.title}
            onClose={() => clearAlertMessage(setAlertMessage)}
          />
        </Grid>

        <FilterContainer>
          <Grid item xs={12} lg={3}>
            <TextField label={'Nome'} name={'title'} value={filters.title} onChange={onChange} />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextField
              label={'Descrição'}
              name={'description'}
              value={filters.description}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} lg={1}>
            <FilterButton onClick={clearForm} />
          </Grid>
        </FilterContainer>

        <TableContainer>
          <TableResponsive />
        </TableContainer>

        <div className={'buttonGroup twoButtons'}>
          <Button variant="outlined" onClick={() => goToAdmin(navigate)}>
            Voltar
          </Button>
          <span></span>
        </div>
      </HiddenForPermission>
    </BasePage>
  );
}
