import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BasePage } from '../../../../components/BaseMain';
import { TitlePages } from '../../../../components/TitlePages';
import { Visibility } from '@mui/icons-material';
import { Content } from './styled';
import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { goToDetailsTermsAndGuidelines } from '../../../../router/coordinator';
import { GENERIC_ERROR } from '../../../../utils/constantes';
import { AlertMessage, EmptyTable, SkeletonDesktop } from '../../../../components';
import { listAcceptTerms } from '../../../../services';
import { clearAlertMessage } from '../../../../utils';

export function TermsAndGuidelines() {
  const breadcrumbs = [{ title: 'Menu' }, { title: 'Termos e diretrizes' }];
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  const [terms, setTerms] = useState([]);

  useEffect(async () => await init(), []);

  const init = async () => {
    try {
      setLoading(true);

      const result = await listAcceptTerms();
      setTerms(result);

      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  };

  const bodyTable = terms.map((t) => (
    <TableRow key={t.uuid} className="not-effect">
      <TableCell>{t.name?.toTitleCase()}</TableCell>
      <TableCell align="right">
        <IconButton onClick={() => goToDetailsTermsAndGuidelines(navigate, t.uuid)}>
          <Visibility />
        </IconButton>
      </TableCell>
    </TableRow>
  ));

  function RenderTable() {
    if (loading) {
      return <SkeletonDesktop numbersOfColumns={2} />;
    }

    if (!terms?.length && !loading) {
      return <EmptyTable />;
    }

    return bodyTable;
  }
  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages title={'Termos e diretrizes'} />

      <Grid container>
        <AlertMessage
          title={alertMessage.title}
          message={alertMessage.message}
          severity={alertMessage.severity}
          onClose={() => clearAlertMessage(setAlertMessage)}
        />
      </Grid>

      <Content>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Título</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <RenderTable />
            </TableBody>
          </Table>
        </TableContainer>
      </Content>
    </BasePage>
  );
}
