import { TextField } from '@mui/material';

/**
 * Input que faz a ação de passar para o próximo input
 * ao ter 'enter' precionado
 *
 * [ Atenção: se o input não tivar a tag "name" a lógica não funciona ]
 *
 * @param {*} props
 * @returns
 */
export function TextFieldNextFocus(props) {
  const handleKeyPress = (event) => {
    const nameCurrentInput = props.name;
    if (nameCurrentInput) {
      if (event.key === 'Enter') {
        // Todos os inputs da página
        const allInputsInPage = document.querySelectorAll(`input`);

        // É me retornado um objeto, transformo ele em array
        const listOfInputs = [];
        allInputsInPage.forEach((input) => listOfInputs.push(input));

        // Busca o index do input atual e pula para o próximo
        const index = listOfInputs.findIndex((input) => input.name === nameCurrentInput);
        if (index >= 0) {
          const nextInput = listOfInputs[index + 1];
          if (nextInput) {
            event.preventDefault();
            nextInput.focus();
          }
        }
      }
    }
  };

  return <TextField {...props} onKeyPress={handleKeyPress} />;
}
