import { api } from '../utils/axiosConfig';

/**
 * Lista todos os arquivos de ajuda.
 */
export const listAllHelpFiles = async () => {
  try {
    const { data } = await api.get('help-file/list');
    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Cria ou atualiza um arquivo de ajuda.
 * @param {File} file - O arquivo PDF a ser enviado.
 * @returns
 */
export const createHelpFile = async (file) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    await api.post('/admin/help-file/create', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  } catch (error) {
    throw error?.response?.data;
  }
};
