import { Grid } from '@mui/material';
import styled from 'styled-components';

export const Content = styled(Grid)`
  && {
    margin-top: 0;
  }

  position: relative;
  padding-bottom: 2rem;
`;
