import styled from 'styled-components';
import { breakpoints } from '../../theme/breakpoints';
import { colors, metrics } from '../../theme/styles';

export const Container = styled.header`
  color: ${colors.backgroundWhite};

  padding: ${metrics.padding.header};
  display: flex;
  align-items: center;
  padding: 0.8rem 1.5rem;

  @media (min-width: ${breakpoints.md}) {
    padding: 2rem;
  }

  #contentMenu {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h1 {
    font-size: 1.5rem;
    font-weight: 500;
    width: 100%;
    text-align: center;
    cursor: pointer;
  }

  h1:hover {
    opacity: 0.8;
  }

  button {
    color: white;

    :hover {
      opacity: 0.8;
    }
  }
`;
