import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ViewListIcon from '@mui/icons-material/ViewList';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { ButtonGroup, ButtonIcon, LineGrid, Title } from './styled';
import { BasePage } from '../../../../components/BaseMain';
import {
  goToBack,
  goToListAdicionalsContract,
  goToListAllContracts,
  goToVisualizeContract,
  goToDatailsContract
} from '../../../../router/coordinator';
import {
  convertCentsToWhole,
  convertNewValueCoin,
  convertReadjustmentMonth,
  convertReadjustmentPeriodicity
} from '../../../../utils/convert';
import {
  AlertMessage,
  ButtonPage,
  HiddenForPermission,
  Loading,
  TargetStatus,
  TopBackArea
} from '../../../../components';
import { findFinancialContract } from '../../../../services/contractService';
import { GENERIC_ERROR } from '../../../../utils/constantes';
import { CoinName } from '../../../../utils/enums';

export function DetailsFinancialContractPage() {
  const navigate = useNavigate();

  const { uuid } = useParams();
  if (!uuid) {
    goToBack(navigate);
  }

  const [loading, setLoading] = useState(true);
  const [financialContract, setFinancialContract] = useState({});
  const [contract, setContract] = useState({});
  const [error, setError] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  const breadcrumbs = [
    { title: 'Contratos', functionCoordinator: () => goToListAllContracts(navigate) },
    { title: 'Detalhes' },
    { title: 'Financeiro' }
  ];

  const [convertCoin, setConvertCoin] = useState({ initialValue: false, currentValue: false });

  useEffect(async () => {
    await getFinancialContract();
  }, []);

  const getFinancialContract = async () => {
    try {
      const result = await findFinancialContract(uuid);
      setFinancialContract(result);
      setContract(result.contract);

      setLoading(false);
    } catch (err) {
      setError({
        title: err.message || GENERIC_ERROR,
        message: err.trace && `Código: ${err.trace}`
      });
      setLoading(false);
    }
  };

  const onConvertCoin = (field) =>
    setConvertCoin((prevValue) => ({ ...prevValue, [field]: !convertCoin[field] }));

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TopBackArea onBack={() => goToBack(navigate)} />
      <Loading loading={loading} />

      <Grid container>
        <Grid item xs={12}>
          <AlertMessage title={error.title} message={error.message} severity={error.severity} />
        </Grid>
      </Grid>

      <Title>
        <h2>
          Informações financeira do contrato {contract.id}
          <span>
            <TargetStatus status={contract.status} variant={'solid'} />
          </span>
        </h2>
        {contract.isConfidential && (
          <p id="confidential">
            <LockIcon /> <span>Confidencial</span>
          </p>
        )}
      </Title>
      <Grid container>
        <LineGrid container>
          <Grid item xs={12} lg={4}>
            <p>Número da conta de débito</p>
            <p>{financialContract?.debit?.toMask('debit-credit')}</p>
          </Grid>
          <Grid item xs={12} lg={8}>
            <p>Número da conta de credito</p>
            <p>{financialContract?.credit?.toMask('debit-credit')}</p>
          </Grid>
        </LineGrid>
        <LineGrid container>
          <Grid item xs={12} lg={4}>
            <p>Periodicidade de pagamento</p>
            <p>{convertReadjustmentPeriodicity(financialContract.paymentPeriodicity)}</p>
          </Grid>
          <Grid item xs={12} lg={4}>
            <p>Parcelas totais</p>
            <p>{financialContract.totalInstallments}</p>
          </Grid>
          <Grid item xs={12} lg={4}>
            <p>Parcelas pagas</p>
            <p>{financialContract.installmentsPaid}</p>
          </Grid>
        </LineGrid>
        <LineGrid container>
          <Grid item xs={12} lg={4}>
            <p>Moeda</p>
            <p>{financialContract?.coin?.title?.toTitleCase()}</p>
          </Grid>
          <Grid item xs={12} lg={4}>
            <p>Cotação inicial usada no contrato</p>
            <p>
              {convertCentsToWhole(
                financialContract.coinHistoryInitial?.exchangeInReal,
                financialContract?.coin?.title
              ) || '-'}
            </p>
          </Grid>
          <Grid item xs={12} lg={4}>
            <p>Cotação atual usada no contrato</p>
            <p>
              {convertCentsToWhole(
                financialContract.coinHistoryCurrent?.exchangeInReal,
                financialContract?.coin?.title
              ) || '-'}
            </p>
          </Grid>
        </LineGrid>
        <LineGrid container>
          <Grid item xs={12} lg={4}>
            <p>
              Valor inicial
              {financialContract?.coin?.title !== CoinName.REAL && (
                <abbr title="Converter para um valor aproximado em real com a cotação inicial (apenas visualização)">
                  <ButtonIcon color="primary" onClick={() => onConvertCoin('initialValue')}>
                    <CurrencyExchangeIcon />
                  </ButtonIcon>
                </abbr>
              )}
            </p>
            <p>
              {convertCoin.initialValue
                ? convertCentsToWhole(
                    convertNewValueCoin(
                      financialContract.initialValue,
                      financialContract.coinHistoryInitial?.exchangeInReal
                    ),
                    'real'
                  )
                : convertCentsToWhole(
                    financialContract.initialValue,
                    financialContract?.coin?.title
                  )}
            </p>
          </Grid>
          {financialContract.currentValue && (
            <Grid item xs={12} lg={4}>
              <p>
                Valor atual
                {financialContract?.coin?.title !== CoinName.REAL && (
                  <abbr title="Converter para um valor aproximado em real com a cotação atual (apenas visualização)">
                    <ButtonIcon color="primary" onClick={() => onConvertCoin('currentValue')}>
                      <CurrencyExchangeIcon />
                    </ButtonIcon>
                  </abbr>
                )}
              </p>

              <p>
                {convertCoin.currentValue
                  ? convertCentsToWhole(
                      convertNewValueCoin(
                        financialContract.currentValue,
                        financialContract.coinHistoryCurrent?.exchangeInReal
                      ),
                      'real'
                    )
                  : convertCentsToWhole(
                      financialContract.currentValue,
                      financialContract?.coin?.title
                    )}
              </p>
            </Grid>
          )}
        </LineGrid>
        <LineGrid container>
          <Grid item xs={12} lg={4}>
            <p>Periodicidade de reajuste</p>
            <p>{convertReadjustmentPeriodicity(financialContract.readjustmentPeriodicity)}</p>
          </Grid>
          <Grid item xs={12} lg={8}>
            <p>Mês de reajuste</p>
            <p>
              {financialContract.readjustmentMonth &&
                convertReadjustmentMonth(financialContract.readjustmentMonth)}
            </p>
          </Grid>
        </LineGrid>

        <LineGrid container>
          <Grid item xs={12} lg={4}>
            <p>Multa por atraso</p>
            <p>{financialContract.lateFee?.toString().replace('.', ',') || 0}%</p>
          </Grid>
          <Grid item xs={12} lg={4}>
            <p>Multa de vencimento</p>
            <p>{financialContract.salaryPenalty?.toString().replace('.', ',')}%</p>
          </Grid>
          <Grid item xs={12} lg={4}>
            <p>Multa rescisória</p>
            <p>
              {financialContract.rescissionFine ? `${financialContract.rescissionFine} dias` : '-'}
            </p>
          </Grid>
        </LineGrid>

        <ButtonGroup>
          <HiddenForPermission keys={'FIND_CONTRACT'}>
            <ButtonPage
              Icon={ViewListIcon}
              onClick={() => goToDatailsContract(navigate, contract?.uuid)}
            >
              Informações básicas
            </ButtonPage>
          </HiddenForPermission>
          <HiddenForPermission keys={['LIST_ADDITIONALS_CONTRACT', 'CREATE_ADDITIONAL_CONTRACT']}>
            <ButtonPage
              Icon={AddToPhotosIcon}
              onClick={() => goToListAdicionalsContract(navigate, contract?.uuid)}
            >
              Contratos adicionais
            </ButtonPage>
          </HiddenForPermission>
          <HiddenForPermission keys={'FIND_FILE_CONTRACT'}>
            <ButtonPage
              Icon={InsertDriveFileIcon}
              onClick={() => goToVisualizeContract(navigate, contract?.uuid)}
            >
              Visualizar contrato
            </ButtonPage>
          </HiddenForPermission>
        </ButtonGroup>
      </Grid>
    </BasePage>
  );
}
