import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Content, Title } from './styled';
import { BasePage } from '../../../../../components/BaseMain';
import { TopBackArea } from '../../../../../components/TopBackArea';
import { goToBack, goToTermsAndGuidelines } from '../../../../../router/coordinator';
import { AlertMessage, Loading, PDFViewer } from '../../../../../components';
import { Grid } from '@mui/material';
import { GENERIC_ERROR } from '../../../../../utils/constantes';
import { getTerm } from '../../../../../services';
import { clearAlertMessage } from '../../../../../utils';

export function DetailsTermsAndGuidelines() {
  const breadcrumbs = [
    { title: 'Menu' },
    { title: 'Termos e diretrizes', functionCoordinator: () => goToTermsAndGuidelines(navigate) },
    { title: 'Visualizar' }
  ];

  const { uuid } = useParams();
  if (!uuid) {
    goToBack(navigate);
  }

  const navigate = useNavigate();
  const [file, setFile] = useState('');
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  useEffect(async () => await init(), []);

  const init = async () => {
    try {
      setLoading(true);

      const file = await getTerm(uuid);
      setFile(file);

      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  };

  function RenderVizualizer() {
    if (file.type === 'html') {
      return (
        <div id="term">
          <div dangerouslySetInnerHTML={{ __html: file.document }} />
        </div>
      );
    }

    if (file.type === 'pdf') {
      return <PDFViewer base64={file.document} />;
    }

    return null;
  }

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TopBackArea onBack={() => goToTermsAndGuidelines(navigate)} />

      <Loading loading={loading} />
      <Grid container>
        <AlertMessage
          title={alertMessage.title}
          message={alertMessage.message}
          severity={alertMessage.severity}
          onClose={() => clearAlertMessage(setAlertMessage)}
        />
      </Grid>

      <Content>
        <Title>{file.name?.toTitleCase()}</Title>

        <RenderVizualizer />
      </Content>
    </BasePage>
  );
}
