import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import { BasePage } from '../../../../../components/BaseMain';
import { goToAdmin, goToBack, goToSectorsList } from '../../../../../router/coordinator';
import {
  AlertMessage,
  Loading,
  Modal,
  TextFieldKeyPress,
  TextFieldNextFocus,
  TitlePages
} from '../../../../../components';
import { clearAlertMessage, isDesktop } from '../../../../../utils';
import { Form } from './styled';
import { GENERIC_ERROR, SECTOR_ACRONYM_LENGTH } from '../../../../../utils/constantes';
import { editSector, findSector } from '../../../../../services';

export function EditSectorPage() {
  const navigate = useNavigate();
  const breadcrumbs = [
    { title: 'Administrativo', functionCoordinator: () => goToAdmin(navigate) },
    { title: 'Setores', functionCoordinator: () => goToSectorsList(navigate) },
    { title: 'Editar' }
  ];

  const [sector, setSector] = useState({
    title: '',
    acronym: ''
  });
  const [errorsValidation, setErrorsValidation] = useState({});
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  const { uuid } = useParams();
  if (!uuid) {
    goToBack(navigate);
  }

  useEffect(async () => await init(), []);

  const init = async () => {
    try {
      setLoading(true);

      const result = await findSector(uuid);

      setSector(result);
      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  };

  // --- Lógica do modal
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const onConfirmModal = async () => {
    await edit();
  };
  // ---

  const disabledButton = () => {
    if (!sector.title) {
      return true;
    }
    if (!sector.acronym) {
      return true;
    }

    return false;
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setSector({ ...sector, [name]: value });
  };

  const validateMandatoryInput = (field) => {
    if (!sector[field]) {
      setErrorsValidation((prevError) => ({ ...prevError, [field]: true }));
      return;
    }
    setErrorsValidation((prevError) => ({ ...prevError, [field]: false }));
  };

  const edit = async () => {
    try {
      setLoading(true);
      handleClose();

      await editSector(uuid, sector.title, sector.acronym);

      setAlertMessage((prev) => ({
        ...prev,
        title: 'Setor editado com sucesso.',
        severity: 'success'
      }));

      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  };

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages title={'Editar setor'} />

      <Loading loading={loading} />

      <Grid container>
        <AlertMessage
          message={alertMessage.message}
          severity={alertMessage.severity}
          title={alertMessage.title}
          onClose={() => clearAlertMessage(setAlertMessage)}
        />
      </Grid>

      <Form container xs={12} rowGap={2} columnSpacing={isDesktop() && 2}>
        <Grid item xs={12} lg={3}>
          <TextFieldNextFocus
            label="Nome"
            name={'title'}
            required
            value={sector.title?.toTitleCase()}
            onChange={onChange}
            onBlur={() => validateMandatoryInput('title')}
            error={errorsValidation.title}
            helperText={errorsValidation.title && 'O nome é obrigatório'}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <TextFieldKeyPress
            label="Sigla"
            name={'acronym'}
            disabled
            value={sector.acronym}
            onChange={onChange}
            inputProps={{ maxLength: SECTOR_ACRONYM_LENGTH }}
          />
        </Grid>
        <Grid item xs={12} className={'buttonGroup twoButtons'}>
          <Button variant="outlined" onClick={() => goToSectorsList(navigate)}>
            Voltar
          </Button>
          <Button onClick={handleOpen} disabled={disabledButton()}>
            Editar
          </Button>
        </Grid>
      </Form>

      <Modal
        open={openModal}
        onClose={handleClose}
        onConfirm={onConfirmModal}
        title={'Editar setor'}
        text={`Tem certeza que deseja editar o setor ${sector.title}? `}
        buttonConfirm={'Sim'}
        buttonCancel={'Não'}
      />
    </BasePage>
  );
}
