import {
  HiddenForPermission,
  MobileTableConteiner,
  SkeletonMobile,
  TableMobileButton,
  TableMobileCell,
  TableMobileContent,
  TableMobileItem,
  TableMobileTitle
} from '../../../../../../components';
import { goToIndexHistory } from '../../../../../../router/coordinator';

export function MobileTable({ indexes, navigate, loading }) {
  const indexesList = indexes?.map((index) => (
    <TableMobileCell key={index.uuid}>
      <TableMobileItem>
        <TableMobileTitle>Índice</TableMobileTitle>
        <TableMobileContent>{index.name?.toUpperCase()}</TableMobileContent>
      </TableMobileItem>

      <TableMobileItem>
        <TableMobileTitle>Descrição</TableMobileTitle>
        <TableMobileContent>{index.description?.toTitleCase() || '-'}</TableMobileContent>
      </TableMobileItem>

      <TableMobileItem>
        <TableMobileTitle>Acumulado 12 meses</TableMobileTitle>
        <TableMobileContent>
          {index.accumulatedValue?.toLocaleString('pt-br', {
            minimumFractionDigits: 4
          }) || '-'}
          %
        </TableMobileContent>
      </TableMobileItem>

      <HiddenForPermission keys="LIST_INDEX_HISTORY">
        <TableMobileButton onClick={() => goToIndexHistory(navigate, index.uuid)}>
          Ver histórico
        </TableMobileButton>
      </HiddenForPermission>
    </TableMobileCell>
  ));

  return (
    <MobileTableConteiner>
      {!loading ? indexesList : <SkeletonMobile numbersOfColumns={2} />}
    </MobileTableConteiner>
  );
}
