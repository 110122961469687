import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { Grid, MenuItem, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { DesktopTable } from './TablesComponents/DesktopTable';
import { MobileTable } from './TablesComponents/MobileTable';
import { Table } from './styled';
import { useForm } from '../../../../hooks/useForm';
import { BasePage } from '../../../../components/BaseMain';
import {
  AlertMessage,
  EmptyTable,
  FilterContainer,
  TargetStatus,
  TitlePages
} from '../../../../components';
import { clearAlertMessage, isDesktop, onChangeDate } from '../../../../utils';
import { Order, Status } from '../../../../utils/enums';
import {
  listCompanies,
  listIndexes,
  listSectors,
  listSegments,
  listTerminationFineContracts
} from '../../../../services';
import { GENERIC_ERROR, INVALID_DATE } from '../../../../utils/constantes';
import { FilterButton } from '../../../../components/FilterButton';
import AutocompleteSearch from '../../../../components/AutocompleteSearch';
import { useUpdateState } from '../../../../hooks/useUpdateState';

export function ListOfContractFines() {
  const navigate = useNavigate();
  const breadcrumbs = [{ title: 'Contratos' }, { title: 'Multa rescisória' }];
  const [{ contracts, loading }, setStates] = useUpdateState({ contracts: [], loading: true });
  const [{ count, page, offset, order, orderBy }, setPaginate] = useUpdateState({
    count: 0,
    page: 0,
    offset: 5,
    order: Order.DESC,
    orderBy: ''
  });
  const [filters, onChange, clearForm] = useForm({
    id: '',
    status: '',
    uuid_segment: '',
    uuid_provider: '',
    uuid_index: '',
    uuid_sector: '',
    provider_is_intern: null,
    start_date: null
  });
  const [debouncedFilter] = useDebounce(filters, 500);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  const listContracts = useCallback(async () => {
    try {
      setStates({ loading: true });

      const filter = handleFilter();
      const result = await listTerminationFineContracts(page, offset, order, filter);

      setStates({ contracts: result.data });
      setPaginate({ count: result.count });
    } catch (error) {
      setAlertMessage({
        title: error?.message || GENERIC_ERROR,
        message: error?.trace && `Código: ${error?.trace}`
      });
    } finally {
      setStates({ loading: false });
    }
  }, [page, offset, order, debouncedFilter]);

  useEffect(() => {
    listContracts();
  }, [listContracts]);

  const handleFilter = () => {
    const startDateFormat = filters.start_date?.format('YYYY-MM-DD');

    const handlerFilter = {
      ...filters,
      order_by: orderBy,
      status: filters?.status === Status.ALL ? undefined : filters?.status,
      start_date: startDateFormat === INVALID_DATE ? undefined : startDateFormat
    };

    return handlerFilter;
  };

  const handlePage = (_event, newPage) => setPaginate({ page: newPage });
  const handleChangeRowsPerPage = (event) => {
    setPaginate({ offset: parseInt(event.target.value, 10), page: 0 });
  };
  const handleOrder = (field) => {
    setPaginate({ orderBy: field, order: order === Order.ASC ? Order.DESC : Order.ASC });
  };

  const getSegments = useCallback(async () => {
    try {
      const data = await listSegments();
      return data;
    } catch (error) {
      setAlertMessage({
        title: error?.message || GENERIC_ERROR,
        message: error?.trace && `Código: ${error?.trace}`
      });
      return [];
    }
  }, []);

  const getIndexes = useCallback(async () => {
    try {
      const data = await listIndexes();
      return data;
    } catch (error) {
      setAlertMessage({
        title: error?.message || GENERIC_ERROR,
        message: error?.trace && `Código: ${error?.trace}`
      });
      return [];
    }
  }, []);

  const getProviders = useCallback(async () => {
    try {
      const data = await listCompanies();
      return data;
    } catch (error) {
      setAlertMessage({
        title: error?.message || GENERIC_ERROR,
        message: error?.trace && `Código: ${error?.trace}`
      });
      return [];
    }
  }, []);

  const getSectors = useCallback(async () => {
    try {
      const data = await listSectors();
      return data;
    } catch (error) {
      setAlertMessage({
        title: error?.message || GENERIC_ERROR,
        message: error?.trace && `Código: ${error?.trace}`
      });
      return [];
    }
  }, []);

  function TableResponsive() {
    if (!contracts?.length && !loading) {
      return <EmptyTable />;
    }

    if (isDesktop()) {
      return (
        <DesktopTable
          contracts={contracts}
          loading={loading}
          count={count}
          page={page}
          offset={offset}
          order={order}
          orderBy={orderBy}
          navigate={navigate}
          handlePage={handlePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleOrder={handleOrder}
        />
      );
    }

    return (
      <MobileTable
        contracts={contracts}
        loading={loading}
        count={count}
        page={page}
        offset={offset}
        navigate={navigate}
        handlePage={handlePage}
      />
    );
  }

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages
        title="Contratos a entrarem em multa rescisória"
        description="Lista os contratos que estão a 30 dias ou menos de entrarem no período de multa rescisória."
      />
      <Grid container>
        <AlertMessage
          message={alertMessage?.message}
          severity={alertMessage?.severity}
          title={alertMessage?.title}
          onClose={() => clearAlertMessage(setAlertMessage)}
        />
      </Grid>

      <FilterContainer>
        <Grid item xs={12} lg={2}>
          <TextField label="Id contrato" name="id" value={filters?.id} onChange={onChange} />
        </Grid>
        <Grid item xs={12} lg={2}>
          <AutocompleteSearch
            label="Segmento"
            name="uuid_segment"
            value={filters?.uuid_segment}
            onChange={onChange}
            optionLabel={['title']}
            getListDataCallback={getSegments}
            formatLabel={(label) => label?.toTitleCase?.()}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <AutocompleteSearch
            label="Índice"
            name="uuid_index"
            value={filters?.uuid_index}
            onChange={onChange}
            optionLabel={['name']}
            getListDataCallback={getIndexes}
            formatLabel={(label) => label?.toUpperCase?.()}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <TextField
            select
            label="Status"
            name="status"
            value={filters?.status}
            onChange={onChange}
          >
            <MenuItem value={Status.ALL}>
              <TargetStatus status={Status.ALL} padding="0" />
            </MenuItem>
            <MenuItem value={Status.ACTIVE}>
              <TargetStatus status={Status.ACTIVE} padding="0" />
            </MenuItem>
            <MenuItem value={Status.INACTIVE}>
              <TargetStatus status={Status.INACTIVE} padding="0" />
            </MenuItem>
            <MenuItem value={Status.PENDING}>
              <TargetStatus status={Status.PENDING} padding="0" />
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} lg={2}>
          <AutocompleteSearch
            label="Fornecedor"
            name="uuid_provider"
            value={filters?.uuid_provider}
            onChange={onChange}
            optionLabel={['name']}
            getListDataCallback={getProviders}
            formatLabel={(label) => label?.toCapitalizeCase?.()}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <AutocompleteSearch
            label="Setor"
            name="uuid_sector"
            value={filters?.uuid_sector}
            onChange={onChange}
            optionLabel={['title']}
            getListDataCallback={getSectors}
            formatLabel={(label) => label?.toCapitalizeCase?.()}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <DatePicker
            label="Data inicio multa rescisória"
            name="start_date"
            value={filters.start_date}
            onChange={(e) => onChangeDate(e, 'start_date', onChange)}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            select
            label="O fornecedor pertence ao grupo graça?"
            name="provider_is_intern"
            value={filters?.provider_is_intern}
            onChange={onChange}
          >
            <MenuItem value={true}>Sim</MenuItem>
            <MenuItem value={false}>Não</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} lg={1}>
          <FilterButton onClick={clearForm} />
        </Grid>
      </FilterContainer>

      <Table>
        <TableResponsive />
      </Table>
    </BasePage>
  );
}
