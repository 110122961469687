import styled from 'styled-components';
import { breakpoints } from '../../../../../../theme/breakpoints';

export const Content = styled.div`
  margin-top: 2rem;

  @media (min-width: ${breakpoints.md}) {
    margin-top: 1rem;
  }

  #table {
    margin-top: 1rem;
  }
`;
