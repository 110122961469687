import { useCallback, useEffect } from 'react';
import { Button, CircularProgress, Grid, InputAdornment, MenuItem, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { Area, BottomArea, LoadingContainer } from './styled';
import {
  formatDate,
  isDesktop,
  onChangeDate,
  onChangeMoney
} from '../../../../../../../../../utils';
import { BorderWithText, HiddenForPermission } from '../../../../../../../../../components';
import { searchCoinQuotation } from '../../../../../../../../../services/coinService';
import { GENERIC_ERROR, INVALID_DATE } from '../../../../../../../../../utils/constantes';
import { convertCentsToWhole } from '../../../../../../../../../utils/convert';
import { listCoins } from '../../../../../../../../../services';
import { CoinName } from '../../../../../../../../../utils/enums';
import { useUpdateState } from '../../../../../../../../../hooks/useUpdateState';

export function CoinArea({
  form,
  setForm,
  onChange,
  coin,
  updateStateGlobal,
  setError,
  isCoinCustom,
  disabledCoin,
  disabledAll,
  isRequired
}) {
  const [{ coins, coinIsReal, loading, localValues }, setStates] = useUpdateState({
    coins: [],
    coinIsReal: false,
    loading: false,
    localValues: { date: '', coin: '', type: '', value: '' }
  });

  const init = useCallback(async () => {
    try {
      const coins = await listCoins();
      setStates({ coins });

      if (isRequired !== true && isRequired !== false) {
        isRequired = true;
      }
    } catch (err) {
      setError({
        title: err.message || GENERIC_ERROR,
        message: err.trace && `Código: ${err.trace}`
      });
    } finally {
      setStates({ loading: false });
    }
  }, []);

  const searchQuotation = async (date, coin, type) => {
    try {
      clearError();
      setStates({ loading: true });

      if (coin === CoinName.REAL) {
        return;
      }

      const formatDate = date?.format('MM-DD-YYYY');
      if (formatDate === INVALID_DATE) {
        if (form.exchang_value) {
          setForm((prev) => ({ ...prev, exchang_value: '' }));
        }

        setStates({ loading: false });
        return;
      }

      const result = await searchCoinQuotation(form.uuid_coin, formatDate, form.exchang_type);

      setForm((prev) => ({
        ...prev,
        exchang_value: result.exchangeInReal,
        uuid_coin_history: result.uuid
      }));

      setStates({
        localValues: {
          ...localValues,
          date,
          coin,
          type
        }
      });
    } catch (err) {
      setError({
        title: err.message || GENERIC_ERROR,
        message: err.trace && `Código: ${err.trace}`
      });
    } finally {
      setStates({ loading: false });
    }
  };

  useEffect(() => {
    init();
  }, [init]);
  useEffect(async () => {
    const { exchange_date: date, uuid_coin: coin, exchang_type: type } = form;

    const hasValue = date && coin && type;
    const changeValue =
      hasValue &&
      (date !== localValues.date || coin !== localValues.coin || type !== localValues.type);

    if (changeValue) {
      await searchQuotation(date, coin, type);
    }
  }, [form]);

  const clearQuotations = () => {
    setForm((prev) => ({
      ...prev,
      exchang_value: '',
      uuid_coin_history: '',
      exchange_date: null,
      exchang_type: ''
    }));
  };

  useEffect(() => {
    if (form?.uuid_coin) {
      setStates({ coinIsReal: coin === CoinName.REAL });
      clearQuotations();
    }
  }, [form?.uuid_coin]);

  const clearError = () =>
    setError({
      title: '',
      severity: '',
      message: ''
    });

  const onChangeCoin = (e) => {
    const coinUUID = e?.target?.value;
    const result = coins.find((c) => c.uuid === coinUUID);

    updateStateGlobal({ currencySymbol: result?.title, coin: result?.title });
    onChange(e);
  };

  function HandleLoading() {
    return (
      loading && (
        <LoadingContainer>
          <CircularProgress size={25} />
        </LoadingContainer>
      )
    );
  }

  function HandleResultPrice() {
    const showResult =
      form.uuid_coin && form.exchange_date && form.exchang_type && form.exchang_value && !loading;

    return (
      <p className="price">
        {showResult && (
          <span>
            No dia {formatDate(form.exchange_date)} o câmbio {form.exchang_type?.toLowerCase()} do{' '}
            {coin && coin?.toLowerCase()} era {convertCentsToWhole(form.exchang_value)}
          </span>
        )}
      </p>
    );
  }

  const changeIsCustomCoin = () => {
    setForm({ ...form, exchang_value: '', exchange_date: '' });
    updateStateGlobal({ isCustomCoin: !isCoinCustom });
  };

  function RenderButtomArea() {
    return (
      <BottomArea>
        <HandleLoading />

        {isCoinCustom ? (
          <>
            <p className="price"></p>

            <Button
              variant="outlined"
              className="custom"
              disabled={loading || coinIsReal}
              onClick={changeIsCustomCoin}
            >
              <AccountBalanceIcon />
              Usar um valor definido pelo banco central
            </Button>
          </>
        ) : (
          <HiddenForPermission keys="CREATE_HISTORY_COIN_PERSONALITY">
            <HandleResultPrice />
            <Button
              variant="outlined"
              className="defined"
              disabled={loading || coinIsReal}
              onClick={changeIsCustomCoin}
            >
              <EditIcon />
              Personalizar valor
            </Button>
          </HiddenForPermission>
        )}
      </BottomArea>
    );
  }

  return (
    <BorderWithText title={'Moeda'}>
      <Area container columnSpacing={isDesktop() && 2}>
        <Grid item xs={12} lg={3}>
          <TextField
            select
            required={isRequired}
            label="Moeda"
            name="uuid_coin"
            value={form.uuid_coin}
            onChange={onChangeCoin}
            disabled={loading || disabledCoin}
          >
            {coins.map((c) => (
              <MenuItem key={c.uuid} value={c.uuid}>
                {c.title?.toTitleCase()}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} lg={5}>
          {isCoinCustom ? (
            <TextField
              required={isRequired}
              label="Digite o valor da cotação combinado para esse contrato"
              name="exchang_value"
              value={form.exchang_value}
              onChange={(e) => onChangeMoney(e, onChange)}
              disabled={coinIsReal}
              InputProps={{
                startAdornment: <InputAdornment position="start">R$</InputAdornment>
              }}
            />
          ) : (
            <DatePicker
              required={isRequired}
              label={`Data do câmbio ${isRequired ? '*' : ''}`}
              name="exchange_date"
              value={form.exchange_date}
              maxDate={dayjs()}
              disabled={loading || disabledAll || coinIsReal}
              onChange={(e) => onChangeDate(e, 'exchange_date', onChange)}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            select
            required={isRequired}
            disabled={loading || disabledAll || coinIsReal}
            name="exchang_type"
            value={form.exchang_type}
            onChange={onChange}
            label="Tipo de câmbio"
          >
            <MenuItem value={'COMERCIAL'}>Comercial</MenuItem>
          </TextField>
        </Grid>
      </Area>

      <RenderButtomArea />
    </BorderWithText>
  );
}
