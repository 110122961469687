import { Avatar } from '@mui/material';
import { Container } from './styled';

/**
 * Componente contém image, nome e documento do usuário
 * @returns
 */
export function UserInformations({ user, src }) {
  return (
    <Container>
      <Avatar src={src} />
      <div>
        <strong>
          {user.name && user.name.toTitleCase()} {user.lastName && user.lastName.toTitleCase()}
        </strong>
        <p>{user.document && user.document.toMask('document')}</p>
      </div>
    </Container>
  );
}
