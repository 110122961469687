import { api } from '../utils/axiosConfig';

/**
 * Listar as permissões de um template
 * @returns
 */
export const listAllPermissionsOfTemplate = async (uuidOffice) => {
  try {
    const { data } = await api.get(`/template/find/${uuidOffice}`);
    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Muda as permissões do usuário em uma empresa
 * @param {*} userUUID - identificador do usuários
 * @param {*} payload - chave: chave da permissão | valor: true ou false
 * @returns
 */
export const editTemplate = async (uuidOffice, payload) => {
  try {
    const { data } = await api.put(`/template/office/${uuidOffice}`, payload);
    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};
