import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import WorkIcon from '@mui/icons-material/Work';
import BadgeIcon from '@mui/icons-material/Badge';
import WidgetsIcon from '@mui/icons-material/Widgets';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BusinessIcon from '@mui/icons-material/Business';
import SegmentIcon from '@mui/icons-material/Segment';
import GppGoodIcon from '@mui/icons-material/GppGood';
import { ButtonGroup, Subtitle } from './styled';
import { BasePage } from '../../../../components/BaseMain';
import { TitlePages } from '../../../../components/TitlePages';
import { ButtonPage } from '../../../../components/ButtonPage';
import {
  goToBack,
  goToCoinList,
  goToCompanyInformation,
  goToCreateHelpFile,
  goToIndexList,
  goToIndustryList,
  goToListCompanies,
  goToProvidersList,
  goToSectorsList,
  goToSegmentList,
  goToTemplatesList,
  goToUsersList
} from '../../../../router/coordinator';
import { getItemLocalStorage } from '../../../../services/localStorageService';
import { HiddenForPermission } from '../../../../components/HiddenForPermission';
import HelpIcon from '@mui/icons-material/Help';

export function PainelAdmin() {
  const navigate = useNavigate();
  const breadcrumbs = [{ title: 'Administrativo' }];

  const [company, setCompany] = useState('');

  const user = getItemLocalStorage('user');
  if (!user) {
    goToBack(navigate);
  }

  useEffect(() => {
    const result = getItemLocalStorage('user');
    setCompany(result?.company);
  }, []);

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages
        title={'Painel administrativo'}
        description={'Ações relacionadas a administração do sistema estarão neste painel.'}
      />

      <Subtitle>Ações relacionadas a {company?.toCapitalizeCase?.()}</Subtitle>

      <ButtonGroup>
        <HiddenForPermission keys={['LIST_USER_ADMIN', 'REGISTER_USER_ADMIN']}>
          <ButtonPage Icon={AccountCircleIcon} onClick={() => goToUsersList(navigate)}>
            Usuários da {company}
          </ButtonPage>
        </HiddenForPermission>
        <HiddenForPermission keys={'FIND_COMPANY'}>
          <ButtonPage
            Icon={WorkIcon}
            onClick={() => goToCompanyInformation(navigate, user.company_uuid)}
          >
            Informações da {company}
          </ButtonPage>
        </HiddenForPermission>
      </ButtonGroup>

      <Subtitle>Visão geral do sistema</Subtitle>

      <ButtonGroup>
        <HiddenForPermission keys={['CREATE_COMPANY', 'LIST_ACCESS_COMPANY']}>
          <ButtonPage Icon={BadgeIcon} onClick={() => goToListCompanies(navigate)}>
            Empresas de acesso
          </ButtonPage>
        </HiddenForPermission>
        <HiddenForPermission keys={['LIST_SECTORS', 'CREATE_SECTOR']}>
          <ButtonPage Icon={WidgetsIcon} onClick={() => goToSectorsList(navigate)}>
            Setores dos contratos
          </ButtonPage>
        </HiddenForPermission>
        <HiddenForPermission keys={['LIST_PROVIDERS', 'CREATE_PROVIDER']}>
          <ButtonPage Icon={FactCheckIcon} onClick={() => goToProvidersList(navigate)}>
            Fornecedores e clientes
          </ButtonPage>
        </HiddenForPermission>
        <HiddenForPermission keys={['LIST_COINS', 'CREATE_COIN']}>
          <ButtonPage Icon={LocalAtmIcon} onClick={() => goToCoinList(navigate)}>
            Moedas
          </ButtonPage>
        </HiddenForPermission>
        <HiddenForPermission keys={['LIST_INDUSTRY', 'CREATE_INDUSTRY']}>
          <ButtonPage Icon={BusinessIcon} onClick={() => goToIndustryList(navigate)}>
            Setores de atuação das empresas
          </ButtonPage>
        </HiddenForPermission>
        <HiddenForPermission keys={['LIST_SEGMENT', 'CREATE_SEGMENT']}>
          <ButtonPage Icon={SegmentIcon} onClick={() => goToSegmentList(navigate)}>
            Segmentos do contrato
          </ButtonPage>
        </HiddenForPermission>
        <HiddenForPermission keys={['LIST_INDEX', 'CREATE_INDEX']}>
          <ButtonPage Icon={AssignmentIcon} onClick={() => goToIndexList(navigate)}>
            Índices
          </ButtonPage>
        </HiddenForPermission>
        <HiddenForPermission keys="EDIT_TEMPLATE">
          <ButtonPage Icon={GppGoodIcon} onClick={() => goToTemplatesList(navigate)}>
            Permissões padrões dos cargos
          </ButtonPage>
        </HiddenForPermission>
        <HiddenForPermission keys="CREATE_HELP_FILE">
          <ButtonPage Icon={HelpIcon} onClick={() => goToCreateHelpFile(navigate)}>
            Arquivo da tela de ajuda
          </ButtonPage>
        </HiddenForPermission>
      </ButtonGroup>
    </BasePage>
  );
}
