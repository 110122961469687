import { InputLabel, Stack } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useState } from 'react';
import { ButtonUpload, ContainerButtonUpload, FileUploadDescription } from './styled';
import { convertBlobToBase64 } from '../../utils/convert';

const MAX_CARACTERS_NAME_FILE = 26;

export function UploadButton({
  text,
  setImage,
  setOutImageName,
  outImageName,
  noConvertToBase64,
  noImageMassege
}) {
  const [imageName, setImageName] = useState('');

  const onImageChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      const fileName = event.target.files[0].name;
      setImageName(fileName);
      setOutImageName(fileName);

      if (noConvertToBase64) {
        setImage(event.target.files[0]);
        return;
      }

      const imageInBase64 = await convertBlobToBase64(event.target.files[0]);
      setImage(imageInBase64);
    }
  };

  const handleImageName = () => {
    const name = imageName || outImageName;
    if (!name) {
      return noImageMassege || 'Nenhuma imagem selecionada';
    }

    const isNameBig = name.length > MAX_CARACTERS_NAME_FILE;
    return isNameBig ? `${name.substring(0, MAX_CARACTERS_NAME_FILE)}...` : name;
  };

  return (
    <ContainerButtonUpload item xs={12}>
      <InputLabel>{text || 'Foto de perfil'}</InputLabel>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <ButtonUpload variant="text" component="label" startIcon={<UploadFileIcon />}>
          Carregar
          <input hidden onChange={onImageChange} type="file" />
        </ButtonUpload>
        <FileUploadDescription>{handleImageName()}</FileUploadDescription>
      </Stack>
    </ContainerButtonUpload>
  );
}
