import { isDesktop } from '../../utils';
import { Container } from './styled';
import { SwipperMenu } from '../lateralMenu/SwipperMenu';
import { goToHelpFileList, goToHome } from '../../router/coordinator';
import { Button } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

export function Header({ navigate }) {
  return (
    <Container>
      <div id="contentMenu">{!isDesktop() && <SwipperMenu navigate={navigate} />}</div>
      <h1 onClick={() => goToHome(navigate)}>SISTEMA GERAL DE CONTRATOS GRAÇA</h1>
      <abbr title="Ajuda">
        <Button variant="text" onClick={() => goToHelpFileList(navigate)}>
          <HelpIcon />
        </Button>
      </abbr>
    </Container>
  );
}
