// Status possível para usuário e contrato
export const Status = {
  ACTIVE: 'ativo',
  INACTIVE: 'inativo',
  PENDING: 'pendente',
  BLOCKED: 'bloqueado',
  DELETED: 'deletado',
  REPROVED: 'reprovado',
  ALL: 'todos',
  ADDITIVE: 'aditivo',
  DISTRACTION: 'distrato'
};

// Categorias das permissões
export const CategoriesPermission = {
  OTHER: 'OTHER',
  COMPANY: 'COMPANY',
  CONFIG: 'CONFIG',
  ADMIN: 'ADMIN',
  USER: 'USER',
  CONTRACT: 'CONTRACT',
  DASHBOARD: 'DASHBOARD',
  SECTORS: 'SECTORS',
  PROVIDER: 'PROVIDER',
  INDEXES: 'INDEXES',
  COINS: 'COINS',
  SECURITY: 'SECURITY',
  FINANCIAL: 'FINANCIAL',
  INDUSTRY: 'INDUSTRY'
};

// Ordem de uma tabelas
export const Order = {
  ASC: 'asc',
  DESC: 'desc'
};

// Nomeclatura de cada moeda
export const Coin = {
  DOLAR: 'USD',
  EURO: 'EUR',
  REAL: 'BRL',
  CANADIAN_DOLLAR: 'CAD',
  AUSTRALIAN_DOLLAR: 'AUD',
  IENE: 'JPY',
  CHINESE_YUAN: 'CNY'
};

// Nome de cada
export const CoinName = {
  DOLAR: 'DOLAR',
  EURO: 'EURO',
  REAL: 'REAL'
};

// Nome de cada
export const Offices = {
  ADMIN: 'ADMINISTRADOR'
};

// Valores gerais
export const Values = {
  NO_INDEX: 'no-index'
};

export const PersonType = {
  PF: 'pf',
  PJ: 'pj'
};

export const LocalStorageKeys = {
  CREATE_CONTRACT: 'create_contract',
  USER: 'user'
};
