import { useNavigate, useParams } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import { ActiveButton, InactiveButton, LineGrid } from './styled';
import { BasePage } from '../../../../../components/BaseMain';
import { goToAdmin, goToBack, goToEditCompanyInformation } from '../../../../../router/coordinator';
import { TitlePages } from '../../../../../components/TitlePages';
import { useEffect, useState } from 'react';
import {
  AlertMessage,
  HiddenForPermission,
  Loading,
  Modal,
  TargetStatus
} from '../../../../../components';
import { activeOrInactiveCompany, getCompanyByUUID } from '../../../../../services';
import { Status } from '../../../../../utils/enums';
import { GENERIC_ERROR } from '../../../../../utils/constantes';
import { CopyText } from '../../../../../components/CopyText';
import { clearAlertMessage } from '../../../../../utils';

export function CompanyInformationsPage() {
  const navigate = useNavigate();

  const { uuid } = useParams();
  if (!uuid) {
    goToBack(navigate);
  }

  const [isActive, setIsActive] = useState(true);
  const [company, setCompany] = useState({});
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  const breadcrumbs = [
    { title: 'Administrativo', functionCoordinator: () => goToAdmin(navigate) },
    { title: 'Empresa' },
    { title: 'Detalhes' }
  ];

  useEffect(async () => await init(), []);

  const init = async () => {
    try {
      const companyInformations = await getCompanyByUUID(uuid);
      setCompany(companyInformations);
      setIsActive(companyInformations.active);
      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  };

  // --- Lógica do modal
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const onConfirmModal = async () => {
    try {
      setLoading(true);
      handleClose();

      await activeOrInactiveCompany(uuid);
      await init();

      setLoading(false);
    } catch (err) {
      setAlertMessage({
        title: err.message || GENERIC_ERROR,
        message: err.trace && `Código: ${err.trace}`
      });
      setLoading(false);
    }
  };
  // ---

  function HandleActiveButton() {
    return isActive ? (
      <InactiveButton onClick={handleOpen}>Inativar empresa</InactiveButton>
    ) : (
      <ActiveButton onClick={handleOpen}>Ativar empresa</ActiveButton>
    );
  }

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages
        title="Detalhes da empresa"
        ButtonRight={HandleActiveButton}
        keyPermissionButton="ACTIVE_INACTIVE_COMPANY"
      />

      <Loading loading={loading} />
      <Grid container>
        <AlertMessage
          message={alertMessage.message}
          severity={alertMessage.severity}
          title={alertMessage.title}
          onClose={() => clearAlertMessage(setAlertMessage)}
        />
      </Grid>

      <Grid container>
        <Loading loading={loading} light={true} />
        <LineGrid container>
          <Grid item xs={12} lg={4}>
            <p>Nome</p>
            <p>{company?.name?.toTitleCase()}</p>
          </Grid>
          <Grid item xs={12} lg={4}>
            <p>Sigla</p>
            <p>{company?.acronym?.toUpperCase() || '-'}</p>
          </Grid>
          <Grid item xs={12} lg={4}>
            <p>Status</p>
            <p>
              <TargetStatus
                id="status"
                variant={'solid'}
                status={company?.active ? Status.ACTIVE : Status.INACTIVE}
              />
            </p>
          </Grid>
        </LineGrid>

        <LineGrid container>
          <Grid item xs={12} lg={4}>
            <p>Documento</p>
            <CopyText
              onCopy={() =>
                setAlertMessage({
                  title: 'Número do documento copiado!',
                  severity: 'success'
                })
              }
            >
              {company?.document?.toMask('document')}
            </CopyText>
          </Grid>
          <Grid item xs={12} lg={4}>
            <p>Setor de atuação</p>
            <p>{company?.industry?.title?.toTitleCase()}</p>
          </Grid>
          {company?.domain && (
            <Grid item xs={12} lg={4}>
              <p>Domínio</p>
              <p>{company?.domain}</p>
            </Grid>
          )}
        </LineGrid>

        <LineGrid container>
          <Grid item xs={12} lg={4}>
            <p>Número de contratos na empresa</p>
            <p>{company?.numberOfContracts}</p>
          </Grid>
          <Grid item xs={12} lg={8}>
            <p>Faz parte das Empresas e Instituições da Graça?</p>
            <p>{company?.isIntern ? 'Sim' : 'Não'}</p>
          </Grid>
        </LineGrid>

        <LineGrid container className={'buttonGroup twoButtons'}>
          <Button variant="outlined" onClick={() => goToBack(navigate)}>
            Voltar
          </Button>
          <HiddenForPermission keys={'EDIT_COMPANY'}>
            <Button onClick={() => goToEditCompanyInformation(navigate, uuid)}>Editar</Button>
          </HiddenForPermission>
        </LineGrid>
      </Grid>

      <Modal
        open={openModal}
        onClose={handleClose}
        onConfirm={onConfirmModal}
        title={'Criar nova empresa'}
        text={`Tem certeza que deseja criar a ${isActive ? 'INATIVAR' : 'ATIVAR'} essa empresa? `}
        warning={
          isActive &&
          'Essa empresa não poderá mais ser usada no cadastro de contratos enquanto inativa'
        }
        buttonConfirm={'Sim'}
        buttonCancel={'Não'}
      />
    </BasePage>
  );
}
