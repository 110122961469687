import { Grid } from '@mui/material';
import styled from 'styled-components';
import { breakpoints } from '../../../../../theme/breakpoints';

export const Content = styled(Grid)`
  #upload-button {
    margin-top: 1rem;
  }

  .buttonGroup {
    margin-top: 2rem;
  }

  @media (min-width: ${breakpoints.md}) {
    padding: 1.5rem 3rem;
  }
`;

export const Price = styled.p`
  padding-top: 1rem;
  font-weight: 600;
  font-size: 1.1rem;
`;
