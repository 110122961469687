import styled from 'styled-components';
import { breakpoints } from '../../../../../theme/breakpoints';

export const Container = styled.div`
  margin-top: 1.5rem;

  #stepper {
    @media (min-width: ${breakpoints.md}) {
      margin-top: 2rem;
    }
  }
`;

export const StepperContent = styled.div`
  width: 80%;
  margin: 2rem auto;
  padding: 1rem;
`;

export const ButtonGroup = styled.div`
  margin-top: 1rem;
`;
