import CircleIcon from '@mui/icons-material/Circle';

import { Target } from './styled';
import { translateStatusColor } from '../../utils';
import { translateStatus } from '../../utils/translate';

/**
 * Componente para exibir o status do alvo.
 *
 * @param {Object} props - As propriedades do componente.
 * @param {string} props.status - O status do alvo.
 * @param {string} props.variant - A variante do componente [solid || null].
 * @returns {JSX.Element|null} O componente de status do alvo.
 */
export function TargetStatus({ status, variant, padding, noCircle, ...props }) {
  if (!status) {
    return null;
  }

  const { backgroundColor, colorsStatus } = translateStatusColor(status);

  return (
    <Target
      {...props}
      padding={padding}
      color={colorsStatus}
      variant={variant}
      backgroundColor={backgroundColor}
    >
      {!noCircle && <CircleIcon />}
      {translateStatus(status)?.toTitleCase()}
    </Target>
  );
}
