import { Grid, InputAdornment } from '@mui/material';
import { BorderWithText, TextFieldNextFocus } from '../../../../../../../../components';
import { handleCoin, isDesktop, onChangeMoney } from '../../../../../../../../utils';
import { useState } from 'react';

export function ValueArea({ form, onChange, coin }) {
  const [errorsValidation, setErrorsValidation] = useState({});

  /**
   * Gera erro se um input obrigatório não está preenchido
   * @param {*} field - campo a ser verificado
   * @returns
   */
  const validateMandatoryInput = (field) => {
    if (!form[field] || (!form[field] && form[field] !== 0)) {
      setErrorsValidation((prevError) => ({ ...prevError, [field]: true }));
      return;
    }
    setErrorsValidation((prevError) => ({ ...prevError, [field]: false }));
  };

  return (
    <BorderWithText title={'Valores'}>
      <Grid container spacing={isDesktop() && 2}>
        <Grid item xs={12}>
          <TextFieldNextFocus
            label={'Valor do contrato'}
            disabled={!form.uuid_coin}
            value={form.initial_value}
            name="initial_value"
            onChange={(e) => onChangeMoney(e, onChange)}
            required
            onBlur={() => validateMandatoryInput('initial_value')}
            error={errorsValidation.initial_value}
            helperText={errorsValidation.initial_value && 'O valor inicial é obrigatório.'}
            InputProps={{
              startAdornment: <InputAdornment position="start">{handleCoin(coin)}</InputAdornment>
            }}
          />
        </Grid>
      </Grid>
    </BorderWithText>
  );
}
