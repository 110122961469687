import { api } from '../utils/axiosConfig';

/**
 * Lista todos os setores de atuação com paginação
 * @param {*} initialPage - Página de inicio
 * @param {*} offset - Itens por página
 * @param {*} order - ordem [asc, desc]
 * @param {*} filter - filtros
 * @returns
 */
export const listAllIndustries = async (initialPage, offset, order = 'ASC', filter = {}) => {
  try {
    const page = initialPage + 1;
    const { data } = await api.get(`/industry/list`, {
      params: {
        initial_page: page,
        offset,
        order,
        ...filter
      }
    });

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Cadastrar um setor de atuação
 * @param {*} title - nome
 * @param description - descrição
 * @returns
 */
export const createIndustry = async (title, description) => {
  try {
    const payload = {
      title,
      description
    };
    const { data } = await api.post(`/industry/create`, payload);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

export const editIndustry = async (uuidIndustry, title, description) => {
  try {
    const payload = {
      title,
      description
    };
    const { data } = await api.put(`/industry/${uuidIndustry}/edit`, payload);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

export const findIndustry = async (uuidIndustry) => {
  try {
    const { data } = await api.get(`/industry/${uuidIndustry}/find`);
    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};
