import styled from 'styled-components';
import { colors, metrics } from '../../../theme/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Grid, Button as ButtonMUI } from '@mui/material';
import { isDesktop } from '../../../utils';

export const Content = styled.div`
  text-align: center;
  width: 80%;
`;

export const Icon = styled(AccountCircleIcon)`
  height: ${metrics.sizeIconCredential};
  width: ${metrics.sizeIconCredential};
`;

export const Title = styled.h1`
  font-size: ${() => (isDesktop() ? '20pt' : '16pt')};
  color: ${colors.theme.primary};
  margin: 0;
`;

export const SubTitle = styled.sub`
  font-size: ${() => (isDesktop() ? '20pt' : '16pt')};
  margin: 0;
`;

export const Form = styled(Grid)`
  width: 100%;
  padding-top: 2rem;
`;

export const Button = styled(ButtonMUI)`
  && {
    width: 100%;
    margin-top: 1rem;
  }
`;

export const Links = styled.div`
  width: 100%;
  text-align: right;
  margin: 1rem 0;

  p {
    margin: 0;
    margin-top: 0.5rem;
  }
`;
