import styled from 'styled-components';
import { breakpoints } from '../../theme/breakpoints';

export const ContainerPDF = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  position: relative;
  overflow-y: auto;
  /* max-height: calc(90vh - 95px); */
  margin: auto;
  width: 100%;
  margin-top: 1rem;
  height: 100vh;

  @media (min-width: ${breakpoints.md}) {
    /* max-height: calc(90vh - 165px); */
  }
`;

export const ContainerLoading = styled.div`
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ToolbarContent = styled.div`
  display: flex;
  align-items: center;
  background-color: #eeeeee;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  bottom: 16px;
  left: 50%;
  padding: 4px;
  position: absolute;
  transform: translate(-50%, 0);
  z-index: 1;

  .rpv-core__tooltip-body {
    pointer-events: none;
    background-color: red;
    display: none;
  }

  @media (min-width: ${breakpoints.md}) {
    flex-direction: row;
    width: auto;
  }

  .item-toolbar {
    display: flex;
    align-items: center;
  }

  #zoom {
    @media (min-width: ${breakpoints.md}) {
      margin-right: 2rem;
    }
  }

  #others {
    margin-left: 2rem;
  }

  #item-empty {
    width: 32px;
  }
`;

export const ContainerViewer = styled.div`
  flex: 1;
  overflow: hidden;
  max-height: 100vh;
`;
