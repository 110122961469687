import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BasePage } from '../../../../../../components/BaseMain';
import {
  goToAdmin,
  goToBack,
  goToDatailsUser,
  goToUsersList
} from '../../../../../../router/coordinator';
import {
  AlertMessage,
  EmptyTable,
  Loading,
  TitlePages,
  TopBackArea
} from '../../../../../../components';
import { clearAlertMessage, fullname, isDesktop } from '../../../../../../utils';
import { DesktopTable } from './TablesComponent/DesktopTable';
import { Content } from './styled';
import { MobileTable } from './TablesComponent/MobileTable';
import { Order } from '../../../../../../utils/enums';
import { findUser, listContractsAddedByUser } from '../../../../../../services';
import { GENERIC_ERROR } from '../../../../../../utils/constantes';

export function UserAddedContactList() {
  const [user, setUser] = useState({});
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(5);
  const [order, setOrder] = useState(Order.DESC);
  const [orderBy, setOrderBy] = useState('');

  const navigate = useNavigate();

  const { uuid } = useParams();
  if (!uuid) {
    goToBack(navigate);
  }

  const breadcrumbs = [
    { title: 'Administrativo', functionCoordinator: () => goToAdmin(navigate) },
    { title: 'Usuários', functionCoordinator: () => goToUsersList(navigate) },
    { title: 'Detalhes', functionCoordinator: () => goToDatailsUser(navigate, uuid) },
    { title: 'Contratos adicionados' }
  ];

  useEffect(async () => await init(), []);
  useEffect(async () => await list(), [page, offset, order]);

  const init = async () => {
    try {
      setLoading(true);

      const result = await Promise.all([
        listContractsAddedByUser(uuid, page, offset, order, orderBy),
        findUser(uuid)
      ]).then((res) => ({ contracts: res[0], user: res[1] }));

      setContracts(result.contracts.data);
      setCount(result.contracts.count);
      setUser(result.user);

      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  };

  const list = async () => {
    try {
      setLoading(true);

      const result = await listContractsAddedByUser(uuid, page, offset, order, orderBy);

      setContracts(result.data);
      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  };

  const handlePage = (_event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setOffset(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleOrder = (field) => {
    setOrderBy(field);

    if (order === Order.ASC) {
      setOrder(Order.DESC);
      return;
    }

    setOrder(Order.ASC);
  };

  function TableResponsive() {
    if (!contracts?.length && !loading) {
      return <EmptyTable />;
    }

    if (isDesktop()) {
      return (
        <DesktopTable
          contracts={contracts}
          loading={loading}
          count={count}
          page={page}
          offset={offset}
          order={order}
          orderBy={orderBy}
          navigate={navigate}
          handlePage={handlePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleOrder={handleOrder}
        />
      );
    }

    return (
      <MobileTable
        contracts={contracts}
        loading={loading}
        count={count}
        page={page}
        offset={offset}
        navigate={navigate}
        setPage={setPage}
        setOffset={setOffset}
        handlePage={handlePage}
      />
    );
  }

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TopBackArea onBack={() => goToDatailsUser(navigate, uuid)} />
      <Content>
        <TitlePages
          title={`Lista de contratos adicionados por ${fullname(user.name, user.lastName)}`}
          toHideDivider={true}
        />

        <Loading loading={loading} />

        <AlertMessage
          message={alertMessage.message}
          severity={alertMessage.severity}
          title={alertMessage.title}
          onClose={() => clearAlertMessage(setAlertMessage)}
        />

        <div id="table">
          <TableResponsive />
        </div>
      </Content>
    </BasePage>
  );
}
