import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Grid, InputAdornment } from '@mui/material';
import { Form } from './styled';
import {
  goToAdmin,
  goToBack,
  goToCoinHistory,
  goToCoinList
} from '../../../../../router/coordinator';
import { useForm } from '../../../../../hooks/useForm';
import { BasePage } from '../../../../../components/BaseMain';
import {
  AlertMessage,
  Loading,
  Modal,
  TextFieldKeyPress,
  TitlePages
} from '../../../../../components';
import { clearAlertMessage, clearPercent, isDesktop, onChangeDate } from '../../../../../utils';
import { createHistoricIndex, findIndex } from '../../../../../services/indexService';
import { GENERIC_ERROR } from '../../../../../utils/constantes';
import { DatePicker } from '@mui/x-date-pickers';
import { InformativeText } from '../../../../../components/InformativeText';

export function CreateIndexHistoryPage() {
  const navigate = useNavigate();

  const { uuid } = useParams();
  if (!uuid) {
    goToBack(navigate);
  }

  const [errorsValidation, setErrorsValidation] = useState({});

  const [index, setIndex] = useState({});
  const breadcrumbs = [
    { title: 'Administrativo', functionCoordinator: () => goToAdmin(navigate) },
    { title: 'Índice', functionCoordinator: () => goToCoinList(navigate) },
    { title: 'Histórico', functionCoordinator: () => goToCoinHistory(navigate, uuid) },
    { title: 'Cadastrar' }
  ];

  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  const [form, onChange, clearForm] = useForm({
    percent: '',
    date: null,
    is_actual: ''
  });

  useEffect(async () => await init(), []);

  const init = async () => {
    try {
      setLoading(true);

      const result = await findIndex(uuid);

      setIndex(result);

      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  };

  // --- Lógica do modal
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const onConfirmModal = async () => {
    try {
      setLoading(true);
      handleClose();

      const { percent, date } = form;
      const percentClear = percent && clearPercent(percent);

      await createHistoricIndex(uuid, percentClear, date);

      setAlertMessage((prev) => ({
        ...prev,
        title: 'Valor para o índice criado com sucesso.',
        severity: 'success'
      }));

      clearForm();
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
    } finally {
      setLoading(false);
    }
  };
  // ---

  const disabledButton = () => {
    if (!form.percent) {
      return true;
    }

    return false;
  };

  const validateMandatoryInput = (field) => {
    if (!form[field]) {
      setErrorsValidation((prevError) => ({ ...prevError, [field]: true }));
      return;
    }
    setErrorsValidation((prevError) => ({ ...prevError, [field]: false }));
  };

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages
        title={`Cadastrar novo valor para o índice ${index?.name}`}
        description="O valor adicionado aqui ficará atrelado ao mês e ano escolhidos."
      />

      <Loading loading={loading} />

      <Grid container>
        <AlertMessage
          message={alertMessage.message}
          severity={alertMessage.severity}
          title={alertMessage.title}
          onClose={() => clearAlertMessage(setAlertMessage)}
        />
      </Grid>

      <Form container xs={12} rowGap={2} columnSpacing={isDesktop() && 2}>
        <Grid item xs={12} lg={3}>
          <TextFieldKeyPress
            label="Valor em Porcentagem"
            name="percent"
            required
            value={form.percent}
            onChange={onChange}
            onBlur={() => validateMandatoryInput('percent')}
            error={errorsValidation.percent}
            helperText={
              errorsValidation.percent
                ? 'A porcentagem é obrigatória'
                : 'O valor pode conter até 4 casas decimais. Exemplo: 1,0450'
            }
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <DatePicker
            label="Data referente a esse índice *"
            value={form.date}
            disableFuture={true}
            required
            name="date"
            format="MM/YYYY"
            views={['year', 'month']}
            onChange={(e) => onChangeDate(e, 'date', onChange)}
          />
        </Grid>

        <InformativeText />

        <Grid item xs={12} className="buttonGroup twoButtons nearTheTop">
          <Button variant="outlined" onClick={() => goToBack(navigate)}>
            Voltar
          </Button>
          <Button onClick={handleOpen} disabled={disabledButton()}>
            Cadastrar
          </Button>
        </Grid>
      </Form>

      <Modal
        open={openModal}
        onClose={handleClose}
        onConfirm={onConfirmModal}
        title="Criar novo valor ao índice"
        text={`Tem certeza que deseja adicionar o valor ${form.percent}% ao índice '${index?.name}'? `}
        warning="Após ser adicionado, o valor não pode ser excluido ou alterado."
      />
    </BasePage>
  );
}
