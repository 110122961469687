import { createGlobalStyle } from 'styled-components';
import { colors } from './styles';
import { breakpoints } from './breakpoints';

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${colors.theme.background};
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  textarea:focus,
  input,
  select:focus {
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
  }

  button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;

    background: transparent;
  }

  h1, h2, h3, h4, h5, h6, p{
    margin: 0;
  }

  .MuiListItemIcon-root {
      &&{
          min-width: 35px;
      }
  }

  .buttonGroup {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 1rem;

    button {
      width: 100%;
      
      @media (min-width: ${breakpoints.md}) {
       width: 10rem;
      }
    }
  }

  .buttonGroup.oneButton{
    justify-content: flex-end;
  }

  .buttonGroup.twoButtons{
    flex-direction: column;
    gap: .5rem;

    @media (min-width: ${breakpoints.md}) {
      flex-direction: row;
      justify-content: space-between;
      gap: 0;
    }
  }

  .buttonGroup.nearTheTop{
    padding-top: 0;
    margin-top: 0;
  }

  tr:nth-child(even) {
    background: #F1EFEF;
  }

  .MuiTableRow-root {
    cursor: pointer;
 
    &:hover {
      opacity: 0.8;
    } 
  }

  // Cabeçalho da tabela com a cor branca
  .MuiTableCell-root.MuiTableCell-head {
    color: white;
    white-space: nowrap;
    cursor: auto;
  }

  .MuiTableRow-root.not-effect {
    cursor: auto;

    &:hover {
      opacity: 1;
    }
  }  

  .MuiButtonBase-root.MuiTableSortLabel-root {
    &&{
      color: white;
    }
  }

  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiTableSortLabel-icon {
    &&{
      color: white;
    }
  }
`;
