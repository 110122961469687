import { Grid, Icon } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { useNavigate } from 'react-router-dom';
import { BasePage } from '../../../components/BaseCredentials';
import { Button, ButtonGroup, Content, Form, Subtitle, Title } from './styled';
import { metrics } from '../../../theme/styles';
import { ReactComponent as SignUpImage } from '../../../assets/createPasswordImage.svg';
import { goToLogin } from '../../../router/coordinator';
import { AlertMessage, Loading, RulesPassword, TextFieldPassword } from '../../../components';
import { useForm } from '../../../hooks/useForm';
import { useEffect, useState } from 'react';
import { validatePassword } from '../../../utils/validates';
import { definePassword, setItemLocalStorage } from '../../../services';
import { GENERIC_ERROR } from '../../../utils/constantes';
import { useQuery } from '../../../hooks/useQuery';
import { clearAlertMessage } from '../../../utils';

export function DefinePassword() {
  const navigate = useNavigate();
  const auth = useQuery().get('auth');
  if (!auth) {
    goToLogin(navigate);
  }

  const [form, onChange] = useForm({ password: '', confirmPassword: '' });
  const [loading, setLoading] = useState(false);
  const [errorsValidation, setErrorsValidation] = useState({
    confirmPassword: false,
    password: false
  });
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: '',
    progressDuration: undefined,
    onCompleteProgress: undefined,
    onClose: () => {}
  });

  const clearError = () => {
    setAlertMessage((prev) => ({
      ...prev,
      title: '',
      severity: '',
      message: ''
    }));
  };

  useEffect(async () => {
    await setItemLocalStorage('token', auth);
  }, []);

  const onConfirmButton = async () => {
    setLoading(true);
    clearError();

    await definePasswordUser();
  };

  // Valida campo de senha
  const validateInputPasword = () => {
    const { password } = form;
    const isValid = validatePassword(password);
    setErrorsValidation((prevError) => ({ ...prevError, password: !isValid }));
  };

  const validateInputConfirmPasword = () => {
    const { confirmPassword, password } = form;
    if (confirmPassword) {
      const isValid = confirmPassword === password;
      setErrorsValidation((prevError) => ({ ...prevError, confirmPassword: !isValid }));
    }
  };

  const disabledButton = () => {
    if (!form.password) {
      return true;
    }
    if (!form.confirmPassword) {
      return true;
    }
    if (errorsValidation.password) {
      return true;
    }
    if (errorsValidation.confirmPassword) {
      return true;
    }

    return false;
  };

  const definePasswordUser = async () => {
    try {
      setLoading(true);

      await definePassword(form.password, form.confirmPassword);

      setAlertMessage((prev) => ({
        ...prev,
        title: `Sua senha foi definida com sucesso. Aguarde que você será redirecionado para a tela de login.`,
        severity: 'success'
      }));
      setLoading(false);

      setTimeout(() => goToLogin(navigate), 4000);
    } catch (err) {
      setAlertMessage({
        title: err.message || GENERIC_ERROR,
        message: err.trace && `Código: ${err.trace}`
      });
      setLoading(false);
    }
  };

  return (
    <BasePage ImageReceived={SignUpImage}>
      <Loading loading={loading} />
      <Content className="center">
        <Icon component={LockIcon} sx={{ fontSize: metrics.sizeIconCredential }} />
        <Title>Definir senha</Title>
        <Subtitle>
          <p>Após definir sua senha, será posível entrar no sistema.</p>
        </Subtitle>
        <Form>
          <AlertMessage
            title={alertMessage?.title}
            message={alertMessage?.message}
            severity={alertMessage?.severity}
            onClose={() => clearAlertMessage(setAlertMessage)}
            progressDuration={alertMessage.progressDuration}
            onCompleteProgress={alertMessage.onCompleteProgress}
          />
          <RulesPassword value={form.password} />
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
              <TextFieldPassword
                value={form?.password}
                onChange={onChange}
                name="password"
                label="Senha"
                onBlur={validateInputPasword}
                error={errorsValidation.password}
                helperText={errorsValidation.password && 'Senha inválida'}
                required
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <TextFieldPassword
                label="Confirmar a senha"
                value={form?.confirmPassword}
                onChange={onChange}
                name="confirmPassword"
                onBlur={validateInputConfirmPasword}
                error={errorsValidation.confirmPassword}
                required
                helperText={
                  errorsValidation.confirmPassword &&
                  'A confirmação de senha não coincide com a senha '
                }
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <ButtonGroup>
                <Button type="submit" disabled={disabledButton()} onClick={onConfirmButton}>
                  Definir
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Form>
      </Content>
    </BasePage>
  );
}
