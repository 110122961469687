import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import { useNavigate } from 'react-router-dom';
import {
  TargetStatus,
  SkeletonDesktop,
  PaginationDesktop,
  HiddenForPermission,
  ButtonWithIcon
} from '../../../../../components';
import { Order } from '../../../../../utils/enums';
import { hasPermission } from '../../../../../utils';
import { convertCentsToWhole } from '../../../../../utils/convert';
import { colors } from '../../../../../theme/styles';
import { goToContractAllValues } from '../../../../../router/coordinator';

export function DesktopTable({
  contracts,
  count,
  loading,
  page,
  offset,
  order,
  orderBy,
  handlePage,
  handleChangeRowsPerPage,
  handleOrder
}) {
  const navigate = useNavigate();

  const goTo = (uuid) => {
    if (hasPermission('LIST_VALUES_OF_CONTRACT')) {
      goToContractAllValues(navigate, uuid);
    }
  };

  function ContractsList() {
    return contracts?.map((contract) => (
      <TableRow key={contract?.uuid} className="not-effect">
        <TableCell
          style={{
            whiteSpace: 'nowrap'
          }}
        >
          {contract?.id || '-'}
        </TableCell>
        <TableCell>
          <TargetStatus status={contract?.status} />
        </TableCell>
        <TableCell>{contract?.segments?.title?.toTitleCase() || '-'}</TableCell>
        <TableCell>{contract?.provider?.name?.toTitleCase()}</TableCell>
        <TableCell>{contract?.initDate?.toMask('date')}</TableCell>
        <TableCell>
          {convertCentsToWhole(
            contract?.monthlyContractsValue[0]?.amount,
            contract.financialContract?.coin?.title
          ) || <span style={{ color: colors.status.yellow }}>Ainda não informado</span>}
        </TableCell>
        <HiddenForPermission keys="LIST_VALUES_OF_CONTRACT">
          <TableCell
            style={{
              textAlign: 'right'
            }}
          >
            <ButtonWithIcon
              onClick={() => goTo(contract?.uuid)}
              variant="outlined"
              Icon={RequestPageIcon}
            ></ButtonWithIcon>
          </TableCell>
        </HiddenForPermission>
      </TableRow>
    ));
  }

  function RenderTable() {
    if (loading) {
      return <SkeletonDesktop numbersOfColumns={6} />;
    }

    return <ContractsList />;
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Segmento</TableCell>
              <TableCell>Fornecedor</TableCell>
              <TableCell>
                <TableSortLabel
                  direction={order}
                  active={order === Order.ASC && orderBy === 'initDate'}
                  onClick={() => handleOrder('initDate')}
                >
                  Data de início
                </TableSortLabel>
              </TableCell>
              <TableCell>Valor desse mês</TableCell>
              <HiddenForPermission keys="LIST_VALUES_OF_CONTRACT">
                <TableCell
                  style={{
                    textAlign: 'right'
                  }}
                >
                  Valores de outros meses
                </TableCell>
              </HiddenForPermission>
            </TableRow>
          </TableHead>
          <TableBody>
            <RenderTable />
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationDesktop
        count={count}
        page={page}
        rowsPerPage={offset}
        handlePage={handlePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}
