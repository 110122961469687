import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import styled from 'styled-components';
import { Box, List as ListMUI, SwipeableDrawer } from '@mui/material';
import { User } from '../../User';
import { colors } from '../../../theme/styles';
import { ListItemsOfMenu } from '../ListItemsOfMenu';

const List = styled(ListMUI)`
  background-color: ${colors.theme.background};
  color: ${colors.backgroundWhite};
  height: 100%;

  svg {
    color: ${colors.backgroundWhite};
  }
`;

export function SwipperMenu({ navigate }) {
  const [openSwipper, setOpenSwipper] = useState(false);

  const toggleDrawer = (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpenSwipper(!openSwipper);
  };

  return (
    <>
      <MenuIcon onClick={toggleDrawer} sx={{ marginTop: '6px' }} />
      <SwipeableDrawer open={openSwipper} onClose={toggleDrawer} onOpen={toggleDrawer}>
        <List>
          <User navigate={navigate} />
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer}
            onKeyDown={toggleDrawer}
          >
            <ListItemsOfMenu navigate={navigate} />
          </Box>
        </List>
      </SwipeableDrawer>
    </>
  );
}
