import { SkeletonMobile } from '../../../../../../components';
import { PaginationMobile } from '../../../../../../components/pagintations/PaginationMobile';
import {
  MobileTableConteiner,
  TableMobileCell,
  TableMobileContent,
  TableMobileItem,
  TableMobileTitle
} from '../../../../../../components/MobileTable';
import { convertCentsToWhole } from '../../../../../../utils/convert';
import { monthsOfTheYear } from '../../../../../../utils/monthsOfTheYear';

export function MobileTable({ contracts, loading, count, page, offset, handlePage, coin }) {
  function ContractList() {
    return contracts?.map((contract) => (
      <TableMobileCell key={contract?.id}>
        <TableMobileItem>
          <TableMobileTitle>Mês </TableMobileTitle>
          <TableMobileContent>
            {monthsOfTheYear?.find((month) => month.value === contract?.month)?.text || '-'}
          </TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Ano </TableMobileTitle>
          <TableMobileContent>{contract?.year || '-'}</TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Valor </TableMobileTitle>
          <TableMobileContent>
            {convertCentsToWhole(contract?.amount, coin) || '-'}
          </TableMobileContent>
        </TableMobileItem>
      </TableMobileCell>
    ));
  }

  return (
    <MobileTableConteiner>
      {!loading ? <ContractList /> : <SkeletonMobile numbersOfColumns={3} />}
      <PaginationMobile count={count} page={page} rowsPerPage={offset} handlePage={handlePage} />
    </MobileTableConteiner>
  );
}
