import { Button, Grid, colors as colorsMUI } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../../../../theme/styles';

export const LineGrid = styled(Grid)`
  margin-top: 2rem;

  > div {
    margin-top: 0.8rem;

    > p:first-child {
      font-weight: 600;
      color: ${colors.theme.primary};
      font-size: 1.2rem;
    }

    p:last-child,
    div p {
      font-weight: 400;
      font-size: 1.5rem;
    }
  }

  #status {
    width: 5rem;
    padding: 0.3rem;
    font-size: 1rem;
  }
`;

export const InactiveButton = styled(Button)`
  && {
    background-color: ${colorsMUI.red[700]};

    &:hover {
      background-color: ${colorsMUI.red[800]};
    }
  }
`;

export const ActiveButton = styled(Button)`
  && {
    background-color: ${colorsMUI.lightGreen[500]};

    &:hover {
      background-color: ${colorsMUI.lightGreen[600]};
    }
  }
`;
