import { Button, Grid } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BasePage } from '../../../../components/BaseMain';
import {
  TextFieldPassword,
  RulesPassword,
  Modal,
  TitlePages,
  Loading,
  AlertMessage
} from '../../../../components';
import { useForm } from '../../../../hooks/useForm';
import { validatePassword } from '../../../../utils/validates';
import { Content } from './styled';
import { GENERIC_ERROR } from '../../../../utils/constantes';
import { redefinePassword } from '../../../../services';
import { clearAlertMessage } from '../../../../utils';

export function RedefinePassword() {
  const breadcrumbs = [{ title: 'Menu' }, { title: 'Redefinir senha' }];
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [form, onChange] = useForm({ password: '', confirm_password: '', old_password: '' });
  const [errorsValidation, setErrorsValidation] = useState({ email: false, password: false });
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const clearError = () => {
    setAlertMessage({
      title: '',
      message: '',
      severity: 'error'
    });
  };

  const onConfirmModal = async () => {
    try {
      setLoading(true);
      handleClose();
      clearError();

      const { password, confirm_password: confirmPassword, old_password: oldPassword } = form;

      await redefinePassword(oldPassword, password, confirmPassword);

      setAlertMessage((prev) => ({
        ...prev,
        title: 'Senha redefinida com sucesso.',
        severity: 'success'
      }));

      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  };

  // Valida campo de senha
  const validateInputPasword = () => {
    const { password } = form;
    const isValid = validatePassword(password);
    setErrorsValidation((prevError) => ({ ...prevError, password: !isValid }));
  };

  const validateInputConfirmPasword = () => {
    const { confirm_password: confirmPassword, password } = form;
    if (confirmPassword) {
      const isValid = confirmPassword === password;
      setErrorsValidation((prevError) => ({ ...prevError, confirm_password: !isValid }));
    }
  };

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages title={'Redefinir senha'} />

      <Loading loading={loading} />
      <Grid container>
        <AlertMessage
          title={alertMessage.title}
          message={alertMessage.message}
          severity={alertMessage.severity}
          onClose={() => clearAlertMessage(setAlertMessage)}
        />
      </Grid>

      <Content container xs={12} lg={11}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <TextFieldPassword
              label={'Senha atual'}
              required={true}
              value={form.old_password}
              onChange={onChange}
              name={'old_password'}
            />
          </Grid>
        </Grid>
        <RulesPassword value={form.password} />
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <TextFieldPassword
              value={form.password}
              onChange={onChange}
              name={'password'}
              label={'Nova senha'}
              required={true}
              onBlur={() => validateInputPasword()}
              error={errorsValidation.password}
              helperText={errorsValidation.password && 'Senha inválida'}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextFieldPassword
              value={form.confirm_password}
              onChange={onChange}
              name={'confirm_password'}
              label={'Confirme nova senha'}
              required={true}
              onBlur={() => validateInputConfirmPasword()}
              error={errorsValidation.confirm_password}
              helperText={
                errorsValidation.confirm_password &&
                'A confirmação de senha não coincide com a senha '
              }
            />
          </Grid>
        </Grid>
        <div className="buttonGroup oneButton">
          <Button type="submit" onClick={handleOpen}>
            Redefinir
          </Button>
        </div>
      </Content>
      <Modal
        open={openModal}
        onClose={handleClose}
        onConfirm={onConfirmModal}
        title={'Redefinir senha'}
        text={'Tem certeza que deseja redefinir sua senha?'}
        buttonConfirm={'Sim'}
        buttonCancel={'Não'}
      />
    </BasePage>
  );
}
