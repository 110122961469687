import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { goToProviderDetails } from '../../../../../../router/coordinator';
import { PaginationDesktop, SkeletonDesktop, TargetStatus } from '../../../../../../components';
import { hasPermission } from '../../../../../../utils';
import { Status } from '../../../../../../utils/enums';

export function DesktopTable({
  providers,
  navigate,
  count,
  loading,
  page,
  offset,
  handlePage,
  handleChangeRowsPerPage
}) {
  const goTo = (uuid) => {
    if (hasPermission('FIND_PROVIDER')) {
      goToProviderDetails(navigate, uuid);
    }
  };

  const providersList = providers?.map((company) => {
    return (
      <TableRow
        key={company.uuid}
        onClick={() => goTo(company.uuid)}
        className={!hasPermission('FIND_PROVIDER') && 'not-effect'}
      >
        <TableCell>{company.name?.toTitleCase()}</TableCell>
        <TableCell>{company.document?.toMask('document')}</TableCell>
        <TableCell>
          <TargetStatus status={company.active ? Status.ACTIVE : Status.INACTIVE} />
        </TableCell>
        <TableCell>
          {company.industry && company.industry.title && company.industry.title.toTitleCase()}
        </TableCell>
      </TableRow>
    );
  });

  function RenderTable() {
    if (loading) {
      return <SkeletonDesktop numbersOfColumns={4} />;
    }

    return providersList;
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Documento</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Setor de atuação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <RenderTable />
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationDesktop
        count={count}
        page={page}
        rowsPerPage={offset}
        handlePage={handlePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}
