import { api } from '../utils/axiosConfig';

/**
 * Lista todos os indices
 * @returns
 */
export const listAllIndex = async (filters = {}) => {
  try {
    const { data } = await api.get(`/index/list-all`, {
      params: {
        ...filters
      }
    });

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Cria um índice
 * @param {*} name - nome do indice
 * @param {*} percent - percentual atual
 * @param {*} description - descrição
 * @returns
 */
export const createIndex = async (name, percent, description) => {
  try {
    const payload = {
      name,
      percent,
      description
    };
    const { data } = await api.post(`/index/create`, payload);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Lista o histórico de indice
 * @param {*} initialPage - Página de inicio
 * @param {*} offset - Itens por página
 * @param {*} order - ordem [asc, desc]
 * @param {*} filter - filtros
 * @returns
 */
export const listAllIndexHistoric = async (
  uuidIndex,
  initialPage,
  offset,
  order = 'ASC',
  filter = {}
) => {
  try {
    const page = initialPage + 1;
    const { data } = await api.get(`/index/history/list-all/${uuidIndex}`, {
      params: {
        initial_page: page,
        offset,
        order,
        ...filter
      }
    });

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Busca um index pelo uuid
 * @param {*} uuidIndex - identificador do index
 * @returns
 */
export const findIndex = async (uuidIndex) => {
  try {
    const { data } = await api.get(`/index/find/${uuidIndex}`);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Adiciona um valor a um indice e o põe no historico
 * @param {*} uuidIndex - identificador do indice
 * @param {*} percent - percentual do indice
 * @param {*} date - data
 * @returns
 */
export const createHistoricIndex = async (uuidIndex, percent, date) => {
  try {
    const payload = {
      percent,
      date
    };
    const { data } = await api.post(`/index/create-history/${uuidIndex}`, payload);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Busca o valor de uma cotação pela data
 * @param {*} uuidIndex
 * @param {*} date
 * @returns
 */
export const searchIndexPercent = async (uuidIndex, date) => {
  try {
    const { data } = await api.get(`/index/${uuidIndex}/search/history`, {
      params: {
        date
      }
    });

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Busca um valor de indice pelo uuid
 * @param {*} uuidHistoryIndex - identificador do index
 * @returns
 */
export const findIndexHistory = async (uuidHistoryIndex) => {
  try {
    const { data } = await api.get(`/index/find/histoy/${uuidHistoryIndex}`);
    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Edita o valor de um índice
 * @param {*} uuidHistoryIndex - identificador do index
 * @param {*} percent - novo valor
 * @returns
 */
export const editIndexHistory = async (uuidHistoryIndex, percent) => {
  try {
    const { data } = await api.put(`/index/history/${uuidHistoryIndex}`, { percent });
    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};
