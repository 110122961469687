import { api } from '../utils/axiosConfig';

/**
 * Gera um token público pro usuário usar as apis publicas
 * @returns
 */
export const createPublicToken = async () => {
  try {
    const { data } = await api.post('/credential/create-public-token');
    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Função para gerar o token do usuário
 * @param {*} email
 * @param {*} password - senha
 * @param {*} recaptchaToken - token gerado pelo recaptcha
 * @returns
 */
export const login = async (email, password, recaptchaToken) => {
  try {
    const payload = { email, password, recaptcha_token: recaptchaToken };
    const { data } = await api.post('/credential/login', payload);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Função para cadastro de usuário
 * @param {*} name - nome
 * @param {*} lastName - sobrenome
 * @param {*} document - CPF ou CNPJ
 * @param {*} email
 * @param {*} officeUUID - uuid do cargo do usuário
 * @param {*} sectorUUID - uuid do setor do usuário
 * @param {*} companyUUID - uuid da empresa em que o usuário trabalha
 * @param {*} password - senha
 * @param {*} confirmPassword - confirmação de senha
 * @param {*} cellphone - celular com DDD (opcional)
 * @param {*} tellphone - telefone com DDD (opcional)
 * @param {*} profileImage - imagem de perfil em base64 (opcional)
 * @returns
 */
export const signup = async (
  name,
  lastName,
  document,
  email,
  officeUUID,
  sectorUUID,
  companyUUID,
  password,
  confirmPassword,
  cellphone = '',
  tellphone = '',
  profileImage = ''
) => {
  try {
    const payload = {
      name,
      last_name: lastName,
      document,
      email,
      cellphone,
      tellphone,
      profile_image: profileImage,
      company_uuid: companyUUID,
      office_uuid: officeUUID,
      sector_uuid: sectorUUID,
      password,
      confirm_password: confirmPassword
    };
    const { data } = await api.post('/credential/signup', payload);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Envia um e-mail com o link para redefinir a senha
 * @param {*} email
 */
export const sendEmailRecoverPassword = async (email) => {
  try {
    await api.post('/credential/recover-password', { email });
  } catch (error) {
    throw error?.response?.data;
  }
};
