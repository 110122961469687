import styled from 'styled-components';
import { metrics, colors as CustomColors } from '../../../theme/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Box, Button as ButtonMUI } from '@mui/material';

export const Icon = styled(AccountCircleIcon)`
  height: ${metrics.sizeIconCredential};
  width: ${metrics.sizeIconCredential};
`;

export const Content = styled(Box)`
  text-align: center;
  width: 90%;
`;

export const Title = styled.h1`
  font-size: ${metrics.size.large};
  margin: 0;
`;

export const Subtitle = styled.h5`
  font-weight: ${metrics.weight.regular};
  color: ${CustomColors.black};
  font-size: ${metrics.size.medium};
  margin-top: 1rem;
`;

export const Button = styled(ButtonMUI)`
  && {
    width: 90%;
    margin-top: 2rem;
  }
`;
