import { api } from '../utils/axiosConfig';

/**
 * Lista todos os setores
 * @param {*} initialPage - Página de inicio
 * @param {*} offset - Itens por página
 * @param {*} order - ordem [asc, desc]
 * @param {*} filter - filtros
 * @returns
 */
export const listAllSectors = async (initialPage, offset, order = 'ASC', filter = {}) => {
  try {
    const page = initialPage + 1;
    const { data } = await api.get('/sector/list-all', {
      params: {
        initial_page: page,
        offset,
        order,
        ...filter
      }
    });

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Edita as informações do setor
 * @param {*} uuidSector - identificador do setor
 * @param {*} title - Nome do setor
 * @param {*} acronym - Sigla do setor
 * @returns
 */
export const editSector = async (uuidSector, title, acronym) => {
  try {
    const payload = { title, acronym };
    const { data } = await api.put(`/sector/${uuidSector}`, payload);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Busca um setor
 * @param {*} uuidSector - identificador do setor
 * @returns
 */
export const findSector = async (uuidSector) => {
  try {
    const { data } = await api.get(`/sector/find/${uuidSector}`);
    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Cadastrar um setor
 * @param {*} title - nome
 * @param {*} acronym - sigla
 * @returns
 */
export const createSector = async (title, acronym) => {
  try {
    const payload = {
      title,
      acronym
    };
    const { data } = await api.post(`/sector/create`, payload);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};
