import { useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { Grid, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Order } from '../../../../../utils/enums';
import { useForm } from '../../../../../hooks/useForm';
import { clearAlertMessage, hasPermission, isDesktop, onChangeMoney } from '../../../../../utils';
import { AlertMessage, EmptyTable, FilterContainer, TitlePages } from '../../../../../components';
import { BasePage } from '../../../../../components/BaseMain';
import { FilterButton } from '../../../../../components/FilterButton';
import {
  goToBack,
  goToContractAddValue,
  goToContractValuesVariables
} from '../../../../../router/coordinator';
import { findContract, listContractsValuesPaginate } from '../../../../../services/contractService';
import { GENERIC_ERROR } from '../../../../../utils/constantes';
import { DesktopTable } from './TablesComponents/DesktopTable';
import { MobileTable } from './TablesComponents/MobileTable';
import { Table } from '../styled';

export function AllValuesContracts() {
  const navigate = useNavigate();
  const breadcrumbs = [
    {
      title: 'Contratos com valores variáveis',
      functionCoordinator: () => goToContractValuesVariables(navigate)
    },
    { title: 'Todos os valores registrados' }
  ];

  const { uuid } = useParams();
  if (!uuid) {
    goToBack(navigate);
  }

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(5);
  const [order] = useState(Order.DESC);
  const [contract, setContract] = useState({});
  const [valuesContract, setValuesContracts] = useState([]);

  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  const [filters, onChange, clearForm] = useForm({
    month: null,
    year: null,
    amount: ''
  });
  const [debouncedFilter] = useDebounce(filters, 500);

  const init = useCallback(async () => {
    try {
      setLoading(true);

      const [contractResult, contractsValuesResult] = await Promise.all([
        findContract(uuid),
        listContractsValuesPaginate(uuid, page, offset, order, filters)
      ]);

      setContract(contractResult);
      setCount(contractsValuesResult?.count);
      setValuesContracts(contractsValuesResult?.data);

      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  }, []);

  const listContracts = useCallback(async () => {
    try {
      setLoading(true);

      const result = await listContractsValuesPaginate(uuid, page, offset, order, filters);

      setValuesContracts(result.data);
      setCount(result.count);

      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  }, [page, offset, order, debouncedFilter]);

  useEffect(() => init(), []);
  useEffect(() => listContracts(), [page, offset, order, debouncedFilter]);

  const handlePage = (_event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setOffset(parseInt(event?.target?.value, 10));
    setPage(0);
  };

  const onChangeDate = (event, name) => {
    const target = {
      name,
      value: event
    };

    onChange({ target });
  };

  function TableResponsive() {
    if (!valuesContract?.length && !loading) {
      return <EmptyTable />;
    }

    if (isDesktop()) {
      return (
        <DesktopTable
          contracts={valuesContract}
          loading={loading}
          count={count}
          page={page}
          offset={offset}
          handlePage={handlePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          coin={contract?.financialContract?.coin?.title}
        />
      );
    }

    return (
      <MobileTable
        contracts={valuesContract}
        loading={loading}
        count={count}
        page={page}
        offset={offset}
        setPage={setPage}
        setOffset={setOffset}
        handlePage={handlePage}
        coin={contract?.financialContract?.coin?.title}
      />
    );
  }

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages
        title={`Lista dos valores registrato no contrato ${contract?.id || ''}`}
        description="Lista os valores adicionados ao contrato."
        textButton="Registrar novo valor"
        Icon={AddCircleOutlineIcon}
        onClickButton={() => goToContractAddValue(navigate, uuid)}
        keyPermissionButton="ADDED_VALUE_CONTRACT"
        toHideDivider={!hasPermission('LIST_CONTRACTS_WITH_PENDING_VALUES')}
      />

      <Grid container>
        <AlertMessage
          message={alertMessage.message}
          severity={alertMessage.severity}
          title={alertMessage.title}
          onClose={() => clearAlertMessage(setAlertMessage)}
        />
      </Grid>

      <FilterContainer>
        <Grid item xs={12} lg={2}>
          <DatePicker
            disableFuture
            label="Mês"
            name="month"
            format="MM"
            views={['month']}
            value={filters?.month}
            onChange={(e) => onChangeDate(e, 'month', onChange)}
            renderInput={(params) => <TextField {...params} helperText={null} />}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <DatePicker
            disableFuture
            label="Ano"
            name="year"
            format="YYYY"
            views={['year']}
            value={filters?.year}
            onChange={(e) => onChangeDate(e, 'year', onChange)}
            renderInput={(params) => <TextField {...params} helperText={null} />}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <TextField
            label="Valor do contrato"
            value={filters?.amount}
            name="amount"
            onChange={(e) => onChangeMoney(e, onChange)}
          />
        </Grid>

        <Grid item xs={12} lg={1}>
          <FilterButton onClick={clearForm} />
        </Grid>
      </FilterContainer>

      <Table>
        <TableResponsive />
      </Table>
    </BasePage>
  );
}
