import { TablePagination } from './styled';

export function PaginationDesktop({ handlePage, handleChangeRowsPerPage, ...props }) {
  return (
    <TablePagination
      {...props}
      component="div"
      labelRowsPerPage="Itens por página"
      labelDisplayedRows={({ from, to, count }) => `${from}–${to} de ${count}`}
      showLastButton
      showFirstButton
      rowsPerPageOptions={[5, 10, 25, 50]}
      onPageChange={handlePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
}
