import { Button } from '@mui/material';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { Container } from './styled';
import { isDesktop } from '../../utils';

export function FilterButton({ onClick }) {
  return (
    <Container>
      <abbr title="Limpar filtro">
        <Button variant="outlined" onClick={onClick}>
          <FilterAltOffIcon />
          {!isDesktop() && <p>Limpar filtro</p>}
        </Button>
      </abbr>
    </Container>
  );
}
