import { ActiveButton, InactiveButton } from './styled';

export function ActiveAndInactiveButton(props) {
  const { active } = props;

  return active ? (
    <InactiveButton {...props}>Inativar</InactiveButton>
  ) : (
    <ActiveButton {...props}>Ativar</ActiveButton>
  );
}
