import { useState, useEffect, useCallback } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { AlertTitle, Collapse, Grid, IconButton } from '@mui/material';
import { Content, LinearProgressContainer, LinearProgressWrapper } from './styled';
import { scrollToTopPage } from '../../utils';
import { LinearProgressLoading } from '../LinearProgress';

export function AlertMessage({
  severity,
  title,
  message,
  progressDuration,
  onCompleteProgress,
  onClose
}) {
  if (!['error', 'warning', 'info', 'success'].includes(severity)) {
    severity = 'error';
  }

  const [open, setOpen] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);

  const convertDateToPercent = useCallback(
    (date) => {
      return 100 - parseInt(((date - Date.now()) * 100) / progressDuration);
    },
    [progressDuration]
  );

  useEffect(() => {
    if (title || message) {
      scrollToTopPage();
      setOpen(true);
    }
  }, [title, message]);

  const handleClose = () => {
    onClose?.();
    setOpen(false);
  };

  useEffect(() => {
    if (progressDuration) {
      const dateComplete = Date.now() + progressDuration;
      setLoadingProgress(convertDateToPercent(dateComplete));
      const interval = setInterval(() => {
        const percent = convertDateToPercent(dateComplete);

        if (percent < 100) {
          setLoadingProgress(percent);
        } else {
          clearInterval(interval);
          setLoadingProgress(100);
          onCompleteProgress?.();
        }
      }, 50);

      return () => clearInterval(interval);
    }

    return () => {};
  }, [convertDateToPercent, onCompleteProgress, progressDuration]);

  return (
    (title || message) && (
      <Grid item xs={12}>
        <Collapse in={open}>
          <Content
            variant="filled"
            severity={severity}
            action={
              <IconButton aria-label="close" color="inherit" size="small" onClick={handleClose}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {progressDuration && (
              <LinearProgressWrapper>
                <LinearProgressContainer>
                  <LinearProgressLoading value={loadingProgress} color="inherit" />
                </LinearProgressContainer>
              </LinearProgressWrapper>
            )}
            <AlertTitle>{title}</AlertTitle>
            {message}
          </Content>
        </Collapse>
      </Grid>
    )
  );
}
