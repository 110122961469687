import { HiddenForPermission, SkeletonMobile, TargetStatus } from '../../../../../components';
import { PaginationMobile } from '../../../../../components/pagintations/PaginationMobile';
import {
  MobileTableConteiner,
  TableMobileButton,
  TableMobileCell,
  TableMobileContent,
  TableMobileItem,
  TableMobileTitle
} from '../../../../../components/MobileTable';
import { convertCentsToWhole } from '../../../../../utils/convert';
import { colors } from '../../../../../theme/styles';
import { hasPermission } from '../../../../../utils';
import { goToContractAllValues } from '../../../../../router/coordinator';
import { useNavigate } from 'react-router-dom';

export function MobileTable({ contracts, loading, count, page, offset, handlePage }) {
  const navigate = useNavigate();

  const goTo = (uuid) => {
    if (hasPermission('LIST_VALUES_OF_CONTRACT')) {
      goToContractAllValues(navigate, uuid);
    }
  };

  function ContractList() {
    return contracts?.map((contract) => (
      <TableMobileCell key={contract?.id}>
        <TableMobileItem>
          <TableMobileTitle>Id </TableMobileTitle>
          <TableMobileContent>{contract?.id || '-'}</TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Status </TableMobileTitle>
          <TableMobileContent>
            <TargetStatus status={contract?.status} />
          </TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Segmento </TableMobileTitle>
          <TableMobileContent>{contract?.segments?.title?.toTitleCase() || '-'}</TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Fornecedor </TableMobileTitle>
          <TableMobileContent>{contract?.provider?.name?.toTitleCase()}</TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Data de início </TableMobileTitle>
          <TableMobileContent>{contract?.initDate?.toMask('date')}</TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Valor desse mês </TableMobileTitle>
          <TableMobileContent>
            {convertCentsToWhole(
              contract?.monthlyContractsValue?.[0]?.amount,
              contract.financialContract?.coin?.title
            ) || <span style={{ color: colors.status.yellow }}>Ainda não informado</span>}
          </TableMobileContent>
        </TableMobileItem>

        <HiddenForPermission keys="LIST_VALUES_OF_CONTRACT">
          <TableMobileButton onClick={() => goTo(contract?.uuid)}>
            Ver valores de outros meses
          </TableMobileButton>
        </HiddenForPermission>
      </TableMobileCell>
    ));
  }

  return (
    <MobileTableConteiner>
      {!loading ? <ContractList /> : <SkeletonMobile numbersOfColumns={10} />}
      <PaginationMobile count={count} page={page} rowsPerPage={offset} handlePage={handlePage} />
    </MobileTableConteiner>
  );
}
