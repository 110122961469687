import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Icon, Link, TextField } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Content, Title, Form, Button, Links, SubTitle } from './styled';
import { metrics } from '../../../theme/styles';
import {
  goToChooseCompany,
  goToForgotPassword,
  goToSignup,
  goToTerms
} from '../../../router/coordinator';
import { BasePage } from '../../../components/BaseCredentials';
import { useForm } from '../../../hooks/useForm';
import { ReactComponent as LoginImage } from '../../../assets/loginImage.svg';
import { AlertMessage } from '../../../components/AlertMessage';
import { TextFieldPassword } from '../../../components/TextFieldPassword';
import {
  clearLocalStorage,
  getItemLocalStorage,
  setItemLocalStorage
} from '../../../services/localStorageService';
import { login } from '../../../services/credentialsService';
import { GENERIC_ERROR } from '../../../utils/constantes';
import { Loading } from '../../../components';
import { listNotAcceptTerms } from '../../../services/termsService';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export function Login() {
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [form, onChange] = useForm({ email: '', password: '' });
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: '',
    onClose: () => {}
  });

  const clearAlertMessage = () => {
    setAlertMessage((prev) => ({
      ...prev,
      title: '',
      severity: '',
      message: ''
    }));
  };

  useEffect(() => {
    const sessionExpired = getItemLocalStorage('session_expired');
    if (sessionExpired !== null) {
      setAlertMessage((prev) => ({
        ...prev,
        title: 'Sua sessão expirou, por favor, faça o login novamente.',
        severity: 'warning'
      }));
    }
  }, []);

  const onSubmitForm = async (event) => {
    try {
      event.preventDefault();
      setLoading(true);
      clearAlertMessage();
      clearLocalStorage();

      if (!executeRecaptcha) {
        throw new Error('ReCaptcha não executado');
      }
      const token = await executeRecaptcha();
      const credentialsInformation = await login(form.email, form.password, token);

      await setItemLocalStorage('token', credentialsInformation.access_token);

      const termsNotAccept = await listNotAcceptTerms();
      setLoading(false);

      if (termsNotAccept?.lenght === 0) {
        goToChooseCompany(navigate);
      } else {
        goToTerms(navigate);
      }
    } catch (err) {
      setAlertMessage({
        title: err?.message || GENERIC_ERROR,
        message: err?.trace && `Código: ${err?.trace}`
      });
      setLoading(false);
    }
  };

  return (
    <BasePage ImageReceived={LoginImage}>
      <Loading loading={loading} />
      <Content className="center">
        <Icon component={AccountCircleIcon} sx={{ fontSize: metrics.sizeIconCredential }} />
        <Title>SISTEMA GERAL DE CONTRATOS GRAÇA</Title>
        <SubTitle>Aro Contábil</SubTitle>

        <Form container>
          <Grid item xs={12}>
            <AlertMessage
              title={alertMessage?.title}
              message={alertMessage?.message}
              severity={alertMessage?.severity}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="E-mail"
              type={'email'}
              name={'email'}
              placeholder={'usuario@empresa.com'}
              value={form.email}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldPassword
              value={form.password}
              onChange={onChange}
              name={'password'}
              label="Senha"
            />
          </Grid>

          <Links>
            <p>
              Não possui uma conta? <Link onClick={() => goToSignup(navigate)}>Crie uma</Link>
            </p>
            <p>
              <Link onClick={() => goToForgotPassword(navigate)}>Esqueci minha senha</Link>
            </p>
          </Links>

          <Button type="submit" onClick={onSubmitForm}>
            Entrar
          </Button>
        </Form>
      </Content>
    </BasePage>
  );
}
