import { useCallback, useEffect, useState } from 'react';
import { Button, Grid, MenuItem, TextField } from '@mui/material';
import ReactInputMask from 'react-input-mask';
import { isDesktop, onlyNumbers } from '../../../../../../utils';
import {
  AlertMessage,
  BorderWithText,
  Loading,
  TextFieldNextFocus
} from '../../../../../../components';
import { validateInputDocument, validateMandatoryInput } from '../../../../../../utils/validates';
import { searchCompany } from '../../../../../../services/companyService';
import { COMPANY_ACRONYM_LENGTH, GENERIC_ERROR } from '../../../../../../utils/constantes';
import { useForm } from '../../../../../../hooks/useForm';
import { PersonType } from '../../../../../../utils/enums';
import { Text } from '../../../../../../components/InformativeText/styles';
import { Area } from '../step2/areas/coinArea/styled';
import AutocompleteSearch from '../../../../../../components/AutocompleteSearch';
import { listIndustries } from '../../../../../../services';

export function SupplierInformation({ onNextStep, onBackStep, setForm, allForm }) {
  const [loading, setLoading] = useState(false);
  const [errorsValidation, setErrorsValidation] = useState({});
  const [error, setError] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  const initialForm = {
    uuid_provider: '',
    document: '',
    name: '',
    uuid_industry: '',
    acronym: '',
    is_intern: false,
    domain: '',
    personType: ''
  };
  const [supplierInformation, onChange, _, setSupplierInformation] = useForm(initialForm);

  const getExistForm = () => {
    // Se já tiver informações no formulario principal ele é preenchido novamente
    for (const key in supplierInformation) {
      setSupplierInformation((prevValue) => {
        return {
          ...prevValue,
          [key]: allForm[key]
        };
      });
    }
  };

  useEffect(() => {
    getExistForm();
  }, [allForm]);

  const onClearError = () => {
    setError({
      title: '',
      message: ''
    });
  };

  const onClearForm = (document) => {
    setSupplierInformation({
      ...supplierInformation,
      uuid_provider: '',
      name: '',
      uuid_industry: '',
      acronym: '',
      domain: '',
      is_intern: '',
      document
    });
  };

  /**
   * Ao colocar o documento do parceiro eu busco no Banco se
   * ele axistir, caso sim eu coloco nos campos. Se não ele será cadastrado futuramente
   * @param {*} e - event
   */
  const onChangeSearchSupplier = async (e) => {
    onChange(e);

    const document = onlyNumbers(e.target.value);
    if (!document) {
      onClearError();
      onClearForm();
      return;
    }

    try {
      onClearError();
      const isSearchSuplier =
        (supplierInformation?.personType === PersonType.PF && document.length === 11) ||
        (supplierInformation?.personType === PersonType.PJ && document.length === 14);

      if (isSearchSuplier) {
        setLoading(true);

        const result = await searchCompany(document);

        setSupplierInformation({
          ...supplierInformation,
          uuid_provider: result.uuid,
          name: result.name?.toTitleCase(),
          uuid_industry: result.industry.uuid,
          acronym: result.acronym?.toUpperCase(),
          domain: result.domain,
          is_intern: result.isIntern,
          document
        });

        setTimeout(() => setLoading(false), 1000);
      }
    } catch (err) {
      onClearForm(document);
      setError({
        title: err?.message || GENERIC_ERROR,
        message: err?.trace && `Código: ${err?.trace}`
      });
    } finally {
      setLoading(false);
    }
  };

  const nextStep = () => {
    setForm((prevForm) => ({ ...prevForm, ...supplierInformation }));
    onNextStep();
  };

  const backStep = () => {
    setForm((prevForm) => ({ ...prevForm, ...supplierInformation }));
    onBackStep();
  };

  const disabledButton = () => {
    if (!supplierInformation.document) {
      return true;
    }
    if (!supplierInformation.name) {
      return true;
    }
    if (!supplierInformation.uuid_industry) {
      return true;
    }
    if (supplierInformation.is_intern === undefined) {
      return true;
    }

    return false;
  };

  const clearSupplierInformations = () => {
    setSupplierInformation((prev) => ({
      ...prev,
      uuid_provider: '',
      name: '',
      uuid_industry: '',
      acronym: '',
      domain: '',
      is_intern: '',
      document: ''
    }));
  };

  const onChangePersonType = (e) => {
    onChange(e);
    clearSupplierInformations();
  };

  const getIndustries = useCallback(async () => {
    try {
      const data = await listIndustries();
      return data;
    } catch (error) {
      setError({
        title: error?.message || GENERIC_ERROR,
        message: error?.trace && `Código: ${error?.trace}`
      });
      return [];
    }
  }, []);

  const handleDocumentMask = () =>
    supplierInformation.personType === PersonType.PF ? '999.999.999-99' : '99.999.999/9999-99';

  return (
    <Grid container spacing={2}>
      <Loading loading={loading} />

      <Grid item xs={12}>
        <AlertMessage title={error.title} message={error.message} severity={error.severity} />
      </Grid>
      <Grid item xs={12}>
        <Text style={{ padding: 0 }}>
          {`Nesta tela você fará a pesquisa do fornecedor pertencente a esse contrato, qualquer edição
          deve ser feita na tela de "Editar empresa de acesso" ou "Editar fornecedor".`}
        </Text>
      </Grid>
      <Grid item xs={12}>
        <BorderWithText title="Campos para busca de fornecedor" xs={12}>
          <Area container columnSpacing={isDesktop() ? 2 : 0}>
            <Grid item xs={12} lg={6}>
              <TextField
                label="PF ou PJ"
                name="personType"
                onChange={onChangePersonType}
                value={supplierInformation.personType}
                select
                required
              >
                <MenuItem value="pf">Pessoa física (PF)</MenuItem>
                <MenuItem value="pj">Pessoa jurídica (PJ)</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} lg={6}>
              <ReactInputMask
                mask={handleDocumentMask()}
                maskChar="_"
                value={supplierInformation.document}
                onChange={onChangeSearchSupplier}
                onBlur={() =>
                  validateInputDocument(supplierInformation.document, setErrorsValidation)
                }
                disabled={loading || !supplierInformation.personType}
              >
                {() => (
                  <TextField
                    required
                    label="Documento"
                    name={'document'}
                    value={supplierInformation.document}
                    error={errorsValidation.document}
                    helperText={errorsValidation.document && 'O documento está inválido'}
                    disabled={loading || !supplierInformation.personType}
                  />
                )}
              </ReactInputMask>
            </Grid>
          </Area>
        </BorderWithText>
      </Grid>
      <Grid item xs={12}>
        <BorderWithText title="Informações do fornecedor" xs={12}>
          <Area container columnSpacing={isDesktop() && 2}>
            <Grid item xs={12} lg={4}>
              <TextFieldNextFocus
                label="Nome"
                name={'name'}
                value={supplierInformation.name}
                disabled
                onChange={onChange}
                onBlur={() =>
                  validateMandatoryInput('name', supplierInformation, setErrorsValidation)
                }
                error={errorsValidation.name}
                helperText={errorsValidation.name && 'O nome é obrigatório'}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <AutocompleteSearch
                disabled
                label="Setor de atuação"
                name="uuid_industry"
                value={supplierInformation.uuid_industry}
                onChange={onChange}
                optionLabel={['title']}
                getListDataCallback={getIndustries}
                formatLabel={(label) => label?.toTitleCase?.()}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                select
                disabled
                label="Faz parte das Empresas e Instituições da Graça?"
                name="is_intern"
                value={supplierInformation.is_intern}
                onChange={onChange}
              >
                <MenuItem value={true}>Sim</MenuItem>
                <MenuItem value={false}>Não</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} lg={6} style={{ padding: 0 }}>
              <TextFieldNextFocus
                label="Sigla"
                name={'acronym'}
                disabled
                value={supplierInformation.acronym}
                onChange={onChange}
                onBlur={() =>
                  validateMandatoryInput('acronym', supplierInformation, setErrorsValidation)
                }
                inputProps={{ maxLength: COMPANY_ACRONYM_LENGTH }}
                error={errorsValidation.acronym}
                helperText={
                  errorsValidation.acronym || `Máximo de ${COMPANY_ACRONYM_LENGTH} caracteres`
                }
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextFieldNextFocus
                disabled
                label="Domínio"
                name={'domain'}
                value={supplierInformation.domain}
                onChange={onChange}
              />
            </Grid>
          </Area>
        </BorderWithText>
      </Grid>

      <Grid item className="buttonGroup twoButtons nearTheTop">
        <Button onClick={backStep} variant="outlined">
          Voltar
        </Button>
        <Button onClick={nextStep} disabled={disabledButton()}>
          Próximo
        </Button>
      </Grid>
    </Grid>
  );
}
