import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import EditIcon from '@mui/icons-material/Edit';
import {
  HiddenForPermission,
  PaginationDesktop,
  SkeletonDesktop,
  TargetStatus,
  UserInformations
} from '../../../../../../components';
import { ActiveAndInactiveButton } from '../../../../../../components/ActiveAndInactiveButton';
import { goToDatailsUser, goToEditUserAdmin } from '../../../../../../router/coordinator';
import { Status } from '../../../../../../utils/enums';

export function DesktopTable({
  users,
  navigate,
  openModal,
  count,
  loading,
  page,
  offset,
  handlePage,
  handleChangeRowsPerPage
}) {
  const usersList = users?.map((user) => {
    const status = user.status?.toLowerCase();
    const notShowButton = status === Status.PENDING || status === Status.REPROVED || false;
    const isActiveButton = status === Status.ACTIVE && status !== Status.PENDING;

    return (
      <TableRow key={user.uuid} className="not-effect">
        <TableCell>
          <UserInformations user={user} src={user.profileImage?.imageBase64} />
        </TableCell>
        <TableCell>
          <TargetStatus status={user.status} />
        </TableCell>
        <TableCell>{user.cellphone?.toMask('cellphone') || '-'}</TableCell>
        <TableCell>{user.tellphone?.toMask('tellphone') || '-'}</TableCell>
        <TableCell>{user.email}</TableCell>
        <TableCell>{user.office?.title?.toTitleCase()}</TableCell>
        <HiddenForPermission keys={'ACTIVE_INACTIVE_USER_ADMIN'}>
          <TableCell>
            {!notShowButton ? (
              <ActiveAndInactiveButton
                style={{ width: ' 100%' }}
                active={isActiveButton}
                onClick={() => openModal(user.uuid)}
              />
            ) : (
              '-'
            )}
          </TableCell>
        </HiddenForPermission>
        <HiddenForPermission keys={['EDIT_ADMIN_USER', 'FIND_USER_ADMIN']}>
          <TableCell style={{ textAlign: 'right' }}>
            <HiddenForPermission keys="EDIT_ADMIN_USER">
              <IconButton onClick={() => goToEditUserAdmin(navigate, user.uuid)}>
                <EditIcon />
              </IconButton>
            </HiddenForPermission>
            <HiddenForPermission keys="FIND_USER_ADMIN">
              <IconButton onClick={() => goToDatailsUser(navigate, user.uuid)}>
                <PersonSearchIcon />
              </IconButton>
            </HiddenForPermission>
          </TableCell>
        </HiddenForPermission>
      </TableRow>
    );
  });

  function RenderTable() {
    if (loading) {
      return <SkeletonDesktop numbersOfColumns={8} />;
    }

    return usersList;
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Usuário</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Celular</TableCell>
              <TableCell>Telefone</TableCell>
              <TableCell>E-mail</TableCell>
              <TableCell>Cargo</TableCell>
              <HiddenForPermission keys={'ACTIVE_INACTIVE_USER_ADMIN'}>
                <TableCell>Mudar status</TableCell>
              </HiddenForPermission>
              <HiddenForPermission keys={['FIND_USER_ADMIN', 'EDIT_ADMIN_USER']}>
                <TableCell></TableCell>
              </HiddenForPermission>
            </TableRow>
          </TableHead>
          <TableBody>
            <RenderTable />
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationDesktop
        count={count}
        page={page}
        rowsPerPage={offset}
        handlePage={handlePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}
