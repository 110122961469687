import { Grid } from '@mui/material';
import styled from 'styled-components';
import { breakpoints } from '../../../../../theme/breakpoints';

export const Form = styled(Grid)`
  .MuiGrid-root {
    margin-top: 1rem;
  }

  .buttonGroup {
    margin-top: 1rem;
  }

  @media (min-width: ${breakpoints.md}) {
    margin: 1rem;
    padding: 0 2rem;
  }
`;
