import { Divider } from '@mui/material';
import { Btn, Card, Cell, Container, Content, Title } from './styled';

export function MobileTableConteiner({ children }) {
  return <Container>{children}</Container>;
}

export function TableMobileCell({ children }) {
  return <Card>{children}</Card>;
}

export function TableMobileItem(props) {
  const { children } = props;

  return (
    <>
      <Cell {...props}>{children}</Cell>
      <Divider />
    </>
  );
}

export function TableMobileTitle(props) {
  const { children } = props;

  return <Title {...props}>{children}</Title>;
}

export function TableMobileContent({ children }) {
  return <Content>{children}</Content>;
}

export function TableMobileButton(props) {
  const { children } = props;
  return <Btn {...props}>{children}</Btn>;
}
