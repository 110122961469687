import { Grid } from '@mui/material';
import styled from 'styled-components';
import { colors, metrics } from '../../theme/styles';

export const Container = styled(Grid)`
  min-height: 100vh;
`;

export const Image = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 70%;
  }
`;

export const Content = styled(Grid)`
  padding: ${metrics.padding.content};
  background-color: ${colors.backgroundWhite};
  display: flex;
  justify-content: center;
  max-height: 100vh;
  overflow-y: auto;
  position: relative;

  .center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
