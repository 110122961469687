import styled from 'styled-components';
import { colors } from '../../../theme/styles';
import { breakpoints } from '../../../theme/breakpoints';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  svg {
    width: 100%;
    height: 40%;

    @media (min-width: ${breakpoints.md}) {
      width: 60%;
      height: 60%;
    }
  }

  p {
    font-size: 2rem;
    color: ${colors.theme.primary};
    font-weight: 700;

    @media (min-width: ${breakpoints.md}) {
      font-size: 5.5rem;
    }
  }
`;
