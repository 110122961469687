import styled from 'styled-components';
import { List as ListMUI } from '@mui/material';
import { colors } from '../../../theme/styles';

export const List = styled(ListMUI)`
  color: ${colors.backgroundWhite};

  && {
    padding: 0 1rem;
  }

  svg {
    color: ${colors.backgroundWhite};
  }
`;

export const ContainerUser = styled.div`
  padding: 1rem 0;
`;
