import styled from 'styled-components';
import { colors } from '../../theme/styles';
import { Grid } from '@mui/material';

export const FilterTitle = styled.div`
  margin-top: 1.2rem;
  margin-bottom: 0.5rem;
  color: ${colors.theme.primary};
  font-weight: 500;
`;

export const Filters = styled(Grid)`
  width: 100%;
`;
