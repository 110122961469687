import { Button, Grid } from '@mui/material';
import styled from 'styled-components';
import { colors, metrics } from '../../theme/styles';

export const ContainerButtonUpload = styled(Grid)`
  text-align: left;
  width: 100%;

  .MuiFormLabel-root {
    margin-top: 0.8rem;
    font-weight: 500;
  }
`;

export const ButtonUpload = styled(Button)`
  && {
    border: 0.13rem dashed ${colors.theme.primary};
    padding: 0.5rem;
    margin-top: 0.3rem;
    min-width: ${metrics.buttonUpload.width};
  }
`;

export const FileUploadDescription = styled.p`
  && {
    align-self: center;
    color: ${colors.gray};
    text-overflow: ellipsis;
    width: 100%;
  }
`;
