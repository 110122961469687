import { Link, Typography } from '@mui/material';
import { Container } from './styled';

/**
 * Texto acima do página com a rota até a aquela página
 *
 * Props esperadas:
 * [breadcrumbs]: array de objetos - lista com titulos e links
 *      [title]: string - título
 *      [functionCoordinator]: function - Função do arquivo coordinator.js
 *      para navegar entre as páginas
 *
 * [navigate]: function - hook de navegação do react-router-dom
 *
 * @param {*} param
 * @returns
 */
export function Breadcrumbs({ breadcrumbs }) {
  const content = () => {
    if (!(breadcrumbs && breadcrumbs.length)) {
      return null;
    }

    return breadcrumbs.map((b) => {
      if (!b.functionCoordinator) {
        return <Typography key={b.title}>{b.title}</Typography>;
      }

      return (
        <Link
          key={b.title}
          underline="hover"
          color="inherit"
          onClick={() => b.functionCoordinator()}
        >
          {b.title}
        </Link>
      );
    });
  };

  return <Container>{content()}</Container>;
}
