import styled from 'styled-components';
import { colors } from '../../theme/styles';

export const Target = styled.span`
  color: ${({ color }) => color || colors.black};
  background-color: ${({ backgroundColor, variant }) =>
    variant === 'solid' ? backgroundColor : ''};
  display: flex;
  align-items: center;
  justify-content: ${({ variant }) => (variant === 'solid' ? 'center' : 'left')};
  padding: ${({ padding }) => padding || '0.5rem'};
  border-radius: 10px;

  svg {
    width: 0.6rem;
    height: 0.6rem;
    margin-right: 0.5rem;
  }
`;
