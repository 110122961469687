import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { Checkbox, Grid, MenuItem, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AlertMessage, EmptyTable, FilterContainer, TitlePages } from '../../../../components';
import { FilterButton } from '../../../../components/FilterButton';
import { BasePage } from '../../../../components/BaseMain';
import { useForm } from '../../../../hooks/useForm';
import { clearAlertMessage, hasPermission, isDesktop, onChangeDate } from '../../../../utils';
import { Order } from '../../../../utils/enums';
import { DesktopTable } from './TablesComponents/DesktopTable';
import { MobileTable } from './TablesComponents/MobileTable';
import { GENERIC_ERROR, INVALID_DATE } from '../../../../utils/constantes';
import {
  listCompanies,
  listSegments,
  listContractsWithPendingValuesPaginate
} from '../../../../services';
import { ContainerCheckbox, Table } from './styled';

export function WithVariableValuesPage() {
  const navigate = useNavigate();
  const breadcrumbs = [{ title: 'Contratos com valores variáveis' }];
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(5);
  const [order, setOrder] = useState(Order.DESC);
  const [orderBy, setOrderBy] = useState('');

  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  const [filters, onChange, clearForm] = useForm({
    id: '',
    uuid_segment: '',
    uuid_provider: '',
    init_date: null
  });
  const [debouncedFilter] = useDebounce(filters, 500);
  const [contracts, setContracts] = useState([]);
  const [segments, setSegments] = useState([]);
  const [providers, setProviders] = useState([]);
  const [showOnlyPending, setShowOnlyPending] = useState(false);

  const listContracts = useCallback(async () => {
    try {
      setLoading(true);

      const filter = handleFilter();
      const result = await listContractsWithPendingValuesPaginate(page, offset, order, filter);

      setContracts(result.data);
      setCount(result.count);

      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  }, [page, offset, order, debouncedFilter, showOnlyPending]);

  const init = useCallback(async () => {
    try {
      setLoading(true);

      const [contractsResult, segmentsResult, providerResult] = await Promise.all([
        listContractsWithPendingValuesPaginate(page, offset, order, filters),
        listSegments(),
        listCompanies()
      ]);

      setContracts(contractsResult.data);
      setCount(contractsResult.count);
      setSegments(segmentsResult);
      setProviders(providerResult);

      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  }, []);

  useEffect(() => init(), []);
  useEffect(() => listContracts(), [page, offset, order, debouncedFilter, showOnlyPending]);

  const handleFilter = () => {
    const initDateFormat = filters.init_date?.format('YYYY-MM-DD');

    const handleFitler = {
      ...filters,
      order_by: orderBy,
      show_only_pending: showOnlyPending,
      init_date: initDateFormat === INVALID_DATE ? undefined : initDateFormat
    };

    return handleFitler;
  };

  const handlePage = (_event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setOffset(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleOrder = (field) => {
    setOrderBy(field);

    if (order === Order.ASC) {
      setOrder(Order.DESC);
      return;
    }

    setOrder(Order.ASC);
  };

  function TableResponsive() {
    if (!contracts?.length && !loading) {
      return <EmptyTable />;
    }

    if (isDesktop()) {
      return (
        <DesktopTable
          contracts={contracts}
          loading={loading}
          count={count}
          page={page}
          offset={offset}
          order={order}
          orderBy={orderBy}
          handlePage={handlePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleOrder={handleOrder}
        />
      );
    }

    return (
      <MobileTable
        contracts={contracts}
        loading={loading}
        count={count}
        page={page}
        offset={offset}
        setPage={setPage}
        setOffset={setOffset}
        handlePage={handlePage}
      />
    );
  }

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages
        title="Lista de contratos com valores variáveis"
        description="Lista de todos os contratos que tem os valores adicionados mensalmente."
        toHideDivider={!hasPermission('LIST_CONTRACTS_WITH_PENDING_VALUES')}
      />

      <Grid container>
        <AlertMessage
          message={alertMessage.message}
          severity={alertMessage.severity}
          title={alertMessage.title}
          onClose={() => clearAlertMessage(setAlertMessage)}
        />
      </Grid>

      <FilterContainer>
        <Grid item xs={12} lg={4}>
          <TextField label="Id do contrato" name="id" value={filters?.id} onChange={onChange} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            select
            label="Segmento"
            name="uuid_segment"
            value={filters?.uuid_segment}
            onChange={onChange}
          >
            {segments?.map((segment) => (
              <MenuItem key={segment.uuid} value={segment.uuid}>
                {segment?.title?.toTitleCase()}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            select
            label="Fornecedor"
            name="uuid_provider"
            value={filters.uuid_provider}
            onChange={onChange}
          >
            {providers?.map((provader) => (
              <MenuItem key={provader.uuid} value={provader.uuid}>
                {provader?.name?.toTitleCase()}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} lg={3}>
          <DatePicker
            label="Data de início"
            name="init_date"
            value={filters.init_date}
            onChange={(e) => onChangeDate(e, 'init_date', onChange)}
          />
        </Grid>
        <Grid item xs={12} lg={5}>
          <ContainerCheckbox
            control={
              <Checkbox
                checked={showOnlyPending}
                onChange={() => setShowOnlyPending(!showOnlyPending)}
              />
            }
            label="Mostrar apenas contratos com valores não definidos"
          />
        </Grid>
        <Grid item xs={12} lg={1}>
          <FilterButton onClick={clearForm} />
        </Grid>
      </FilterContainer>

      <Table>
        <TableResponsive />
      </Table>
    </BasePage>
  );
}
