import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { colors } from '../../theme/styles';
import { Content, Item, Title } from './styled';

export function RulesPassword({ value }) {
  // Ao menos uma letra minúscula
  const atLeastOneLowercaseLetter = () => {
    const regex = /[a-z]/;
    return regex.test(value);
  };

  // Ao menos uma letra maiúscula
  const atLeastOneUppercaseLetter = () => {
    const regex = /[A-Z]/;
    return regex.test(value);
  };

  // Ao menos um caracter especial
  const atLeastOneSpecialCharacter = () => {
    const regex = /[^a-zA-Z0-9]/;
    return regex.test(value);
  };

  // Ao menos um número
  const atLeastOneNumber = () => {
    const regex = /[0-9]/;
    return regex.test(value);
  };

  // Ter no mínimo 8 caracteres
  const atLeast8Characters = () => {
    const regex = /.{8,}/;
    return regex.test(value);
  };

  const handleIcon = (rule) => {
    return rule() ? (
      <CheckCircleIcon color="primary" className="circle-check" />
    ) : (
      <CircleIcon sx={{ color: colors.mediumBlack }} />
    );
  };

  return (
    <Content>
      <Title>Sua senha deve ter:</Title>
      <div>
        <Item>
          {handleIcon(atLeast8Characters)}
          <span> Ter no mínimo ter 8 caracteres </span>
        </Item>
        <Item>
          {handleIcon(atLeastOneLowercaseLetter)}
          <span> Ao menos uma letra minúscula </span>
        </Item>
        <Item>
          {handleIcon(atLeastOneUppercaseLetter)}
          <span> Ao menos uma letra maiúscula </span>
        </Item>
        <Item>
          {handleIcon(atLeastOneSpecialCharacter)}
          <span> Ao menos um caractere especial </span>
        </Item>
        <Item>
          {handleIcon(atLeastOneNumber)}
          <span> Ao menos um número </span>
        </Item>
      </div>
    </Content>
  );
}
