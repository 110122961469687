import { useState } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Grid, Icon, TextField } from '@mui/material';
import { Title, Content, Subtitle, Form, Button, ButtonGroup } from './styled';
import { BasePage } from '../../../components/BaseCredentials';
import { ReactComponent as ForgotPasswordImage } from '../../../assets/forgotPasswordImage.svg';
import { metrics } from '../../../theme/styles';
import { useForm } from '../../../hooks/useForm';
import { validateEmail } from '../../../utils/validates';
import { useNavigate } from 'react-router-dom';
import { goToLogin } from '../../../router/coordinator';
import { AlertMessage } from '../../../components/AlertMessage';
import { sendEmailRecoverPassword } from '../../../services/credentialsService';
import { Loading } from '../../../components';
import { GENERIC_ERROR } from '../../../utils/constantes';

export function ForgotPassword() {
  const navigate = useNavigate();
  const [form, onChange] = useForm({ email: '' });
  const [loading, setLoading] = useState(false);
  const [errorsValidation, setErrorsValidation] = useState({ email: false });
  const [message, setMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  const onSubmitForm = async (event) => {
    event.preventDefault();
    setLoading(true);

    await recoverPassword();
  };

  const recoverPassword = async () => {
    try {
      await sendEmailRecoverPassword(form.email);

      setMessage((prev) => ({
        ...prev,
        title: `E-mail enviado, por favor verifique a caixa de entrada de ${form.email}`,
        severity: 'success'
      }));
      setLoading(false);
    } catch (err) {
      setMessage({
        title: err.message || GENERIC_ERROR,
        message: err.trace && `Código: ${err.trace}`
      });
      setLoading(false);
    }
  };

  // Valida campo de e-mail
  const validateInputEmail = () => {
    const { email } = form;
    const isValid = validateEmail(email);
    setErrorsValidation((prevError) => ({ ...prevError, email: !isValid }));
  };

  const disabledButton = () => {
    if (!form.email) {
      return true;
    }
    if (errorsValidation.email) {
      return true;
    }

    return false;
  };

  return (
    <BasePage ImageReceived={ForgotPasswordImage}>
      <Loading loading={loading} text={'Enviando...'} light={false} />
      <Content className="center">
        <Icon component={AccountCircleIcon} sx={{ fontSize: metrics.sizeIconCredential }} />
        <Title>Recuperação de senha</Title>
        <Subtitle>
          <p>Lhe enviaremos um e-mail com mais informações sobre como redefinir sua senha</p>
        </Subtitle>

        <Form container>
          <AlertMessage
            title={message.title}
            message={message.message}
            severity={message.severity}
          />

          <Grid item xs={12}>
            <TextField
              label="E-mail"
              type={'email'}
              name={'email'}
              placeholder={'usuario@empresa.com'}
              value={form.name}
              onChange={onChange}
              onBlur={() => validateInputEmail()}
              error={errorsValidation.email}
              helperText={errorsValidation.email && 'E-mail inválido'}
            />
          </Grid>

          <ButtonGroup>
            <Button type="submit" onClick={onSubmitForm} disabled={disabledButton()}>
              Enviar para o e-mail
            </Button>
            <Button variant="outlined" onClick={() => goToLogin(navigate)}>
              Voltar
            </Button>
          </ButtonGroup>
        </Form>
      </Content>
    </BasePage>
  );
}
